import React, { useState, useEffect } from 'react'
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import Rating from '@mui/material/Rating';
// import StarIcon from '@mui/icons-material/Star';
import {
    Box, Switch, Grid, Button, TextField, Tab, Tabs, Typography, styled, useTheme, Drawer, Card, CssBaseline, IconButton, Avatar, InputBase, Menu,
    AppBar as MuiAppBar, Toolbar, Divider, ListItemIcon, ToggleButtonGroup, MenuItem, Paper,
    AccountCircle as AccountCircleIcon, Collections as CollectionsIcon, Undo as UndoIcon, Redo as RedoIcon, Remove as RemoveIcon, Add as AddIcon, Search as SearchIcon, Delete as DeleteIcon,
    Image as ImageIcon, DateRange as DateRangeIcon, AttachEmail as AttachEmailIcon, LocalPhone as LocalPhoneIcon, Checklist as ChecklistIcon, TextFields as TextFieldsIcon, ChangeHistoryTwoTone as ChangeHistoryTwoToneIcon, ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon, ContentCopy as ContentCopyIcon,
    Home as HomeIcon, Preview as PreviewIcon, Reorder as ReorderIcon, Download as DownloadIcon, Circle as CircleIcon, Rectangle as RectangleIcon, EggTwoTone, Star as StarIcon, Logout, Settings, FormatColorFill
    // } from '../../../utils/AllImportsHelper';
} from '../../lib/utils/AllImportsHelper';
import QuestionCanvas from './QuestionCanvas';
import QuetionSettings from './questionSettings/QuetionSettings';
import QuestionContext from './QuestionContext';
// import DateRangeIcon from '@mui/icons-material/DateRange';
// import AttachEmailIcon from '@mui/icons-material/AttachEmail';
// import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
// import ChecklistIcon from '@mui/icons-material/Checklist';

const QuestionPage = ({ selectedItems, ...props }) => {

    const [selectedItem, setSelectedItem] = useState('');

    useEffect(() => {
        setSelectedItem(selectedItems);
        // console.log("Switch",setSelectedItem(selectedItems));
    }, [selectedItems]);
    const [age, setAge] = React.useState('');

    const [checked, setChecked] = React.useState(true);
    const handleRequiredChange = (event) => {
        setChecked(event.target.checked);
        if (event.target.checked) {
            console.log("Switch is on");
        } else {
            console.log("Switch is off");
        }
    };

    const [check, setCheck] = React.useState(true);



    const [valueDescription, setValueDescription] = useState('');


    const [isEditing, setIsEditing] = useState(false);
    const [question, setQuestion] = useState('Write a question here and start a survey?');

    const handleBoxClick = () => {
        setIsEditing(true);
    };

    const handleTextFieldChange = (event) => {
        setQuestion(event.target.value);
    };

    const handleTextFieldBlur = () => {
        setIsEditing(false);
    };

    const [surveyCount, setSurveyCount] = useState(2);


    const handleDeleteSurvey = (index) => {
        if (surveyCount > 1) {
            setSurveyCount(prevCount => prevCount - 1);
        }
    };


    const [value, setValue] = React.useState(2);

    const questionContextData = {
        ...props,
    }

    return (
        <QuestionContext.Provider value={questionContextData}>

            <Box>
                <Box sx={{ display: 'flex', width: '100%' }}>
                    <Grid container spacing={2} sx={{ width: 'inherit !important' }}>
                        <Grid item xs={12} sm={6} md={8} lg={8}>
                            <Card sx={{ borderRadius: '1rem !important' }}>
                                <Box sx={{ backgroundColor: '#E1EDFC', p: 1.5 }}>
                                    <Typography variant="subtitle1" fontWeight="bold">
                                        Questions
                                    </Typography>
                                </Box>
                                <Box sx={{ height: 'calc(100vh - 122px)', marginBottom: "5rem", overflow: 'auto' }}>
                                    <QuestionCanvas handleBoxClick={handleBoxClick} isEditing={isEditing}
                                        question={question} handleTextFieldChange={handleTextFieldChange}
                                        handleTextFieldBlur={handleTextFieldBlur} valueDescription={valueDescription}
                                        selectedItem={selectedItem} check={check} checked={checked} handleDeleteSurvey={handleDeleteSurvey}
                                        value={value}
                                    />
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={4}>
                            <Card sx={{ borderRadius: '1rem !important', height: 'calc(100% - 64px)}', overflow: 'auto' }}>
                                <QuetionSettings selectedItem={selectedItem} />
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Box >

        </QuestionContext.Provider>
    )
}

export default QuestionPage
import React, { useState } from 'react';
import { Box, Switch, TextField, Typography, Grid } from '@mui/material';

const ShortText = () => {
  const [isSwitchOn, setIsSwitchOn] = useState(false);

  const handleSwitchChange = (event) => {
    setIsSwitchOn(event.target.checked);
  };

  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  return (
    <Box sx={{p: 2, my: 1}}>
      <Box>
        <Typography variant="subtitle2" fontWeight="bold">
          Setting
        </Typography>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={8}>
            <Typography variant="subtitle1">Max Characters</Typography>
          </Grid>
          <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Box>
              <Switch {...label} checked={isSwitchOn} onChange={handleSwitchChange} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {isSwitchOn && (
        <Box>
          <TextField
            id="input-field"
            label="0-9999"
            type="number"
            fullWidth
          />
        </Box>
      )}
    </Box>
  );
};

export default ShortText;

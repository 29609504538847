import React, { useContext } from "react";
// import { AnswerContext } from "../../../_services/FromType/AnswerContext";
// import { QuestionContext } from "../../../_services/FromType/QuestionContext";
// import TextField from '@material-ui/core/TextField';

function PaymentQues(props) {
  // const number = useContext(QuestionContext);

  return (
    <div className="">
      <h2>Payment</h2>
    </div>
  );
}

export default PaymentQues

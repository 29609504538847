import React, { useEffect, useState } from 'react';
import {
    Box,
} from "../../../lib/utils/AllImportsHelper";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';

import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";



const iconsData = {
    "right-align": <FormatAlignRightIcon />,
    "left-align": <FormatAlignLeftIcon />,
    "center-align": <FormatAlignCenterIcon />,
    "justify-align": <FormatAlignJustifyIcon />,
}

function TextAlignmentPalette(props) {

    // console.debug("TextAlignmentPalette : props : ", props);

    const [selectedId, setSelectedId] = useState(null);
    const [data, setData] = useState([]);
    useEffect(function () {
        setData(props?.itemData);
    }, [props?.itemData]);


    useEffect(() => {
        if (props?.defaultSelectedId)
            setSelectedId(props.defaultSelectedId);
    }, [props?.defaultSelectedId]);

    const handleListItemClick = (e, d) => {
        // console.debug("TextAlignmentPalette : handleListItemClick : item : ", d);
        setSelectedId(d.id);
        if (d) {
            if (props?.changeHandler) {
                props.changeHandler(d);
            }
        }

    }

    return (
        <Box
            sx={{ display: "flex" }}
        >
            <List
                sx={{ display: "flex", padding: 0 }}
            >
                {data?.map((d, i) => {
                    return (
                        <ListItemButton
                            key={i}
                            sx={{
                                padding: "4px",
                                backgroundColor: selectedId === d.id ? '#1976d2  !important' : 'inherit',
                                borderRadius: selectedId === d.id ? '5px' : 'inherit',
                            }}
                            selected={selectedId === d.id}
                            onClick={(event) => handleListItemClick(event, d)}
                        >
                            <ListItemText sx={{ margin: '0px !important' }} primary={
                                <Box sx={{ display: "flex", mt: 1, gap: "5px" }}>
                                    <Box >{iconsData[d?.sectionProperty?.identifier]}</Box>
                                </Box>
                            }
                            />
                        </ListItemButton>
                    )
                })}
            </List>
        </Box>
    );
}

export default TextAlignmentPalette;
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useContext } from 'react';
import CanvasContext from './CanvasContext';
import { AddBox, ChangeHistoryTwoTone, ChevronLeft, ChevronRight, ChevronRightOutlined, Drafts, Inbox } from '@mui/icons-material';
import { Avatar, Box, CssBaseline, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Tab, Tabs, Typography, styled } from '@mui/material';

const CanvasTab = (props) => {
    const canvasContextData = useContext(CanvasContext);
    // console.debug("Canvaslayout : CanvasTab : canvasContextData : ", canvasContextData);

    const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
        ({ theme, open }) => ({
            flexGrow: 1,
            padding: theme.spacing(3),
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: `-${canvasContextData?.drawerWidth}px`,
            ...(open && {
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                marginLeft: 0,
            }),
        }),
    );


    return (
        <Box sx={{ display: 'flex' }}>
            <Box
                sx={{ flexGrow: 1, bgcolor: '#EEF6FF', display: 'flex', position: 'fixed', top: '64px', width: '100%' }}
            >
                <List sx={{ backgroundColor: 'white', p: '0px !Important' }}>
                    {canvasContextData?.tabs.map((t, i) => {
                        // console.debug("Canvaslayout : CanvasTab : canvasContextData : tab : ", t);
                        return (
                            <ListItemButton
                                key={i}
                                className='listButtonBase'
                                component="a"
                                href={t.path}
                                underline="none"
                                selected={t?.path === window?.location?.pathname}
                                onClick={(e) => {
                                }}
                                sx={{
                                    '&:hover': {
                                        backgroundColor: '#f0f0f0',
                                    },
                                    display: 'flex',
                                    alignItems: 'center', // Align vertically centered
                                    flexDirection: 'column', // Align icon and text vertically
                                    textAlign: 'center', // Center align text horizontally
                                    textDecoration: 'none',
                                    width: '100%',
                                    zIndex: 1,

                                    '&.listButtonBase:hover': {
                                        backgroundColor: '#EAF3FF !important'
                                    },
                                    '&.Mui-selected': {
                                        backgroundColor: '#DBEAFE',
                                        '&.MuiListItemButton-root:hover': {
                                            backgroundColor: '#E1EDFC !important',
                                        },
                                        color: "#1976d2",
                                        borderRight: "3px solid #1976d2",
                                    },
                                }}
                            >
                                <ListItemIcon sx={{
                                    minWidth: 'auto',
                                    color: t?.path === window?.location?.pathname ? "#1976d2" : 'inherit',
                                }}
                                >
                                    {t.icon ? t.icon : <ChangeHistoryTwoTone />}
                                </ListItemIcon>
                                <ListItemText primary={t.name} sx={{ textAlign: 'center' }} />
                            </ListItemButton>
                        );
                    })}
                </List>

                {props.children}

            </Box>
        </Box >
    );
};
export default CanvasTab;


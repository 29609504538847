import React, { useContext, useEffect, useState } from 'react';
import PublishIcon from '@mui/icons-material/Publish';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import EditIcon from '@mui/icons-material/Edit';
import {
    Box, Button, TextField, Typography, styled, IconButton, Menu, AppBar as MuiAppBar, Toolbar, Divider, ListItemIcon, MenuItem, Tooltip, tooltipClasses, Avatar,
    Skeleton,
    Drawer
} from '@mui/material';
import CanvasContext from './CanvasContext';
import { Link } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CollectionsIcon from '@mui/icons-material/Collections';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import PreviewIcon from '@mui/icons-material/Preview';
import ApiService from '../../../ApiService';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { updateSurvey, addArrayOfQuestionsInSurveyQuestionList } from '../../../redux/slice/surveyQuetions/surveyQuestionSlice';
import { EnahanceUpdateActionButton as LoadingButton } from '../../../lib';

import ShareDrawer from '../../../component/question/ShareDrawer';

const drawerWidth = 382;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#347edd',
        color: '#ffffff',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: '#347edd',
    },
}));

function CanvasHeader(props) {
    let { id } = useParams();
    const dispatch = useDispatch();
    const surveyQuestion = useSelector(state => state.surveyQuestion);

    const canvasContextData = useContext(CanvasContext);

    const [editable, setEditable] = useState(false);
    const [publishLoading, setPublishLoading] = useState(false);
    const [publishBtnName, setPublishBtnName] = useState("publish");
    const [publish, setPublish] = useState();
    const [canvasHeaderValue, setCanvasHeaderValue] = useState(canvasContextData.canvasHeaderTitle || '');

    const testFieldEditClickHandler = () => {
        setEditable(true);
    };

    useEffect(() => {
        setPublish(Number(surveyQuestion?.survey?.isPublished));
        if (Number(surveyQuestion?.survey?.isPublished)) {
            setPublishBtnName('unPublish');
        } else {
            setPublishBtnName('publish');
        }
    }, [surveyQuestion?.survey]);

    const canvasHeaderTitleChangeHandler = (e) => {
        setCanvasHeaderValue(e.target.value);
    };

    const textFieldBlurHandler = () => {
        setEditable(false);
        canvasContextData.canvasHeaderTitleChangeHeader(canvasHeaderValue);
    };

    const textFieldKeyDownHandler = (e) => {
        if (e.key === 'Enter') {
            textFieldBlurHandler();
        }
    };

    useEffect(() => {
        if (canvasContextData?.canvasHeaderTitle)
            setCanvasHeaderValue(canvasContextData.canvasHeaderTitle);
    }, [canvasContextData]);

    const truncateText = (text, charLimit) => {
        if (!text) return '';
        return text.length > charLimit ? `${text.slice(0, charLimit)}...` : text;
    };

    const isTruncated = (canvasHeaderValue || '').length > 15;
    const truncatedText = truncateText(canvasHeaderValue, 15);

    const fetchSurveyData = () => {
        console.debug('"SurveyEndUser : QuestionInputTypes : props"',"debug")
       
           
        if (id) {
            setPublishLoading(true);
           
            let currentPublishState = Number(surveyQuestion?.survey?.isPublished);

            let params = {};
            

            // Toggle the current publish state
            params.isPublished = currentPublishState ? 0 : 1;

            ApiService.put('surveyView', params, id).then(r => {
                console.debug("SurveyEndUser : fetchSurveyData : then : r : ", r);
                console.debug("SurveyEndUser : fetchSurveyData : then : surveyResponce : ", r.data);

                setPublishLoading(false);
                if ( r?.data?.isPublished  === '1'){
                    toggleDrawer();
                }
                
                if (r?.data) {
                    let surveyResponce = r.data;
                    if (surveyResponce) {
                        dispatch(updateSurvey(surveyResponce));
                        dispatch(addArrayOfQuestionsInSurveyQuestionList([...surveyResponce.surveyQuestion]));
                        

                    } else {
                        console.error("API response issue");
                    }
               
                }
            }).catch(e => {
                setPublishLoading(false);
                console.debug("SurveyCreate : fetchSurveyData : catch : e : ", e);
                // navigate to 404 page or show error message
            });
        } else {
            // navigate to 404 page
        }
    };
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    return (
        <AppBar sx={{ background: '#EEF6FF', boxShadow: 'none' }}>
            <Toolbar sx={{ width: '100%', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex' }}>
                    <Link to='/survey' className='MuiLink-underlineNone'>
                        <IconButton
                            color="primary"
                            aria-label="Home"
                            edge="start"
                            sx={{ mr: 2 }}
                        >
                            <KeyboardBackspaceIcon />
                        </IconButton>
                    </Link>
                    <Box sx={{ display: 'flex' }}>
                        <Box sx={{ p: 1 }}>
                            {editable ? (
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    value={canvasHeaderValue}
                                    onChange={canvasHeaderTitleChangeHandler}
                                    onBlur={textFieldBlurHandler}
                                    onKeyDown={textFieldKeyDownHandler}
                                    size="small"
                                    sx={{ bgcolor: 'transparent', '& .MuiOutlinedInput-root': { borderRadius: '0' } }}
                                />
                            ) : (
                                <LightTooltip title={canvasHeaderValue} disableHoverListener={!isTruncated} arrow>
                                    {canvasContextData.loadingSurvey ?
                                        <Skeleton height={30} width={200} /> : 
                                        <Typography variant="h7" component="div" color={'#000'}>
                                            {truncatedText}
                                        </Typography>}
                                </LightTooltip>
                            )}
                        </Box>
                        <Box sx={{ ml: 2 }}>
                            <IconButton
                                color="black"
                                aria-label="Edit"
                                edge="start"
                                sx={{ mr: 2 }}
                                fontSize="small"
                                onClick={testFieldEditClickHandler}
                            >
                                <EditIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex' }}>
                    <Button variant="outlined" size='medium' href={"/survey/preview/" + id} startIcon={<PreviewIcon />} sx={{ display: { xs: 'none', sm: 'inline-flex' } }}>PREVIEW</Button>
                    {/* <LoadingButton
                        value={Number(surveyQuestion?.survey?.isPublished)}
                        sx={{ mx: 1, display: { xs: 'none', sm: 'inline-flex' } }}
                        loading={publishLoading}
                        loadingPosition="start"
                        startIcon={<PublishIcon />}
                        variant="contained"
                        onClick={fetchSurveyData}
                        // onClick={onclickPublis}
                    >
                        <span>{publishBtnName}</span>
                    </LoadingButton> */}
                    <LoadingButton
                        // value={Number(surveyQuestion?.survey?.isPublished)}
                        sx={{ mx: 1, display: { xs: 'none', sm: 'inline-flex' } }}
                        updatingStatus={publishLoading}
                        // loadingPosition="start"
                        startIcon={<PublishIcon />}
                        variant="contained"
                        onClick={fetchSurveyData}
                        // onClick={onclickPublis}
                    >
                        <span>{publishBtnName}</span>
                    </LoadingButton>
                    <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer}>
                      <ShareDrawer onClose={toggleDrawer}/>
                    </Drawer>
                    <Box sx={{ display: { xs: 'flex', sm: 'none' }, alignItems: 'center' }}>
                        <PreviewIcon color='primary' />
                        <PublishIcon sx={{ mx: 1 }} color='primary' />
                    </Box>
                    {/* <IconButton sx={{ p: 0, ml: 3 }}
                        size="small"
                        aria-controls={canvasContextData?.openMenu ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={canvasContextData?.openMenu ? 'true' : undefined}
                    >
                        <Avatar alt="Remy Sharp" sx={{ width: 32, height: 32 }} />
                    </IconButton>
                    <Menu
                        anchorEl={canvasContextData?.anchorEl}
                        id="account-menu"
                        open={canvasContextData?.openMenu}
                        onClose={canvasContextData?.handleClose}
                        onClick={canvasContextData?.handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <Typography variant='body1' p={1.5} pl={2}>Welcome Alina,</Typography>
                        <Divider />
                        <MenuItem onClick={canvasContextData?.handleClose} sx={{ mt: 1 }}>
                            <ListItemIcon>
                                <CollectionsIcon fontSize="small" />
                            </ListItemIcon>
                            My Templates
                        </MenuItem>
                        <MenuItem onClick={canvasContextData?.handleClose}>
                            <ListItemIcon>
                                <Settings fontSize="small" />
                            </ListItemIcon>
                            Settings
                        </MenuItem>
                        <MenuItem onClick={canvasContextData?.handleClose}>
                            <ListItemIcon>
                                <AccountCircleIcon fontSize="small" />
                            </ListItemIcon>
                            Profile
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={canvasContextData?.handleClose}>
                            <ListItemIcon>
                                <Logout fontSize="small" />
                            </ListItemIcon>
                            Logout
                        </MenuItem>
                    </Menu> */}
                </Box>
            </Toolbar>
        </AppBar>
    );
}

export default CanvasHeader;

import { default as axios } from "axios";
import ApiError from "./ApiError";
import { getPublicAppToken,getPublicSelfToken } from 'auth-component';
// import { getAppToken, getSelfToken } from "../_helper/secureToken"
const BASE_URL = "https://gatewayapi.eela.tech/api";
// const BASE_URL = 'https://surveyapi.eela.tech/api';
// https://surveyapi.eela.tech/api/v1/survey?app=1234560&limit=10&page=1
function getAppToken() {
  // return "getAppToken";
  // console.debug("PublicApiService : getPublicAppToken : ",getPublicAppToken())
  return getPublicAppToken();
  // return "1234562";
}

function getSelfToken() {
  // return "getSelfToken";
  // console.debug("PublicApiService : getPublicSelfToken : ",getPublicSelfToken())
  return getPublicSelfToken();
  // return "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiMjZlMDllMTUzMmNiZTg2Mjc5ZWYzNDIzNjI0OTcyMTA5MjE2YWI1M2YyYzcxYmExMTcyNjU5NWVmNmU2MTJhMWIyYzFjNDI5ODcwYzk0YjUiLCJpYXQiOjE3MTI3NTc3MzYuNjcyMDM1LCJuYmYiOjE3MTI3NTc3MzYuNjcyMDQxLCJleHAiOjE3NDQyOTM3MzYuNjUzMTU1LCJzdWIiOiIxIiwic2NvcGVzIjpbXX0.fXQ_WVl6NvCtILq99G-glLSXrqdPCadDsO-CYwHevMly09JGlbBx0dnrvveH5Tzrq4iRxjUnGr4arFvI-BOQc9Z90dcEXVIB6J5l1UVyQXWEBJ_xf9ES0qzJyhX7WPOt0pZZYvVQPe5Ctd1mBz8ER5s_RzlhMnT3OaNyPmdBG1vquhDhwrxQRXRFlUT3CLwJoK-27C0P_tIXTiCMIAYvHwRopbNVO0s501FDlhqt1rKjbkwQj3RjHF6krqm_bcy-M5iBHMNQlxQlMJDlXkBhkalFnv6mAzk8wHkzGEeVEZgvA9_lGRJZv8M6MeHVTz9ITwLhCDdWkV54rak9qtWEteWNxJYlbCwhpOX21l6Yl_eokxSxKX8zbhWW1MNTwCJTEmKUv7fygMrUvLQWxSdFmWY4Pn2sV1_1yz-ZSUllP_tJWtQKNyb87cI5aSC8kpM2bRcjmH4aodRUQUJmVjQUDe6YXpug3sAA5w-6AsYXwd4_pm0pnswzmdm1spYo_rh_BclklsY1XJxwzR1qjHHmkmXZR4NJ8bmmrRL7YP1KCKsST-iHXNDBNfI3iikqm_unY2EofgJMcu7DZ5B3NL7oB1GucZ59YTNwC8Sqbvobh3zvz-CclH2r-Bi6WUNecANWhoiDIn18xc6319CkHPSauouhlKVI1sPEDXQT8pVTTQM";
}
class APIService {
  constructor(baseURL) {
    this.baseURL = baseURL || BASE_URL;
    this.api = axios.create({
      baseURL: this.baseURL,
    });

    // Define your endpoints here
    this.endpoints = {
      // --------- Survey ---------------///
      surveyList: "v1/survey",
      surveyCreate: "v1/survey",
      surveyView: "v1/survey",
      survey: "v1/survey",
      publish: "v1/survey",
      surveyDuplicate: "v1/survey/duplicate", // get with dataId
      surveySearch: "v1/survey-search",
      surveyRename: "v1/survey",
      surveyDelete: "v1/survey",
      surveyMultipleDelete: "v1/survey-multiple-delete",
      scheduleSurvey: "v1/schedule-report",
      scheduledReports: 'v1/scheduled-reports',
      downloadReports: 'v1/download-report-tracker',
      seoIndex:'v1/seo-open-graph',
      seoUpdateIndex:'v1/seo-open-graph',

      // --------- Survey ---------------///

      // ---------Question---------- ///
      questionCreate: "v1/question",
      duplicateQuestion: "v1/question/duplicate",
      deleteQuestion: "v1/question",
      updateQuestion: "v1/question",
      // updateOptionInMultiple: "v1/option-choice",
      updateOneOptionInMultipleChoice: "v1/option-choice",
      deleteOneOptionInMultipleChoice: "v1/option-choice",
      // addOptionInMultipleChoice: "v1/question-option-choice-sync",
      addOptionInMultipleChoice: "v1/question-option",

      // --------- Question Type ---------------///
      questionTypeList: "v1/question-type",
      questionTypeListCategory: "v1/question-type-category",

      // ---------Answer---------- ///
      surveyAnswer: "v1/survey-answers",

      // ---------GenAi---------- ///
      generateSurveyQuestion: "v1/survey-genai-response",
      surveyCreateGeneratedByAI: "v1/genai-question",

      //---------For Settings-------//
      surveyLayout: "v1/survey-layout-view",
      surveyLogoCreate: "v1/survey-logo",
      surveyPublishSetting: "v1/survey-section-properties",

      //---------For Answer Store-------//
      surveyAnswerStore: 'v1/answer-store',
      userInfo: "v1/userinfo",

      //---------For Attendee Form-------//
      attendeeFieldStatus: 'v1/attendee-field-status',
      surveyattendeeFormData: 'v1/survey-attendee',
      //-----------End-------------//

      //----------------Maturity Model-------------///
        maturityModel:'v1/maturity-model'
      //-----------End-------------//
    };
  }

  async get(endpointKey, params = {}, searchParam = null) {
    // console.debug("aaaaaaaa", { params, searchParam })
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + "/" + searchParam;
      }
      params.app = getAppToken();

      const response = await this.api.get(endpoint, {
        params,
        headers: { Authorization: "Bearer " + getSelfToken() },
      });
      return response;
    } catch (error) {
      if (error.response) {
        if (error.response.data && error.response.data) {
          throw new ApiError(error.response.data);
        }
        else {
          throw new ApiError(error.response);
        }
      } else {
        // console.error("PublicApiService :  error : ",error);
        throw new ApiError(error);
      }
      throw error;
      // throw new Error("API request failed:", error);
    }
  }

  async post(endpointKey, data = null, searchParam = null) {
    try {
      //console.debug(data)
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + "/" + searchParam;
      }
      // data.app = getAppToken();
      // console.debug('data', data);
      if (typeof data === 'object' && !(data instanceof FormData)) {
        // console.debug('This is a JSON object.');
        data.app = getAppToken();
      } else if (data instanceof FormData) {
        // console.debug('This is a FormData object.');
        data.append('app', getAppToken());
      } else {
        console.error('Unknown type.');
      }

      const response = await this.api.post(endpoint, data, {
        headers: { Authorization: "Bearer " + getSelfToken() },
      });
      return response;
    } catch (error) {
      if (error.response) {
        if (error.response.data && error.response.data) {
          throw new ApiError(error.response.data);
        }
        else {
          throw new ApiError(error.response);
        }
      } else {
        // console.error("SurveyEndUser : submitSurveyResponse : surveyAnswerStore : error : ", error);
        throw new ApiError(error);
      }
      throw error;
      // throw new Error("API request failed:", { error });
    }
  }

  async delete(endpointKey, params = {}, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + "/" + searchParam;
      }
      params.app = getAppToken();
      const response = await this.api.delete(endpoint, {
        params,
        headers: { Authorization: "Bearer " + getSelfToken() },
      });
      return response;
    } catch (error) {
      if (error.response) {
        if (error.response.data && error.response.data) {
          throw new ApiError(error.response.data);
        }
        else {
          throw new ApiError(error.response);
        }
      } else {
        throw new ApiError(error);
      }
      throw error;
      // throw new Error("API request failed:", error);
    }
  }

  async put(endpointKey, data = null, searchParam = null) {
    try {
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + "/" + searchParam;
      }
      data["app"] = getAppToken();
      const response = await this.api.put(endpoint, data, {
        headers: { Authorization: "Bearer " + getSelfToken() },
      });
      return response;
    } catch (error) {
      if (error.response) {
        if (error.response.data && error.response.data) {
          throw new ApiError(error.response.data);
        }
        else {
          throw new ApiError(error.response);
        }
      } else {
        throw new ApiError(error);
      }
      throw error;
      // throw new Error("API request failed:", { error });
    }
  }

  // Because some APIs are not in proper format we need to impliment another methods to handle
  // The API's which are not following conventions, by which other APIs are following,
  // are :- unsaveFavoriteFeeds,
  async postAdopter(endpointKey, data = null, searchParam = null) {
    try {
      //console.debug(data)
      let endpoint = this.endpoints[endpointKey];
      if (searchParam) {
        endpoint = endpoint + "/" + searchParam;
      }
      endpoint = endpoint + "?app=" + getAppToken();
      const response = await this.api.post(endpoint, data, {
        headers: { Authorization: "Bearer " + getSelfToken() },
      });
      return response;
    } catch (error) {
      if (error.response) {
        if (error.response.data && error.response.data) {
          throw new ApiError(error.response.data);
        }
        else {
          throw new ApiError(error.response);
        }
      } else {
        throw new ApiError(error);
      }
      throw error;
      // throw new Error("API request failed:", { error });
    }
  }
}

export default new APIService(BASE_URL);

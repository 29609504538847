// src/OptionsList.js
import React, { useState, useEffect } from 'react';
import { TextField, IconButton, Button, Box, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const MultipleChoiceEnduser = ({ optionsData, onAnswerSelect, values,...props } ) => {
  // console.debug("SurveyEndUser : submitSurveyResponse : MultipleChoiceEnduser : props : ",props);
  const [value, setValue] = useState(values); // Initialize with an empty string
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(optionsData || []);
  }, [optionsData]);

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    // console.debug("SurveyEndUser : submitSurveyResponse : MultipleChoiceEnduser : handleChange : ", event.target.value)
    // console.debug("SurveyEndUser : submitSurveyResponse : MultipleChoiceEnduser : handleChange : selectedValue ", selectedValue)
    const selectedObject = options.find(option => option.optionChoiceId == selectedValue);
    // console.debug("SurveyEndUser : submitSurveyResponse : MultipleChoiceEnduser : handleChange : options ", options)
    // console.debug("SurveyEndUser : submitSurveyResponse : MultipleChoiceEnduser : handleChange : selectedObject ", selectedObject)
    setValue(selectedValue);
    // onAnswerSelect(selectedValue);
    onAnswerSelect({
      optionChoiceId: selectedObject.optionChoiceId,
      optionChoiceName: selectedObject.optionChoiceName
  });
    // console.log(selectedValue)
  };
  const handleAddOption = () => {
    const newId = options.length ? options[options.length - 1].id + 1 : 1;
    setOptions([...options, { id: newId, text: `Option ${newId}` }]);
  };

  const handleDeleteOption = (id) => {
    setOptions(options.filter(option => option.id !== id));
  };

  // const handleEditOption = (e) => {
  //   setValue(e.target.value)
  //   console.log(setValue(e.target.value))
  //   // setOptions(options.map(option => option.id === id ? { ...option, text: newText } : option));
  // };

  const handleEditOption = (event) => {
    // setValue(optionId);
    // console.log(optionId);
    setValue(event.target.value);
  };
 

  return (
    // <Box>
    //   {options.map((option, index) => (
    //     <Box key={option.optionChoiceId} display="flex" alignItems="center" mb={2}>
    //       <Button
    //         startIcon={<Typography sx={{border:"1px solid #1976d2", px:1, fontSize:"12px !important", borderRadius:1}} >{index }</Typography>}
    //         variant={value === option.optionChoiceId ? 'contained' : 'outlined'}
    //         onClick={(e) => handleEditOption(e, option.optionChoiceId)}
    //         size="small"
    //         sx={{ marginRight: 1 }}
    //       >{option.optionChoiceName}</Button>

    //     </Box>
    //   ))}

    // </Box>
    <Box>
    <RadioGroup value={value} onChange={handleChange}>
      {options.map((option, index) => (
        <Box key={option.optionChoiceId} display="flex" alignItems="center">
          <FormControlLabel
            value={option.optionChoiceId}
            control={<Radio />}
            // label={
            //   <Typography sx={{ border: "1px solid #1976d2", px: 1, fontSize: "12px !important", borderRadius: 1 }}>
            //     {index}
            //   </Typography>
            // }
            // labelPlacement="start"
          />
          <Typography>{option.optionChoiceName}</Typography>
        </Box>
      ))}
    </RadioGroup>
  </Box>
  );
};

export default MultipleChoiceEnduser;

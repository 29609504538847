import React, { useEffect, useState } from 'react';
// import {
//   Table,
//   HelloComponent,
//   HelloComponentWithHook,
// } from './lib';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Routes,

} from "react-router-dom";
import AuthenticateRender from './component/auth/AuthenticateRender';
import Logout from './component/auth/Logout';
import PrivateComponent from './routesComponent/PrivateRoutesComponent';
// import store from './component/redux/NodeRedux/TabStore'; 
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import SecurityIcon from '@mui/icons-material/Security';
import PublicAuthenticateRender from './component/auth/PublicAuthenticateRender';
// import EndComponent from './pages/Survey/EndComponent';
import SurveyEndUser from './pages/Survey/SurveyEndUser';
import routes from './routesComponent/Routes';

function App() {
  const [component, setComponent] = useState([]);
  useEffect(() => {
    setComponent(PrivateComponent());
  }, []);

  const itemsList = [
    {
      id: "1", name: "Profile", icon: <RecentActorsIcon />, subList: [
        { id: "1", label: "Personal Details", path: "/abc" },
        { id: "1", label: "Contact  Details", path: "/xyz" },
        // { id: "1", label: "Official Details", path: officialDetailUrl.list },
        // { id: "1", label: "Family Details", path: familyDetailUrl.list },
        // { id: "1", label: "Education & Certifications", path: educationUrl.list },
        // { id: "1", label: "Employment Details", path: employementUrl.list },
        // { id: "1", label: "Bank  Details", path: bankUrl.list },
        // { id: "1", label: "Documents",  path: documentUrl.list },
      ]
    },
    {
      id: "2", name: "Security", icon: <SecurityIcon />, subList: [
        { id: "2", label: "Password", path: 'pqr' },
        // { id: "2", label: "Allowed IP Address", path: ipAddressUrl.list },
        // { id: "2", label: "Device Sign-ins", path: deviceSignInUrl.list },


      ]
    },
  ];

  const logoInfo = {
    logo: "https://gatewayapi.eela.tech/media/THP1234560/companyLogo?file=thpLogoN.png",
    logoAltText: "Vikram"
  }

  const loggedInUserData = {
    fName: "Vikram",
    mName: "pratap",
    lName: "Singh",
    uAvatar: "https://gatewayapi.eela.tech/media/THP1234560/companyLogo?file=thpLogoN.png",
  }

  return (
    <Router>

      <Routes>
        {component.map((u, i) =>
          <Route key={i} exact={true} path={u.path}
            element={
              <AuthenticateRender >
                {u.page}
              </AuthenticateRender>
            }
          >
          </Route>
        )}
        <Route exact={true} path={routes.surveyEndUser+":id"}
            element={
              <PublicAuthenticateRender>
                  <SurveyEndUser/>
              </PublicAuthenticateRender>
            }
          >
        </Route>
        <Route exact={true} path={routes.surveyYourOpinion+":id"}
            element={
              <PublicAuthenticateRender>
                  <SurveyEndUser/>
              </PublicAuthenticateRender>
            }
          >
        </Route>
        <Route path="/logout" element={<Logout />} />


      </Routes>
    </Router>
  )
  // return (
  //   <TinggitThemeProvider
  //     sidebarMenuItems={itemsList}
  //     debug={false}
  //     logoInfo={logoInfo}
  //     loggedInUserData={loggedInUserData}
  //   >
  //     {/* <Table */}
  //   </TinggitThemeProvider>
  // );
}

export default App;

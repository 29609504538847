import React, { useContext, useState } from 'react';
import { Box, Checkbox, Divider, Grid, Button, IconButton, Card, TextField, styled, Tooltip, Menu, MenuItem, tooltipClasses, List, ListItem, ListItemButton, ListItemText, ListItemIcon, Typography, Skeleton } from '@mui/material';
// import { styled } from '@mui/system';
import StartIcon from '@mui/icons-material/Start';
import EastIcon from '@mui/icons-material/East';
import RefreshIcon from '@mui/icons-material/Refresh';
import GenAIContext from './GenAIContext';

function SourceQuestionBody(props) {

    const genAiContextData = useContext(GenAIContext);

    const skeletonItem = Array(8).fill(null);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', }}>
            {genAiContextData?.sourceItemListLoadingStatus ?
                <List
                    sx={{ width: '100%', bgcolor: 'background.paper', py: '0px !important', height: 'calc(100vh - 200px)', overflow: 'auto' }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >
                    {skeletonItem.map((_, index) => (
                        <ListItem key={index} className='listButtonBase'>
                            <Skeleton variant="rounded" width="100%" height={60} />
                        </ListItem>
                    ))}

                </List>
                : ""}


            <List
                sx={{ width: '100%', bgcolor: 'background.paper', py: '0px !important', height: 'calc(100vh - 200px)', overflow: 'auto' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                {genAiContextData?.sourceItemList?.map((itemObj) => {
                    console.debug("a9", itemObj);
                    if (itemObj?.childQuestions) {
                        return (
                            itemObj?.childQuestions.map(item =>
                            (

                                <ListItemButton
                                    className='listButtonBase'
                                    sx={{
                                        '&.listButtonBase:hover': {
                                            backgroundColor: '#EAF3FF !important',
                                            '& .more-vert-button': {
                                                opacity: 1,
                                            }
                                        },
                                        '&.Mui-selected': {
                                            backgroundColor: '#DBEAFE',
                                            '&.MuiListItemButton-root:hover': {
                                                backgroundColor: '#E1EDFC !important',
                                            }
                                        },
                                        position: 'relative',
                                        '& .MuiListItemIcon-root': {
                                            // No opacity transition for the checkbox icon
                                        },
                                        '& .more-vert-button': {
                                            opacity: 0,
                                            transition: 'opacity 0.2s',
                                        },
                                    }}>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            // checked={genAiContextData?.selectedSourceRows?.has(item.id)}
                                            // checked={genAiContextData?.selectedSourceRows?.has(item)}
                                            checked={genAiContextData?.selectedSourceRows?.has(JSON.stringify(item))}
                                            tabIndex={-1}
                                            disableRipple
                                            onClick={genAiContextData?.sourceRowCheckboxSelectionHandler ? (e) => genAiContextData?.sourceRowCheckboxSelectionHandler(item.id, item, itemObj, e) : undefined}
                                        />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Box>
                                            <Typography variant="subtitle2">{item.id ? ("Q." + item.id) : ""} {item.question}</Typography>
                                        </Box>
                                        <Box>
                                            {/* <Typography variant="subtitle2">{itemObj?.question}</Typography> */}
                                            <ListItemText secondary={itemObj?.question} />
                                        </Box>

                                    </ListItemText>
                                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            startIcon={<EastIcon />}
                                            className="more-vert-button"
                                            onClick={(e) => genAiContextData?.handleMoveClickedItemFromSourceToDestination(e, item, itemObj)}

                                        >
                                        </Button>
                                    </Box>
                                </ListItemButton>
                            )
                            )
                        )
                    } else {
                        return "";
                    }
                    return "hi";
                    return (
                        itemObj?.childQuestions.map(item =>
                        (

                            <ListItemButton
                                className='listButtonBase'
                                sx={{
                                    '&.listButtonBase:hover': {
                                        backgroundColor: '#EAF3FF !important',
                                        '& .MuiListItemIcon-root, .more-vert-button': {
                                            opacity: 1,
                                        }
                                    },
                                    '&.Mui-selected': {
                                        backgroundColor: '#DBEAFE',
                                        '&.MuiListItemButton-root:hover': {
                                            backgroundColor: '#E1EDFC !important',
                                        }
                                    },
                                    position: 'relative',
                                    '& .MuiListItemIcon-root, .more-vert-button': {
                                        // opacity: 0,
                                        transition: 'opacity 0.2s',
                                    },
                                }}>
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        // checked={genAiContextData?.selectedSourceRows?.has(item.id)}
                                        checked={genAiContextData?.selectedSourceRows?.has(item)}
                                        tabIndex={-1}
                                        disableRipple
                                        onClick={genAiContextData?.handleRowSelect ? (e) => genAiContextData?.handleRowSelect(item.id, item, e) : undefined}
                                    />
                                </ListItemIcon>
                                <ListItemText>
                                    <Box>
                                        <Typography variant="subtitle2">{item.id ? ("Q." + item.id) : ""} {item.question}</Typography>
                                    </Box>

                                </ListItemText>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        startIcon={<StartIcon />}
                                        className="more-vert-button"
                                        onClick={(e) => genAiContextData?.handleMoveClickedItemFromSourceToDestination(e, item)}

                                    >
                                    </Button>
                                </Box>
                            </ListItemButton>
                        )
                        )
                    )
                })
                }
            </List>
        </Box>
    );
}

export default SourceQuestionBody;
function ApiError(response) {
    // console.debug("ApiError : response : ", response);

    if (!response || !response.errors || !Array.isArray(response.errors) || response.errors.length === 0) {
      throw new Error("Invalid API error response format");
    }
  
    const firstError = response.errors[0];

    if(firstError.detail=='The request does not contain an valid email '){
        firstError.detail="Your account is not register with us, follow this link to set up your account <a href='https://tinggit.com/contact' target='_blank'>https://tinggit.com/contact</a>"
    }
    this.code = firstError.code;
    this.status = firstError.status;
    this.title = firstError.title;
    this.detail = firstError.detail;
  
    // Set the error message
    // this.message = `${this.title} (${this.status}): ${this.detail}`;
    this.message = this.detail;
  
    // Include the full response for debugging (optional)
    this.response = response;
  
    // Include a stack trace for better debugging (optional)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }
  }
  
  ApiError.prototype = Object.create(Error.prototype);
  ApiError.prototype.constructor = ApiError;
  
export default ApiError;

// Note:-
// The Error Object
// JavaScript has a built in error object that provides error information when an error occurs.
// The error object provides two useful properties: name and message.
// Error Object Properties
// name	Sets or returns an error name
// message	Sets or returns an error message (a string)

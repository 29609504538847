import React, { useState, useEffect, useCallback } from 'react';
import {
  ReactFlow,
  useNodesState,
  useEdgesState,
  addEdge,
  Controls,
  MarkerType,
  Position
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import ColorSelectorNode from './ColorSelectorNode';
import { Box, Button, Drawer } from '@mui/material';
import ApiService from '../../ApiService';
import dagre from 'dagre'; // Import Dagre for layout
import QuestionTypeIcon from '../../component/question/questionSettings/questionType/QuestionTypeIcon';
import { questionTypeConst } from '../../constants/questionTypeConst';
import NodeUpdateDrawer from '../../component/nodeFolder/nodeDrawer/NodeUpdateDrawer';

const initBgColor = '#ededed';
const connectionLineStyle = { stroke: '#347edd' };
const snapGrid = [20, 20];
const nodeTypes = {
  selectorNode: ColorSelectorNode,
};

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 172;
const nodeHeight = 36;

// Dagre layout function to automatically assign x and y positions
// const getLayoutedElements = (nodes, edges) => {
//   dagreGraph.setGraph({ rankdir: 'LR' }); // 'TB' for top-to-bottom layout
const getLayoutedElements = (nodes, edges, direction = 'LR') => {
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };
  });

  return { nodes, edges };
};

const Node = ({addTab}) => {
  // console.debug('node  : icon :', questionType)
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  console.debug('node : nodeDrawer', nodes)
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [bgColor, setBgColor] = useState(initBgColor);
  const [selectedNodeId, setSelectedNodeId] = useState(null); 
  const animatedStatus = false;
  const [open, setOpen] = React.useState(false);


  function findNodeObjectFromNodes(nodeId){
    let tempNodes=[...nodes]
    let findNodeObject= tempNodes?.find(d=>{
      if(Number(d.nodeId?.id)===Number(nodeId)){
        return d;
      }
    })
    return findNodeObject;
  }


  function nodeObjectFormatted(node){
    let formatedNode={
      id: node.id.toString(),
      type: 'selectorNode',
      data: { 
        label: `${node.question}`, 
        isParent: true, 
        icon: <QuestionTypeIcon questionTypeLabel={questionTypeConst.questionGroup} />, 
        nodes: [...nodes,node],
        node: node,
      },
      position: { x: 0, y: 0 },
      sourcePosition: Position.Top,
      targetPosition: Position.Bottom,
    };
    return formatedNode;
  }

  // function updateNodeObjectsForLinking(sourceNode,targetNode){
  //   let formatedNode={
  //     id: node.id.toString(),
  //     type: 'selectorNode',
  //     data: { 
  //       label: `${node.question}`, 
  //       isParent: true, 
  //       icon: <QuestionTypeIcon questionTypeLabel={questionTypeConst.questionGroup} />, 
  //       nodes: [...nodes,node],
  //       node: node,
  //     },
  //     position: { x: 0, y: 0 },
  //     sourcePosition: Position.Top,
  //     targetPosition: Position.Bottom,
  //   };
  //   return formatedNode;
  // }

  useEffect(() => {
    let formData = {
      related: ['followUps.fallBackQuestion', 'followUps.fallBackQuestionType'],
      showQuestion: true,
    };

    ApiService.get('nodeModel', formData).then((res) => {
      const response = res.data.data;

      // Extract nodes
      const fetchedNodes = response.node.map((node) => ({
        id: node.nodeId.id.toString(),
        type: 'selectorNode',
        data: { 
          label: `${node.nodeId.question}`, 
          isParent: true, 
          // icon: <AcUnitIcon />, 
          icon: <QuestionTypeIcon questionTypeLabel={questionTypeConst.questionGroup} />, 
          

          nodes: response.node,
          node: node.nodeId,
        },
        position: { x: 0, y: 0 },
        sourcePosition: Position.Top,
        targetPosition: Position.Bottom,
      }));


      let fetchedEdges=[];
      
      response.link.forEach(link => {
        
        if(link?.start?.questionId?.id !== undefined && link?.end?.questionId?.id !== undefined ){
          let tempEdge= {
          id: `${link.start.questionId.id}-${link.end.questionId.id}`,
          source: link.start.questionId.id.toString(),
          target: link.end.questionId.id.toString(),
          label: <AcUnitIcon />,
          animated: animatedStatus,
          type: 'smoothstep',
          style: { stroke: '#90a4ae' },
          markerEnd: {
          type: MarkerType.ArrowClosed,
          width: 20,
          height: 20,
          color: '#90a4ae',
          },
          }
          fetchedEdges=[...fetchedEdges,tempEdge];
        }
      });

      // console.debug("Node : useEffect : fetchedEdges : ",fetchedEdges)
      const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(fetchedNodes, fetchedEdges);


      setNodes(layoutedNodes);
      setEdges(layoutedEdges);
    });
  }, []);


  const toggleDrawer = () => {
    setOpen(!open);
  };

  const onConnect = useCallback(
    (params) => {
      console.debug("Node : onConnect : params : ",params)
      console.debug("Node : onConnect : edges : ",edges)
      let tempNodes=[...nodes];
      console.debug("Node : onConnect : tempNodes : ",tempNodes);
      let sourceNode=params.source;
      let sourceNodeObject=tempNodes.find(d=>d.id===sourceNode);
      let targetNode=params.target;
      let targetNodeObject=tempNodes.find(d=>d.id===targetNode);
      console.debug("Node : onConnect : sourceNodeObject : ",sourceNodeObject);
      console.debug("Node : onConnect : targetNodeObject : ",targetNodeObject);
      tempNodes.forEach(d => {
        if(d.id===sourceNode){
          console.debug("Node : onConnect : tempNodes : d : ",d);
          let tempFollowUpsData=d?.data?.node?.follow_ups;
          if(tempFollowUpsData.length===0){
            console.debug("Node : onConnect : tempNodes : d : tempFollowUpsData : if Block : ",tempFollowUpsData);
            let newFollupData=[];
            newFollupData[0]={
              condition:[],
              currentQuestionId: sourceNode,
              deleted_at: null,
              fallBackQuestionId: targetNode,
              fallBackQuestionTypeId: 2,
              // fall_back_question: {id: 333, queGroupId: 72, question: "Recommendation 1", status: 1, image: null, video: null},
              fall_back_question: targetNodeObject,
              fall_back_question_type: {id: 2, questionTypeLabel: "Question", description: "Question", iconId: null, questionTypeValue: null},
              id: null,
            }
            console.debug('node : onConnect : newFollupData',newFollupData);
            tempFollowUpsData=[...newFollupData];
          }else{
            console.debug("Node : onConnect : tempNodes : d : tempFollowUpsData : else Block : ",tempFollowUpsData);
            let newCondition={
              conditionOperator: null,
              conditionType: null,
              conditionValue: null,
              followUpQuestionId: targetNode,
              followUpQuestionTypeId: null,
              response: null,
            }
            console.debug('node : onConnect : newFollupData',newCondition);
            console.debug("Node : onConnect : tempNodes : d : newCondition : ",newCondition);
            let newFollupData=[...tempFollowUpsData];
            console.debug("Node : onConnect : tempNodes : d : newFollupData : ",newFollupData);
            if(newFollupData[0]?.condition?.length>0){
              newFollupData[0].condition=[...newFollupData[0].condition,newCondition];
            }else{
              newFollupData[0].condition=[newCondition];
            }
            console.debug("Node : onConnect : tempNodes : d : newFollupData : ",newFollupData);

            tempFollowUpsData=[...newFollupData];
          }
          console.debug("Node : onConnect : tempNodes : d : tempFollowUpsData : updated : ",tempFollowUpsData);
        }
          
      });


      let newEdge={
        id: `${params.source}-${params.target}`,
        source: params.source,
        target: params.target,
        label: <AcUnitIcon />,
        animated: animatedStatus,
        type: 'smoothstep',
        style: { stroke: '#90a4ae' },
        markerEnd: {
          type: MarkerType.ArrowClosed,
          width: 20,
          height: 20,
          color: '#90a4ae',
        },
      }
      setEdges((eds) =>
        addEdge(
          newEdge,
          eds
        )
      );
    },
    [edges]
  );
  console.debug('node : edge :'.newEdge,addEdge)

  // const highlightEdges = (edges, selectedNodeId) => {
  //   return edges.map((edge) => {
  //     // const isConnected = edge.source === selectedNodeId || edge.target === selectedNodeId;
  //     const isConnected = edge.source === selectedNodeId ;
  //     console.debug('node : edge : source',edge.source,edge,nodes)
  //     // console.debug('node : edge : target',edge.target,edge,selectedNodeId)
  //     return {
  //       ...edge,
  //       style: {
  //         ...edge.style,
  //         stroke: isConnected ? '#ff0072' : '#90a4ae', 
  //         strokeWidth: isConnected ? 3 : 2,
  //       },
  //       animated: isConnected,   
  //     };
  //   });
  // };

  const getConnectedEdges = (sourceNodeId, edges) => {
    const connectedEdges = [];
  
    const findConnections = (currentNodeId) => {
      edges.forEach((edge) => {
        if (edge.source === currentNodeId) {
          connectedEdges.push(edge); 
          findConnections(edge.target);
        }
      });
    };
  
    findConnections(sourceNodeId);
    return connectedEdges;
  };
  
  const highlightEdges = (edges, selectedNodeId) => {
    const connectedEdges = getConnectedEdges(selectedNodeId, edges);
  
    return edges.map((edge) => {
      const isConnected = connectedEdges.includes(edge); 
      console.debug('node : edge : source',edge.source,connectedEdges,isConnected)
      return {
        ...edge,
        style: {
          ...edge.style,
          stroke: isConnected ? '#347edd' : '#90a4ae', 
          strokeWidth: isConnected ? 3 : 2,
        },
        markerEnd: {
          type: MarkerType.ArrowClosed,
          width: 10,
          height: 10,
          color: '#90a4ae',
          color: isConnected ? '#347edd' : '#90a4ae'
        },
        // animated: isConnected, 
      };
    });
  };
  

  const onNodeClick = (event, node) => {
    setSelectedNodeId(node.id); 
  };
  return (
    <Box sx={{ height: '30rem', overflow: 'auto', bgcolor: bgColor }}>
      <Box sx={{padding:'1rem'}}>
      {/* <Button variant="outlined" size="small" onClick={addTab}>
        Add Tab
      </Button> */}
      </Box>
      <ReactFlow
        nodes={nodes}
        // edges={edges}
        edges={highlightEdges(edges, selectedNodeId)} 
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onNodeClick={onNodeClick}
        style={{ background: bgColor }}
        nodeTypes={nodeTypes}
        connectionLineStyle={connectionLineStyle}
        snapToGrid={true}
        snapGrid={snapGrid}
        fitView
        attributionPosition="bottom-left"
      >
        <Controls />
      </ReactFlow>
    </Box>
  );
};



export default Node;

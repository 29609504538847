
import { useEffect, useState } from 'react';
import { Autocomplete, Box, TextField, Button, Grid, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { ComboBox } from '../../../lib';

const conditionTypes = [
    { identifier: "percentage", value: "Percentage" },
    { identifier: "total", value: "Total" },
    { identifier: "average", value: "Average" }
];


const yesNoOptions = [
    "Yes",
    "No",
];


const operators = [
        { identifier: "add", value: "Add" },
        { identifier: "subtract", value: "Subtract" },
        { identifier: "multiply", value: "Multiply" },
        { identifier: "divide", value: "Divide" },
        { identifier: "Greater Than Equal To", value: "(>=)" },
];



function ConditionComponent({ index , onDelete, data, node, condition,...props }) {


    const [selectedConditionType, setSelectedConditionType] = useState();
    const [selectedConditionOperator, setSelectedConditionOperator] = useState();
    const [selectedConditionValue, setSelectedConditionValue] = useState();
    const [selectedConditionQuestion, setSelectedConditionQuestion] = useState();
    const [selectedConditionResponse, setSelectedConditionResponse] = useState();
    console.debug("Node : ColorSelectorNode : NodeUpdateDrawer : ConditionComponent  : data : ", data);
    const questionOptions = data.nodes.map((node) => node?.nodeId);


    
    const handleConditionTypeChange = (value) => {
        setSelectedConditionType(value); 
        console.debug("Node : ColorSelectorNode : NodeUpdateDrawer : ConditionComponent  : handleConditionTypeChange : value : ", value);
        console.debug("Node : ColorSelectorNode : NodeUpdateDrawer : ConditionComponent  : handleConditionTypeChange : props : ", props);
        if(props?.handleConditionChange){
            console.debug("Node : ColorSelectorNode : NodeUpdateDrawer : ConditionComponent  : handleConditionTypeChange : value : ", value);
            props.handleConditionChange(index,'conditionType',value)
        }
    };
    
    const handleConditionOperatorChange = (value) => {
        setSelectedConditionOperator(value); 
        if(props?.handleConditionChange){
            props.handleConditionChange(index,'conditionOperator',value)
        }
    };

    const handleConditionQuestionChange = (value) => {
        // const selectedNode = data.nodes.find((node) => node.nodeId === value);
        // setSelectedConditionQuestion(selectedNode?.nodeId); 
        setSelectedConditionQuestion(value); 
        if(props?.handleConditionChange){
            // props.handleConditionChange(index,'followUpQuestionId',value?.fallBackQuestionId)
            props.handleConditionChange(index,'followUpQuestionId',value?.id)
        }
    };

    const handleConditionValueChange = (event) => {
        setSelectedConditionValue(event.target.value);
        if(props?.handleConditionChange){
            props.handleConditionChange(index,'conditionValue',event.target.value)
        }
    };


    const handleConditionResponseChange = (value) => {
        setSelectedConditionQuestion(value); 
        // if(props?.handleConditionChange){
        //     // props.handleConditionChange(index,'followUpQuestionId',value?.fallBackQuestionId)
        //     // props.handleConditionChange(index,'followUpQuestionId',value?.id)
        // }
    };

    
    // Function to handle changes in any field
    const handleValueChange = (field, value) => {
        // setSelectedCondition((prevCondition) => {
        //     let updatedCondition = { ...prevCondition, [field]: value };

        //     if (field === 'question') {
        //         const node = data.nodes.find((node) => node.nodeId.question === value);
        //         updatedCondition = { ...updatedCondition, currentSelectedNodeData: node ? node.nodeId : null };
        //     }

        //     return updatedCondition;
        // });
    };

    const handleConditionTypeValueChange = (value) => {

    }

    function capitalizeFirstLetter(str) {
      if (!str) return ''; // Handle empty string or null
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
  

    useEffect(function () {
        console.debug("Node : ColorSelectorNode : NodeUpdateDrawer : ConditionComponent : UseEffect  : condition : condition : ", condition);
        
        if (condition?.conditionType === 'count') {
            setSelectedConditionType('Total')
        } else {
            setSelectedConditionType(condition?.conditionType)
        }
        if (condition?.conditionOperator === '>=') {
            setSelectedConditionOperator(operators[4])
        } else {
            setSelectedConditionOperator(condition?.conditionOperator)
        }
        
        setSelectedConditionResponse((capitalizeFirstLetter(condition?.response)));
        setSelectedConditionValue(condition?.conditionValue)
        let followupQuestionId = Number(condition?.followUpQuestionId);
        let findQuestionIndex = data?.nodes?.findIndex((d) => d?.nodeId?.id === followupQuestionId);
        console.debug("Node : ColorSelectorNode : NodeUpdateDrawer : ConditionComponent : UseEffect  : condition : findQuestionIndex : ", findQuestionIndex);
        if (findQuestionIndex >= 0) {
            let question = data?.nodes?.[findQuestionIndex]?.nodeId;
            console.debug("Node : ColorSelectorNode : NodeUpdateDrawer : ConditionComponent : UseEffect  : condition : question : ", question);
            setSelectedConditionQuestion(question)
        }

    }, [condition]);

    return (
      <Box sx={{ background: "#EEF6FF", p: 2, my: 1, borderRadius: "12px" }}>
        <Box
          sx={
            {
              //  display: "flex", alignItems: "center"
            }
          }
        >
          <Grid container spacing={1}>
            <Grid item xs={1}>
              <Box sx={{ mt: "0.5rem" }}>
                <Typography variant="subtitle2" fontWeight="bold">
                  If
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <ComboBox
                options={conditionTypes}
                defaultValue={selectedConditionType}
                onChangeHandler={handleConditionTypeChange}
              />
            </Grid>
            <Grid item xs={4}>
              <ComboBox
                options={yesNoOptions}
                defaultValue={selectedConditionResponse}
                onChangeHandler={handleConditionResponseChange}
              />
            </Grid>

            <Grid item xs={4}>
              <ComboBox
                options={operators}
                defaultValue={selectedConditionOperator}
                onChangeHandler={handleConditionOperatorChange}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="outlined-basic"
                label="Value"
                variant="outlined"
                size="small"
                onChange={handleConditionValueChange}
                value={selectedConditionValue}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Grid container spacing={1}>
            <Grid item xs={1}>
              <Box sx={{ mt: "0.5rem" }}>
                <Typography variant="subtitle2" fontWeight="bold">
                  Then
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={11}>
              <ComboBox
                options={questionOptions}
                optionKey={"id"}
                optionLabelKey={"question"}
                defaultValue={selectedConditionQuestion}
                onChangeHandler={handleConditionQuestionChange}
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Button
            variant="text"
            size="small"
            aria-label="Delete Rule"
            startIcon={<DeleteIcon fontSize="small" />}
            onClick={onDelete}
          >
            Delete Rule
          </Button>
        </Box>
      </Box>
    );
}

export default ConditionComponent;
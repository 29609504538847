import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import { publish } from '../helper/publisher.js';


const InputAutocomplete = ({label, model, modelKey, validation, id, type, sx = {}, change = () => {}, value, data, ...props}) => {

  const [autocompleteValue, setAutocompleteValue] = React.useState([]);
  const [error, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const onChangeAutocompleteValue = (event, newValue) => {
    setAutocompleteValue(newValue);
    model[modelKey].value = newValue;
    
    let error = false;
    let errorMessage = '';

    for (let i = 0; i < validation?.length; i++) {
      let res = validation[i](newValue);
      if (res.error) {
        error = res.error;
        errorMessage = res.errorMessage;
        break;
      }
    }

    setError(error);
    setErrorMessage(errorMessage);
    change(event);

    // Publishing data to the subscribers
    publish({ data: model, id: 'touched' + id });
    publish({ data: model[modelKey], id: 'activeTouch' + id });
    publish({ data: error, id: 'filled' + id });
  };

  return (
    <FormControl size="small" error={error} sx={sx}>
      <Autocomplete
        multiple
        id={id}
        options={data}
        getOptionLabel={(option) => option.title}
        value={autocompleteValue}
        onChange={onChangeAutocompleteValue}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            placeholder="Favorites"
            error={error}
          />
        )}
        {...props}
      />
      {error && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};

export default InputAutocomplete;


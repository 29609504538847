import { configureStore } from '@reduxjs/toolkit'
import surveyQuestionSlice from './slice/surveyQuetions/surveyQuestionSlice';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import userInfo from './userInfoStore/userSlice';
import snackbarReducer from './snackbar/snackbarSlice';
import selectedQuestionRequiredStatusSlice from './survey/selectedQuestionRequiredStatusSlice';
import selectedQuestionDescriptionStatusSlice from './survey/selectedQuestionDescriptionStatusSlice';
import selectedQuestionDescriptionSlice from './survey/selectedQuestionDescriptionSlice';
import selectedQuestionOptionsSlice from './survey/selectedQuestionOptionsSlice';
import TabSlice from '../component/redux/NodeRedux/TabSlice';

const loggerMiddleware = createLogger();

export const store = configureStore({
  reducer: {
    surveyQuestion: surveyQuestionSlice,
    tabNodeData:TabSlice,
    selectedQuestionDescriptionStatus: selectedQuestionDescriptionStatusSlice,
    selectedQuestionDescription: selectedQuestionDescriptionSlice,
    selectedQuestionRequiredStatus: selectedQuestionRequiredStatusSlice,
    selectedQuestionOptions: selectedQuestionOptionsSlice,
    // ./Survey
    user: userInfo,
    snackbar: snackbarReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(loggerMiddleware),
  //   devTools: process.env.NODE_ENV !== 'production', // Enable Redux DevTools in development
  //   enhancers: [composeWithDevTools()] // Add Redux DevTools Extension
}, composeWithDevTools())
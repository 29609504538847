import React from 'react';
import QuestionTypeIcon from '../questionSettings/questionType/QuestionTypeIcon';
import ClearIcon from '@mui/icons-material/Clear';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import {
    Box, Switch, Grid, Button, TextField, Tab, Tabs, Typography, styled, useTheme, Drawer, Card, CssBaseline, IconButton, Avatar, InputBase, Menu,
    AppBar as MuiAppBar, Toolbar, Divider, ListItemIcon, ToggleButtonGroup, MenuItem, Paper,
    AccountCircle as AccountCircleIcon, Collections as CollectionsIcon, Undo as UndoIcon, Redo as RedoIcon, Remove as RemoveIcon, Add as AddIcon, Search as SearchIcon, Delete as DeleteIcon,
    Image as ImageIcon, DateRange as DateRangeIcon, AttachEmail as AttachEmailIcon, LocalPhone as LocalPhoneIcon, Checklist as ChecklistIcon, TextFields as TextFieldsIcon, ChangeHistoryTwoTone as ChangeHistoryTwoToneIcon, ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon, ContentCopy as ContentCopyIcon,
    Home as HomeIcon, Preview as PreviewIcon, Download as DownloadIcon, Circle as CircleIcon, Rectangle as RectangleIcon, EggTwoTone, Star as StarIcon, Logout, Settings, FormatColorFill
} from '../../../lib/utils/AllImportsHelper';

function QuestionTypeItem({ handleItemClick, questionType, ...props }) {
    // selectedQuestionTypeToAddHandler
    console.debug('questionTypeItem : label',questionType)
   
    return (
        <List sx={{ py: '0px !important', '&:hover': { backgroundColor: '#E1EDFC' } }}>
            <ListItem onClick={(e) => handleItemClick(e,questionType)}>
                <ListItemIcon sx={{ minWidth: '2rem !important' }}>
                    <QuestionTypeIcon questionTypeLabel={questionType?.identifier} />
                </ListItemIcon>
                <ListItemText primary={questionType?.questionTypeLabel} />
            </ListItem>
        </List>
    );
}

export default QuestionTypeItem;
// src/OptionsList.js
import React, { useState } from 'react';
import { TextField, IconButton, Button, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const QuestionGroup = () => {

  return (
    <Box>
    
    </Box>
  );
};

export default QuestionGroup;

import React, { useContext } from "react";
// import { useState } from "react";
// import { AnswerContext } from "../../../_services/FromType/AnswerContext";
// import { QuestionContext } from "../../../_services/FromType/QuestionContext";

function RankingQues(props) {
  // const number = useContext(QuestionContext);

  // const [addChoice, setAddChoice] = useState([{ Choice: "" }]);
  // const handleInputChange = (e, index) => {
  //   const { name, value } = e.target;
  //   const choice = [...addChoice];
  //   choice[index][name] = value;
  //   setAddChoice(choice);
  // };
  // const handleRemoveClick = index => {
  //   const choice = [...addChoice];
  //   choice.splice(index, 1);
  //   setAddChoice(choice);
  // };
  // const handleAddClick = () => {
  //   setAddChoice([...addChoice, { Choice: "" }]);
  // };
  return (
    <>
      <h1>Ranking</h1></>
    // <div className="">
    //   {addChoice.map((x, i) => {
    //     return (
    //       <div>
    //         <input
    //           name="Choice"
    //           className="border border-left-0 border-right-0 border-top-0 w-100 small"
    //           placeholder="Enter Option Value"
    //           onChange={e => handleInputChange(e, i)}
    //           value={x.Choice}
    //         />

    //         <div className="btn-box pt-3">
    //           {addChoice.length - 1 === i && <button className="btn btn-default bg-gray mr-10" onClick={() => handleRemoveClick(i)}>Remove</button>}
    //           {addChoice.length - 1 === i && <button className="btn btn-default bg-gray ml-2 pr-5 pl-5" onClick={handleAddClick}>Add</button>}
    //         </div>
    //       </div>
    //     );
    //   })}

    // </div>
  );
}

export default RankingQues

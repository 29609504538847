import React, { useEffect, useState } from "react";
import ApiService from "../../ApiService";
import { useParams } from "react-router-dom";

import {
  Box,
  Grid,
  Typography,
  Card,
} from "./../../lib/utils/AllImportsHelper";
import SurveyLayout from "./publishSetting/SurveyLayout";
import PublishSettingContext from "./publishSetting/PublishSettingContext";
import DesignHeader from "./publishSetting/DesignHeader";
import DesignBody from "./publishSetting/DesignBody";
import DesignFooter from "./publishSetting/DesignFooter";
import { openSnackbar } from "../../redux/snackbar/snackbarSlice";
import { useDispatch } from "react-redux";
import { EnahanceUpdateActionButton, LoadingAndErrorHandling } from "../../lib";


const PublishSettings = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  
  const [updatingLoadingStatus, setUpdatingLoadingStatus] = useState(false);
  const [surveySettingLoadingStatus, setSurveySettingLoadingStatus] = useState(false);
  
  const dispatch = useDispatch();

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const newFiles = files.map((file) => URL.createObjectURL(file));
    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const [surveyLayout, setSurveyLayout] = useState([]);
  const [surveySettingLoadingError, setSurveySettingLoadingError] = useState(null);
  const [surveyProperties, setSurveyProperties] = useState([]);

  const [headerBackgroundColor, setHeaderBackgroundColor] = useState([]);
  const [headerTextColor, setHeaderTextColor] = useState([]);
  const [headerAlignment, setHeaderAlignment] = useState([]);
  const [headerLogo, setHeaderLogo] = useState([]);
  const [selectedHeaderLogo, setSelectedHeaderLogo] = useState(null);
  const [selectedHeaderAlignment, setSelectedHeaderAlignment] = useState(null);
  const [selectedHeaderBackgroundColor, setSelectedHeaderBackgroundColor] = useState(null);
  const [selectedHeaderTextColor, setSelectedHeaderTextColor] = useState(null);

  function headerBackgroundColorChangeHandler(d) {
    console.debug("PublishSettings : headerBackgroundColorChangeHandler : d : ", d);
    setSelectedHeaderBackgroundColor(d);
  }

  function headerTextColorChangeHandler(d) {
    setSelectedHeaderTextColor(d);
  }
  function headerAlignmentChangeHandler(d) {
    setSelectedHeaderAlignment(d);
  }
  function headerLogoChangeHandler(d) {
    setSelectedHeaderLogo(d);
  }



  const [bodyBackgroundColor, setBodyBackgroundColor] = useState([]);
  const [bodyTextColor, setBodyTextColor] = useState([]);
  const [bodyAlignment, setBodyAlignment] = useState([]);
  const [selectedBodyAlignment, setSelectedBodyAlignment] = useState(null);
  const [selectedBodyBackgroundColor, setSelectedBodyBackgroundColor] = useState(null);
  const [selectedBodyTextColor, setSelectedBodyTextColor] = useState(null);

  function bodyBackgroundColorChangeHandler(d) {
    setSelectedBodyBackgroundColor(d);
  }

  function bodyTextColorChangeHandler(d) {
    setSelectedBodyTextColor(d);
  }
  function bodyAlignmentChangeHandler(d) {
    setSelectedBodyAlignment(d);
  }

  const [footerBackgroundColor, setFooterBackgroundColor] = useState([]);
  const [footerTextColor, setFooterTextColor] = useState([]);
  const [footerAlignment, setFooterAlignment] = useState([]);
  const [footerText, setFooterText] = useState({});
  const [selectedFooterAlignment, setSelectedFooterAlignment] = useState(null);
  const [selectedFooterBackgroundColor, setSelectedFooterBackgroundColor] = useState(null);
  const [selectedFooterTextColor, setSelectedFooterTextColor] = useState(null);

  function footerBackgroundColorChangeHandler(d) {
    setSelectedFooterBackgroundColor(d);
  }

  function footerTextColorChangeHandler(d) {
    setSelectedFooterTextColor(d);
  }
  function footerAlignmentChangeHandler(d) {
    setSelectedFooterAlignment(d);
  }

  function footerTextChangeHandler(d) {
    if (footerText?.sectionProperty)
      setFooterText({ ...footerText, sectionProperty: { ...footerText.sectionProperty, name: d } });
  }


  const [sectionProperty, setSectionProperty] = useState([]);
  const { id } = useParams();
  const [selectedValue, setSelectedValue] = useState("");


  useEffect(function () {
    const params = {
      sectionProperties: true, surveyLayout: true,
      surveyId: id
      // surveyId: 1,
    };

    setSurveySettingLoadingStatus(true);
    ApiService.get("surveyLayout", params)
      .then((r) => {
        if (r.data.data.surveyLayout) {
          const sectionTypes = ["Header", "Body", "Footer"];
          // const propertyNames = ["Background Color", "Text Color", "Text Alignment", "Logo"];
          const propertyNames = ["background-color", "text-color", "text-alignment", "logo", "footer-text"];

          // const sectionData = {
          //   Header: {
          //     BackgroundColor: [],
          //     TextColor: [],
          //     TextAlignment: [],
          //     Logo: []
          //   },
          //   Body: {
          //     BackgroundColor: [],
          //     TextColor: [],
          //     TextAlignment: []
          //   },
          //   Footer: {
          //     BackgroundColor: [],
          //     TextColor: [],
          //     TextAlignment: []
          //   }
          // };

          const sectionData = {
            Header: {
              "background-color": [],
              "text-color": [],
              "text-alignment": [],
              "logo": []
            },
            Body: {
              "background-color": [],
              "text-color": [],
              "text-alignment": []
            },
            Footer: {
              "background-color": [],
              "text-color": [],
              "text-alignment": [],
              "footer-text": ""
            }
          };

          r.data.data.sectionProperties.forEach(section => {
            if (sectionTypes.includes(section.name)) {
              section.sectionProperties.forEach(property => {
                // console.debug("PublishSettings :  useEffect : property : ", property)
                // console.debug("PublishSettings :  useEffect : propertyNames : ", propertyNames)

                if (propertyNames.includes(property.sectionPropertyLabel)) {
                  // let propertyKey = property.name.replace(" ", "");
                  let propertyKey = property.sectionPropertyLabel;
                  // console.debug("PublishSettings :  useEffect : propertyKey : ", propertyKey)
                  // console.debug("PublishSettings :  useEffect : property : ", property)
                  if (property.sectionPropertyLabel == "footer-text") {
                    sectionData[section.name][propertyKey] = property;
                  } else {
                    sectionData[section.name][propertyKey].push(property);
                  }
                }
              });
            }
          });

          // console.debug("PublishSettings :  useEffect : sectionData : ", sectionData)

          setHeaderBackgroundColor(sectionData.Header["background-color"]);
          setHeaderTextColor(sectionData.Header["text-color"]);
          setHeaderAlignment(sectionData.Header["text-alignment"]);
          setHeaderLogo(sectionData.Header.logo);

          setBodyBackgroundColor(sectionData.Body["background-color"]);
          setBodyTextColor(sectionData.Body["text-color"]);
          setBodyAlignment(sectionData.Body["text-alignment"]);

          setFooterBackgroundColor(sectionData.Footer["background-color"]);
          setFooterTextColor(sectionData.Footer["text-color"]);
          setFooterAlignment(sectionData.Footer["text-alignment"]);
          setFooterText(sectionData.Footer["footer-text"]);

          setSurveyLayout(r.data.data.surveyLayout);
          setSurveyProperties(r.data?.data?.sectionProperties);

          const activeItem = r.data.data.surveyLayout.find(
            (d) => Number(d.activeStatus) === 1
          );
          if (activeItem) {
            setSelectedValue(activeItem.name);
          }
        }
        setSurveySettingLoadingStatus(false);
      })
      .catch((e) => {
        console.debug("surveyLayout : useEffect :catch : e : ", e);
        setSurveySettingLoadingError(e);
      });
  }, []);


  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  function getFormatedDataToUpdate(sourceObject) {
    let d = {};
    d.sectionTypeId = sourceObject.sectionTypeId;
    d.sectionPropertyLabel = sourceObject.sectionPropertyLabel;
    d.propertyId = sourceObject.propertyId;
    return d;
  }

  function onApplySettingChange() {
    let formData = { surveySectionProperties: [] }; // Initialize formData with surveySectionProperties as an empty array
    let tempData = [];

    // List of source objects to process
    let sourceObjects = [
      selectedHeaderAlignment, selectedHeaderBackgroundColor, selectedHeaderTextColor,
      selectedHeaderLogo,
      selectedBodyAlignment, selectedBodyBackgroundColor, selectedBodyTextColor,
      selectedFooterAlignment, selectedFooterBackgroundColor, selectedFooterTextColor,
    ];

    sourceObjects.forEach(sourceObject => {
      if (sourceObject) {
        let formattedData = getFormatedDataToUpdate(sourceObject);
        tempData.push(formattedData);
      }
    });

    console.debug("PublishSettings : onApplySettingChange : tempData : ", tempData);
    // console.debug("PublishSettings : onApplySettingChange : footerText : ", footerText);
    tempData.forEach((item, index) => {
      // console.debug("PublishSettings : onApplySettingChange : item : ", item);
      // Initialize the nested object for the current index
      formData['surveySectionProperties'][index] = {};
      for (let key in item) {
        // console.debug("PublishSettings : onApplySettingChange : key", key);
        if (item.hasOwnProperty(key)) {
          // console.debug("PublishSettings : onApplySettingChange : item[key] : " + key + " : ", item[key]);
          formData['surveySectionProperties'][index][key] = item[key];
        }
      }
    });

    if (Object.keys(footerText) && footerText?.sectionProperty) {
      let tempFooterObject = {
        sectionTypeId: footerText.sectionTypeId, sectionPropertyLabel: footerText.sectionPropertyLabel,
        propertyId: footerText.propertyId, footerTextName: footerText.sectionProperty.name
      }
      formData.surveySectionProperties.push(tempFooterObject);
    }

    // console.debug("PublishSettings : onApplySettingChange : formData : ", formData);
    if (formData?.surveySectionProperties?.length) {
      // let id = 1;
      setUpdatingLoadingStatus(true);
      ApiService.put("surveyPublishSetting", formData, id).then(response => {
        // console.debug("PublishSettings : onApplySettingChange : response", response)
        setUpdatingLoadingStatus(false);
        dispatch(openSnackbar({ dataLoad: true, message: 'Record updated successfully', severity: "info" }));
        setSelectedHeaderAlignment(null);
        setSelectedHeaderBackgroundColor(null);
        setSelectedHeaderTextColor(null);
        setSelectedBodyAlignment(null);
        setSelectedBodyBackgroundColor(null);
        setSelectedBodyTextColor(null);
        setSelectedFooterAlignment(null);
        setSelectedFooterBackgroundColor(null);
        setSelectedFooterTextColor(null);
      }).catch(e => {
        console.error("PublishSettings : onApplySettingChange : e", e)
        setUpdatingLoadingStatus(false);
        dispatch(openSnackbar({ dataLoad: true, message: e.message, severity: "error" }));
      })
    } else {
      console.debug("PublishSettings :  Nothing to update");
    }

  }


  const publishSettingContextData = {
    // Header
    headerBackgroundColor: headerBackgroundColor,
    headerTextColor: headerTextColor,
    headerAlignment: headerAlignment,
    headerLogo: headerLogo,
    headerBackgroundColorChangeHandler: headerBackgroundColorChangeHandler,
    headerTextColorChangeHandler: headerTextColorChangeHandler,
    headerAlignmentChangeHandler: headerAlignmentChangeHandler,
    headerLogoChangeHandler: headerLogoChangeHandler,
    // ./Header

    // Body
    bodyBackgroundColor: bodyBackgroundColor,
    bodyTextColor: bodyTextColor,
    bodyAlignment: bodyAlignment,
    bodyBackgroundColorChangeHandler: bodyBackgroundColorChangeHandler,
    bodyTextColorChangeHandler: bodyTextColorChangeHandler,
    bodyAlignmentChangeHandler: bodyAlignmentChangeHandler,

    // ./Body

    // Footer
    footerBackgroundColor: footerBackgroundColor,
    footerTextColor: footerTextColor,
    footerAlignment: footerAlignment,
    footerText: footerText,
    footerBackgroundColorChangeHandler: footerBackgroundColorChangeHandler,
    footerTextColorChangeHandler: footerTextColorChangeHandler,
    footerAlignmentChangeHandler: footerAlignmentChangeHandler,
    footerTextChangeHandler: footerTextChangeHandler
    // ./Footer

  }



  return (

    <PublishSettingContext.Provider value={publishSettingContextData}>

      <Box sx={{ display: "flex" }}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Card sx={{ height: "calc(100vh - 64px)", borderTopLeftRadius:'1rem',borderTopRightRadius:'1rem' }}>
              <Box sx={{ backgroundColor: "#E1EDFC", p: 1.4 }}>
                <Typography variant="subtitle1" fontWeight="bold">
                  Publish Settings
                </Typography>
              </Box>
              <LoadingAndErrorHandling type={"contentBody"} isLoading={surveySettingLoadingStatus}
                errorMessage={surveySettingLoadingError}
              >
                <SurveyLayout surveyLayout={surveyLayout}
                  handleChange={handleChange}
                  selectedValue={selectedValue}
                />
              </LoadingAndErrorHandling>
            </Card >
          </Grid >
          <Grid item xs={4}>
            <Box>
              <Card sx={{borderTopLeftRadius:'1rem',borderTopRightRadius:'1rem' }}>
                <Box>
                  <Box sx={{ p: 1.4, display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Typography variant="subtitle2" fontWeight="bold">
                      Design
                    </Typography>
                    <EnahanceUpdateActionButton
                      size="small"
                      onCliCkHandler={onApplySettingChange}
                      loadingStatus={surveySettingLoadingStatus}
                      updatingStatus={updatingLoadingStatus}
                    >
                      Apply
                    </EnahanceUpdateActionButton>
                  </Box>
                  <Box
                    sx={{ height: "calc(100vh - 64px - 53px)", overflow: "auto" }}
                  >
                    <LoadingAndErrorHandling type={"contentBody"} isLoading={surveySettingLoadingStatus}
                      errorMessage={surveySettingLoadingError}
                    >

                      <DesignHeader />
                      <DesignBody />
                      <DesignFooter />
                    </LoadingAndErrorHandling>

                  </Box>
                </Box>
              </Card >
            </Box >
          </Grid>
        </Grid >
      </Box >
    </PublishSettingContext.Provider >

  );
};

export default PublishSettings;

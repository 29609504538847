import React, { useContext, useEffect, useState } from "react";
import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from '../../../../lib/utils/AllImportsHelper';
import { OutlinedInput } from "@mui/material";
import QuestionTypeContext from "../../../../lib/layouts/QuestionFormatLayout/QuestionTypeContext";
// import { QuestionContext } from "../../../_services/FromType/QuestionContext";
function LongTextQues(props) {
  const questionContect = useContext(QuestionTypeContext)
  const [answer,setAnswer]=useState('')
  const quetionChangeHandler = (event) => {
    setAnswer(event.target.value);
  };

  const textFieldBlurHandlder = () => {
    questionContect?.setValue(answer);
  };

  const textFieldKeyDownHandler = (e) => {
    if (e.key === 'Enter') {
      textFieldBlurHandlder();
    }
  };
  return (
    <Box>
     <FormControl sx={{ m: 1, width:"100%" }} variant="outlined">
        <OutlinedInput
          size="small"
          id="outlined-adornment-weight"
          aria-describedby="outlined-weight-helper-text"
          multiline
          rows={4}
          inputProps={{
            'aria-label': 'weight',
          }}
          readOnly={!questionContect}
          value={answer}
          onChange={quetionChangeHandler}
          onBlur={textFieldBlurHandlder}
          onKeyDown={textFieldKeyDownHandler}
          placeholder="Type your answer here"
        />
      </FormControl>
    </Box>
  );
}

export default LongTextQues

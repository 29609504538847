import {
    Box,
    FormControl,
    FormLabel,
    Skeleton,
  } from "@mui/material";
  import React, { useContext, useEffect, useState } from "react";
  import QuestionTypeLayout from "../../lib/layouts/QuestionFormatLayout";
  import QuestionInputTypes from "../question/addQuestion/QuestionInputTypes";
  import { KeyboardArrowRight } from "@mui/icons-material";
  import { endUserPageMaster } from "../../constants/endUserPageMaster";
  import { useDispatch } from "react-redux";
  import { useParams } from "react-router-dom";
  import ApiService from "../../ApiService";
  import PublicApiService from "../../PublicApiService";
  import { setUserInfo } from '../../redux/userInfoStore/userSlice'
  import { openSnackbar } from "../../redux/snackbar/snackbarSlice";
  import EndUserFooterButton from "./EndUserFooterButton";
  import { questionTypeConst } from "../../constants/questionTypeConst";
  import { HtmlRender } from "../../lib";
  import SurveyEndUserContext from "./SurveyEndUserContext";
  import QuestionComponent from "./QuestionComponent";
  import QuestionComponentWrapper from "./QuestionComponentWrapper";
    import { useFormValidation } from "../../hooks/useFormValidation";
  
  const currentDate = new Date();
  // const surveyStartDate = startDate ? new Date(startDate) : null;
  // const surveyEndDate = endDate ? new Date(endDate) : null;
  
  function QuestionWrapper(props) {
    console.debug("SurveyEndUser : QuestionWrapper : props : ", props);
    const surveyEndUserContext = useContext(SurveyEndUserContext);
  //   console.debug("SurveyEndUser : QuestionWrapper : surveyEndUserContext : ", surveyEndUserContext);
  
    const dispatch = useDispatch();
    let { id } = useParams()
  
    const [publicPrivate, setPublicPrivate] = useState();
    const [showAttendee, setShowAttendee] = useState();
    const [closeSurvey, setCloseSurvey] = useState();
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [showReport, setShowReport] = useState();
    const [publish, setPublish] = useState();
  
    const [surveyData, setSurveyData] = useState(null);
    const [surveyQuestions, setSurveyQuestions] = useState([]);
    const [surveyQuestionMasterData, setSurveyQuestionMasterData] = useState([]);
    const [followUpRulesResource, setFollowUpRulesResource] = useState(null);
  
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [selectedQuestionAnswers, setSelectedQuestionAnswers] = useState([])
  
    const surveyStartDate = startDate ? new Date(startDate) : null;
    const surveyEndDate = endDate ? new Date(endDate) : null;
  
    const [footerNextButtonVisibilityStatus, setFooterNextButtonVisibilityStatus] = useState(true);
  
    const [userId, setUserId] = useState(null);
    const { errors, validate } = useFormValidation(surveyQuestions, selectedQuestionAnswers);
   
  //  Set Survey Question Hanlder
  
  function setSurveyQuestionsHandler(questions) {
      console.debug("SurveyEndUser : QuestionWrapper : setSurveyQuestionsHandler : surveyData : ", surveyData);
      console.debug("SurveyEndUser : QuestionWrapper : setSurveyQuestionsHandler : questions : ", questions);
      console.debug("SurveyEndUser : QuestionWrapper : setSurveyQuestionsHandler : questions : tempFollowUpRulesResource : ", questions?.followUpRulesResource?.[0]?.followUpQuestion?.id);
  
      if(surveyData !== null && surveyData?.workFlow !== undefined){
          if(surveyData.workFlow){
              // console.debug("SurveyEndUser : QuestionWrapper : setSurveyQuestionsHandler : with Workflow : ");
              if (selectedQuestionAnswers.length === 0) {
                  // console.debug("SurveyEndUser : QuestionWrapper : setSurveyQuestionsHandler : handleInitialRendering : ");
                  handleInitialRendering(questions);
              } else if (shouldRenderWithoutFollowUp(questions)) {
                  // console.debug("SurveyEndUser : QuestionWrapper : setSurveyQuestionsHandler : handleRenderingWithoutFollowUp : ");
                  handleRenderingWithoutFollowUp(questions);
              } else {
                  // console.debug("SurveyEndUser : QuestionWrapper : setSurveyQuestionsHandler : handleRenderingWithFollowUp : ");
                  handleRenderingWithFollowUp(questions);
              }
          }else{
              // console.debug("SurveyEndUser : QuestionWrapper : setSurveyQuestionsHandler : without Workflow : ");
              let tempQuestions=[];
              // console.debug("SurveyEndUser : QuestionWrapper : setSurveyQuestionsHandler : workFlow else block : surveyQuestionMasterData : ", surveyQuestionMasterData);
              surveyQuestionMasterData.forEach(data=>{
                  if(data?.identifier==='question-group' && data?.childQuestions?.length>0){
                      // console.debug("SurveyEndUser : QuestionWrapper : setSurveyQuestionsHandler : workFlow else block : data?.childQuestions : ", data?.childQuestions);
                      data.forEach(childData=>{
                          tempQuestions.push(childData);
                      })
                  }else{
                      // console.debug("SurveyEndUser : QuestionWrapper : setSurveyQuestionsHandler : workFlow else block : data : ", data);
                      tempQuestions.push(data);
                  }
              })
              // console.debug("SurveyEndUser : QuestionWrapper : setSurveyQuestionsHandler : workFlow else block : tempQuestions : ", tempQuestions);
  
              setSurveyQuestions(tempQuestions);
          }
      }else{
          console.error("Something Went Wrong");
          
      }
      /*
      if (questions) {
          if (selectedQuestionAnswers.length === 0) {
              handleInitialRendering(questions);
          } else if (shouldRenderWithoutFollowUp(questions)) {
              handleRenderingWithoutFollowUp(questions);
          } else {
              handleRenderingWithFollowUp(questions);
          }
      } else {
          console.error("No questions provided.");
      }
      */
  }
  
  /**
   * Handles the initial rendering of questions when the user has not given any answers yet.
   * @param {Object} questions - The questions object to render.
   */
  function handleInitialRendering(questions) {
      // console.debug("SurveyEndUser : QuestionWrapper : handleInitialRendering : ");
      setSurveyQuestionsBasedOnType(questions);
  
      if (questions?.followUpRulesResource && questions.followUpRulesResource.length > 0) {
          setFollowUpRulesResource(questions.followUpRulesResource[0]);
      }
  }
  
  /**
   * Checks if the questions should be rendered without follow-up logic.
   * @param {Object} questions - The questions object to evaluate.
   * @returns {boolean} - True if the questions should be rendered without follow-up, false otherwise.
   */
  function shouldRenderWithoutFollowUp(questions) {
      // console.debug("SurveyEndUser : QuestionWrapper : shouldRenderWithoutFollowUp : selectedQuestionAnswers.length : ",selectedQuestionAnswers?.length);
      // console.debug("SurveyEndUser : QuestionWrapper : shouldRenderWithoutFollowUp : questions: ",questions);
      // console.debug("SurveyEndUser : QuestionWrapper : shouldRenderWithoutFollowUp : questions?.followUpRulesResource : ",questions?.followUpRulesResource);
      // console.debug("SurveyEndUser : QuestionWrapper : shouldRenderWithoutFollowUp : questions?.followUpRulesResource?.length : ",questions?.followUpRulesResource?.length);
  
      return selectedQuestionAnswers.length > 0 && 
             (!questions?.followUpRulesResource || questions.followUpRulesResource.length === 0);
  }
  
  /**
   * Handles rendering questions when no follow-up logic is involved.
   * @param {Object} questions - The questions object to render.
   */
  function handleRenderingWithoutFollowUp(questions) {
      console.debug("SurveyEndUser : QuestionWrapper : handleRenderingWithoutFollowUp : ");
      setSurveyQuestionsBasedOnType(questions);
  }
  
  /**
   * Handles rendering questions when follow-up logic is involved.
   * @param {Object} questions - The questions object to render.
   */
  function handleRenderingWithFollowUp(questions) {
      // console.debug("SurveyEndUser : QuestionWrapper : handleRenderingWithFollowUp : ");
  
      if (questions?.followUpRulesResource && questions.followUpRulesResource.length > 0) {
          // Need to modify once the relationship of backend updated
          setFollowUpRulesResource(questions.followUpRulesResource[0]);
  
          setSurveyQuestionsBasedOnType(questions);
          setSelectedQuestionAnswers([]);
          /*
          if (followUpRulesResource?.followUpQuestionType?.identifier === "question-group") {
              setSurveyQuestionsBasedOnType(questions);
              setSelectedQuestionAnswers([]);
          } else {
              console.error("Invalid follow-up question type.");
          }
          */
      } else {
          console.error("No follow-up rules resource provided.");
      }
  }
  
  /**
   * Sets the survey questions based on the question type (e.g., question-group).
   * @param {Object} questions - The questions object to evaluate.
   */
  function setSurveyQuestionsBasedOnType(questions) {
      // console.debug("SurveyEndUser : QuestionWrapper : setSurveyQuestionsBasedOnType : questions : ",questions);
      if (questions.questionType.identifier === "question-group") {
          setSurveyQuestions(questions?.childQuestions);
      } else {
          setSurveyQuestions([questions]);
      }
  }
  
  //  ./Set Survey Question Hanlder
  
  
  const getFomratAnswerForStoreRequestData = () => {
      // console.debug("SurveyEndUser : getFomratAnswerForStoreRequestData : selectedQuestionAnswers : ", selectedQuestionAnswers)
  
      var formetedData = [];
      // Object.entries(selectedQuestionAnswers).forEach((objectData) => {
      selectedQuestionAnswers.forEach((objectData) => {
          // console.debug("SurveyEndUser : getFomratAnswerForStoreRequestData : selectedQuestionAnswers : objectData : ", objectData)
  
          let d = {};
          // d.optionChoiceId = answer?.optionChoiceId;
          if(objectData?.question?.questionType?.identifier !== undefined && objectData?.answer !== undefined){
              d.questionId = objectData.question.id;
                
            //   switch (objectData.question.questionType.identifier) {
            //       case '[questionTypeConst.yesNo]':
            //           d.answer = objectData.answer;
            //           break;
            //       case "[questionTypeConst.multipleChoice]":
            //               d.answer = objectData.answer?.optionChoiceName;
            //           break;
            //       case '[questionTypeConst.dropdown]':
            //                 d.answer = objectData.answer?.id;
            //           break;
            //       default:
            //                 d.answer = objectData.answer;
            //           break;
            //   }
            

            switch (objectData.question.questionType.identifier) {
                case questionTypeConst.yesNo:
                    d.answer = objectData.answer;
                    break;
                case questionTypeConst.multipleChoice:
                    d.answer = objectData.answer?.optionChoiceName;
                    break;
                case questionTypeConst.dropdown:
                    // console.debug("SurveyEndUser : submitSurveyResponse : getFomratAnswerForStoreRequestData : selectedQuestionAnswers : objectData : ", objectData);
                    // console.debug("SurveyEndUser : submitSurveyResponse : getFomratAnswerForStoreRequestData : selectedQuestionAnswers : objectData : id : ", objectData?.answer?.id);
                    d.answer = objectData?.answer?.id;
                    break;
                default:
                    // console.debug("SurveyEndUser : submitSurveyResponse : getFomratAnswerForStoreRequestData : selectedQuestionAnswers : objectData : default ", objectData.question.questionType.identifier);
                    d.answer = objectData.answer;
                    break;
            }
              // d.answer = answer?.optionChoiceName;
              formetedData.push(d);
          }
          
    //   console.debug("SurveyEndUser : submitSurveyResponse : getFomratAnswerForStoreRequestData : Formatted : d : ", d);
      });
  
      // console.debug("SurveyEndUser : getFomratAnswerForStoreRequestData : Formatted answer: ", formetedData);
      // console.debug('a10', formetedData);
      return formetedData;
  
  
      // let formetedData = [];
      // Object.entries(selectedAnswers).forEach(([questionId, answer]) => {
      //     console.debug('a10', questionId);
      //     console.debug('a10', answer);
  
      //     let d = {};
      //     // d.optionChoiceId = answer?.optionChoiceId;
      //     d.answer = answer?.optionChoiceName;
      //     d.questionId = questionId;
      //     formetedData.push(d);
  
      //     console.debug('Formatted answer:', d);
      // });
  
      // console.debug('a10', formetedData);
      // return formetedData;
  }
  
  
  const submitSurveyResponse = async () => {
      // console.debug("a10", selectedAnswers);
      let formData = {};
      const formattedAnswers = getFomratAnswerForStoreRequestData();
    //   console.debug("SurveyEndUser : submitSurveyResponse : formattedAnswers : ", formattedAnswers);
    //   console.debug("SurveyEndUser : submitSurveyResponse : selectedQuestionAnswers : ", selectedQuestionAnswers);
      // console.debug("SurveyEndUser : submitSurveyResponse : props : ", props);
      formData.answers = formattedAnswers;
      // formData.surveyId = id;
      formData.surveyId = props.surveyData?.data.id;
      ;
  
      if(surveyData.public===0){
          formData.userId = userId;
      }
      // formData.userId = 1;
      formData.averageType = true;
      if(props?.surveySessionId !== undefined && props.surveySessionId !== null ){
          formData.sessionId = props.surveySessionId;
      }
  
      try {
          const response = await PublicApiService.post('surveyAnswerStore', formData);
          // console.debug("SurveyEndUser : submitSurveyResponse : surveyAnswerStore : response : ", response);
          // if(props?.setSessionHandler !== undefined && response?.data?.data?.report?.sessionId !== undefined){
          if(props?.setSessionHandler !== undefined && response?.data?.data?.session?.id !== undefined){
              props.setSessionHandler(response?.data?.data?.session?.id);
          }
          if(props?.setGraphDataHandler !== undefined && response?.data !== undefined){
              props.setGraphDataHandler(response?.data);
          }
          
          // setSurveySessionId(response?.data?.data?.report?.sessionId)
          // alert('Answer Submitted successfully');
          dispatch(openSnackbar({ dataLoad: true, message: 'Answer submitted successfully', severity: "info" }));
  
      } catch (error) {
          // alert('Answer not Submitted successfully')
          dispatch(openSnackbar({ dataLoad: true, message: error.message, severity: "error" }));;
      }
  
  };
  
  
  
      function nextProcessHandler() {
          
        const isValid = validate(); // Validate all required questions
        if (isValid) {
        // console.debug("SurveyEndUser : QuestionWrapper : nextProcessHandler : surveyQuestions : ", surveyQuestions);
        // console.debug("SurveyEndUser : QuestionWrapper : nextProcessHandler : surveyQuestionMasterData : ", surveyQuestionMasterData);
        // console.debug("SurveyEndUser : QuestionWrapper : nextProcessHandler : followUpRulesResource : ", followUpRulesResource);
        // console.debug("SurveyEndUser : QuestionWrapper : nextProcessHandler : selectedQuestionAnswers : ", selectedQuestionAnswers);
            submitSurveyResponse().then(r=>{
                //  props.nextClickHandler(page);
                if(surveyData.workFlow){
                    // console.debug("SurveyEndUser : QuestionWrapper : nextProcessHandler : workFlow if block : ");
                    // console.debug("SurveyEndUser : QuestionWrapper : nextProcessHandler : workFlow if block : ");
                    // Need to modify to work for multiple If block
                    let condition = followUpRulesResource?.condition[0];
                        if (condition) {
                            // console.debug("SurveyEndUser : QuestionWrapper : nextProcessHandler : submitSurveyResponse : condition : ", condition);
                            condition.fallBackQuestionId=followUpRulesResource.fallBackQuestionId
                            condition.fallBackQuestionTypeId=followUpRulesResource.fallBackQuestionTypeId
                            // console.debug("SurveyEndUser : QuestionWrapper : nextProcessHandler : submitSurveyResponse : updated condition : ", condition);
                            // console.debug("SurveyEndUser : QuestionWrapper : nextProcessHandler : submitSurveyResponse : selectedQuestionAnswers : ", selectedQuestionAnswers);
        
                            let conditionStatus = evaluateCondition(condition, selectedQuestionAnswers);
                            // console.debug("SurveyEndUser : QuestionWrapper : nextProcessHandler : submitSurveyResponse : conditionStatus : ", conditionStatus);
                            // console.debug("SurveyEndUser : QuestionWrapper : nextProcessHandler : submitSurveyResponse : followUpRulesResource : ", followUpRulesResource);
                            // console.debug("SurveyEndUser : QuestionWrapper : nextProcessHandler : submitSurveyResponse : surveyQuestionMasterData : ", surveyQuestionMasterData);
                    
                            // let nextQuestion = getNextQuestion(conditionStatus, followUpRulesResource, surveyQuestionMasterData);
                            let nextQuestion = getNextQuestion(conditionStatus, condition, surveyQuestionMasterData);
                            // console.debug("SurveyEndUser : QuestionWrapper : nextProcessHandler : next Question : nextQuestion : ", nextQuestion);
                            
                            if (nextQuestion) {
                                setSurveyQuestionsHandler(nextQuestion);
                            } else {
                                console.error("Next question not found.");
                            }
                        } else {
                            console.error("Invalid or missing condition.");
                        }
                    }else{
                        // console.debug("SurveyEndUser : QuestionWrapper : nextProcessHandler : workFlow else block : ");
                        if(props?.nextClickHandler !== undefined){
                            let page="";
                            if(surveyData?.public){
                                if(surveyData?.showReportAttendeeSettings?.showReport){
                                    page = endUserPageMaster.graph;
                                }else if(surveyData?.showReportAttendeeSettings?.showAttendee){
                                    page = endUserPageMaster.attendees;
                                }else{
                                    page = endUserPageMaster.thankYou;
                                }
                            }else{
                                if(surveyData?.showReportAttendeeSettings?.showReport){
                                    page = endUserPageMaster.graph;
                                }else{
                                    page = endUserPageMaster.thankYou;
                                }
                            }
        
                            props.nextClickHandler(page);
                            
                        }else{
                            console.error("Something Went Wrong");
                        }
                    }
            }).catch(e=>{
        
            });
        } else {
            console.debug('SurveyEndUser : QuestionWrapper : nextProcessHandler : Validation failed:', errors); // If invalid, log errors
        }  
  
      }
  
  /**
   * Evaluates the condition based on the provided operator and value.
   * @param {Object} condition - The condition object containing type, operator, and value.
   * @param {Array} selectedAnswers - The selected answers to evaluate against the condition.
   * @returns {boolean} - The result of the condition evaluation.
   */
  function evaluateCondition(condition, selectedAnswers) {
      let countOfResponse = 0;
      // console.debug("SurveyEndUser : QuestionWrapper : evaluateCondition : condition : ", condition);
      // console.debug("SurveyEndUser : QuestionWrapper : evaluateCondition : selectedAnswers : ", selectedAnswers);
  
      if (condition.conditionType === 'count') {
          selectedAnswers.forEach(answer => {
              if (answer.answer === condition.response) {
                  countOfResponse++;
              }
          });
          // console.debug("SurveyEndUser : QuestionWrapper : evaluateCondition : countOfResponse : ", countOfResponse);
          // console.debug("SurveyEndUser : QuestionWrapper : evaluateCondition : condition.conditionOperator : ", condition?.conditionOperator);
          // console.debug("SurveyEndUser : QuestionWrapper : evaluateCondition : condition.conditionValue : ", condition?.conditionValue);
          return evaluateConditionStatus(countOfResponse, condition.conditionOperator, condition.conditionValue);
      }
      return false;
  }
  
  /**
   * Evaluates the condition status based on the operator and values.
   * @param {number} count - The count of responses that match the condition.
   * @param {string} operator - The operator to use for evaluation.
   * @param {number} value - The value to compare against.
   * @returns {boolean} - The result of the evaluation.
   */
  function evaluateConditionStatus(count, operator, value) {
      switch (operator) {
          case '==': return count == value;
          case '!=': return count != value;
          case '>': return count > value;
          case '>=': return count >= value;
          case '<': return count < value;
          case '<=': return count <= value;
          default:
              console.error('Invalid condition operator:', operator);
              return false;
      }
  }
  
  /**
   * Retrieves the next question based on the condition status.
   * @param {boolean} conditionStatus - The result of the condition evaluation.
   * @param {Object} followUpRules - The follow-up rules resource containing question IDs.
   * @param {Array} questionsData - The array of survey questions data.
   * @returns {Object|null} - The next question object or null if not found.
   */
  function getNextQuestion(conditionStatus, followUpRules, questionsData) {
      let questionId = conditionStatus ? followUpRules.followUpQuestionId : followUpRules.fallBackQuestionId;
      return questionsData.find(question => question.id === Number(questionId)) || null;
  }
  
  
  useEffect(function(){
      if(surveyQuestions?.length>0 && surveyQuestions?.[0]?.questionType?.identifier==questionTypeConst?.endPage){
          setFooterNextButtonVisibilityStatus(false);
      }
  },[surveyQuestions]);
  
  // footerNextButtonVisibilityStatus
  
  
  
    const handleAnswerSelect = (question, answer) => { 
      // console.debug("SurveyEndUser : QuestionWrapper : handleAnswerSelect : question : ", question)
      // console.debug("SurveyEndUser : QuestionWrapper : handleAnswerSelect : answer : ", answer)
   
      setSelectedAnswers(prevAnswers => ({
          ...prevAnswers,
          // [question.id]: {
          //     optionChoiceId: answer.optionChoiceId,
          //     optionChoiceName: answer.optionChoiceName
          // }
          [question.id]: answer,
      }));
  
      let tempSelectedQuestionAnswers = [...selectedQuestionAnswers];
  
      if (question?.id) {
          const selectedAnswerIndex = tempSelectedQuestionAnswers.findIndex(d => d.question?.id === question?.id);
      
          if (selectedAnswerIndex > -1) {
              // If the question already exists, update the answer
              tempSelectedQuestionAnswers[selectedAnswerIndex].answer = answer;
              setSelectedQuestionAnswers([...tempSelectedQuestionAnswers]);
          } else {
              // If the question does not exist, add a new question-answer pair
              setSelectedQuestionAnswers(prevAnswers => [
                  ...prevAnswers,
                  { question, answer }
              ]);
          }
      } else {
          console.error("Invalid question: question ID is missing.");
      }
  };
  
  
    useEffect(
      function () {
        setSurveyQuestionsHandler(surveyQuestionMasterData[0]);
      },
      [surveyQuestionMasterData]
    );
  
    useEffect(
      function () {
        if (props?.surveyData !== undefined) {
          let tempSurveyData = props.surveyData?.data;
          if (tempSurveyData?.surveyQuestion !== undefined) {
            // setSurveyQuestions(tempSurveyData.surveyQuestion);
            console.debug(
              "SurveyEndUser : QuestionWrapper : useEffect : tempSurveyData.surveyQuestion : ",
              tempSurveyData.surveyQuestion
            );
          //   setSurveyQuestionsHandler(tempSurveyData.surveyQuestion[0])
            setSurveyQuestionMasterData(tempSurveyData.surveyQuestion);
          }
          setSurveyData(tempSurveyData);
          setPublicPrivate(tempSurveyData?.public);
          setPublish(tempSurveyData?.isPublished);
          setShowAttendee(
            tempSurveyData?.showReportAttendeeSettings?.showAttendee
          );
          setShowReport(tempSurveyData?.showReportAttendeeSettings?.showReport);
          setCloseSurvey(tempSurveyData?.isOpen);
          setStartDate(tempSurveyData?.startDate);
          setEndDate(tempSurveyData?.endDate);
          
          if(props?.surveyData?.data?.public===0){
              ApiService.get('userInfo').then(res => {
                  let response = res.data.data;
                  setUserId(res.data.data.userId)
                  console.log('a5', response);
                  if (response) {
                      dispatch(setUserInfo({
                          ...response,
                          // userAvatar: response.userAvatar,
                      }));
                  }
              });
          }
  
        }
      },
      [props?.surveyData]
    );
  
    const filteredChildQuestions = (data) =>
      data?.childQuestions?.filter(
        (cData) =>
          cData?.questionType?.identifier !== "multiple-choice" ||
          (cData?.optionChoice && cData?.optionChoice.length > 0)
      );
  
    return (
      <Box sx={{ px: "1rem",}}>
          <Box
          className="scrollHeight"
          sx={{
            height: "calc(100vh - 143px)",
            overflow: "auto",
            "&:hover": {
              overflowY: "auto",
            },
            "&": {
              overflowY: "hidden",
            },
          }}
        //   sx={{ height: "calc(100vh - 143px)", overflow: "auto" }}
        >
            <Box sx={{p:1,pt:2}}>
                {surveyQuestions?.length > 0 ? 
                    (
                        <Box >
                        {surveyQuestions?.[0].questionType?.identifier !== questionTypeConst.endPage?
                            (<h1>
                                Questionnaire
                            </h1>)
                            :""}
                            {
                                surveyQuestions.map((data) => {
                                // console.debug("a10", data?.questionType?.identifier);
                                    return data?.questionType?.identifier === "question-group" ? 
                                        (
                                            filteredChildQuestions(data)?.map((cData) => (
                                                <QuestionComponentWrapper
                                                    questionDataObject={cData}
                                                    handleAnswerSelect={handleAnswerSelect}
                                                    error={errors[cData.id]} // Pass error message as a prop
                                                />
                                            ))
                                        )
                                        : 
                                        (
                                            <Box key={data.id}>
                                                {" "}
                                                {/* Added key prop */}
                                                {data?.questionType?.identifier== questionTypeConst.endPage?
                                                    <HtmlRender htmlContent={data.questionDescription.description}/>
                                                
                                                :
                                                <QuestionComponentWrapper
                                                    questionDataObject={data}
                                                    handleAnswerSelect={handleAnswerSelect}
                                                    error={errors[data.id]} // Pass error message as a prop
                                                />
                                                }

                                            </Box>
                                        );
                                    }
                                )
                            }
                        </Box>  
                    ) 
                : (
                      <>
                          {[...Array(6)].map((_, index) => (
                          <Box key={index} sx={{ mb: 2 }}>
                              {" "}
                              {/* Added mb for space between boxes */}
                              <Skeleton
                              variant="text"
                              sx={{ fontSize: "1rem", width: "30%", mb: 1 }}
                              />
                              <Skeleton
                              variant="text"
                              sx={{ fontSize: "1rem", width: "30%", ml: 2, mb: 1 }}
                              />
                              <Skeleton
                              variant="text"
                              sx={{ fontSize: "1rem", width: "30%", ml: 2, mb: 1 }}
                              />
                              <Skeleton
                              variant="text"
                              sx={{ fontSize: "1rem", width: "30%", ml: 2, mb: 1 }}
                              />
                          </Box>
                          ))}
                      </>
                  )
              }
  
          </Box>
  
              
          </Box>
  
          <EndUserFooterButton nextProcessHandler={nextProcessHandler}
              nextButtonVisibilityStatus={footerNextButtonVisibilityStatus}
          />
  
      </Box>
    );
  
  }
  
  export default QuestionWrapper;
  
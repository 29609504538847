import { Box } from '@mui/material';
import React, { useState, useEffect } from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import PropTypes from 'prop-types';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  border: '1px solid #c6c6c6',
  borderRadius: '2rem',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      '&:focus': {
        width: '100%',
      },
    },
  },
}));

const UploadFiles = ({ uploadedFiles, onSelectImage, selectedImage }) => {
  const [apiImages, setApiImages] = useState([]);
console.log('uploadedFiles',uploadedFiles)
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get('https://filemanagerapiv2.eela.tech/api/file-list', {
          params: { app: '1234562', identifier: 'survey-questionFileType' }
        });
        
        const images = response.data.data.map(img => ({
          mediaPath: img.mediaPath,
          name: img.name || 'Uploaded image'
        }));

        const reversedApiImages = [...images].reverse();
        const allImages = [...uploadedFiles, ...reversedApiImages];
        setApiImages(allImages);

        if (uploadedFiles.length > 0 && !selectedImage) {
          onSelectImage(uploadedFiles[uploadedFiles.length - 1]);
        }
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();
  }, [uploadedFiles, onSelectImage, selectedImage]);

  const renderImages = () => {
    const uploadedSection = uploadedFiles.map((image, index) => (
      <ImageListItem
        key={`uploaded-${index}`}
        onClick={() => onSelectImage(image)}
        sx={{
          border: selectedImage?.mediaPath === image.mediaPath
            ? '2px solid #1976d2'
            : '1px solid #eee',
          borderRadius: 1,
          overflow: 'hidden',
          cursor: 'pointer'
        }}
      >
        <img
          src={image.mediaPath || image.url}
          alt={image.name}
          style={{ height: 140, objectFit: 'cover' }}
        />
        {/* <Box sx={{ p: 1 }}>
          <Typography variant="caption" noWrap>{image.name}</Typography>
        </Box> */}
      </ImageListItem>
    ));

    const apiSection = apiImages
      .filter(image => !uploadedFiles.some(uploaded => uploaded.mediaPath === image.mediaPath))
      .map((image, index) => (
        <ImageListItem
          key={`api-${index}`}
          onClick={() => onSelectImage(image)}
          sx={{
            border: selectedImage?.mediaPath === image.mediaPath
              ? '2px solid #1976d2'
              : '1px solid #eee',
            borderRadius: 1,
            overflow: 'hidden',
            cursor: 'pointer'
          }}
        >
          <img
            src={image.mediaPath || image.url}
            alt={image.name}
            style={{ height: 140, objectFit: 'cover' }}
          />
          {/* <Box sx={{ p: 1 }}>
            <Typography variant="caption" noWrap>{image.name}</Typography>
          </Box> */}
        </ImageListItem>
      ));

    return [...uploadedSection, ...apiSection];
  };

  return (
    <Box>
      <Search>
        <SearchIconWrapper>
          <SearchIcon />
        </SearchIconWrapper>
        <StyledInputBase
          placeholder="Search…"
          inputProps={{ 'aria-label': 'search' }}
        />
      </Search>
      
      <ImageList cols={2} gap={8}>
        {renderImages()}
      </ImageList>
    </Box>
  );
};

UploadFiles.propTypes = {
  uploadedFiles: PropTypes.array.isRequired,
  onSelectImage: PropTypes.func.isRequired,
  selectedImage: PropTypes.object
};


export default UploadFiles;

import React, { useContext, useState, useEffect } from 'react'
import { ContentCopy, Drafts, Box, Tooltip, Typography, tooltipClasses, DragIndicator, IconButton, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, MoreVert, styled, ContentCopyIcon, BlockIcon, DeleteIcon, ListItemButton } from "../../lib/utils/AllImportsHelper"
import { useDispatch, useSelector } from 'react-redux'
import { Add, Block, Delete, ExpandLess, ExpandMore, StarBorder } from '@mui/icons-material';
import CanvasSubLayoutContext from '../../lib/layouts/CanvasSubLayout/CanvasSubLayoutContext';
import MenuComponent from '../Menu/MenuComponent';
import QuestionTypeIcon from './questionSettings/questionType/QuestionTypeIcon';
import { addQuestionInSurveyQuestionList, removeQuestionFromSurveyQuestionList, updateSurvey, setSelectedQuestion, setSelectedParentQuestion, updateSelectedQuestionAndQuestionInQuestionArray } from '../../redux/slice/surveyQuetions/surveyQuestionSlice';
import { Collapse } from '@mui/material';
import ApiService from '../../ApiService';
import QuestionContext from './QuestionContext';
import AddQuestions from './AddQuestions';
import { openSnackbar, closeSnackbar } from '../../redux/snackbar/snackbarSlice';
import { updateQuestionStatus } from '../../redux/slice/surveyQuetions/surveyQuestionSlice';
import { CSSTransition } from 'react-transition-group';
import { keyframes } from '@emotion/react';
import { setSelectedQuestionOptions } from '../../redux/survey/selectedQuestionOptionsSlice';
import { setSelectedQuestionDescription } from '../../redux/survey/selectedQuestionDescriptionSlice';
import { setSelectedQuestionDescriptionStatus } from '../../redux/survey/selectedQuestionDescriptionStatusSlice';
import { setSelectedQuestionRequiredStatus } from '../../redux/survey/selectedQuestionRequiredStatusSlice';
import { modeBasedDataProcessor } from '../../lib/utils/developmentMode';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#347edd',
        color: '#ffffff',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: '#347edd',
    },
}));

const StyledBox = styled(Box)`
  &.animated-list-content {
    transition: all 500ms cubic-bezier(0.4, 0, 0.2, 1);
    overflow: hidden;
  }

  &.list-enter {
    opacity: 0;
    max-height: 0;
    transform: translateY(-20px);
  }

  &.list-enter-active {
    opacity: 1;
    max-height: 2000px; // Increased to accommodate more content
    transform: translateY(10);
  }

  &.list-exit {
    opacity: 1;
    max-height: 2000px;
    transform: translateY(10);
  }

  &.list-exit-active {
    opacity: 0;
    max-height: 0;
    transform: translateY(-20px);
  }
`;




function QuestionListItem({ question, index, questionTypeWithCategory, questionType, selectedQuestionId, onSelectItemChangeHandler, ...props }) {
    console.debug("AddQuestionDrawer : props",questionTypeWithCategory, questionType,props)
    const [opened, setOpened] = React.useState(true);
    const questions = useSelector(state => state.surveyQuestion.questions);
    const selectedQuestion = useSelector(state => state.surveyQuestion.selectedQuestion);

    const [status, setStatus] = useState(Number(question?.status));
    const [loading, setLoading] = useState(false);

    const surveyQuestion = useSelector(state => state.surveyQuestion);

    const selectedQuestionDescriptionStatus = useSelector(state => state.selectedQuestionDescriptionStatus);
    const selectedQuestionDescription = useSelector(state => state.selectedQuestionDescription);
    const selectedQuestionRequiredStatus = useSelector(state => state.selectedQuestionRequiredStatus);
    const selectedQuestionOptions = useSelector(state => state.selectedQuestionOptions);
    // const selectedQuestionDescriptionStatus = useSelector(memoizedSelectedQuestionDescriptionStatus)

    function updateCurrentSelectionChanges() {
        let currentSelectedQuestionParent = surveyQuestion.selectedParentQuestion;
        let currentSelectedQuestion = surveyQuestion.selectedQuestion;
        let updatedCurrentSelectedQuestion = { ...currentSelectedQuestion }
        updatedCurrentSelectedQuestion.questionRequired = selectedQuestionRequiredStatus.activeStatus;
        updatedCurrentSelectedQuestion.questionDescriptionStatus = selectedQuestionDescriptionStatus.activeStatus;
        updatedCurrentSelectedQuestion.questionDescription = selectedQuestionDescription.descriptionObject;
        updatedCurrentSelectedQuestion.optionChoice = selectedQuestionOptions.options;
        let actionPayload = { question: updatedCurrentSelectedQuestion, parentQuestion: currentSelectedQuestionParent };
        dispatch(updateSelectedQuestionAndQuestionInQuestionArray(actionPayload));
    }

    function onSelectItemStateHandler(selectedItem) {
        if (selectedItem?.questionType?.identifier == "multiple-choice") {
            dispatch(setSelectedQuestionOptions([...selectedItem?.optionChoice]));
        } else {
            dispatch(setSelectedQuestionOptions([]));
        }
        if (selectedItem?.questionDescriptionId !== undefined) {
            dispatch(setSelectedQuestionDescription(selectedItem?.questionDescription));
        }
        if (selectedItem?.questionDescriptionStatus !== undefined) {
            // dispatch(setSelectedQuestionDescriptionStatus({ descriptionStatus: selectedItem?.questionDescriptionStatus }));
            dispatch(setSelectedQuestionDescriptionStatus(selectedItem?.questionDescriptionStatus));
        }
        if (selectedItem?.questionRequired && selectedItem?.questionRequired !== undefined) {
            // dispatch(setSelectedQuestionDescriptionStatus({ descriptionStatus: selectedItem?.questionDescriptionStatus }));
            dispatch(setSelectedQuestionRequiredStatus(selectedItem?.questionRequired));
        }
        dispatch(setSelectedQuestion(selectedItem))
    }

    function onSelectItemChangeHandlerV2(e, selectedItem, selectedPrarentItem) {
        if (selectedPrarentItem !== undefined && selectedPrarentItem !== null) {
            dispatch(setSelectedParentQuestion(selectedPrarentItem))
        } else {
            dispatch(setSelectedParentQuestion(null))
        }


        // let updatedSelectedItem = updateCurrentSelectionChanges(selectedItem);
        // let updatedSelectedItem = updateCurrentSelectionChanges(selectedItem);
        // onSelectItemStateHandler(updatedSelectedItem);

        updateCurrentSelectionChanges(); //updated the currentSelection

        onSelectItemStateHandler(selectedItem);
    }



    // useEffect(() => {
    //     console.log("Questions updated:", questions);
    // }, [questions]);
    useEffect(() => {
        setStatus(Number(question?.status));
    }, [question]);

    useEffect(() => {
        setStatus(props?.selectedQuetionItem?.status !== undefined ? Number(props?.selectedQuetionItem?.status) : undefined);
    }, [props?.selectedQuetionItem]);

    const toggleCollapse = (e) => {
        // e.stopPropagation();
        setOpened(prevState => {
            console.log("Toggling state:", !prevState);
            return !prevState;
        });
    };

    const open = true;
    let dispatch = useDispatch()
    // const selectedQuestion = useSelector(state => state.surveyQuestion.selectedQuestion);
    console.debug("props?.selectedQuetionItem", props?.selectedQuetionItem?.questionType?.identifier)

    const handleAddQuestion = (e, selectedQuestionType) => {

        if (props?.selectedQuestionTypeToAddHandler !== undefined) {
            props.selectedQuestionTypeToAddHandler(null, selectedQuestionType);
        } else {
            console.error("selectedQuestionTypeToAddHandler Not FOund")
        }


        let formData = {
            surveyId: props.surveyId,
            questionTypeId: selectedQuestionType.id,
            question: "Untitled Question",
            returnPayload: true,
        };
        let related = ['questionType'];
        related.forEach((data, i) => {
            formData[`related[${i}]`] = data;
        });

        if (question?.questionType?.identifier === "question-group") {
            formData.questionGroupId = question.id;
        }
        // // handleMenuClose();
        // ApiService.post('questionCreate', formData).then(r => {
        //     if (r?.data) {
        //         const newQuestion = r.data.data;
        //         if (onSelectItemChangeHandler !== undefined) {
        //             onSelectItemChangeHandler(null, selectedQuestionType);
        //         } else {
        //             console.error("selectedQuestionTypeToAddHandler Not FOund")
        //         }
        //         dispatch(addQuestionInSurveyQuestionList({
        //             newQuestion,
        //             parentQuestionId: question?.questionType?.identifier === "question-group" ? question.id : null
        //         }));
        //         dispatch(setSelectedQuestion(newQuestion));
        //         dispatch(openSnackbar({ dataLoad: true, message: 'Question added successfully', severity: "info" }));
        //     }
        // }).catch(e => {
        //     dispatch(openSnackbar({ dataLoad: true, message: e.message, severity: "error" }));
        //     console.error("questionCreate error:", e);
        // });

        // closeAddQuestionsDrawer();
        handleMenuClose();
    };

    const [anchorMenu, setAnchorMenu] = useState(null);
    const handleMenuClose = (e) => {
        // e.stopPropagation();
        setAnchorMenu(null);
    };
    const flag = 'true'
    const addCss = 'true'


    const menuItemsList = React.useMemo(() => [
        question?.questionType?.identifier === 'question-group' ? 
        {
            id: 1, name: (<Typography variant='body2' sx={{ display: 'flex', width: '100%' }}>Add Question
                <AddQuestions
                    // {console.debug('a1',as)}
                    flag={flag}
                    addCss={addCss}
                    questionType={questionType}
                    questionTypeWithCategory={questionTypeWithCategory}
                    selectedQuestionTypeToAddHandler={handleAddQuestion}
                />
            </Typography>), 
            icon: (
                <AddQuestions
                    flag={flag}
                    questionType={questionType}
                    questionTypeWithCategory={questionTypeWithCategory}
                    selectedQuestionTypeToAddHandler={handleAddQuestion}
                />
            ),
            onClick: (e) => handleMenuClose(e)
        } 
        // { id: 4, name: "Add Question", icon: <DeleteIcon />, onClick: (e) => onClickDeleteHandler(e, question) }
        : null,
        { id: 2, name: "Duplicate", icon: <ContentCopyIcon />, onClick: (e) => onClickDuplicateHandler(e, question) },
        ...modeBasedDataProcessor([{
            id: 3,
            name: status === 0 ? "Enable" : "Disable",
            icon: <BlockIcon />,
            onClick: (e) => onClickDisableHandler(e, question)
        }]),
        { id: 4, name: "Delete", icon: <DeleteIcon />, onClick: (e) => onClickDeleteHandler(e, question) },
    ].filter(Boolean), [status, question]);


    const openMenuIcon = Boolean(anchorMenu);

    const handleMenuOpen = (event) => {
        setAnchorMenu(event.currentTarget);
    };


    const onClickAddQuestionHandler = (e, item) => {
        console.debug(
            "add Item"
        )
    }
    // console.debug("Check Staus value ", status)
    // const onClickDuplicateHandler = (e, item) => {
    //     console.debug("menucomponent : ClickDuplicateApi", item)
    //     console.debug("item.id", item)
    //     if (item?.id) {
    //         ApiService.get('duplicateQuestion', {}, item?.id).then(response => {
    //             console.debug("duplicateQuestion: list", response.data)
    //             if (response) {
    //                 // if questtiontype is group then add in child else add in question
    //                 dispatch(addQuestionInSurveyQuestionList(response.data.data))
    //             }
    //         }).catch(e => {
    //             console.debug("SurveyCreate : useEffect : questionTypeList : catch :", e);
    //         })
    //         handleMenuClose();
    //     }
    // }
    const onClickDuplicateHandler = async (e, item) => {
        console.debug("menucomponent : ClickDuplicateApi", item);
        console.debug("item.id", item);
        if (item?.id) {
            try {
                const response = await ApiService.get('duplicateQuestion', {}, item?.id);
                console.debug("duplicateQuestion: list", response.data);
                if (response && response.data && response.data.data) {
                    const newQuestion = response.data.data;
                    // Determine if this is a child question or not
                    const parentQuestionId = item.parentId || null;
                    if (selectedQuestion?.questionType?.identifier == "question-group") {
                        if (props?.onSelectItemChangeHandler !== undefined) {
                            props.onSelectItemChangeHandler(null, newQuestion, selectedQuestion);
                        } else {
                            console.error("onSelectItemChangeHandler No FOund");
                        }
                    } else {
                        if (props?.onSelectItemChangeHandler !== undefined) {
                            props.onSelectItemChangeHandler(null, newQuestion);
                        } else {
                            console.error("onSelectItemChangeHandler No FOund");
                        }
                    }
                    // const surveyQuestion = useSelector(state => state.surveyQuestion);
                    dispatch(addQuestionInSurveyQuestionList({
                        newQuestion: newQuestion,
                        parentQuestionId: parentQuestionId,
                        afterQuestionId: item.id
                    }));
                    dispatch(openSnackbar({ message: 'Question duplicated successfully', severity: "info" }));
                }
            } catch (e) {
                dispatch(openSnackbar({ message: e.message, severity: "error" }));
            }
            handleMenuClose();
        }
    };

    const onClickDisableHandler = (e, item) => {
        console.debug('a8')
        if (item?.id) {
            // setLoading(true);
            const newStatus = status === 0 ? 1 : 0;

            const formData = { status: newStatus };
            console.debug('a8',formData)
            if (item.questionType?.identifier === 'question-group') {
                formData.questionType = 'question-group';
            }

            // Update local state and Redux state immediately for responsive UI
            setStatus(newStatus);
            console.debug('a8', setStatus(newStatus))
            dispatch(updateQuestionStatus({ id: item.id, status: newStatus, isGroup: item.questionType?.identifier === 'question-group' }));

            ApiService.post('updateQuestion', formData, item.id)
                .then(response => {
                    if (response?.data) {
                        dispatch(updateSurvey(response?.data));
                        // props.onSelectItemChangeHandler(response?.data);
                        onSelectItemChangeHandlerV2(null, response?.data, null)
                        if (item.questionType?.identifier === 'question-group' && item.childQuestions) {
                            item.childQuestions.forEach(childQuestion => {
                                dispatch(updateQuestionStatus({ id: childQuestion.id, status: newStatus }));
                            });
                        }
                        dispatch(openSnackbar({ dataLoad: true, message: newStatus == 1 ? 'Enable successfully' : 'Disable successfully', severity: "info" }));
                    }
                })
                .catch(e => {
                    console.error("Error updating question status:", e);
                    // Revert the status if API call fails
                    setStatus(status);
                    dispatch(updateQuestionStatus({ id: item.id, status, isGroup: item.questionType?.identifier === 'question-group' }));
                    if (item.questionType?.identifier === 'question-group' && item.childQuestions) {
                        item.childQuestions.forEach(childQuestion => {
                            dispatch(updateQuestionStatus({ id: childQuestion.id, status }));
                        });
                    }
                    dispatch(openSnackbar({ dataLoad: true, message: e.message, severity: "error" }));
                })
                .finally(() => {
                    // setLoading(false);
                });

            handleMenuClose();
        }
    };
    const onClickDeleteHandler = (e, item) => {
        console.debug("onClickDeleteHandler", item);
        if (item?.id) {
            ApiService.delete('deleteQuestion', {}, item?.id).then(response => {
                if (response) {
                    dispatch(removeQuestionFromSurveyQuestionList({ id: item.id }));
                    dispatch(openSnackbar({ message: 'Question deleted successfully', severity: "info" }));

                    // If the deleted question was selected, clear the selection
                    if (selectedQuestion?.id === item.id) {
                        dispatch(setSelectedQuestion(null));
                    }
                }
            }).catch(e => {
                dispatch(openSnackbar({ message: e.message, severity: "error" }));
            });
        }
    };

    console.debug("question?.questionType?.questionTypeLabel", question?.questionType?.questionTypeLabel)

    const isSelected = props?.selectedQuetionItem?.id === question?.id;
    const isChildSelected = question?.questionType?.identifier === "question-group" &&
        question.childQuestions?.some(childQ => childQ.id === selectedQuestionId);
    console.debug("QuestionList ListItemButton : props ", props)
    console.debug("QuestionList ListItemButton : props onSelectItemChangeHandler", onSelectItemChangeHandler)

    return (
        <>
            <ListItemButton
                className='listButtonBase'
                key={index}
                sx={{
                    '&.listButtonBase:hover': {
                        backgroundColor: props?.selectedQuetionItem?.status ? '#f5f5f5' : '#EAF3FF !important'
                    },
                    '&.Mui-selected': {
                        backgroundColor: '#DBEAFE',
                        borderRadius: '5px',
                        margin: '2px',
                        '&.MuiListItemButton-root:hover': {
                            backgroundColor: '#E1EDFC !important',
                        }
                    },
                    position: 'relative',
                    '&:hover .more-vert-button': {
                        opacity: 1,
                    },
                    ...(isChildSelected && {
                        borderLeft: '2px solid #1976d2', // Highlight color for parent group
                        paddingLeft: '13px', // Adjust padding to compensate for border
                        borderRadius: '5px',
                        background: 'aliceblue'
                    }),
                }}
                // selected={props?.selectedQuetionItem?.id === question?.id}
                selected={isSelected}
                // onClick={(e) => props?.onSelectItemChangeHandler(e, question, props?.parentQuestion)}
                onClick={(e) => onSelectItemChangeHandlerV2(e, question, props?.parentQuestion)}
            >
                <DragIndicator sx={{ pr: 1 }} />
                <ListItemIcon
                    sx={{
                        minWidth: '40px !important',
                        paddingLeft: (question?.questionGroupId || 0) ? "14px" : "0px",
                    }}
                >
                    <QuestionTypeIcon questionTypeLabel={question?.questionType?.identifier} />
                </ListItemIcon>
                {question?.question?.length > 25 ? (
                    <LightTooltip title={question?.question} arrow>
                        <ListItemText primary={question?.question?.substring(0, 20) + '...'} />
                    </LightTooltip>
                ) : (
                    <ListItemText
                        primary={question?.question}
                        sx={{
                            // backgroundColor: question.id === lastAddedQuestionId ? '#FFFF00' : 'inherit',
                            transition: 'background-color 0.3s'
                        }}
                    />
                )}
                <>
                    <MenuComponent
                        menuItem={menuItemsList}
                        itemValue={question}
                        index={index}
                        selectedQuetionItem={selectedQuestion}
                        status={status}
                        loading={loading}
                        menuActiveStatus={openMenuIcon}
                        handleMenuClose={handleMenuClose}
                        anchorMenu={anchorMenu}
                    />
                    <Box>
                        <IconButton
                            aria-label="comment"
                            onClick={(e) => handleMenuOpen(e, index)}
                            className="more-vert-button"
                            sx={{
                                opacity: 0,
                                transition: 'opacity 0.3s',
                                position: 'absolute',
                                right: 0,
                                top: '50%',
                                transform: 'translateY(-50%)'
                            }}
                        >
                            <MoreVert />
                        </IconButton>
                    </Box>
                </>
                {
                    question?.questionType?.identifier === "question-group" && (
                        <IconButton onClick={toggleCollapse} sx={{ mr: '1.5rem' }}>
                            {opened ? (
                                <Box>
                                    <ExpandLess />
                                </Box>
                            ) : (
                                <Box>
                                    <ExpandMore />
                                </Box>
                            )}
                        </IconButton>
                    )
                }
            </ListItemButton>



            {question?.questionType?.identifier === "question-group" && (
                <CSSTransition
                    in={opened}
                    timeout={500} // Increased to 500ms
                    classNames="list"
                    unmountOnExit

                >
                    <StyledBox className="animated-list-content"
                        sx={{
                            maxWidth: 360,
                            overflow: 'hidden',
                            '&:hover': {
                                overflow: 'auto',
                            },

                            borderLeft: '1px dashed #1976d2',

                            ml: '22px',
                            mr: '5px'
                            // borderRadius:'5px'
                        }}
                    >
                        {question?.childQuestions && question.childQuestions.length > 0 ? (
                            question.childQuestions.map((childQuestion, childIndex) => (
                                <QuestionListItem
                                    question={childQuestion}
                                    key={childQuestion.id}
                                    index={childIndex}
                                    {...props}
                                    parentQuestion={question}
                                    selectedQuestionId={selectedQuestionId}
                                    style={{ paddingLeft: 32 }}
                                />
                            ))
                        ) : (
                            <Box sx={{ paddingLeft: '5rem', py: '0.5rem' }}>
                                <Typography variant="body2">No questions are listed.</Typography>
                            </Box>
                        )}
                    </StyledBox>
                </CSSTransition>
            )}
            {/* { question?.questionType?.identifier === "question-group" && question.childQuestions && (
                <Collapse in={opened} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {question.childQuestions.map((childQuestion, childIndex) => (
                            <QuestionListItem
                                key={childQuestion.id}
                                question={childQuestion}
                                index={childIndex}
                                {...props}
                            />
                        ))}
                    </List>
                </Collapse>
            )} */}
        </>

    );
}

export default QuestionListItem;

const routes = {
    dashboard: "/",
    surveyCreate: "/survey/create/",
    surveyCreateAssessment: "/survey/create-assessment/",
    surveyEdit: "/survey/edit/",
    surveyEditAssessment: "/survey/edit-assessment/",
    surveyList: "/survey",
    surveyConnect: "/survey/connect/",
    surveySetting: "/survey/setting/",
    surveyShare: "/survey/share/",
    surveyResult: "/survey/result/",
    surveyGenAI: "/survey/genAI",
    surveyEndUser: "/survey/enduser/",
    surveyPreview: "/survey/preview/",
    surveyYourOpinion:'/survey/your-opinion/',
    surveyFlow: "/survey/logic-flow/",
    

};

export default routes;
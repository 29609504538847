import React, { useEffect, useState } from 'react';
import { Box, Switch, Grid, TextField, Typography, FormControlLabel, } from '../../../lib/utils/AllImportsHelper';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedQuestionDescriptionStatus } from '../../../redux/survey/selectedQuestionDescriptionStatusSlice';
import { setSelectedQuestionDescription } from '../../../redux/survey/selectedQuestionDescriptionSlice';


function QuestionSettingDescription(props) {
    let dispatch = useDispatch();
    const selectedQuestionDescriptionStatus = useSelector(state => state.selectedQuestionDescriptionStatus);
    const selectedQuestionDescription = useSelector(state => state.selectedQuestionDescription);

    const [check, setCheck] = useState(false);
    const [description, setDescription] = useState();
    const activeStatusChangeHandler = (e) => {
        setCheck(e.target.checked)
        dispatch(setSelectedQuestionDescriptionStatus(e.target.checked))
        // console.debug('a9', dispatch(selectedQuestionDescriptionStatus(e.target.checked)));
    };
    useEffect(function () {
        setCheck(selectedQuestionDescriptionStatus.activeStatus)
        // console.debug('a9', dispatch(selectedQuestionDescriptionStatus));
    }, [selectedQuestionDescriptionStatus]);

    useEffect(function () {
        if (selectedQuestionDescription.descriptionObject?.description !== undefined)
            setDescription(selectedQuestionDescription.descriptionObject.description)
    }, [selectedQuestionDescription]);


    const quetionChangeHandler = (event) => {
        setDescription(event.target.value);
    };

    const textFieldBlurHandlder = () => {
        dispatch(setSelectedQuestionDescription({ ...selectedQuestionDescription.descriptionObject, description: description }))
    };
    const textFieldKeyDownHandler = (e) => {
        if (e.key === 'Enter') {
            textFieldBlurHandlder();
        }
    };

    return (
        <Box sx={{ backgroundColor: '#ECEFF1', p: 1, mb: 2, borderRadius: 2 }}>
            <Box>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={8} >
                        <Typography variant="subtitle2" >Add Description</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Switch checked={Boolean(check)} inputProps={{ 'aria-label': 'controlled' }}
                            onChange={activeStatusChangeHandler}
                        />
                    </Grid>
                </Grid>
            </Box>
            {check === true && <Box>
                <TextField id="outlined-basic" label="Write Here" variant="outlined"
                    fullWidth sx={{ backgroundColor: 'white', mt: 1 }}
                    // contentEditable={true}
                    value={description}
                    onChange={quetionChangeHandler}
                    onBlur={textFieldBlurHandlder}
                    onKeyDown={textFieldKeyDownHandler}
                />
            </Box>}
        </Box>
    );
}

export default QuestionSettingDescription;
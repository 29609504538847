import React from 'react';
import { Table, AdminLayout as TinggitThemeProvider } from '../../lib';
import {
  HelloComponent,
  //  HelloComponentWithHook
} from '../../lib';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import SecurityIcon from '@mui/icons-material/Security';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import EditIcon from '@mui/icons-material/Edit';
import { useCallback, useEffect, useState } from 'react';
import ApiService from '../../ApiService';
import TableToolbar from '../../component/tableToolbar/TableToolbar';
import { Box, Grid, Avatar, Typography, Card, CircularProgress, Drawer } from '@mui/material';
// import TableToolbar from '../../TableToolbar';
import { useNavigate } from "react-router-dom";
import routes from '../../routesComponent/Routes';
// import { updateSurvey} from '../../redux/slice/surveyQuetions';
import { addArrayOfQuestionsInSurveyQuestionList, updateSurvey } from '../../redux/slice/surveyQuetions/surveyQuestionSlice';
import { useDispatch } from 'react-redux';
import { surveyOrigin } from '../../constants/surveyOrigin';
import { modeBasedDataProcessor } from '../../lib/utils/developmentMode';
import CreateMaturityModal from './CreateMaturityModal';
import CreateWorkflowdetails from './CreateWorkflowdetails';

function SurveyList(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingAssessment, setLoadingAssessment] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState('dashboard');

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDrawerSurveyOpen, setIsDrawerSurveyOpen] = useState(false);

  const toggleDrawer = () => {
      setIsDrawerOpen(!isDrawerOpen);
  };
  const toggleSurveyDrawer = () => {
    setIsDrawerSurveyOpen(!isDrawerSurveyOpen);
};
  useEffect(function () {

    dispatch(updateSurvey("null"));
  }, []);

  function surveyListDataChangeHandler(updatedData) {
    if (updatedData) {
      console.debug("TableToolbar : onDuplicateClickHandler : r : surveyListDataChangeHandler : ", updatedData)
      setData([...updatedData]);
    }
  }

  const itemsList = [
    {
      id: "1", name: "Dashboard", icon: <SecurityIcon />, path: routes.dashboard
    },
    {
      id: "2", name: "Survey", icon: <SecurityIcon />, path: routes.surveyList
    },
  ];

  const logoInfo = {
    logo: "https://gatewayapi.eela.tech/media/THP1234560/companyLogo?file=thpLogoN.png",
    logoAltText: "Vikram"
  }

  const loggedInUserData = {
    fName: "Vikram",
    mName: "pratap",
    lName: "Singh",
    uAvatar: "https://gatewayapi.eela.tech/media/THP1234560/companyLogo?file=thpLogoN.png",
  }

  const [columnsHeadings, setColumnsHeadings] = useState([]);

  const setMaterialTableRowLimit = null;
  const setPageNo = null;
  const pageNo = null;
  const page = null;
  const setPage = null;
  const metaData = null;

  // const selectedRows = null;
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const ColumnsHeadingsTemp = [
    // {
    //   id: 'select',
    //   label: '', 
    //   width: 30,
    //   headerTemplate: () => (
    //     <> <Checkbox onClick={checkboxGridHelper.headerCheckboxListener} checked={headerCheckStatus} indeterminate={headerIntermidiateStatus} /> </>),
    //   headerName: 'Type', flex: 1,
    //   template: (key) => (
    //     <>
    //       <Box>
    //         <Checkbox
    //           disabled={key?.disable}
    //           onClick={() => checkBoxHandler(key)}
    //         checked={selectedRows.has(key.id)}
    //         />
    //       </Box>
    //     </>
    //   ),
    // },
    {
      id: 'id',
      label: 'Id',
      // minWidth: 300,
      // wdith:"10px",
      // maxWidth:"18px",
      // Final and corrected as per API DOC
      name: 'id',
      selector: row => row.id,
    },
    {
      id: 'surveyTitle',
      label: 'Name',
      minWidth: 100,
      // Final and corrected as per API DOC
      name: 'surveyTitle',
      selector: row => row.questionGroupName,
    },
    // {
    //   id: 'uuid',
    //   label: 'UUID',
    //   minWidth: 100,
    //   // Final and corrected as per API DOC
    //   name: 'UUID',
    //   selector: row => row.uuid,
    // },
    {
      id: 'description',
      label: 'Description',
      name: 'description',
      minWidth: 100, selector: row => row.description,
    },
    {
      id: 'isPublished',
      label: 'Published',
      wdith: "5px",
      name: 'isPublished',
      selector: row => row.isPublished ? "Yes" : "No",
    },
    {
      id: 'session',
      label: 'Respondents',
      minWidth: 100,
      name: 'id',
      selector: row => {
        return row?.session?.length
      },
    },
    {
      id: 'public',
      label: 'Audience',
      wdith: "5px",
      name: 'public',
      selector: row => row.public ? "Open For Public" : "Organization",
    },
    {
      id: 'startDate',
      label: 'Start Date',
      wdith: "10px",
      name: 'startDate',
      selector: row => row.startDate ? row.startDate : "-",
    },
    // { id: 'created_at', label: 'Old Designation', minWidth: 100},
    // { id: 'newDesignation', label: 'New Designation', minWidth: 100},
  ]

  const fetchData = useCallback((page, rowsPerPage) => {
    // Simulate remote data fetching
    console.debug("SurveyList : fetchData : Called : ", rowsPerPage);
    return new Promise((resolve, reject) => {
      let param = { limit: rowsPerPage ? rowsPerPage : 5, page: page };
      console.debug("EnhancedTableA : fetchData : Promise : param : ", param);
      ApiService.get('surveyList', param).then(r => {
        // console.debug("SurveyList : EnhancedTableA : fetchData : Promise : then : r : ", r.data.data);
        console.debug("SurveyList : EnhancedTableA : fetchData : Promise : then : r : ", r);
        // console.debug("App : EnhancedTable : surveyList : then : r : ", r.data.data);
        // setData(r.data.data)
        resolve({ tableData: r.data.data, totalData: r.data.meta.total });
        // return ({ tableData: r.data.data, page: r.data.meta.current_page, totalData: r.data.meta.total });
      }).catch(e => {
        console.debug("EnhancedTableA : surveyList : Catch : e : ", e);
        reject(e); // Reject the promise in case of an error
      });
    });
  }, []);


  useEffect(() => {
    // console.debug("SurveyList : useEffect");
    setColumnsHeadings(ColumnsHeadingsTemp);
  }, []);

  const [selectedRows, setSelectedRows] = useState([]);
  // const [selectedRows, setSelectedRows] = useState(new Set());


  const searchHandler = (data, e) => {
    console.debug("searchHandler : data ", data);
    console.debug("searchHandler : e ", e);
  }

  const onCreateHandler = (e, item) => {
    if (e && e.preventDefault) {
      e.preventDefault(); // Only call preventDefault if e exists
    }
    // e.preventDefault();
    // let postBody = { defaultQuestion: true };
    let postBody = {
      defaultQuestion: true,
      returnPayload: 1,
      questionGroupName: item.name, 
      description: item.description,
      workFlow: item.workflowType,
    };
    if (item?.identifier) {
      postBody.surveyOriginIdentifier = item.identifier;
    }
    // console.debug("SurveyList : onCreateHandler : item : ", item);
    // console.debug("SurveyList : onCreateHandler : postBody : ", postBody);

    if (item.identifier == surveyOrigin.assessmentBased) {
      setLoadingAssessment(true)
    } else {
      setLoading(true)
    }


    ApiService.post('surveyCreate', postBody).then(r => {
      // console.debug("SurveyList : onCreateHandler :then : r : ", r);
      // console.debug("SurveyList : onCreateHandler :then : r : ", r.data.data);

      if (r?.data?.data) {
        let surveyResponse = r.data.data;
        if (surveyResponse) {
          dispatch(updateSurvey(surveyResponse));
          dispatch(addArrayOfQuestionsInSurveyQuestionList(surveyResponse?.surveyQuestion));
          // dispatch(updateSurvey(surveyResponse.surveyQuestion));
          if (item.identifier == surveyOrigin.assessmentBased) {
            navigate(routes.surveyCreateAssessment + surveyResponse.id);
          } else {
            navigate(routes.surveyCreate + surveyResponse.id);
          }
        }
        setLoading(false)
        setLoadingAssessment(false)

      }
    }).catch(e => {
      setLoading(false)
      setLoadingAssessment(false)

      console.debug("SurveyCreate : onCreateHandler :catch : e : ", e);
    });
    // id
  }

  const onCreateAI = () => {
    navigate(routes.surveyGenAI)
  }


  const handleRowSelected = (selectedRow) => {
    setSelectedRows(selectedRow);
  };

  const cards = [
    // ...modeBasedDataProcessor([
    //   {
    //   title: 'Create from Scratch',
    //   identifier: surveyOrigin.manual,
    //   icon: loading ? <CircularProgress /> : <EditIcon sx={{ color: 'black' }} />,
    //   onClick: onCreateHandler
    // },
  // ]),
  {
    title: 'Create from Scratch',
    identifier: surveyOrigin.manual,
    icon: loading ? <CircularProgress /> : <EditIcon sx={{ color: 'black' }} />,
    onClick: toggleSurveyDrawer
  },
    {
      title: 'Create with AI',
      identifier: surveyOrigin.aiGenerated,
      icon: <AutoAwesomeIcon sx={{ color: 'black' }} />,
      onClick: onCreateAI
    },
    {
      title: 'Create Assessment',
      identifier: surveyOrigin.assessmentBased,
      icon: loadingAssessment ? <CircularProgress /> : <ContentPasteSearchIcon sx={{ color: 'black' }} />,
      onClick: onCreateHandler
    }
    // {
    //   title: 'Create Assessment',
    //   identifier: surveyOrigin.assessmentBased,
    //   icon: loadingAssessment ? <CircularProgress /> : <ContentPasteSearchIcon sx={{ color: 'black' }} />,
    //   onClick: toggleDrawer
    // }
  ];

  return (
    <TinggitThemeProvider
      sidebarMenuItems={itemsList}
      debug={false}
      logoInfo={logoInfo}
      loggedInUserData={loggedInUserData}
    >
      <Card sx={{ borderRadius: '1rem !important' }}>
        <Grid container spacing={1}>
          {cards.map((card, index) => (
            <Grid item xs={12} sm={6} md={3.5} key={index}>
              <Card
                sx={{ p: 2, cursor: 'pointer', mb: 2 }}
                onClick={(e) => card.onClick(e, card)}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar sx={{ border: '1px dotted black', width: 56, height: 56, mr: 2 }}>
                    {card.icon}
                  </Avatar>
                  <Typography variant="subtitle1">{card.title}</Typography>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Table
          title="Survey"
          // rows={surveyData ? surveyData : []} // removed and pass it indo data props
          setMaterialTableRowLimit={setMaterialTableRowLimit}
          setPageNo={setPageNo}
          pageNo={pageNo}
          page={page}
          setPage={setPage}
          metaData={metaData}
          rowsPerPage={rowsPerPage}
          // Final and corrected as per API DOC
          loading={false}
          columns={columnsHeadings}
          // data={data}
          data={fetchData}
          selectableRows
          // selectableRowsNoSelectAll
          actions={<TableToolbar
            selectedRows={selectedRows}
            surveyListDataChangeHandler={surveyListDataChangeHandler}
            tableData={data}
          />}
          onSelectedRowsChange={handleRowSelected}
          // selectedRows={selectedRows}
          searchHandler={searchHandler}

        />

      </Card>
      <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer}>
       <CreateMaturityModal onClose={toggleDrawer}/>
      </Drawer>
      <Drawer anchor="right" open={isDrawerSurveyOpen} onClose={toggleSurveyDrawer}>
      <CreateWorkflowdetails  onClose={toggleSurveyDrawer}   onCreateHandler={onCreateHandler} />
      </Drawer>
    </TinggitThemeProvider>
  );
}

export default SurveyList;
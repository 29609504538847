import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import SurveyEndUserWorkFlowComponent from '../../component/surveyEndUser/SurveyEndUserWorkFlowComponent';

const SurveyEndUser = ({ isPreview,surveyBackgroundColor,showHeaderFooter, ...props }) => {

    return (
        <Box>
            <SurveyEndUserWorkFlowComponent/>
        </Box>
    );
};

export default SurveyEndUser;

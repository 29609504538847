import React, { useContext, useState } from "react";
import { List, Box } from '@mui/material';
// import { FormControl, MenuItem, Select } from "@material-ui/core";
import { FormControl, MenuItem, Select } from "../../../../lib/utils/AllImportsHelper"
import QuestionContext from "../../QuestionContext";
// import { QuestionContext } from "../../../_services/FromType/QuestionContext";
function Rate(props) {
    const questionContextData = useContext(QuestionContext);
    const [value, setValue] = useState(questionContextData?.selectedQuetionItem?.questionTypeRating?.ratings ||5)
    const quetionChangeHandler = (event) => {
        setValue(event.target.value);
    };
    console.debug("questionContextData", questionContextData.selectedQuetionItem)
    const textFieldBlurHandlder = () => {
        questionContextData.updateQuestionTypeRating(value)
    };
    const textFieldKeyDownHandler = (e) => {
        if (e.key === 'Enter') {
            textFieldBlurHandlder();
        }
    };
    return (
        <Box className="">

            <FormControl sx={{ width: "100%", pt: 1.5 }}>
                <Select
                    size="small"
                    value={value}
                    onChange={quetionChangeHandler}
                    onBlur={textFieldBlurHandlder}
                    onKeyDown={textFieldKeyDownHandler}
                    className="border border-dark rounded pl-3"
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={6}>6</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                    <MenuItem value={8}>8</MenuItem>
                    <MenuItem value={9}>9</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                </Select>
            </FormControl>
        </Box>
    );
}

export default Rate 

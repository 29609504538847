import React, { useState } from 'react';
import { Box, Button, Grid, Typography, IconButton, Card, Avatar, Link } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';

const RecentlyUploadDrawer = ({ onClose }) => {
    const [selectedFiles, setSelectedFiles] = useState([]);

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        const newFiles = files.map((file) => URL.createObjectURL(file));
        setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
    };

    return (
        <Box>
            <Box sx={{ backgroundColor: '#DBEAFE' }}>
                <Box sx={{ mx: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <Box sx={{ marginTop: '0.5rem' }}>
                                <Typography variant="subtitle2" fontWeight="bold">Logo</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Box>
                                <IconButton aria-label="delete" onClick={onClose}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box sx={{ mx: 2 }}>
                <Box sx={{ my: 3 }}>
                    <Box>
                        <Card sx={{
                            border: '1px dashed', height: '8rem',
                            cursor: 'pointer',
                            alignItems: 'center', flexDirection: 'column', justifyContent: 'center', display: 'flex', padding: '10px'
                        }}>
                            <label htmlFor="fileInputDrawer" style={{ cursor: 'pointer', alignItems: 'center' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Avatar sx={{ height: '2.5rem', width: '2.5rem', marginRight: '8px', backgroundColor: '#1976D21F' }}>
                                        <ImageOutlinedIcon color="primary" />
                                    </Avatar>
                                </Box>
                                <Box sx={{ display: 'flex', my: 1 }}>
                                    <Box><Link><Typography variant="subtitle2">click to upload</Typography></Link></Box>
                                    <Box sx={{ ml: 1 }}><Typography variant="subtitle2">or drag and drop</Typography></Box>
                                </Box>
                                <Box><Typography variant="subtitle2" color="#c6c6c6">SVG, PNG, JPG or GIF (max. 3MB)</Typography></Box>
                            </label>
                        </Card>
                        <input
                            type="file"
                            id="fileInputDrawer"
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                            accept=".png, .jpg, .jpeg,.gif"
                            multiple
                        />
                    </Box>
                    <Box sx={{ my: 3 }}>
                        <Grid container spacing={2}>
                            {selectedFiles.map((file, index) => (
                                <Grid item key={index} xs={4}>
                                    <Card sx={{
                                        textAlign: 'center',
                                        height: '92px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                        <img
                                            src={file}
                                            alt={`Selected ${index}`}
                                            style={{
                                                width: '123px',
                                                height: '82px',
                                                objectFit: 'cover',
                                            }}
                                        />
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default RecentlyUploadDrawer;

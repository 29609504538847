import React, { useEffect, useState } from 'react';
import {
    styled, Menu,
    AppBar as MuiAppBar, MenuItem, Paper,
    AccountCircle as AccountCircleIcon, Collections as CollectionsIcon, Undo as UndoIcon, Redo as RedoIcon, Remove as RemoveIcon, Add as AddIcon, Search as SearchIcon, Delete as DeleteIcon,
    Image as ImageIcon, TextFields as TextFieldsIcon, ChangeHistoryTwoTone as ChangeHistoryTwoToneIcon, ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon, ContentCopy as ContentCopyIcon,
    Home as HomeIcon, Preview as PreviewIcon, Download as DownloadIcon, Circle as CircleIcon, Rectangle as RectangleIcon, EggTwoTone, Star as StarIcon, Logout, Settings, FormatColorFill
} from '../../lib/utils/AllImportsHelper';
import CircularProgress from '@mui/material/CircularProgress';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'left',
            horizontal: 'right',
        }}
        {...props}
        sx={{zIndex:'1200 !important'}}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        minWidth: 180,
        // left: '14rem !important',
        // top: '10rem !important',
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
        },
        '& .MuiMenu-paper-MuiPaper-root-MuiPopover-paper': {
            left: '14rem !important',
            top: '10rem !important',
        }
    },
}));


function MenuComponent({ index, ...props }) {

    // console.debug("props.menucomponent", props)

    function onClickHandler() {
        console.debug("menucomponent : props.menucomponent", props.itemValue)
    }
    return (
        <StyledMenu
            id={`demo-customized-menu-${index}`}
            MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
            }}

            anchorEl={props.anchorMenu}
            anchor="right"
            open={props.menuActiveStatus}
            onClose={props.handleMenuClose}
        >
            {props?.menuItem.map((menu, index) =>
                // <MenuItem key={index} onClick={(e) => menu.onClick(e, props.itemValue)} disableRipple className='menuOption'
                // <MenuItem key={index} onClick={(e) => menu.onClick(e, props.question)} disableRipple className='menuOption'
                <MenuItem key={index} onClick={(e) => menu.onClick(e, props.selectedQuetionItem)} disableRipple className='menuOption'
                disabled={props.loading}    
                sx={{
                        '&.menuOption:hover': {
                            backgroundColor: '#EAF3FF !important'
                        },
                    }}>
                    {/* <MenuItem key={index}  onClick={console.debug("jswjdnwk", menu)} disableRipple> */}
                    {/* {menu?.icon}
                    {menu?.name} */}
                   {menu?.icon}
                   {menu?.name}
                    {props.loading && index === 2 && <CircularProgress size={20} style={{ marginLeft: '10px' }} />}
                </MenuItem>
            )}
        </StyledMenu>
    );
}

export default MenuComponent;
import React, { useState, useEffect } from "react";
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { Switch } from "@mui/material";
import { useSelector } from 'react-redux'


function Required(props) {
  return (
    <div className="">
      <ListItemButton className=" d-flex p-0" >
        <ListItemText primary="Required" /> <Switch
          className=""
          checked={props.requiredSetting}
          onClick={props.onChangeTextRequiredHandler}
          // onChange={props.onChangeTextRequiredHandler}
          name="required"
          color="primary"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </ListItemButton>
    </div>
  );
}

export default Required 

import React, { useEffect, useState } from 'react';
import { Box, Switch, Grid, TextField, Typography, } from '../../../lib/utils/AllImportsHelper';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedQuestionRequiredStatus } from '../../../redux/survey/selectedQuestionRequiredStatusSlice';

function QuestionSettingRequired(props) {
    let dispatch = useDispatch();
    // console.debug("QuestionPage : QuetionSettings : QuestionSettingRequired : questionContextData : ", questionContextData?.selectedQuetionItem?.questionRequired);
    const selectedQuestionRequiredStatus = useSelector(state => state.selectedQuestionRequiredStatus);


    const [check, setCheck] = useState(false);
    console.debug('a9', check);

    // const activeStatusChangeHandler = (e) => {
    //     setCheck(e.target.checked);
    //     console.debug('a9', setCheck(e.target.checked));
    //     dispatch(setSelectedQuestionRequiredStatus(e.target.checked))
    //     console.debug('a9', dispatch(setSelectedQuestionRequiredStatus(e.target.checked)));
    // };
    const activeStatusChangeHandler = (e) => {
        const isChecked = e.target.checked;
        setCheck(isChecked);
        console.debug('a9', isChecked);
        dispatch(setSelectedQuestionRequiredStatus(isChecked));
        console.debug('a9', isChecked);
    };

    useEffect(() => {
            setCheck(selectedQuestionRequiredStatus.activeStatus);
            console.debug('a9', selectedQuestionRequiredStatus.activeStatus);
    }, [selectedQuestionRequiredStatus]);

    // useEffect(function () {
    //     setCheck(selectedQuestionRequiredStatus?.activeStatus)
    //     console.debug('a9',setCheck(selectedQuestionRequiredStatus.activeStatus),selectedQuestionRequiredStatus.activeStatus,selectedQuestionRequiredStatus)
    // }, [selectedQuestionRequiredStatus]);

    // useEffect(function () {
    //     setCheck(surveyQuestion?.selectedQuestionRequiredStatus)
    // }, []);

    return (
        <Box sx={{ backgroundColor: '#ECEFF1', p: 1, my: 1, borderRadius: 2 }}>
            <Box>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={8} >
                        <Typography variant="subtitle2" >Required</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Box>
                            <Switch inputProps={{ 'aria-label': 'controlled' }}
                            checked={check}
                            onChange={activeStatusChangeHandler} />
                            </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default QuestionSettingRequired;
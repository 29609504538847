import React from 'react';
import { removeUserToken,generateAuthServerLoginUrlWithCallback } from 'auth-component';

// import { removeUserToken } from '../_helper/secureToken';
function Logout(props) {
    let logoutauthenticationUrl = `${process.env.REACT_APP_AUTHENTICATION_SERVER}/logout?callback=${window.location.origin}`;
    removeUserToken()
    window.location.assign(logoutauthenticationUrl)
}

export default Logout;
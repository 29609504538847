import React from 'react';
import InputTextField from '../../component/form/component/inputTextField.js';
import InputSingleSelect from '../../component/form/component/InputSingleSelect';
import InputAutocomplete from '../../component/form/component/InputAutoComplete.js'
import { subscribe } from '../form/helper/publisher.js';

const RenderHelper = ({ model, id, readOnly, disabled ,onChange = () => {} }) => {
  // console.debug('surveyList',change = () => {})
  const [valueUpdated, setValueUpdated] = React.useState(true);
  const [modelRender, setModelRender] = React.useState(model);

  function subscriberFunction(data) {
    setValueUpdated(false);
  }

  React.useEffect(() => {
    subscribe(subscriberFunction, id);
  }, []);

  React.useEffect(() => {
    if (!valueUpdated) {
      setValueUpdated(true);
    }
  }, [valueUpdated]);

  React.useEffect(() => {
    setModelRender(model);
  }, [readOnly, disabled]);

  React.useEffect(() => {
    setValueUpdated(false);
  }, [modelRender]);

  return valueUpdated ? (
    <>
      {modelRender?.map((v, i) => {
        switch (v.type) {
          case 'textField':
            return <InputTextField model={model} modelKey={i} key={i} id={id} {...v} onChange={(value) => onChange(value, i)} />;
          case 'singleSelect':
            return <InputSingleSelect model={model} modelKey={i} id={id} key={i} {...v} />;
          case 'autocomplete':
            return <InputAutocomplete model={model} modelKey={i} id={id} key={i} {...v} />;
          // case 'dateField': 
          // return (<InputDatePicker model={model} modelKey={i} id={id} key={i} {...v} />);
        }
      })}
    </>
  ) : (
    <></>
  );
};

export default RenderHelper;

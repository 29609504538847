import React, { useState } from "react";
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import { styled } from '@mui/system';
// import SwitchUnstyled, { switchUnstyledClasses } from '@mui/base/SwitchUnstyled';
// import { Box, Switch, TextField, Typography, Grid } from '@mui/material';
import { Switch } from "@mui/material";
import { useContext } from "react";
import { QuestionContext } from "../FromType/QuestionContext";
import Typography from "../../../../lib/theme/overrides/Typography";

function Statement(props) {
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const [isSwitchOn, setIsSwitchOn] = useState(false);

  
  const statementSetting = useContext(QuestionContext);
  const [open, setOpen] = useState(false)

  // const handleSwitch = (e) => {
  //   e.preventDefault();
  //   statementSetting.setQotationMark(true)
  // };
  return (
    <div className="">
      <Typography variant="body1" fontWeight="bold" >
          Setting
        </Typography>
      <div>
        <List
          sx={{ width: '100%', bgcolor: 'background.paper' }}
          aria-labelledby="nested-list-subheader"
        >
          {/* <label className="text-bold">Setting</label> */}
          <ListItemButton className="  d-flex p-0" >
            <ListItemText primary="Quotation marks" />
            <Switch {...label} />
          </ListItemButton>
        </List>
      </div>
    </div>
  );
}

export default Statement 

import React, { useState, useEffect } from "react";
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import { Switch } from "@mui/material";
import { useSelector } from 'react-redux'


function Description(props) {
  const [state, setState] = useState(false)
  const selectedQueObj = useSelector((state) => state.questionList?.selectedQuestionList)

  const handleSwitch = () => {
    setState(!state);
  };
  useEffect(function () {
    if (selectedQueObj) {
      if (selectedQueObj.questionDescription === 1) {
        setState(true)
      }
    }
  }, []);
  return (
    <div className="">
      <ListItemButton className=" d-flex p-0" >
        <ListItemText primary="Description" /> <Switch
          className=""
          checked={props.descriptionSetting}
          onClick={handleSwitch}
          onChange={props.onChangeTextRequiredHandler}
          name="description"
          color="primary"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </ListItemButton>

      {state && <textarea id="w3review" name="w3review" rows="4" className="w-100 fs-14" value="" />

      }
    </div>
  );
}

export default Description 

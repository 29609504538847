import React, { useState } from "react";
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Required from "./Required";
import { Switch } from "@mui/material";
import Description from "./Description";

function Dropdown(props) {
  const [state, setState] = useState(false)

  const handleSwitch = () => {
    setState(!state);
  };
  return (

    <div className="">
      <label className="fs-14">Setting</label>
      <div>
        <List
          sx={{ width: '100%', bgcolor: 'background.paper' }}
          aria-labelledby="nested-list-subheader"
        >
          {/* <Required />
          <Description /> */}

          <ListItemButton className=" p-0 d-flex " >
            <ListItemText primary="Randomize" />
            <Switch
              className=""
              checked={state}
              onClick={handleSwitch}
              // onChange={props.onChangeTextHandler}
              name="description"
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </ListItemButton>
          <ListItemButton className=" p-0 d-flex " >
            <ListItemText primary="Alphabetical order" />
            <Switch
              className=""
              checked={state}
              onClick={handleSwitch}
              // onChange={props.onChangeTextHandler}
              name="description"
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </ListItemButton>
        </List>
      </div>
    </div>
  );
}

export default Dropdown 

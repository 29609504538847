import React, { useCallback, useContext, useEffect, useState } from "react";
import {Box, TextField} from '../../../../lib/utils/AllImportsHelper';
import QuestionTypeContext from "../../../../lib/layouts/QuestionFormatLayout/QuestionTypeContext";
// import { QuestionContext } from "../../../_services/FromType/QuestionContext";
function EmailQues(props) {
  const questionContect = useContext(QuestionTypeContext)
  const [answer,setAnswer]=useState('')

  const quetionChangeHandler = (event) => {
    setAnswer(event.target.value);
  };

  const textFieldBlurHandlder = () => {
    questionContect?.setValue(answer);
  };

  const textFieldKeyDownHandler = (e) => {
    if (e.key === 'Enter') {
      textFieldBlurHandlder();
    }
  };
  return (
   <Box>
      <TextField
        sx={{
          width: "100%", '& .MuiInputBase-input': {
            fontSize: '1rem', // Change this value to your desired font size
          }
        }}
        value={answer}
        readOnly={!questionContect}

        onChange={quetionChangeHandler}
        onBlur={textFieldBlurHandlder}
        onKeyDown={textFieldKeyDownHandler}
        size='small'
        type="email"
        name="number"
        placeholder="Sample@gmail.com"
      />
   </Box>
  );
}

export default EmailQues

import React, { useContext, useEffect, useState } from "react";
// import { QuestionContext } from "../../../_services/FromType/QuestionContext";
import { Button } from "../../../../lib/utils/AllImportsHelper";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
// import { useTheme } from "@emotion/react";

function YesNoQues(props) {
  // console.debug("SurveyEndUser : QuestionInputTypes : YesNoQues : props", props)

  // const answer = useContext(QuestionContext);
  // useEffect(function () {
  //   if (answer) {
  //     if (answer.setPressEnterText) {
  //       answer.setPressEnterText(false)
  //     }
  //   }
  // }, [answer])
  // const theme = useTheme();
  const handleChange = (e) => {
    // console.debug("SurveyEndUser : QuestionInputTypes : YesNoQues : handleChange", e.target.value)
    // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setValue((e.target as HTMLInputElement).value);
    // };
    setSelectedValue(e.target.value);
    if (props?.onAnswerSelect !== undefined) {
      props.onAnswerSelect(e.target.value);
      // console.debug("SurveyEndUser : QuestionInputTypes : YesNoQues : handleChange : if Block", e.target.value, props)

    }


    // const selectedValue = event.target.value;
    // console.debug("SurveyEndUser : submitSurveyResponse : MultipleChoiceEnduser : handleChange : ", event.target.value)
    // console.debug("SurveyEndUser : submitSurveyResponse : MultipleChoiceEnduser : handleChange : selectedValue ", selectedValue)
    // const selectedObject = options.find(option => option.optionChoiceId == selectedValue);
    // console.debug("SurveyEndUser : submitSurveyResponse : MultipleChoiceEnduser : handleChange : options ", options)
    // console.debug("SurveyEndUser : submitSurveyResponse : MultipleChoiceEnduser : handleChange : selectedObject ", selectedObject)
    // setValue(selectedValue);
    // onAnswerSelect(selectedValue);
    //   onAnswerSelect({
    //     optionChoiceId: selectedObject.optionChoiceId,
    //     optionChoiceName: selectedObject.optionChoiceName
    // });
    // console.log(selectedValue)
  };
  const [selectedValue, setSelectedValue] = useState('');

  useEffect(function(){
    if(props?.answer!== undefined){
      setSelectedValue(props?.answer);
    }
  },[props?.answer]);
  // const handleChange = (event) => {
  //   setSelectedValue(event.target.value);
  //   console.log(event.target.value);
  // };

  return (
    <div>
      {/* 
      {answer ? <>
        <Button variant={answer.answer === 'Yes' ? "contained" : "outlined"} name="yesOrNo" value='Yes' className=" mui-button text-black  " startIcon={"Y"} onClick={answer.onChangeAnswer}>
          Yes
        </Button>
        <Button variant={answer.answer === 'No' ? "contained" : "outlined"} name="yesOrNo" value='No' className="  mui-button text-black  ml-3" startIcon={"N"} onClick={answer.onChangeAnswer}>
          No
        </Button>
      </>
        : */}
      <>
        {/* yes no */}
        {/* <Button variant="outlined" className="bg-gray mui-button text-black border border-dark" >
            Yes
          </Button>
          <Button variant="outlined" className=" bg-gray mui-button text-black border border-dark ml-3"  >
            No
          </Button> */}
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            onChange={handleChange}
            // defaultValue={props?.answer??null}
            // defaultValue={props?.answer}
            // defaultValue={props?.answer}
            value={selectedValue}
          >
            <FormControlLabel value="yes" control={<Radio />}   label={
            <span style={{ fontFamily: 'Montserrat, sans-serif',color: selectedValue === 'yes' ? '#000000' : '90a4ae'  }}>
              Yes
            </span>
          } />
            <FormControlLabel value="no" control={<Radio />}   label={
            <span style={{ fontFamily: 'Montserrat, sans-serif', color: selectedValue === 'no' ? '#000000' : '90a4ae'}}>
              No
            </span>
          }/>
          </RadioGroup>
        </FormControl>
      </>
      {/* } */}
    </div >
  );
}

export default YesNoQues

import React, { useContext, useState } from "react";
import { Box, MenuItem, Select, TextField } from '../../../../lib/utils/AllImportsHelper';
import QuestionTypeContext from "../../../../lib/layouts/QuestionFormatLayout/QuestionTypeContext";


function PhoneNumberQues(props) {
  const questionContect = useContext(QuestionTypeContext)
  const [answer, setAnswer] = useState('')

  const quetionChangeHandler = (event) => {
    setAnswer(event.target.value);
  };

  const textFieldBlurHandlder = () => {
    questionContect?.setValue(answer);
  };

  const textFieldKeyDownHandler = (e) => {
    if (e.key === 'Enter') {
      textFieldBlurHandlder();
    }
  };
  return (
    <Box display={'flex'}>
      <Select
      size="small"
        id="country-code-select"
        label="91"
        readOnly={!questionContect}
      >
        <MenuItem value={1}>+1 (USA)</MenuItem>
        <MenuItem value={44}>+44 (UK)</MenuItem>
        <MenuItem value={49}>+49 (Germany)</MenuItem>
      </Select>
      <TextField
        sx={{
          width: "100%",
          mx:'1rem', '& .MuiInputBase-input': {
            fontSize: '1.2rem', // Change this value to your desired font size
          }
        }}
        size="small"
        readOnly={!questionContect}
        onChange={quetionChangeHandler}
        onBlur={textFieldBlurHandlder}
        onKeyDown={textFieldKeyDownHandler}
        type="number"
        name="number"
        placeholder="Enter Phone  Number..." />
    </Box>
  );
}

export default PhoneNumberQues

import React, { useContext, useState, useEffect } from 'react';
import {
    Box,
    Grid,
    Button,
    Link,
    Typography,
    Drawer,
    Card,
    Avatar,
    List,
    ListItemButton,
    ListItemText,
} from "./../../../lib/utils/AllImportsHelper";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import RecentlyUploadDrawer from "../../surveyDrawer/PublishSettingPage/RecentlyUploadDrawer";
import PublishSettingContext from './PublishSettingContext';

function LogoSetting(props) {
    const publishSettingContextData = useContext(PublishSettingContext);

    // console.debug("LogoSetting : publishSettingContextData : ", publishSettingContextData);
    const [open, setOpen] = useState(false);

    const recentlyUploadDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const [selectedId, setSelectedId] = useState(null);
    const [data, setData] = useState([]);
    useEffect(function () {
        setData(publishSettingContextData?.headerLogo);
        setSelectedId(publishSettingContextData?.headerLogo?.filter(d => d?.activeStatus == "1")?.[0]?.id);
    }, [publishSettingContextData?.headerLogo]);


    // useEffect(() => {
    //     if (props?.defaultSelectedId)
    //         setSelectedId(props.defaultSelectedId);
    // }, [props?.defaultSelectedId]);

    const handleListItemClick = (e, d) => {
        // console.debug("TextAlignmentPalette : handleListItemClick : item : ", d);
        setSelectedId(d.id);
        if (d) {
            if (publishSettingContextData?.headerLogoChangeHandler) {
                publishSettingContextData.headerLogoChangeHandler(d);
            }
        }

    }



    return (
        <Box>
            <Box sx={{ my: 2 }}>
                <Box>
                    <Typography variant="subtitle2" fontWeight="bold">
                        Logo
                    </Typography>
                </Box>
                <Box sx={{ my: 1 }}>
                    <Card
                        sx={{
                            border: "1px dashed",
                            height: "8rem",
                            cursor: "pointer",
                            alignItems: "center",
                            flexDirection: "column",
                            justifyContent: "center",
                            display: "flex",
                            padding: "10px",
                        }}
                    >
                        <label
                            htmlFor="fileInput"
                            style={{
                                cursor: "pointer",
                                alignItems: "center",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <Avatar
                                    sx={{
                                        height: "2.5rem",
                                        width: "2.5rem",
                                        marginRight: "8px",
                                        backgroundColor: "#1976D21F",
                                    }}
                                >
                                    <ImageOutlinedIcon color="primary" />
                                </Avatar>
                            </Box>
                            <Box sx={{ display: "flex", my: 1 }}>
                                <Box>
                                    <Link>
                                        <Typography variant="subtitle2">
                                            click to upload
                                        </Typography>
                                    </Link>
                                </Box>
                                <Box sx={{ ml: 1 }}>
                                    <Typography variant="subtitle2">
                                        or drag and drop
                                    </Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Typography variant="subtitle2" color="#c6c6c6">
                                    SVG, PNG, JPG or GIF (max. 3MB)
                                </Typography>
                            </Box>
                        </label>
                    </Card>
                    <input
                        type="file"
                        id="fileInput"
                        onChange={props?.handleFileChange}
                        style={{ display: "none" }}
                        accept=".png, .jpg, .jpeg,.gif"
                        multiple
                    />
                </Box>
            </Box>
            <Box sx={{ my: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Box sx={{ mt: "0.3rem" }}>
                                <Typography
                                    variant="subtitle2"
                                    fontWeight="bold"
                                >
                                    Recently Uploaded
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            <Box sx={{ pb: 1 }}>
                                <Button
                                    variant="text"
                                    endIcon={<ArrowOutwardIcon />}
                                    size="small"
                                    sx={{
                                        textTransform: "capitalize !important",
                                    }}
                                    onClick={recentlyUploadDrawer(true)}
                                >
                                    View all
                                </Button>
                                <Drawer
                                    open={open}
                                    anchor="right"
                                    onClose={recentlyUploadDrawer(false)}
                                    sx={{ width: "25%" }}
                                    PaperProps={{
                                        sx: {
                                            width: "25%",
                                        },
                                    }}
                                >
                                    <RecentlyUploadDrawer
                                        onClose={recentlyUploadDrawer(false)}
                                    />
                                </Drawer>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ my: 2 }}>
                    <Grid container spacing={2}>
                        <List
                            sx={{ display: "flex", padding: 0 }}
                        >
                            {data?.map((d, i) => {
                                return (
                                    <ListItemButton
                                        key={i}
                                        sx={{
                                            padding: "4px",
                                            backgroundColor: selectedId === d.id ? '#1976d2  !important' : 'inherit',
                                            borderRadius: selectedId === d.id ? '5px' : 'inherit',
                                        }}
                                        selected={selectedId === d.id}
                                        onClick={(event) => handleListItemClick(event, d)}
                                    >
                                        <ListItemText sx={{ margin: '0px !important' }} primary={
                                            <Card
                                                sx={{
                                                    textAlign: "center",
                                                    height: "92px",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <img
                                                    src={d?.sectionProperty?.filePath}
                                                    alt={`Selected ${i}`}
                                                    style={{
                                                        width: "123px",
                                                        height: "82px",
                                                        objectFit: "none",
                                                    }}
                                                />
                                            </Card>
                                        }
                                        />
                                    </ListItemButton>
                                )
                            })}
                        </List>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
}

export default LogoSetting;
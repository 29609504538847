// import React, { useContext, useState } from "react";
// import ListItemText from '@mui/material/ListItemText';
// import List from '@mui/material/List';
// import ListItemButton from '@mui/material/ListItemButton';
// import { styled } from '@mui/system';
// // import SwitchUnstyled, { switchUnstyledClasses } from '@mui/base/SwitchUnstyled';
// import Required from "./Required";
// import Description from "./Description";
// import { Switch, TextField } from "@mui/material";
// import { FormTypeContext } from "../FromType/FormTypeContext";
// import { QuestionContext } from "../FromType/QuestionContext";


// function LongText(props) {
//   const Root = styled('span')`
//   font-size: 0;
//   position: relative;
//   display: inline-block;
//   width: 32px;
//   height: 20px;
//   margin: 10px;
//   cursor: pointer;
//   & .${switchUnstyledClasses.track} {
//     background: #b3c3d3;
//     border-radius: 10px;
//     display: block;
//     height: 100%;
//     width: 100%;
//     position: absolute;
//   }
//   & .${switchUnstyledClasses.thumb} {
//     display: block;
//     width: 14px;
//     height: 14px;
//     top: 3px;
//     left: 3px;
//     border-radius: 16px;
//     background-color: #fff;
//     position: relative;
//     transition: all 200ms ease;
//   }
//   &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
//     background-color: rgba(255, 255, 255, 1);
//     box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
//   }
//   &.${switchUnstyledClasses.checked} {
//     .${switchUnstyledClasses.thumb} {
//       left: 14px;
//       top: 3px;
//       background-color: #fff;
//     }
//     .${switchUnstyledClasses.track} {
//       background: #007fff;
//     }
  
//   }
//   & .${switchUnstyledClasses.input} {
//     cursor: inherit;
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     top: 0;
//     left: 0;
//     opacity: 0;
//     z-index: 1;
//     margin: 0;
//   }
//   `;
//   const [open, setOpen] = useState(false)
//   const textSetting = useContext(QuestionContext);

//   const handleSwitch = (e) => {
//     if (e.target.name === 'max') {
//       setOpen(!open)
//     }
//   };
//   return (

//     <div>
//       <label className="fs-14">Setting</label>
//       <List
//         sx={{ width: '100%', bgcolor: 'background.paper' }}
//         aria-labelledby="nested-list-subheader"
//       >
//         <ListItemButton className="d-flex p-0" >
//           <ListItemText primary="Max Character" />
//           <Switch
//             className=""
//             checked={open}
//             onClick={handleSwitch}
//             name="max"
//             color="primary"
//             inputProps={{ 'aria-label': 'primary checkbox' }}
//           />
//         </ListItemButton>
//       </List>
//       {open &&
//         <TextField variant="outlined" className="w-100" value={textSetting.longAnsMaxValue} onChange={(e) => textSetting.setLongAnsMaxValue(e.target.value)} placeholder="0-999999" />
//       }
//     </div>
//   );
// }

// export default LongText
import React, { useState } from 'react';
import { Box, Switch, TextField, Typography, Grid } from '@mui/material';

const ShortText = () => {
  const [isSwitchOn, setIsSwitchOn] = useState(false);

  const handleSwitchChange = (event) => {
    setIsSwitchOn(event.target.checked);
  };

  const label = { inputProps: { 'aria-label': 'Switch demo' } };

  return (
    <Box sx={{p: 2, my: 1}}>
      <Box>
        <Typography variant="subtitle2" fontWeight="bold">
          Setting
        </Typography>
      </Box>
      <Box sx={{ display: 'flex' }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={8}>
            <Typography variant="subtitle1">Max Characters</Typography>
          </Grid>
          <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Box>
              <Switch {...label} checked={isSwitchOn} onChange={handleSwitchChange} />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {isSwitchOn && (
        <Box>
          <TextField
            id="input-field"
            label="0-9999"
            type="number"
            fullWidth
          />
        </Box>
      )}
    </Box>
  );
};

export default ShortText;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedTab: "1",
    tabs: [{ label: 'Node1', value: '1', data: {} }],
};

export const tabNodeDataSlice = createSlice({
    name: 'tabNodeData',
    initialState,
    reducers: {
        addTab: (state, action) => {
            state.tabs.push(action.payload);
        },
        removeTab: (state, action) => {
            state.tabs = state.tabs.filter(tab => tab.value !== action.payload);
        },
        setSelectedTab: (state, action) => {
            state.selectedTab = action.payload;
        },
        updateTabData: (state, action) => {
            const { value, data } = action.payload;
            const tabIndex = state.tabs.findIndex(tab => tab.value === value);
            if (tabIndex >= 0) {
                state.tabs[tabIndex].data = { ...state.tabs[tabIndex].data, ...data };
            }
        },
    },
});

export const { addTab, removeTab, setSelectedTab, updateTabData } = tabNodeDataSlice.actions;
export default tabNodeDataSlice.reducer;
import {  Box, Button, Typography, Grid, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import ConditionComponent from '../nodeDrawer/ConditionComponent';
import { ComboBox } from '../../../lib';
import CloseIcon from '@mui/icons-material/Close';
// import ApiService from '../../../../ApiService';

const NodeUpdateDrawer = ({ toggleDrawer, data, nodes, node }) => {
    const [conditions, setConditions] = useState([]);
    const [selectedFallbackQuestion, setSelectedFallbackQuestion] = useState(null);
    console.debug('nodesDrawer : nodes : Selected Node:', data, conditions);
    console.debug("Node : ColorSelectorNode : NodeUpdateDrawer : node : ", node);

    const handleAddValueClick = () => {
        setConditions([...conditions, { id: Date.now() }]);
    };

    const handleDeleteCondition = (id) => {
        setConditions(conditions.filter((condition) => condition.id !== id));
    };


    const submitConditionHandler = () => {
        console.debug("Node : ColorSelectorNode : NodeUpdateDrawer : submitConditionHandler  : conditions : ", conditions);
        let postBody={condition:[]};

        conditions.forEach(element => {
            let cOArray=[];
            cOArray.push({'key':'conditionOperator','value':element.conditionOperator});
            cOArray.push({'key':'conditionType','value':element.conditionType});
            cOArray.push({'key':'conditionValue','value':element.conditionValue});
            // cOArray.push({'key':'conditionType','value':element.followUpQuestionId});
            // cOArray.push({'key':'conditionType','value':element.followUpQuestionTypeId});
            cOArray.push({'key':'response','value':element.response});
            cOArray.push({'key':'followUpTargetId','value':element.followUpQuestionId});

            postBody.condition=[...postBody.condition,cOArray]
        });

        postBody.currentQuestionId=node?.node?.id;
        postBody.fallBackQuestionTypeId=1;
        postBody.fallBackQuestionId=selectedFallbackQuestion?.id;
        // postBody.followUpTargetId=element?.followUpQuestionId; // this is wrong in live api

        console.debug("Node : ColorSelectorNode : NodeUpdateDrawer : submitConditionHandler  : node : ", node);
        console.debug("Node : ColorSelectorNode : NodeUpdateDrawer : submitConditionHandler  : postBody : ", postBody);

        let followupId=node?.node?.follow_ups?.[0]?.id;
        if(followupId !== null && followupId !== undefined){
            // ApiService.put('questionFollowUpCondition',postBody,followupId).then(r=>{
            //     console.debug("questionFollowUpCondition : r : ",r);
            // }).catch(e=>{
            //     console.error("questionFollowUpCondition : e : ",e);
            // })
        }

    };

    const questionOptions = nodes.map((node) => node.nodeId); // Use nodes from props


    const handleConditionChange = (index, key, value) => {
        const updatedConditions = conditions.map((d, i) =>
            i === index ? { ...d, [key]: value } : d
        );
        console.debug("Node : ColorSelectorNode : NodeUpdateDrawer : handleConditionChange  : conditions : ", conditions);
        console.debug("Node : ColorSelectorNode : NodeUpdateDrawer : handleConditionChange  : index : ", index);
        console.debug("Node : ColorSelectorNode : NodeUpdateDrawer : handleConditionChange  : key : ", key);
        console.debug("Node : ColorSelectorNode : NodeUpdateDrawer : handleConditionChange  : value : ", value);
        console.debug("Node : ColorSelectorNode : NodeUpdateDrawer : handleConditionChange  : updatedConditions : ", updatedConditions);
        setConditions(updatedConditions);
    };

    useEffect(function () {
        // setSelectedFallbackQuestion(node)

        if (node?.node) {
            console.debug("Node : ColorSelectorNode : NodeUpdateDrawer : UseEffect  : if Block : node : ", node);
            setSelectedFallbackQuestion(node?.node?.follow_ups?.[0]?.fall_back_question)
            if (node?.node?.follow_ups?.[0] !== undefined) {
                let conditionArray = node?.node?.follow_ups?.[0]?.condition;
                if (Array.isArray(conditionArray)) {
                    setConditions(conditionArray)
                }
            }
        } else {
            console.debug("Node : ColorSelectorNode : NodeUpdateDrawer : UseEffect  : else Block : node : ", node);
            setSelectedFallbackQuestion(null)
        }
    }, [node]);

    return (
        <Box sx={{ width: '40rem' }}>
            <Box sx={{ p: 1, background: '#e1edfc' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems:'center' }}>
                    <Box><Typography variant='subtitle1' fontWeight='bold'> Node Branching</Typography></Box>
                    <Box>
                    <IconButton aria-label="close" onClick={toggleDrawer(false)}>
                        <CloseIcon />
                    </IconButton>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ m: 2, border: "1px solid #347edd" }}>
                <Box sx={{ p: 1.5, background: "#347edd" }}>
                    <Typography variant='subtitle1' fontWeight='bold'> {node?.node?.question} </Typography>
                </Box>

                <Box sx={{ p: "2rem", height: 'calc(100vh - 190px)', overflow: 'auto', }}>
                    {conditions.map((condition, index) => (
                        <ConditionComponent
                            onDelete={() => handleDeleteCondition(condition.id)}
                            data={data}
                            node={node}
                            condition={condition}
                            key={index}
                            index={index}
                            handleConditionChange={handleConditionChange}
                        />
                    ))}
                    <Box>
                        <Button
                            variant="text"
                            size="small"
                            aria-label="Add Value"
                            startIcon={<AddIcon fontSize="small" />}
                            onClick={handleAddValueClick}
                        >
                            Add Value
                        </Button>
                        <Box>
                            <Grid container spacing={1}>
                                <Grid item xs={3}>
                                    <Box sx={{ mt: "0.5rem" }}>
                                        <Typography variant='subtitle2' fontWeight='bold'>
                                            {conditions.length ? "All other cases go to" : "Always go to"}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={9}>
                                    <ComboBox
                                        options={questionOptions}
                                        optionKey={"id"}
                                        optionLabelKey={"question"}
                                        defaultValue={selectedFallbackQuestion}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', m: 2 }}>
                <Button variant='contained' size='small' aria-label='Save' sx={{ mr: 1 }} onClick={submitConditionHandler}>Save</Button>
                <Button variant='text' size='small' aria-label='Cancel' onClick={toggleDrawer(false)}>Cancel</Button>
            </Box>
        </Box>
    );
};

export default NodeUpdateDrawer;
import React, { useEffect, useState } from 'react';
import { Box, Button, Card, TextField, Radio, RadioGroup, FormControl, FormControlLabel, FormLabel, Typography, AppBar, Toolbar, Grid, Container } from '@mui/material';
import { KeyboardArrowRight, KeyboardBackspace, Replay } from '@mui/icons-material';
import FooterWrapper from '../../lib/layouts/AdminLayout/Footer/FooterWrapper';
import { useParams } from 'react-router-dom';
import ApiService from '../../ApiService';
import QuestionInputTypes from '../../component/question/addQuestion/QuestionInputTypes';
import FooterSurvey from '../../lib/layouts/AdminLayout/Footer/FooterSurvey';
import SurveyEndUser from './SurveyEndUser';
import { useNavigate } from 'react-router-dom';


const SurveyPreview = () => {
    let { id } = useParams()
    const [surveyQuestions, setSurveyQuestions] = useState([])
    const navigate = useNavigate();

    const handleRefresh = () => {
        window.location.reload();
    };
    const [publish, setPublish] = useState()
    const [isOpens, setIsOpen] = useState()
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()

    useEffect(function () {
        ApiService.get('surveyView', {}, id).then(r => {
            console.debug("a9 ", r);
            if (r?.data?.data) {
                console.debug("a9 ", r?.data?.data?.isPublished);
                setPublish(r?.data?.data?.isPublished)
                setIsOpen(r?.data?.data?.isOpen)
                setStartDate(r?.data?.data?.startDate)
                setEndDate(r?.data?.data?.endDate)
                setSurveyQuestions(r.data.data.surveyQuestion)
            }
        }).catch(e => {
            console.debug("SurveyCreate : useEffect :catch : e : ", e);
        });
    }, [])
    console.debug("surveyQuestions", surveyQuestions)

    const currentDate = new Date();
    const surveyStartDate = startDate ? new Date(startDate) : null;
    const surveyEndDate = endDate ? new Date(endDate) : null;
    // const renderSurveyContent = () => {

    //     const currentDate = new Date();
    //     const surveyStartDate = startDate ? new Date(startDate) : null;
    //     const surveyEndDate = endDate ? new Date(endDate) : null;


    //     if (surveyStartDate && surveyEndDate) {
    //         if (currentDate < surveyStartDate) {
    //             return <Box className='scrollHeight' sx={{ height: 'calc(100vh - 31px)', overflow: 'auto', padding: '1rem', mx: '2rem' }}>
    //                 <Box sx={{ display: 'flex', justifyContent: 'center', padding: '13rem' }}>
    //                     <Typography variant="h5" fontWeight='bold'>
    //                         Survey will start on {surveyStartDate.toDateString()}
    //                     </Typography>
    //                 </Box>
    //             </Box>;
    //         } else if (currentDate > surveyEndDate) {
    //             return <Box className='scrollHeight' sx={{ height: 'calc(100vh - 31px)', overflow: 'auto', padding: '1rem', mx: '2rem' }}>
    //                 <Box sx={{ display: 'flex', justifyContent: 'center', padding: '13rem' }}>
    //                     <Typography variant="h5" fontWeight='bold'>
    //                         Survey has expired
    //                     </Typography>
    //                 </Box>
    //             </Box>;
    //         }
    //     }

    //     return (
    //         <Box sx={{ padding: '1rem', mx: '2rem' }}>
    //             <Box className='scrollHeight' sx={{ height: 'calc(100vh - 31px)', overflow: 'auto' }}>

    //                 {!submitted ? (
    //                     <>
    //                         <Box>
    //                             {surveyQuestions?.length > 0 ? (

    //                                 surveyQuestions.map((data) => {
    //                                     console.debug("aman : ", data?.questionType?.identifier);
    //                                     return (data?.questionType?.identifier === "question-group" ?
    //                                         filteredChildQuestions(data)?.map(cData => (
    //                                             <Box key={cData.id}> {/* Added key prop */}
    //                                                 {console.debug("a9", cData?.questionRequired)}
    //                                                 {console.debug("a9", cData)}
    //                                                 <FormControl component="fieldset">
    //                                                     <FormLabel required={cData?.questionRequired === 1} sx={{ color: 'black' }}>
    //                                                         <Box>
    //                                                             {cData.questionSequence ? `${cData.questionSequence}-${cData.question}` : cData.question}
    //                                                         </Box>
    //                                                         <Box>
    //                                                             {cData?.questionDescription?.description ? (
    //                                                                 <span>({cData.questionDescription.description})</span>
    //                                                             ) : null}
    //                                                         </Box>
    //                                                         {/* <Box sx={{ ml: 1 }}>
    //                                                             <Typography variant="subtitle2">
    //                                                                 {cData?.questionDescriptionStatus !== undefined && cData.questionDescriptionStatus == 1 ? (
    //                                                                     cData?.questionDescription?.description
    //                                                                 ) : ""}
    //                                                             </Typography>
    //                                                         </Box> */}
    //                                                     </FormLabel>
    //                                                     {/* {cData.questionDescriptionStatus === 1 && (
    //                                                         <FormLabel sx={{ color: 'black' }}>
    //                                                             {cData.questionDescription}
    //                                                         </FormLabel>
    //                                                     )} */}
    //                                                     <Box sx={{ ml: 2, my: 1 }}>
    //                                                         <QuestionTypeLayout>
    //                                                             <QuestionInputTypes
    //                                                                 selectedQuesType={cData?.questionType?.identifier}
    //                                                                 onAnswerSelect={(answer) => handleAnswerSelect(cData, answer)}
    //                                                                 optionsData={cData?.optionChoice}
    //                                                                 enduser={true}
    //                                                                 value={selectedAnswers[cData.id]?.optionChoiceId || ''}
    //                                                             />
    //                                                             {console.debug("a8", cData?.optionChoice, (answer) => handleAnswerSelect(cData, answer))}
    //                                                         </QuestionTypeLayout>
    //                                                     </Box>
    //                                                 </FormControl>
    //                                             </Box>
    //                                         ))
    //                                         :
    //                                         (
    //                                             <Box key={data.id}> {/* Added key prop */}
    //                                                 <FormControl component="fieldset">
    //                                                     <FormLabel required={data?.questionRequired === 1} sx={{ color: 'black' }}>
    //                                                         {data.questionSequence ? data.questionSequence + "-" + data.question : data.question}
    //                                                         {/* {cData.questionSequence} - {cData.question} */}
    //                                                         <Box>
    //                                                             {data?.questionDescription?.description ? (
    //                                                                 <span>({data.questionDescription.description})</span>
    //                                                             ) : null}
    //                                                         </Box>
    //                                                     </FormLabel>
    //                                                     <Box sx={{ ml: 2, my: 1 }}>
    //                                                         <QuestionTypeLayout>
    //                                                             <QuestionInputTypes
    //                                                                 selectedQuesType={data?.questionType?.identifier}
    //                                                                 enduser={true}
    //                                                                 onAnswerSelect={(answer) => handleAnswerSelect(data, answer)}
    //                                                                 optionsData={data?.optionChoice}
    //                                                             />
    //                                                         </QuestionTypeLayout>
    //                                                     </Box>
    //                                                 </FormControl>
    //                                             </Box>
    //                                         )
    //                                     );
    //                                 }))
    //                                 : (

    //                                     <>
    //                                         {[...Array(6)].map((_, index) => (
    //                                             <Box key={index} sx={{ mb: 2 }}> {/* Added mb for space between boxes */}
    //                                                 <Skeleton variant="text" sx={{ fontSize: '1rem', width: '30%', mb: 1 }} />
    //                                                 <Skeleton variant="text" sx={{ fontSize: '1rem', width: '30%', ml: 2, mb: 1 }} />
    //                                                 <Skeleton variant="text" sx={{ fontSize: '1rem', width: '30%', ml: 2, mb: 1 }} />
    //                                                 <Skeleton variant="text" sx={{ fontSize: '1rem', width: '30%', ml: 2, mb: 1 }} />
    //                                             </Box>
    //                                         ))}
    //                                     </>

    //                                 )

    //                             }
    //                         </Box>
    //                         {/* {renderButton()} */}
    //                     </>
    //                 ) : (
    //                     <Box sx={(thankSubmitted || (!submitted)) ? { display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' } : {}}>
    //                         {renderContent()}
    //                     </Box>
    //                 )}


    //             </Box>
    //             {(
    //                 <Box sx={{ display: 'flex', justifyContent: (!submitted || ['survey', 'graph', 'attendees'].includes(currentStep)) && !thankSubmitted ? 'space-between' : 'center', mt: '1rem' }}>
    //                     {(!submitted && ['survey', 'graph', 'attendees'].includes(currentStep)) ? (
    //                         <Button variant='text' size='small' onClick={handleRestartSurvey} sx={{ display: ['thankYou'].includes(currentStep) ? 'none' : 'block' }}>Restart Survey</Button>
    //                     ) : (
    //                         <Button variant='text' size='small' onClick={handleRestartSurvey} sx={{ display: ['thankYou'].includes(currentStep) ? 'none' : 'block' }}>Restart Survey</Button>
    //                     )}

    //                     {!submitted && showHeaderFooter ? (
    //                         <Button variant='contained'
    //                             onClick={() => submitSurveyResponse()}
    //                             endIcon={<KeyboardArrowRight />} size='small'>Submit</Button>
    //                     ) : (
    //                         null
    //                     )}

    //                     <Box>{renderButton()}</Box>
    //                 </Box>
    //             )}
    //         </Box>
    //     )
    // }

    return (
        <Box sx={{ backgroundColor: "#EEF6FF" }}>
            <AppBar position="static" sx={{ backgroundColor: '#EEF6FF', boxShadow: 'none' }}>
                <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Box>
                        <Button variant='text' startIcon={<KeyboardBackspace />} sx={{ color: 'black' }} onClick={() => navigate(-1)}>Live Preview</Button>
                    </Box>
                    <Box>
                        <Button variant='contained' startIcon={<Replay />} onClick={handleRefresh} disabled={publish === 0 || isOpens === 0 || currentDate < surveyStartDate}>Restart</Button>
                    </Box>
                </Toolbar>
            </AppBar>

            {/* <Container maxWidth="md" sx={{}} > */}
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <Card sx={{ width: '50%', background: 'linear-gradient(to top, #ADB4DC 0%, #D3E1EB 100%)' }}>
                    <Box>

                        <Box sx={{}}>
                            <Box>

                                <SurveyEndUser isPreview={true}
                                    showHeaderFooter={false}
                                />

                            </Box>
                        </Box>
                    </Box>
                </Card>

            </Box>
        </Box>
    );
};

export default SurveyPreview;

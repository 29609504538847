import React from 'react'
import { Box } from '../../../../lib/utils/AllImportsHelper';
import Rate from '../TypeSelect/Rate';
import Phone from '../TypeSelect/PhoneNumber';
import Dropdown from '../TypeSelect/Dropdown';
// import Contact Info from '../TypeSelect/Number';
// import Statement from '../TypeSelect/Statement';
import Number from '../TypeSelect/Number';
import Date from '../TypeSelect/Date';
import OpinionState from '../TypeSelect/OpinionState';
import ContactInfo from '../TypeSelect/PhoneNumber';
import Email from '../TypeSelect/Email';
import Ranking from '../TypeSelect/Ranking';
import Shortext from '../TypeSelect/ShortText';
import LongText from '../TypeSelect/LongText';
import Statement from '../TypeSelect/Statement';
import YesNo from '../TypeSelect/YesNo';
import MultipleChoice from '../TypeSelect/MultipleChoice';
import { Skeleton } from '@mui/material';
import QuestionGroup from '../questionTypeSettingOptionComponent/QuestionGroup';


function QuestionTypeSettingOptions(props) {
    console.debug("props.selectedQuesType", props.selectedQuesType)
    const PAGES = {
        'multiple-choice': MultipleChoice,
        'phone': Phone,
        'short-text': Shortext,
        'long-text': LongText,
        'statement': Statement,
        'ranking': Ranking,
        'email': Email,
        'opinion-state': OpinionState,
        'rating': Rate,
        'date': Date,
        'number': Number,
        'dropdown': Dropdown,
        'Yes/No':YesNo,
        'contact-info': ContactInfo,
        'question-group':QuestionGroup,
        // contact:         ContactPage,
    };
    const Handler = PAGES[props.selectedQuesType] || (() => <Skeleton height={40} />)

    return (
        <Box>
            <Handler {...props} />
        </Box>
    );
}

export default QuestionTypeSettingOptions;
import React from "react";
import List from '@mui/material/List';
import Required from "./Required";
import Description from "./Description";
function Ranking(props) {

  return (

    <div className="">
      <div>
        <List
          sx={{ width: '100%', bgcolor: 'background.paper' }}
          aria-labelledby="nested-list-subheader"
        >
          {/* <label className="text-bold">Setting</label> */}
          {/* <Required />
          <Description onChangeTextRequiredHandler={props.onChangeTextRequiredHandler} descriptionSetting={props.descriptionSetting} /> */}
          Ranking
        </List>
      </div>
    </div>
  );
}

export default Ranking 

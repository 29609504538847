import React, { memo, useState } from 'react';
import { Handle, Position } from '@xyflow/react';
import { Box, IconButton, Drawer, Typography, Menu, MenuItem, Tooltip, tooltipClasses } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import StorageIcon from '@mui/icons-material/Storage';
import NodeUpdateDrawer from '../../component/nodeFolder/nodeDrawer/NodeUpdateDrawer'
import { styled } from '@mui/material/styles';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#347edd',
    color: '#ffffff',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#347edd',
  },
}));



const ColorSelectorNode = memo(({ data, isConnectable }) => {
  const { nodes } = data;

  console.debug('node : nodeDrawer', data);

  // const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [hover, setHover] = useState(false); // New hover state
  const [selectedNode, setSelectedNode] = useState(null);
  // const open = Boolean(anchorEl);


  // const toggleDrawer = (e) =>{
  //   console.debug("Node : nodeDrawer : toggleDrawer : e : ",e);
  //   setDrawerOpen(!open);
  // };
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };


  const nodeEditClickhandler = (e, node) => {
    console.debug("Node : ColorSelectorNode : nodeEditClickhandler : e : ", e);
    console.debug("Node : ColorSelectorNode : nodeEditClickhandler : node : ", node);
    setSelectedNode(node);
    toggleDrawer(e)
  };

  const truncateText = (text, charLimit) => {
    if (text.length > charLimit) {
      return text.slice(0, charLimit) + '...';
    }
    return text;
  };


  const truncatedLabel = truncateText(data.label, 8);

  return (
    <Box
      sx={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      onMouseEnter={() => setHover(true)} // Track mouse hover
      onMouseLeave={() => setHover(false)} // Track mouse leave
    >
      <Box
        sx={{
          border: '1px solid #347edd',
          borderRadius: '0.5rem',
          textAlign: 'center',
          bgcolor: '#fff',
          fontSize: 12,
          position: 'relative',
        }}
      >
        <Handle
          type="target"
          position={Position.Top}
          style={{ background: '#347edd' }}
          onConnect={(params) => console.log('handle onConnect', params)}
          isConnectable={isConnectable}
        />

        <Box display="flex" alignItems="center" justifyContent="center">
          <Box sx={{ background: '#add8e6', p: '3px', borderTopLeftRadius: '0.5rem', borderBottomLeftRadius: '0.5rem' }}>
            <Box sx={{ color: '#fff', display: 'flex' }}>
              {data.icon}
            </Box>
          </Box>
          <LightTooltip title={data.label} arrow>
            <Box sx={{ p: '2px',}}>
              <Typography noWrap>
                {truncatedLabel}
              </Typography>
            </Box>
          </LightTooltip>
          <IconButton
            aria-label="edit"
            size="small"
            // onClick={toggleDrawer(true)}
            // onClick={toggleDrawer(true)}
            // onClick={(e)=>nodeEditClickhandler(e,data)}
            onClick={(e) => {
              // Call both functions here
              toggleDrawer(true)(e); // Call toggleDrawer with "true"
              nodeEditClickhandler(e, data); // Call nodeEditClickhandler
            }}
            sx={{ marginLeft: 1 }}
          >
            <EditIcon sx={{ height: '1rem', width: '1rem' }} />
          </IconButton>
        </Box>

        <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
          <NodeUpdateDrawer toggleDrawer={toggleDrawer} data={data} nodes={nodes} node={selectedNode} />
        </Drawer>

        <Handle
          type="source"
          position={Position.Bottom}
          id="a"
          isConnectable={isConnectable}
        />
        <Handle
          type="source"
          position={Position.Bottom}
          id="b"
          isConnectable={isConnectable}
        />
      </Box>
    </Box>
  );
});


export default ColorSelectorNode;

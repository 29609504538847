import React, { useEffect, useState, useCallback } from 'react';
import { Table } from '../../lib';
import TableToolbar from '../tableToolbar/TableToolbar';
import { useParams } from 'react-router-dom';
import ApiService from '../../ApiService';
import { Box, Drawer, Button, Tooltip, styled, IconButton, tooltipClasses } from '@mui/material';
import QuestionTypeIcon from '../question/questionSettings/questionType/QuestionTypeIcon';
import ScheduleSurveyDrawer from './ScheduleSurveyDrawer';
import { AddBoxIcon } from '../../lib/utils/AllImportsHelper';
import DownloadIcon from '@mui/icons-material/Download';
import { useDispatch, useSelector } from 'react-redux'
import Chip from '@mui/material/Chip';
import { openSnackbar, closeSnackbar } from '../../redux/snackbar/snackbarSlice';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#347edd',
    color: '#ffffff',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#347edd',
  },
}));

const spanStyle = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: 2,
  // width: '50px',
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical'
};

function SurveyResponse(props) {
  let { id } = useParams();
  const surveyQuestion = useSelector(state => state.surveyQuestion);

  const [data, setData] = useState([]);
  const [tableHead, setTableHead] = useState([]);

  const questionColumnWidth = "200px";
  // const  =[];
  const setMaterialTableRowLimit = null;
  const setPageNo = null;
  const pageNo = null;
  const page = null;
  const setPage = null;
  const metaData = null;

  // const selectedRows = null;
  const [rowsPerPage, setRowsPerPage] = useState();
  console.debug('a8',rowsPerPage,setRowsPerPage)


  const [selectedRows, setSelectedRows] = useState(new Set());


  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  let dispatch = useDispatch()
  const userInfo = useSelector((state) => state.user);
  const searchHandler = (data, e) => {
    console.debug("searchHandler : data ", data);
    console.debug("searchHandler : e ", e);
  }


  const handleRowSelected = (selectedRow) => {
    console.debug("handleRowSelected : selectedRows ", selectedRow);
  };



  function getFormattedDate(dateString) {
    let date = new Date(dateString);

    // Extract the day, month, and year
    let day = String(date.getDate()).padStart(2, '0');
    let month = date.toLocaleString('default', { month: 'short' }); 
    let year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }


  const handleDownload = async (row) => {
    console.debug('a7', row);
    let formData = {
      downloadedById: userInfo.userListName.userId,
      reportScheduleId: row.id,
      statusId: row?.reportStatus?.id,
      related: ['status', 'user', 'reportScheduleTracker'],
      returnPayload: 'true'
    };

    try {
      const response = await ApiService.post('downloadReports', { formData, responseType: 'blob' });
      if (response?.data) {
        console.debug('a8', response?.data);
        console.debug('a8', row.filePath);
        const url = row.filePath;
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'report.pdf');
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);


        dispatch(openSnackbar({ dataLoad: true, message: 'Download successfully', severity: 'info' }));
      }
    } catch (e) {
      console.error('Download error:', e);
      dispatch(openSnackbar({ dataLoad: true, message: 'Download failed. please try again.', severity: 'error' }));
    }
  };

  const handleButtonClickDownload = (row) => {
    console.debug('a7', row);
    handleDownload(row);
  };
  const getChipColor = (status) => {
    switch (status) {
      case 'In Progress':
        return 'warning'; // Green
      case 'New':
        return 'info'; // Blue
      case 'Done':
        return 'success'; // Green
      default:
        return 'default'; // Gray for unknown statuses
    }
  };
  const ColumnsHeadingsTemp = [
    {
      id: 'id',
      label: 'Id',
      selector: row => row.id,
    },
    {
      id: 'id',
      label: 'Schedule By',
      selector: row => {
        const user = row?.User?.[0];
        if (user) {
          const { firstName, middleName, lastName } = user;
          return `${firstName || ''} ${middleName || ''} ${lastName || ''}`.trim();
        }
        return '';
      },
    },
    {
      id: 'id',
      label: 'Schedule On',
      selector: row => getFormattedDate(row.scheduledOn),
      // selector: row => (row.scheduledOn),
    },
    {
      id: 'id',
      label: 'Date Range',
      selector: row => {
        return <Box>
          {getFormattedDate(row.sourceDate)}
          <br />
          {getFormattedDate(row.destinationDate)}

        </Box>
      },
    },
    {
      id: 'id',
      label: 'Schedule Status',
      selector: (row) => (
        <Chip
          size="small"
          label={row?.reportStatus[0]?.name || 'New'}
          color={getChipColor(row?.reportStatus[0]?.name || 'New')}
        />
      ),
      // selector: row =>  row?.reportStatus?.name ? row?.reportStatus?.name : 'New',
    },

    {
      id: 'id',
      label: 'Download',
      selector: row => (
        row.reportStatus[0]?.name === 'Done' ? (
          <IconButton
            aria-label="download"
            onClick={() => handleButtonClickDownload(row)}
          >
            <DownloadIcon />
          </IconButton>
        ) : (
          <IconButton aria-label="download" disabled>
            <DownloadIcon />
          </IconButton>
        )
      ),
    }
  ];



  const fetchData = useCallback((page, rowsPerPage) => {
    return new Promise((resolve, reject) => {
      const dataParams = {
        related: ['reportStatus', 'user'],
        // related: ['user'],
        limit: '10',
        surveyId: id,
      }
      ApiService.get('scheduledReports', dataParams).then(r => {
        console.log('a7', r.data.data.filePath)
        resolve({ tableData: r.data.data, totalData: r.data?.meta?.total ?? r.data.data.length });
      }).catch(e => {
        console.debug("EnhancedTableA : surveyList : Catch : e : ", e);
        reject(e); // Reject the promise in case of an error
      });
    });
  }, []);


  useEffect(function () {

  }, []);

  return (
    <Box>
      <Box sx={{ my: 1 }}>
        <Button variant="text" startIcon={<AddBoxIcon />} onClick={toggleDrawer}>
          Add
        </Button>
        <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer}>
          <ScheduleSurveyDrawer onClose={toggleDrawer} />
        </Drawer>
      </Box>
      <Table
        title="Schedule Exports"
        setMaterialTableRowLimit={setMaterialTableRowLimit}
        setPageNo={setPageNo}
        pageNo={pageNo}
        page={page}
        setPage={setPage}
        metaData={metaData}
        rowsPerPage={rowsPerPage}
        // Final and corrected as per API DOC
        loading={false}
        // columns={ColumnsHeadingsTemp}
        columns={ColumnsHeadingsTemp}
        data={fetchData}
        // selectableRows
        // actions={<TableToolbar selectedRows={selectedRows} />}
        // onSelectedRowsChange={handleRowSelected}
        searchHandler={searchHandler}

      />
    </Box>

  );
}

export default SurveyResponse;
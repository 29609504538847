import React, { useEffect } from 'react'
import { Box, Typography, Button } from '@mui/material'
import ErrorImg from '../../../src/assets/ErrorImg.png'

const SurveyErrorPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Box  sx={{display: 'flex',flexDirection: 'column',justifyContent: 'center',alignItems: 'center',textAlign: 'center'}}>
            <Box>
                <img src={ErrorImg} alt="Logo" style={{height: '22rem', width: '22rem'}}/>;
            </Box>
            <Typography variant="h6" gutterBottom>
                404 we can't find the page
            </Typography>
            <Typography variant="body2" gutterBottom>
                It was moved, deleted, or *gasp* never existed.
            </Typography>
            {/* <Button variant="contained" size='small'>Go to my workArea</Button> */}
        </Box>
    )
}

export default SurveyErrorPage
import React, { useRef, useState} from 'react'
import {
    Box, Button, Grid, TextField, Tab, Tabs, Typography, styled, alpha, useTheme, Drawer, Card, CssBaseline, IconButton, Avatar, InputBase, Menu,
    AppBar as MuiAppBar, Toolbar, Divider, ListItemIcon, ToggleButtonGroup, MenuItem, Paper,
    AccountCircle as AccountCircleIcon, Collections as CollectionsIcon, Undo as UndoIcon, Redo as RedoIcon, Remove as RemoveIcon, Add as AddIcon, Search as SearchIcon, Delete as DeleteIcon,
    Image as ImageIcon, TextFields as TextFieldsIcon, ChangeHistoryTwoTone as ChangeHistoryTwoToneIcon, ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon, ContentCopyIcon,
    Home as HomeIcon, Preview as PreviewIcon, Download as DownloadIcon, Circle as CircleIcon, Rectangle as RectangleIcon, EggTwoTone, Star as StarIcon, Logout, Settings, FormatColorFill,
    Tooltip
} from '../../lib/utils/AllImportsHelper';
import FacebookIcon from '@mui/icons-material/Facebook';
import { Skeleton, tooltipClasses } from '@mui/material';
// import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import routes from '../../routesComponent/Routes';
import SurveyEndUser from '../../pages/Survey/SurveyEndUser';
import {getAppToken} from 'auth-component';
// import SurveyLink from './SurveyLink';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#347edd',
        color: '#ffffff',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: '#347edd',
    },
}));

const SurveyLink = () => {

    let { id } = useParams()
    const textInput = useRef(null);
    const surveyQuestion = useSelector(state => state.surveyQuestion);
    const [tooltipMessage, setTooltipMessage] = useState('You are welcome to share this survey.');

    console.debug("SurveyLink : surveyQuestion : ", surveyQuestion)

    // const handleCopy = () => {
    //     const textFieldValue = textInput.current.value;
    //     navigator.clipboard.writeText(textFieldValue).then(() => {
    //         console.log('Text copied to clipboard:', textFieldValue);
    //     }).catch((error) => {
    //         console.error('Error copying text:', error);
    //     });
    // };

    const handleCopy = () => {
        const textFieldValue = textInput.current.value;
        navigator.clipboard.writeText(textFieldValue).then(() => {
            console.log('Text copied to clipboard:', textFieldValue);
            setTooltipMessage('Copied!');
        }).catch((error) => {
            console.error('Error copying text:', error);
        });
    };
    return (
        <Box>
            {surveyQuestion?.survey==null?
            <Box>
                <Skeleton></Skeleton>
                <Skeleton></Skeleton>
                <Skeleton></Skeleton>
                <Skeleton></Skeleton>
            </Box>
            :
                <Box>

                    <Box sx={{ my: 1, pt: 1 }}>
                        <Box sx={{ display: 'flex' }}>
                            <Box sx={{ width: "100%" }}>
                                <TextField
                                    inputRef={textInput}
                                    value={window.location.origin + routes.surveyYourOpinion + surveyQuestion?.survey?.uuid+"?app="+getAppToken()}
                                    variant="outlined"
                                    size="small"
                                    disabled={surveyQuestion?.survey?.isPublished === '0'}
                                    readOnly={surveyQuestion?.survey?.isPublished === '1'}
                                    fullWidth
                                    InputProps={{
                                        readOnly: true,
                                        style: {
                                            userSelect: 'none',  // Prevents text selection
                                        },
                                    }}
                                />
                            </Box>
                            <Box>
                                <LightTooltip
                                    title={
                                        surveyQuestion?.survey?.isPublished === '0' ? (
                                            <Typography>You cannot share this survey until it has been published.</Typography>
                                        ) : (
                                            <Typography>{tooltipMessage}</Typography>
                                        )
                                    }
                                    arrow
                                >
                                    <span>
                                        <IconButton onClick={handleCopy} disabled={surveyQuestion?.survey?.isPublished === '0'}>
                                            <ContentCopyIcon />
                                        </IconButton>
                                    </span>
                                </LightTooltip>
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Typography variant="subtitle1" fontWeight={"bold"}>
                            Share
                        </Typography>
                    </Box>
                    <Box sx={{ my: 1, display: 'flex' }}>
                        <FacebookIcon disabled sx={{ height: 40, width: 40, mr: 2, color: "gray" }} />
                        <LinkedInIcon disabled sx={{ height: 40, width: 40, mr: 2, color: "gray" }} />
                        <TwitterIcon disabled sx={{ height: 40, width: 40, mr: 2, color: "gray" }} />
                    </Box>
                    </Box>
            }
        </Box>
    )
}

export default SurveyLink
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    options: [],
};

const selectedQuestionOptionsSlice = createSlice({
    name: 'selectedQuestionOptionsSlice',
    initialState,
    reducers: {
        setSelectedQuestionOptions: (state, action) => {
            state.options = [...action.payload];
        },
    }
});

export const {
    setSelectedQuestionOptions,
} = selectedQuestionOptionsSlice.actions;

export default selectedQuestionOptionsSlice.reducer;
import React, { useEffect, useState } from 'react';
import {
    Box,
} from "../../../lib/utils/AllImportsHelper";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';



const baseBoxStyle = {
    width: "40px",
    height: "40px",
    borderRadius: "5px",
    // border: "10px solid",
    borderImageSlice: 1,
    // borderWidth: "4px",
};

const boxStyles = {
    box1: {
        ...baseBoxStyle,
        backgroundColor: "#FFFFFF",
        // borderImageSource:
        //     "linear-gradient(to left, #FF00F5, #FF0000, #FFC700, #04E1FF, #A303BE, #2400FF)",
    },
    box2: {
        ...baseBoxStyle,
        backgroundColor: "#DEE3FF",
        borderImageSource:
            "linear-gradient(to left, #FF0000, #FFC700, #04E1FF, #A303BE, #2400FF, #FF00F5)",
    },
    box3: {
        ...baseBoxStyle,
        backgroundColor: "#DEE3FF",
        borderImageSource:
            "linear-gradient(to left, #FFC700, #04E1FF, #A303BE, #2400FF, #FF00F5, #FF0000)",
    },
};

const BoxComponent = ({ id, value }) => {
    // console.debug("DesignColorPalette : BoxComponent : value : ", value)

    const style = boxStyles[id];
    return <Box
        style={{ ...style, backgroundColor: value }}
        sx={{
            borderRadius: ".5rem"
        }}

    // sx={{ mr: 2 }}
    ></Box>;
};



function DesignColorPalette(props) {

    // console.debug("DesignColorPalette : props : ", props);

    const [selectedId, setSelectedId] = useState(null);
    const [data, setData] = useState([]);
    useEffect(function () {
        // console.debug("DesignColorPalette : props?.itemData : ", props?.itemData)
        setData(props?.itemData);
    }, [props?.itemData]);


    useEffect(() => {
        if (props?.defaultSelectedId)
            setSelectedId(props.defaultSelectedId);
    }, [props?.defaultSelectedId]);

    const handleListItemClick = (e, d) => {
        // console.debug("DesignColorPalette : handleListItemClick : item : ", d);
        setSelectedId(d.id);
        if (d) {
            if (props?.changeHandler) {
                props.changeHandler(d);
            }
        }

    }

    return (
        <Box
            sx={{ display: "flex" }}
        >

            <List
                sx={{ display: "flex", padding: 0 }}
            >
                {data?.map((d, i) => {
                    // console.debug("DesignColorPalette : BoxComponent : d : ", d)
                    return (
                        <ListItemButton
                            key={i}
                            sx={{
                                padding: "4px",
                                backgroundColor: selectedId === d.id ? '#1976d2 !important' : 'white',
                                borderRadius: selectedId === d.id ? '5px !important' : '0px',
                            }}
                            selected={selectedId === d.id}
                            onClick={(event) => handleListItemClick(event, d)}
                        >
                            <ListItemText sx={{ margin: '0px !important' }} primary={
                                <BoxComponent id="box1" value={d?.sectionProperty?.value}>
                                    {d?.value}
                                </BoxComponent>}
                            />
                        </ListItemButton>
                    )
                })}
            </List>


        </Box>
    );
}

export default DesignColorPalette;
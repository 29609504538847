import React, { useRef, useState, useEffect } from 'react';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import MuiAlert from '@mui/material/Alert';
import {
  Avatar, Box, Card, Typography, Snackbar, Grid, IconButton, ImageList,
  ImageListItem
} from '@mui/material';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#347edd',
    color: '#ffffff',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#347edd',
  },
}));

const FileUpload = ({ onSelectImage, selectedImage, uploadedFiles, setUploadedFiles }) => {
  const fileInputRef = useRef(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('Image uploaded successfully!');

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Check if the file is already uploaded
    const isDuplicate = uploadedFiles.some((img) => img.name === file.name && img.size === file.size);
    if (isDuplicate) {
      setSnackbarMessage('This file has already been uploaded.');
      setOpenSnackbar(true);
      return;
    }

    const formData = new FormData();
    formData.append('file[0]', file);
    formData.append('identifier', 'survey-questionFileType');

    try {
      const response = await fetch('https://filemanagerapiv2.eela.tech/api/handle-upload?app=1234562', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const responseData = await response.json();
        const newImage = {
          file,
          name: file.name,
          size: file.size,
          url: URL.createObjectURL(file),
          mediaPath: responseData.data[0].mediaPath
        };

        // Add the new image to the uploadedFiles state
        setUploadedFiles((prev) => [...prev, newImage]);
        onSelectImage(newImage); // Automatically select the new image
        setSnackbarMessage('Image uploaded successfully!');
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error('Upload failed:', error);
      setSnackbarMessage('Upload failed. Please try again.');
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleFileDelete = (index, event) => {
    event.stopPropagation();
    setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  return (
    <Box>
      {/* <Card onClick={handleUploadClick} sx={{ cursor: 'pointer', padding: 2, textAlign: 'center' }}>
        <Avatar><ImageOutlinedIcon color="primary" /></Avatar>
        <Typography>Click to upload image</Typography>
      </Card> */}
      <Card
        sx={{
          border: "1px dashed",
          height: "8rem",
          cursor: "pointer",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          display: "flex",
          padding: "10px",
          height: 'calc(100vh - 285px)',
          background: '#eef6ff',
        }}
      >
        <label style={{ cursor: "pointer", alignItems: "center" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Avatar
              sx={{
                height: "2.5rem",
                width: "2.5rem",
                marginRight: "8px",
                backgroundColor: "#1976D21F",
              }}
            >
              <ImageOutlinedIcon color="primary" />
            </Avatar>
          </Box>
          <Box sx={{ display: "flex", my: 1 }}>
            <Box onClick={handleUploadClick}>
              <Typography variant="subtitle2" sx={{ color: 'blue', textDecoration: 'underline' }}>
                click to upload
              </Typography>
            </Box>
            <Box sx={{ ml: 1 }}>
              <Typography variant="subtitle2">
                or drag and drop
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography variant="subtitle2" color="#c6c6c6">
              SVG, PNG, JPG or GIF (max. 3MB)
            </Typography>
          </Box>
        </label>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          style={{ display: "none" }}
          accept=".png, .jpg, .jpeg, .gif"
          multiple
        />
      </Card>


      {/* <ImageList cols={2} gap={8}> */}
      {uploadedFiles.map((item, index) => (
        <Box sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={3}>
              <Box
                key={index}
                onClick={() => onSelectImage(item)}
              >
                <img
                  src={item.url}
                  alt={item.name}
                  style={{ height: 80, width: 80 }}
                />
              </Box>
            </Grid>
            <Grid item xs={6} md={6}>
              <Box>
                {item.name.length > 25 ? (
                  <LightTooltip title={item.name} arrow>
                    <Typography variant="subtitle2" color="text.primary" sx={{ display: 'inline-block', maxWidth: '150px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {item.name.slice(0, 20) + '...'}
                    </Typography>
                  </LightTooltip>
                ) : (
                  <Typography variant="subtitle2" color="text.primary">
                    {item.name}
                  </Typography>
                )}
                <Typography variant="subtitle2" color="text.secondary">
                  {(item.size / 1024).toFixed(2)} KB
                </Typography>
              </Box>
            </Grid>
            {/* <Grid item xs={6} md={3}  sx={{display:'flex',justifyContent:'flex-end'}}>
              <DeleteIcon onClick={(e) => handleFileDelete(index, e)} sx={{ cursor: 'pointer' }} />
            </Grid> */}
          </Grid>
        </Box>
      ))}


      <Snackbar open={openSnackbar} autoHideDuration={2000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="success">
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

FileUpload.propTypes = {
  onFileUpload: PropTypes.func.isRequired,
  onSelectImage: PropTypes.func.isRequired,
  selectedImage: PropTypes.object,
  uploadedFiles: PropTypes.array.isRequired,
  setUploadedFiles: PropTypes.func.isRequired
};

export default FileUpload;
import React, { useContext, useEffect, } from "react";
import { Box, TextField, Typography } from "@mui/material";
// import {Typography} from "../../../../lib/theme/overrides/Typography";
function DateQues(props) {

  return (
    <Box display={'flex'}>

      <TextField
        size="small"
        id="outlined-number"
        label="Month"
        type="number"
        placeholder="MM"
        InputLabelProps={{
          shrink: true,
        }}
        // variant="standard"
        inputProps={{
          min: 1,
          max: 10,
        }}

        // sx={{

        //   '& .MuiInputBase-input': {
        //     fontSize: '30px',
        //   },
        // }}
      /><Typography fontSize={30} sx={{ mx: 2 }} color="darkgray "fontWeight={300}>/</Typography>
      <TextField
      size="small"
        id="standard-number"
        label="Day"
        type="number"
        placeholder="DD"

        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          min: 1,
          max: 10,
        }}

        // sx={{

        //   '& .MuiInputBase-input': {
        //     fontSize: '30px', 
        //   },
        // }}
      /><Typography fontSize={30} sx={{ mx: 2 }} color="darkgray "fontWeight={300}>/</Typography>
      <TextField
      size="small"
        id="standard-number"
        label="Year"
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        placeholder="YYYY"

        inputProps={{
          min: 1,
          max: 10,
        }}

        // sx={{

        //   '& .MuiInputBase-input': {
        //     fontSize: '30px', 
        //   },
        // }}
      />
      {/* {answer ? <input type="date" className="border border-left-0 border-right-0 border-top-0 w-100 small"
        placeholder="Enter Date"
        name="date"
        onChange={answer.onChangeAnswer}
        onKeyDown={answer.onChangeAnswer}
      />
        :
        <input type="date" className="border border-left-0 border-right-0 border-top-0 w-100 small"
          placeholder="Enter Date"
          name="date"
        // onChange={answer.onChangeAnswer}
        // onKeyDown={answer.onChangeAnswer}
        />} */}
    </Box>
  );
}

export default DateQues

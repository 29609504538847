import React, { useContext, useState } from 'react';
import {
    Box,
    Typography,
    styled,
    Tooltip,
    tooltipClasses,
} from "./../../../lib/utils/AllImportsHelper";
import ImageIcon from "@mui/icons-material/Image";
import InfoIcon from "@mui/icons-material/Info";
import DesignColorPalette from './DesignColorPalette';
import PublishSettingContext from './PublishSettingContext';
import TextAlignmentPalette from './TextAlignmentPalette';


const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#347edd",
        color: "#ffffff",
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "#347edd",
    },
}));


function DesignBody(props) {
    const publishSettingContextData = useContext(PublishSettingContext);

    return (
        <Box >
            <Box sx={{ backgroundColor: "#ECEFF1" }}>
                <Box sx={{ p: 2, display: "flex" }}>
                    <Box>
                        <ImageIcon fontSize="small" />
                    </Box>
                    <Box sx={{ ml: 1 }}>
                        <Typography variant="body1">
                            Body
                        </Typography>
                    </Box>

                </Box>
            </Box>
            <Box sx={{ p: 2 }}>
                <Box sx={{ my: 1 }}>
                    <Box sx={{ display: "flex" }}>
                        <Box>
                            <Typography variant="subtitle2" fontWeight="bold">
                                Background Color
                            </Typography>
                        </Box>
                        <Box sx={{ ml: 1 }}>
                            <LightTooltip
                                title="The text color will be automatically set based on the background color"
                                placement="top"
                                arrow
                            >
                                <InfoIcon fontSize="small" />
                            </LightTooltip>
                        </Box>
                    </Box>

                    <DesignColorPalette
                        itemData={publishSettingContextData?.bodyBackgroundColor}
                        changeHandler={publishSettingContextData?.bodyBackgroundColorChangeHandler}
                        defaultSelectedId={publishSettingContextData?.bodyBackgroundColor?.filter(d => d?.activeStatus == "1")?.[0]?.id}
                    />

                    <Box sx={{ display: "flex" }}>
                        <Box>
                            <Typography variant="subtitle2" fontWeight="bold">
                                Text Color
                            </Typography>
                        </Box>
                        <Box sx={{ ml: 1 }}>
                            <LightTooltip
                                title="The text color will be automatically set based on the background color"
                                placement="top"
                                arrow
                            >
                                <InfoIcon fontSize="small" />
                            </LightTooltip>
                        </Box>
                    </Box>

                    <DesignColorPalette
                        itemData={publishSettingContextData?.bodyTextColor}
                        changeHandler={publishSettingContextData?.bodyTextColorChangeHandler}
                        defaultSelectedId={publishSettingContextData?.bodyTextColor?.filter(d => d?.activeStatus == "1")?.[0]?.id}
                    />

                    <Box sx={{ display: "flex" }}>
                        <Box>
                            <Typography variant="subtitle2" fontWeight="bold">
                                Text Align
                            </Typography>
                        </Box>
                        <Box sx={{ ml: 1 }}>
                            <LightTooltip
                                title="The text color will be automatically set based on the background color"
                                placement="top"
                                arrow
                            >
                                <InfoIcon fontSize="small" />
                            </LightTooltip>
                        </Box>
                    </Box>

                    <TextAlignmentPalette
                        itemData={publishSettingContextData?.bodyAlignment}
                        changeHandler={publishSettingContextData?.bodyAlignmentChangeHandler}
                        defaultSelectedId={publishSettingContextData?.bodyAlignment?.filter(d => d?.activeStatus == "1")?.[0]?.id}
                    />
                </Box>
            </Box>
        </Box>
    );
}

export default DesignBody;
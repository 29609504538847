// src/OptionsList.js
import React, { useContext, useEffect, useState } from 'react';
import { TextField, IconButton, Button, Box, InputAdornment } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import QuestionContext from '../../QuestionContext';
import { setSelectedQuestion, updateSelectedQuestionAndQuestionInQuestionArray, updateSurvey } from '../../../../redux/slice/surveyQuetions/surveyQuestionSlice';
import { useDispatch, useSelector } from 'react-redux';
import ApiService from '../../../../ApiService';
import { Save } from '@mui/icons-material';
import { openSnackbar } from '../../../../redux/snackbar/snackbarSlice';
import MultipleChoiceQuesOptions from './MultipleChoiceQuesOptions';
import { setSelectedQuestionOptions } from '../../../../redux/survey/selectedQuestionOptionsSlice';

const prefillOptions = [{
  optionChoiceId
    : 1, optionChoiceName
    : 'Option 1'
}, {
  optionChoiceId
    : 2, optionChoiceName
    : 'Option 2'
}];

const MultipleChoiceQues = () => {
  let dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const [currentSelectedOption, setCurrectSelectedOption] = useState(null);
  const questionContextData = useContext(QuestionContext);
  const surveyQuestion = useSelector(state => state.surveyQuestion);
  const selectedQuestionOptions = useSelector(state => state.selectedQuestionOptions);

  const handleAddOption = () => {
    // const newId = options.length ? options[options.length - 1].optionChoiceId + 1 : 1;
    const optionPostfix = options.length + 1;
    let tempId = null;
    if (options.length) {
      tempId = options[options.length - 1].optionChoiceId + 1
    } else {
      tempId = options.length + 1;
    }

    setOptions([...options, { optionChoiceId: tempId, optionChoiceName: `Option ${optionPostfix}`, tempFlag: "add" }]);
    // console.debug("MultipleChoiceQues : handleAddOption ");
  };

  const handleDeleteOption = (e, optionAsParam, index) => {
    // if (optionAsParam?.optionChoiceId != null) {
    if (optionAsParam?.tempFlag != "add") {

      // console.debug("MultipleChoiceQues : handleDeleteOption : work as delete : optionAsParam : ", optionAsParam);
      let updatedOptions = options.filter(o => o.optionChoiceId != optionAsParam.optionChoiceId)
      // console.debug("MultipleChoiceQues : handleDeleteOption : options : ", options);
      // console.debug("MultipleChoiceQues : handleDeleteOption : updatedOptions : ", updatedOptions);
      setOptions([...updatedOptions]);
      let updatedSelectedQuestion = { ...surveyQuestion?.selectedQuestion };
      updatedSelectedQuestion = { ...updatedSelectedQuestion, optionChoice: [...updatedOptions] };
      // let parentQuestion = { ...surveyQuestion?.selectedParentQuestion }

      let params = {};
      params.optionChoiceName = optionAsParam.optionChoiceName;
      ApiService.delete('deleteOneOptionInMultipleChoice', {}, optionAsParam.optionChoiceId).then(r => {
        // console.debug("MultipleChoiceQues : handleDeleteOption : deleteOneOptionInMultipleChoice : r : ", r);
        if (r) {
          // let actionPayload = { question: updatedSelectedQuestion, parentQuestion: parentQuestion };
          // dispatch(updateSelectedQuestionAndQuestionInQuestionArray(actionPayload))
          dispatch(setSelectedQuestionOptions([...updatedOptions]));
          dispatch(openSnackbar({ message: 'Option deleted successfully', severity: "info" }));
          // setQuestionUpdateLoading(false)
          // dispatch(updateSurvey(response.data));
        }
      }).catch(e => {
        console.error("MultipleChoiceQues : handleDeleteOption : e : ", e);
        // setQuestionUpdateLoading(false)
        // dispatch(openSnackbar({ message: e.message, severity: "error" }));
        dispatch(openSnackbar({ message: "Something went wrong", severity: "error" }));
      });
    } else {
      // console.debug("MultipleChoiceQues : handleDeleteOption : work as cancel : optionAsParam : ", optionAsParam);
      let tempOptions = [...options];
      tempOptions.splice(index, 1);
      setOptions([...tempOptions]);
    }

  };

  const currentSelectionChangeHandler = (optionParam) => {
    // console.debug("MultipleChoiceQues : currentSelectionChangeHandler : optionParam : ", optionParam);
    setCurrectSelectedOption(optionParam);
    // setSelectedIndex(index);
  }
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(function () {
    // console.debug("MultipleChoiceQues : useEffect : surveyQuestion : ", surveyQuestion);
    // if (surveyQuestion?.selectedQuestion?.optionChoice) {
    //   if (options.length == 0) {
    //     // console.debug("MultipleChoiceQues : useEffect : surveyQuestion : options lenght 0", options);
    //     setOptions(surveyQuestion?.selectedQuestion?.optionChoice)
    //   }

    //   // if (surveyQuestion?.selectedQuestion?.optionChoice?.length > 0) {
    //   //   setCurrectSelectedOption(surveyQuestion?.selectedQuestion?.optionChoice?.[0])
    //   // }
    // }
    // }, [surveyQuestion]);
    // }, [surveyQuestion?.selectedQuestion?.optionChoice])

    if (selectedQuestionOptions.options) {
      if (options.length == 0) {
        setOptions(selectedQuestionOptions.options)
      }
    }
  }, [selectedQuestionOptions.options])


  // console.debug("options value", questionContextData?.selectedQuetionItem?.optionChoice)


  return (
    <Box>
      {options.map((optionData, index) => (

        <MultipleChoiceQuesOptions
          key={index}
          option={optionData} index={index}
          currentSelectedOption={currentSelectedOption}
          currentSelectionChangeHandler={currentSelectionChangeHandler}
          handleDeleteOption={handleDeleteOption}
        />
        // <Box key={optionData.optionChoiceId} display="flex" alignItems="center" mb={2}>
        //   <TextField
        //     InputProps={{
        //       startAdornment: <InputAdornment position="start" sx={{ border: "1px solid gray ", p: 1.5, borderRadius: 1.5 }}>{index + 1}</InputAdornment>,
        //     }}
        //     value={optionData.optionChoiceName}
        //     onSelect={e => handleOnSelectOption(e, optionData)}
        //     onChange={(e) => handleEditOption(index, e.target.value)}
        //     onBlur={textFieldBlurHandlder}
        //     onKeyDown={textFieldKeyDownHandler}
        //     variant="outlined"
        //   />
        //   {optionData?.optionChoiceId == currentSelectedOption?.optionChoiceId ?
        //     <Box>
        //       <IconButton onClick={(e) => handleSaveOption(e, optionData, index)}>
        //         <Save />
        //       </IconButton>
        //       <IconButton onClick={(e) => handleDeleteOption(e, optionData, index)}>
        //         <DeleteIcon />
        //       </IconButton>
        //     </Box>
        //     : ""}
        // </Box>
      ))}
      <Button onClick={handleAddOption} color="primary" size='small'>
        Add Option
      </Button>
    </Box>
  );
};

export default MultipleChoiceQues;

import React, { useEffect, useState } from 'react';
import {
    Box,
    Grid,
    Button,
    TextField,
    Link,
    Typography,
    styled,
    Drawer,
    Card,
    IconButton,
    Avatar,
    FormGroup,
    Tooltip,
    tooltipClasses,
    Radio,
    RadioGroup,
    FormControl,
    FormLabel,
    FormControlLabel,
    Skeleton,
} from "./../../../lib/utils/AllImportsHelper";

import ApiService from '../../../ApiService';

function SurveyLayout(props) {


    const skeletonItem = Array(2).fill(null);

    return (
        <Box sx={{ p: 2 }}>


            <FormControl>
                <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    sx={{ color: "black" }}
                >
                    <Typography variant="subtitle2">Question View</Typography>
                </FormLabel>
                {props?.surveyLayout.length ? "" :

                    <Box sx={{
                        display: "flex",
                        marginTop: "12px",
                    }} >

                        {skeletonItem.map((_, index) => (
                            <Box key={index} display={"flex"} sx={{
                                marginRight: "20px",
                                // paddingRight: "10px"
                            }} >
                                <Skeleton variant="circular" width={20} height={20} sx={{
                                    marginRight: "4px",
                                    // paddingRight: "10px"
                                }} />
                                <Skeleton variant="rounded" width={70} height={20} />
                            </Box>
                        ))
                        }

                    </Box>
                }
                <RadioGroup
                    value={props?.selectedValue}
                    onChange={props?.handleChange}
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                >
                    {props?.surveyLayout.length
                        ? props?.surveyLayout.map((d, i) => (
                            <FormControlLabel
                                key={i}
                                value={d.name}
                                // control={<Radio checked={d.activeStatus === "1"} />}
                                control={<Radio />}
                                label={d.name}
                            />
                        ))
                        : null}
                    {/* <FormControlLabel value="horizontal" control={<Radio />} label="Horizontal" /> */}
                </RadioGroup>
            </FormControl>
        </Box>
    );
}

export default SurveyLayout;
import React, { useEffect, useState } from 'react';
import QuestionTypeContext from './QuestionTypeContext';


function QuestionTypeLayout(props) {
  const [value, setValue] = useState('')

 
  const questionTypeContextData = {
    setValue
  }
  // console.debug("setValue", value)
  return (
    <>
      <QuestionTypeContext.Provider value={questionTypeContextData}>

        {props.children}

      </QuestionTypeContext.Provider>
    </>
  );
}

export default QuestionTypeLayout;

import React from "react";
import { Box, TextField } from "@mui/material";
// import Required from "./Required";
// import Description from "./Description";
function Email(props) {
  return (
    <>
    </>
    // <Box>
    //   <TextField
    //     sx={{
    //       width: "100%", '& .MuiInputBase-input': {
    //         fontSize: '1.2rem', // Change this value to your desired font size
    //       }
    //     }}

    //     type="email"
    //     name="number"
    //     placeholder="Enter Email Ex-Sample@gmail.com" variant="standard"
    //   />
    // </Box>
  );
}

export default Email

import React, { useContext, useEffect, useState } from "react";
// import Button from "../../../../lib/theme/overrides/Button";
import { Box , Button} from "@mui/material";
import QuestionTypeContext from "../../../../lib/layouts/QuestionFormatLayout/QuestionTypeContext";
// import { QuestionContext } from "../../../_services/FromType/QuestionContext";

function OpinionState(props) {
  const questionContect = useContext(QuestionTypeContext)
  const [answer,setAnswer]=useState('')
  const quetionChangeHandler = (event) => {
    setAnswer(event.target.value);
    textFieldBlurHandlder()
  };

  const textFieldBlurHandlder = () => {
    questionContect?.setValue(answer);
  };

  const textFieldKeyDownHandler = (e) => {
    if (e.key === 'Enter') {
      textFieldBlurHandlder();
    }
  };
  return (
 <Box>
  {Array.from({ length: 10}).map((data, index)=>
  <Button variant="outlined" sx={{mr:1,my:1,minWidth:'0px !important',padding:'0px 8px !important'}}   onClick={quetionChangeHandler}
          onBlur={textFieldBlurHandlder}
          onKeyDown={textFieldKeyDownHandler}>{index+1}
  </Button>)}
 </Box>
  );
}

export default OpinionState

import React from 'react';
import { Box, Grid, IconButton, TextField, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const CreateFromScratch = ({
  modelName, setModelName,
  description, setDescription,
  level, setLevel,
  practiceCriteria, setPracticeCriteria,
  area, setArea,
  areaExample, setAreaExample,selectedCard
}) => {

console.debug('a5',selectedCard)

  const handleChange = (event) => setModelName(event.target.value);
  const handleDescriptionChange = (event) => setDescription(event.target.value);
  const handleLevelChange = (event) => setLevel(Number(event.target.value));
  const handlePracticeCriteriaChange = (event) => setPracticeCriteria(event.target.value);
  const handleAreaChange = (event) => setArea(Number(event.target.value));
  const handleAreaExampleChange = (event) => setAreaExample(event.target.value);

  const handleIncrement = () => {
    if (level < 5) {
      setLevel(prev => prev + 1);
    }
  };

  const handleDecrement = () => {
    if (level > 1) {
      setLevel(prev => prev - 1);
    }
  };

  const handleAreaIncrement = () => {
    if (area < 5) {
      setArea(prev => prev + 1);
    }
  };

  const handleAreaDecrement = () => {
    if (area > 1) {
      setArea(prev => prev - 1);
    }
  };

  return (
    <Box>
      <Grid container spacing={2} sx={{ p: 1 }}>
        <Grid item xs={3}>
          <Typography variant="subtitle2" fontWeight="bold">Model Name</Typography>
        </Grid>
        <Grid item xs={9}>
          <TextField
            size='small'
            value={selectedCard ? selectedCard.name : modelName}
            onChange={handleChange}
            placeholder='Maturity Model Name'
          />
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle2" fontWeight="bold">Description</Typography>
        </Grid>
        <Grid item xs={9}>
          <TextField
            size='small'
            value={selectedCard ? selectedCard.description : description}
            onChange={handleDescriptionChange}
            placeholder='Description of the model'
          />
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle2" fontWeight="bold">Levels</Typography>
        </Grid>
        <Grid item xs={9} sx={{ display: 'flex', alignItems: 'center' }}>
          <Box display="flex" alignItems="center">
            <IconButton onClick={handleDecrement} disabled={level <= 1} sx={{ border: '1px solid #2563EB' }} size='small'>
              <RemoveIcon sx={{ color: '#2563EB' }} size='small' />
            </IconButton>
            <TextField
              variant="outlined"
              size="small"
              type="number"
              style={{ width: '50px', textAlign: 'center' }}
              sx={{ mx: 1 }}
              value={selectedCard ? selectedCard.noOfLevel : level}
              onChange={handleLevelChange}
            />
            <IconButton onClick={handleIncrement} disabled={level >= 5} sx={{ border: '1px solid #2563EB' }} size='small'>
              <AddIcon sx={{ color: '#2563EB' }} size='small' />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle2" fontWeight="bold">Practice Criteria</Typography>
        </Grid>
        <Grid item xs={9}>
          <TextField
            size='small'
            value={selectedCard ? selectedCard.practiceCriteria : practiceCriteria}
            onChange={handlePracticeCriteriaChange}
            placeholder='Practice Criteria'
          />
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle2" fontWeight="bold">Areas</Typography>
        </Grid>
        <Grid item xs={9} sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton onClick={handleAreaDecrement} disabled={area <= 1} sx={{ border: '1px solid blue' }} size='small'>
            <RemoveIcon sx={{ color: 'blue' }} size='small' />
          </IconButton>
          <TextField
            variant="outlined"
            size="small"
            type="number"
            style={{ width: '50px', textAlign: 'center' }}
            sx={{ mx: 1 }}
            value={selectedCard ? selectedCard.noOfArea : area}
            onChange={handleAreaChange}
          />
          <IconButton onClick={handleAreaIncrement} disabled={area >= 5} sx={{ border: '1px solid blue' }} size='small'>
            <AddIcon sx={{ color: 'blue' }} size='small' />
          </IconButton>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle2" fontWeight="bold">Area Example</Typography>
        </Grid>
        <Grid item xs={9}>
          <TextField
            size='small'
            value={selectedCard ? selectedCard.description : areaExample}
            onChange={handleAreaExampleChange}
            placeholder='Example of Area'
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CreateFromScratch;

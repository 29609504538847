import React from "react";
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import { Switch } from "@mui/material";
function MultipleChoice(props) {
 
const label = { inputProps: { 'aria-label': 'Color switch demo' } };
  return (
    <>
    </>
    // <div className="">
    //   <label className="fs-14">Setting</label>
    //   <div>
    //     <List
    //       sx={{ width: '100%', bgcolor: 'background.paper' }}
    //       aria-labelledby="nested-list-subheader"
    //     >
    //       <ListItemButton className="d-flex p-0" >
    //         <ListItemText primary="Multiple Selection" /><Switch {...label} defaultChecked />
    //       </ListItemButton>
    //       <ListItemButton className="d-flex p-0">
    //         <ListItemText primary="Randomise" /><Switch {...label} defaultChecked />
    //       </ListItemButton>
    //       <ListItemButton className="d-flex p-0">
    //         <ListItemText primary="Other option" /><Switch {...label} defaultChecked />
    //       </ListItemButton>
    //     </List>
    //   </div>
    // </div>
  );
}

export default MultipleChoice

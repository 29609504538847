import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box, Button, createTheme,IconButton, ThemeProvider } from '@mui/material';
import Grid from '@mui/material/Grid';
import RotateRightIcon from '@mui/icons-material/RotateRight';
// import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
function EndUserFooterButton(props) {
    // Mastech                
    // #FCA311(oranage)
    // #297abd(blue)
    // #14213d(black)
    const themeProvider = createTheme({
        palette: {
            primary: {
                main: '#FCA311',
            },
            secondary: {
                main: '#14213d'
            },

        },
    });

    const handleRestartSurvey = () => {
        window.location.reload();
    };

    function nextProcessHandler() {
        // console.debug("SurveyEndUser : EndUserFooterButton : nextProcessHandler : ");
        if (props?.nextProcessHandler !== undefined) {
            props?.nextProcessHandler();
        }
    }

    if (props?.nextButtonVisibilityStatus !== undefined && props?.nextButtonVisibilityStatus == false) {

        return (
            <ThemeProvider theme={themeProvider}>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        mt: "1rem",
                        width: '100%',
                        // height:'100vh',
                        // mb: '1rem'
                    }}
                >
                   
                    <Button
                        variant="contained"
                        size="small"
                        startIcon={<RotateRightIcon />}
                        onClick={handleRestartSurvey}
                        sx={{
                            backgroundColor: '#78909c',
                            color: '#ffffff',
                            '&:hover': {
                                backgroundColor: '#78909c'
                            }
                        }}
                    >
                        Restart
                    </Button>
                </Box>
            </ThemeProvider>

        )
    } else {
        return (
            <ThemeProvider theme={themeProvider}>
                 <Grid container spacing={2}  sx={{padding: {xs: '0.4rem', sm: '0.5rem', }}}>
                 <Grid  item xs={6}  md={4}  >
                 <Button
                        variant="contained"
                        size="small"
                        startIcon={<RotateRightIcon />}
                        onClick={handleRestartSurvey}
                        sx={{
                            backgroundColor: '#6c6e6f',
                            color: '#ffffff', // Ensures the text color is white
                            '&:hover': {
                                backgroundColor: '#78909c' // Adjusts the hover color for effect
                            }
                        }}
                    >
                        Restart
                    </Button>
                </Grid>
                <Grid  item xs={6} md={8} sx={{display:'flex',justifyContent:'flex-end'}}>
                {props?.previousButtonActiveStatus !== undefined && props?.previousProcessHandler !== undefined?
                            <Button
                                variant="contained"
                                onClick={() => props?.previousProcessHandler()}
                                size="small"
                                disabled={!(props?.previousButtonActiveStatus)}
                                color='primary'
                                sx={{
                                    mr:1,
                                    minWidth:'0px !important',
                                    padding: '6px 10px !important',
                                    backgroundColor: '#6c6e6f',
                                    color: '#ffffff', 
                                    '&:hover': {
                                        backgroundColor: '#6c6e6f' 
                                    }
                                }}
                            >
                              <ArrowBackIcon fontSize='small'/>
                            </Button>
                        :""}
                        
                        <Button
                            variant="contained"
                            onClick={() => nextProcessHandler()}
                            endIcon={<ArrowForwardIcon />}
                            size="small"
                            // disabled={isSubmitDisabled}
                            color='primary'
                        >
                            {(props?.nextLabel !== undefined && props?.nextLabel !== null) ? props?.nextLabel : "Next"}
                        </Button>
                </Grid>
                </Grid>

                {/* <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        // mt: "1rem",
                    }}
                >
                    <Button
                        variant="contained"
                        size="small"
                        startIcon={<RotateRightIcon />}
                        onClick={handleRestartSurvey}
                        sx={{
                            backgroundColor: '#6c6e6f',
                            color: '#ffffff', // Ensures the text color is white
                            '&:hover': {
                                backgroundColor: '#78909c' // Adjusts the hover color for effect
                            }
                        }}
                    >
                        Restart
                    </Button>

                    <Box>
                        {props?.previousButtonActiveStatus !== undefined && props?.previousProcessHandler !== undefined?
                            <Button
                                variant="contained"
                                onClick={() => props?.previousProcessHandler()}
                                size="small"
                                disabled={!(props?.previousButtonActiveStatus)}
                                color='primary'
                                sx={{
                                    mr:1,
                                    minWidth:'0px !important',
                                    padding: '6px 10px !important',
                                    backgroundColor: '#6c6e6f',
                                    color: '#ffffff', 
                                    '&:hover': {
                                        backgroundColor: '#6c6e6f' 
                                    }
                                }}
                            >
                              <ArrowBackIcon fontSize='small'/>
                            </Button>
                        :""}
                        
                        <Button
                            variant="contained"
                            onClick={() => nextProcessHandler()}
                            endIcon={<ArrowForwardIcon />}
                            size="small"
                            // disabled={isSubmitDisabled}
                            color='primary'
                        >
                            {(props?.nextLabel !== undefined && props?.nextLabel !== null) ? props?.nextLabel : "Next"}
                        </Button>
                    </Box>
                    
                    
                </Box> */}
            </ThemeProvider>
        );
    }

}

export default EndUserFooterButton;
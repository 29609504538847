import React, { useState } from 'react';

import { Box, Grid, Card, Divider, Typography } from '@mui/material'
import TipsAndTricks from './TipsAndTricks'
import ProblemStatementCard from './ProblemStatementCard'
import ListAltIcon from '@mui/icons-material/ListAlt';
import QuestionSetTable from './QuestionSetTable';
import { useMediaQuery } from '@mui/material';
import ApiService from '../../ApiService';


function GenAiBody(props) {
    const isWideScreen = useMediaQuery('(max-width:1500px)');
    return (
        <Box>
            <Grid container spacing={2} sx={{ backgroundColor: '#DBEAFE' }}>
                <Grid item xs={3.5} sx={{ pt: '0px !important' }}>
                    <Card>
                        <Divider sx={{ borderTop: '3px solid blue', }} />
                        <Box sx={{ height: 'calc(100vh - 70px)', overflow: isWideScreen ? 'auto' : 'hidden', }}>
                            <Box sx={{ backgroundColor: '#DBEAFE' }}>
                                <Box sx={{ p: 2 }}>
                                    <ProblemStatementCard/>
                                </Box>
                            </Box>
                            <Box sx={{ mt: 0.5, backgroundColor: '#BBE0FF' }}>
                                <Box sx={{ justifyContent: 'center', display: 'flex' }}>
                                    <TipsAndTricks />
                                </Box>
                            </Box>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={8.5} sx={{ pt: '0px !important' }}>
                    <Card sx={{ height: '100%' }}>
                        <Divider sx={{ borderTop: '3px solid blue', }} />
                        <Box sx={{ p: 1, backgroundColor: '#DBEAFE', height: 'calc(100vh - 70px)' }}>
                            <Box sx={{ display: 'flex' }}>
                                <Box sx={{ mt: 0.5 }}><ListAltIcon /></Box>
                                <Box sx={{ ml: 2 }}>
                                    <Box>
                                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                                            Choose the right question
                                        </Typography>
                                    </Box>
                                    <Box>Customize your survey by moving the question from left to the selected list on right. </Box>
                                </Box>
                            </Box>
                            <Box>
                                <QuestionSetTable/>
                            </Box>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}

export default GenAiBody;
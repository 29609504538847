import React, { useState, useEffect, useContext } from 'react'
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import Rating from '@mui/material/Rating';
// import LoadingButton from '@mui/lab/LoadingButton';
import { EnahanceUpdateActionButton as LoadingButton } from '../../../lib';

import QuestionTypeIcon from './questionType/QuestionTypeIcon';
// import StarIcon from '@mui/icons-material/Star';
import {
    Box, Skeleton, Switch, Grid, Button, TextField, Tab, Tabs, Typography, styled, useTheme, Drawer, Card, CssBaseline, IconButton, Avatar, InputBase, Menu,
    AppBar as MuiAppBar, Toolbar, Divider, ListItemIcon, ToggleButtonGroup, MenuItem, Paper,
    AccountCircle as AccountCircleIcon, Collections as CollectionsIcon, Undo as UndoIcon, Redo as RedoIcon, Remove as RemoveIcon, Add as AddIcon, Search as SearchIcon, Delete as DeleteIcon,
    Image as ImageIcon, DateRange as DateRangeIcon, AttachEmail as AttachEmailIcon, LocalPhone as LocalPhoneIcon, Checklist as ChecklistIcon, TextFields as TextFieldsIcon, ChangeHistoryTwoTone as ChangeHistoryTwoToneIcon, ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon, ContentCopy as ContentCopyIcon,
    Home as HomeIcon, Preview as PreviewIcon, Reorder as ReorderIcon, Download as DownloadIcon, Circle as CircleIcon, Rectangle as RectangleIcon, EggTwoTone, Star as StarIcon, Logout, Settings, FormatColorFill
    // } from '../../../utils/AllImportsHelper';
} from '../../../lib/utils/AllImportsHelper';
import QuestionContext from '../QuestionContext';
import QuestionTypeOption from './questionType/QuestionTypeOption';
import QuestionSettingDescription from './QuestionSettingDescription';
import QuestionSettingRequired from './QuestionSettingRequired';
import QuestionTypeSettingOptions from './questionType/QuestionTypeSettingOptions';
import { useDispatch, useSelector } from 'react-redux';
import ApiService from '../../../ApiService';
import { openSnackbar } from '../../../redux/snackbar/snackbarSlice';
import ImageSelection from './TypeSelect/ImageSelection';
import LayoutSelection from './TypeSelect/LayoutSelection';
import FocalView from './TypeSelect/FocalView';

const SkeletonLoader = () => (
    <Box>
        <Box sx={{ my: 2 }}>
            <Skeleton variant="rectangular" width="100%" height={40} />
        </Box>
        <Box sx={{ my: 2 }}>
            <Skeleton variant="rectangular" width="100%" height={40} />
        </Box>
        <Box sx={{ my: 2 }}>
            <Skeleton variant="rectangular" width="100%" height={40} />
        </Box>
    </Box>
);

function QuetionSettings({ selectedItems, ...props }) {
    const questionContextData = useContext(QuestionContext);
    let dispatch = useDispatch()
    const surveyQuestion = useSelector(state => state.surveyQuestion);
    const selectedQuestionDescriptionStatus = useSelector(state => state.selectedQuestionDescriptionStatus);
    const selectedQuestionDescription = useSelector(state => state.selectedQuestionDescription);
    const selectedQuestionRequiredStatus = useSelector(state => state.selectedQuestionRequiredStatus);


    const [questionType, setQuestionType] = useState(questionContextData?.questionType ? questionContextData.questionType : []);

    const [selectedItem, setSelectedItem] = useState(questionContextData?.selectedQuetionItem?.questionType);


    function onClickHandler(e, value) {
        questionContextData?.selectedQuestionItemObjectChangeHandler({ questionType: value })
    }

    function onClickSubmitHandler() {
        let params = {}
        // console.debug("a9", questionContextData?.selectedQuetionItem?.questionRequired)
        params.questionTypeId = questionContextData?.selectedQuetionItem?.questionType.id;
        // params.questionRequired = questionContextData?.selectedQuetionItem?.questionRequired
        // params.questionRequired = selectedQuestionRequiredStatus.activeStatus;

        if (selectedQuestionRequiredStatus.activeStatus === true) {

            params.questionRequired = 1
        }
        else {
            params.questionRequired = 0
        }

        if (selectedQuestionDescriptionStatus.activeStatus === true) {

            params.questionDescriptionStatus = 1
        }
        else {
            params.questionDescriptionStatus = 0
        }
        if (questionContextData?.selectedQuetionItem?.questionTypeRating?.ratings) {
            params.ratings = questionContextData?.selectedQuetionItem?.questionTypeRating.ratings
        }
        if (questionContextData?.selectedQuetionItem?.questionTypeNumber?.minNumber || questionContextData?.selectedQuetionItem?.questionTypeNumber?.maxNumber) {
            params.minNumber = questionContextData?.selectedQuetionItem?.questionTypeNumber.minNumber
            params.maxNumber = questionContextData?.selectedQuetionItem?.questionTypeNumber.maxNumber
        }
        // maxCharacter= long answerType
        // maxCharacter - short answerType
        // maxNumber - opinion type
        // minNumber- opinion state
        // ratings-
        // qotationMark
        // /\queGroupId

        // params.questionDescription = questionContextData?.selectedQuetionItem?.questionDescription
        if (selectedQuestionDescription.descriptionObject?.description !== undefined) {
            // params.description = surveyQuestion?.selectedQuestionDescription?.description;
            params.questionDescription = selectedQuestionDescription.descriptionObject.description;
        }
        // questionContextData?.onUpdateQuestionDetails(params)
        ApiService.post('updateQuestion', params, surveyQuestion?.selectedQuestion?.id).then(response => {
            console.debug("QuestionTitle update ", response.data)
            console.debug("a9", params)
            if (response) {
                // setQuestionUpdateLoading(false)
                // dispatch(updateSurvey(response.data));
                dispatch(openSnackbar({ dataLoad: true, message: 'Question updated successfully', severity: "info" }));
                window.location.reload();
            }
        }).catch(e => {
            dispatch(openSnackbar({ dataLoad: true, message: e.message, severity: "error" }));
            // setQuestionUpdateLoading(false)
        })
    }
   const [selectedImage, setSelectedImage] = useState(null);
   console.debug('questionSettings : selectedImage :' ,selectedImage)
  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false);

  const handleImageSelect = (image) => {
    setSelectedImage(image);
  };

    // console.debug("questionContextData?.selectedQuetionItem",questionContextData?.selectedQuetionItem)
    return (
        <Box sx={{ p: 2 }}>
            <Box sx={{ maxHeight: "100vh", overflow: 'auto' }}>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Box>
                            <Typography variant="subtitle1" fontWeight="bold">Question Settings</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Box>
                            <LoadingButton
                                size="small"
                                onClick={onClickSubmitHandler}
                                updatingStatus={questionContextData?.questionUpdateLoading}
                                variant="text" >
                                Apply
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <>
                {!questionContextData?.selectedQuetionItem?.questionType ? (
                    <SkeletonLoader />
                ) : (
                    <Box sx={{height: 'calc(100vh - 160px)', overflow: 'auto'}}>
                        <Box sx={{ my: 2 }}>
                            <Box>
                                <Autocomplete
                                    size="small"
                                    id="country-select-demo"
                                    options={questionType}
                                    autoHighlight
                                    getOptionLabel={(option) => option.questionTypeLabel}
                                    value={questionContextData?.selectedQuetionItem?.questionType}
                                    renderOption={(props, option) => (
                                        <QuestionTypeOption {...props} questionType={option} />
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Question Type"
                                            InputProps={{
                                                ...params.InputProps,
                                                startAdornment: (
                                                    <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                                                        <QuestionTypeIcon questionTypeLabel={questionContextData?.selectedQuetionItem?.questionType?.identifier} />
                                                        {console.debug('questionType : questionTypeLabel :', questionContextData?.selectedQuetionItem?.questionType?.identifier)}
                                                        {params.InputProps.startAdornment}
                                                    </Box>
                                                ),
                                            }}
                                        />
                                    )}
                                    clearIcon={null}
                                    onChange={(e, value) => onClickHandler(e, value)}
                                />
                            </Box>
                        </Box>
                        <Box>
                            <QuestionSettingDescription />
                            <QuestionSettingRequired />
                            <QuestionTypeSettingOptions selectedQuesType={selectedItem?.identifier} />
                            <ImageSelection onImageSelect={handleImageSelect} />
                            <LayoutSelection />
                            <FocalView selectedImage={selectedImage} />
      
                        </Box>
                    </Box>
                )}
            </>
        </Box>
    );
}

export default QuetionSettings;
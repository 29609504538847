import React, { useEffect, useState } from 'react';

import './CanvasLayout.css';
import {
  Box, Button, TextField, Tab, Tabs, Typography, styled, alpha, useTheme, Drawer, Card, CssBaseline, IconButton, Avatar, InputBase, Menu,
  AppBar as MuiAppBar, Toolbar, Divider, ListItemIcon, ToggleButtonGroup, MenuItem, Paper,
  AccountCircle as AccountCircleIcon, Collections as CollectionsIcon, Undo as UndoIcon, Redo as RedoIcon, Remove as RemoveIcon, Add as AddIcon, Search as SearchIcon, Delete as DeleteIcon,
  Image as ImageIcon, TextFields as TextFieldsIcon, ChangeHistoryTwoTone as ChangeHistoryTwoToneIcon, ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon, ContentCopy as ContentCopyIcon,
  Home as HomeIcon, Preview as PreviewIcon, Download as DownloadIcon, Circle as CircleIcon, Rectangle as RectangleIcon, EggTwoTone, Star as StarIcon, Logout, Settings, FormatColorFill
} from '../../utils/AllImportsHelper';
import CanvasHeader from './CanvasHeader';
import CanvasContext from './CanvasContext';
import CanvasTab from './CanvasTab';


function CanvasLayout(props) {
  // console.debug("Canvaslayout : props : ", props);

  // const [open, setOpen] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);
  const [showField, setShowFields] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);


  const handleChange = (event, newValue) => {

    setValue(newValue);
    // setCurrentNode(null)
  };

  ///----survey-----///


  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const [questions, setQuestions] = useState(["Default Question"]);


  const [openMenuIcon, setopenMenuIcon] = useState(false);
  const [anchorE9, setAnchorE9] = useState(null);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(null);

  const handleMenuClick = (event, index) => {
    setAnchorE9(event.currentTarget);
    setSelectedQuestionIndex(index);
    setopenMenuIcon(true);
  };
  const handleMenuClose = () => {
    setAnchorE9(null);
    setopenMenuIcon(false);
  };

  const handleDuplicate = () => {
    const newQuestions = [...questions];
    newQuestions.splice(selectedQuestionIndex + 1, 0, questions[selectedQuestionIndex]);
    setQuestions(newQuestions);
    handleMenuClose();
  };

  const [disabledItems, setDisabledItems] = useState(Array(questions.length).fill(false));

  const handleDisable = (index) => {
    const updatedDisabledItems = [...disabledItems];
    updatedDisabledItems[index] = true;
    setDisabledItems(updatedDisabledItems);
    handleMenuClose();
  };

  const handleDelete = () => {
    const newQuestions = [...questions];
    newQuestions.splice(selectedQuestionIndex, 1);
    setQuestions(newQuestions);
    handleMenuClose();
  };

  ///----survey-----///


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const calcTemplateFields = () => {
    switch (value) {
      case 1: return (<>
        <TextFieldsIcon fontSize='small' />
        <Typography variant="body2" pl={1}>Text</Typography>
      </>);
      case 2: return (<>
        <ImageIcon fontSize='small' />
        <Typography variant="body2" pl={1}>Image</Typography>
      </>);
      case 3: return (<>
        <ChangeHistoryTwoToneIcon fontSize='small' />
        <Typography variant="body2" pl={1}>Shape</Typography>
      </>);
      case 4: return (<>
        <FormatColorFill fontSize='small' />
        <Typography variant="body2" pl={1}>Canvas Color</Typography>
      </>);
      case 0: return (<>
        <ImageIcon fontSize='small' />
        <Typography variant="body2" pl={1}>Template Style</Typography>
      </>);

    }
  }

  useEffect(() => {
    if (!showField)
      setShowFields(true);
  }, [showField]);


  const handleClose = () => {
    setAnchorEl(null);
  };

  


  const canvasContextData = {
    ...props,
    openMenu: openMenu,
    anchorEl: anchorEl,
    handleClose, handleClose,
    open: open,
    handleDrawerClose: handleDrawerClose,
    handleDrawerOpen: handleDrawerOpen,
    toggleDrawer: toggleDrawer,
    isDrawerOpen: isDrawerOpen,
    value: value,

    questions: questions,
    openMenuIcon: openMenuIcon,
    disabledItems: disabledItems,
    handleMenuClick: handleMenuClick,
    anchorE9: anchorE9,
    handleMenuClose: handleMenuClose,
    handleDuplicate: handleDuplicate,
    handleDisable: handleDisable,
    handleDelete: handleDelete,
    handleChange:handleChange,
    
    // handleRowSelect: handleRowSelect,
    // handleSelectAllClick: handleSelectAllClick,
    // selectedRows: selectedRows,
    // data: rowData,
  };

  return (
    <>
      <CanvasContext.Provider value={canvasContextData}>

        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <CanvasHeader />
          <Box component="main" sx={{ p: 0, width: 1, position: 'relative', top: '64px' }}>
            <CanvasTab>
              {props.children}
            </CanvasTab>
          </Box>
        </Box>

      </CanvasContext.Provider>
    </>
  );
}

export default CanvasLayout;

import React, { useContext } from "react";
import {Box} from '../../../../lib/utils/AllImportsHelper';
// import { AnswerContext } from "../../../_services/FromType/AnswerContext";
// import { QuestionContext } from "../../../_services/FromType/QuestionContext";

function PictureChoiceQues(props) {
  // const number = useContext(QuestionContext);



  return (

    <div className="d-flex">
      <Box
        sx={{
          width: 150,
          height: 150,
          backgroundColor: 'lightgrey',
          '&:hover': {
            backgroundColor: '25.118.210',
            opacity: 0.5,
          },
        }}
        className="d-flex justify-content-center"
      >
        <h1 className="pt-5">,MDSNWMEN</h1>
      </Box>


      <Box
        sx={{

          width: 150,
          height: 150,
          backgroundColor: 'lightgrey',
          '&:hover': {
            backgroundColor: 'lightgrey',
            opacity: 0.5,
          },
        }}
        className="ml-4"
      >
        <h1 className="d-flex justify-content-center pt-5"> KNDEW</h1>
      </Box>

    </div>

  );
}

export default PictureChoiceQues

import { Box, Button, Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography, styled, useTheme } from '@mui/material';
import React, { useContext } from 'react';
import CanvasSubLayoutContext from './CanvasSubLayoutContext';

import { AddBox, Block, ChevronLeft, ChevronRight, ContentCopy, Delete, Drafts, MoreVert } from '@mui/icons-material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
    justifyContent: 'space-between',
}));



const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        minWidth: 180,
        left: '14rem !important',
        top: '10rem !important',
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
        },
        '& .MuiMenu-paper-MuiPaper-root-MuiPopover-paper': {
            left: '14rem !important',
            top: '10rem !important',
        }
    },
}));


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ px: 2 }}>
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function CanvasDrawer(props) {
    const canvasSubLayoutContextData = useContext(CanvasSubLayoutContext);
    const theme = useTheme();

    return (
        <Drawer
            sx={{
                // width: canvasSubLayoutContextData?.drawerWidth,
                width: canvasSubLayoutContextData?.drawerWidth,

                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: canvasSubLayoutContextData?.drawerWidth,
                    left: '95px',
                    backgroundColor: '#F8FBFF',
                    borderRight: '0px',
                    borderTopRightRadius: '12px',
                    top: '64px',
                    boxSizing: 'border-box',
                },
            }}
            variant="persistent"
            anchor="left"
            open={canvasSubLayoutContextData?.open}>
            <DrawerHeader>
                <Box sx={{ display: 'flex' }}>
                    <Box sx={{ p: '0.5rem' }}>
                        <Typography pl={1} variant="subtitle1" fontWeight="bold">
                            {canvasSubLayoutContextData?.drawerHeaderTitle}
                        </Typography>
                    </Box>
                    {canvasSubLayoutContextData?.drawerHeaderTool}
                </Box>
            </DrawerHeader>

            {canvasSubLayoutContextData?.drawerComp}

        </Drawer>
    );
}

export default CanvasDrawer;
import React, { useEffect, useState } from 'react'
import {
  Box, Switch, Grid, Button, TextField, Tab, Tabs, Tooltip, Typography, styled, useTheme, Drawer, Card, CssBaseline, IconButton, Avatar, InputBase, Menu,
  AppBar as MuiAppBar, Toolbar, Divider, ListItemIcon, ToggleButtonGroup, MenuItem, Paper,
  AccountCircle as AccountCircleIcon, Collections as CollectionsIcon, Undo as UndoIcon, Redo as RedoIcon, Remove as RemoveIcon, Add as AddIcon, Search as SearchIcon, Delete as DeleteIcon,
  Image as ImageIcon, TextFieldsIcon, ChangeHistoryTwoTone as ChangeHistoryTwoToneIcon, ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon, ContentCopy as ContentCopyIcon,
  Home as HomeIcon, Preview as PreviewIcon, Download as DownloadIcon, Circle as CircleIcon, Rectangle as RectangleIcon, EggTwoTone, Star as StarIcon, Logout, Settings, FormatColorFill,
} from './../../lib/utils/AllImportsHelper';
import ApiService from '../../ApiService';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { tooltipClasses } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { CircularProgress, FilledInput, OutlinedInput, Slider } from '@mui/material';
import MuiInput from '@mui/material/Input';
import { useDispatch, useSelector } from 'react-redux';
import { updateSurvey } from '../../redux/slice/surveyQuetions/surveyQuestionSlice';
import { useParams } from 'react-router-dom';
import { EnahanceUpdateActionButton, LoadingAndErrorHandling } from '../../lib';
import { openSnackbar } from '../../redux/snackbar/snackbarSlice';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#347edd',
    color: '#ffffff',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: '#347edd',
  },
}));


const SurveySettings = (props) => {
  const { id } = useParams();

  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  // console.debug("surveySettting ", props)
  const Input = styled(MuiInput)`
  width: 42px;
`;
  const dispatch = useDispatch();

  const surveyQuestion = useSelector(state => state.surveyQuestion);
  console.debug('SurveyCreate : useEffect :then : surveyQuestion :', surveyQuestion)
  const [surveyTitle, setSurveyTitle] = useState(null);
  const [surveyDescription, setSurveyDescription] = useState(null);
  const [updateLoadingStaus, setUpdateLoadingStaus] = useState(false);
  const [attendeeDetails, setAttendeeDetails] = useState(false);
  const [reportDetails, setReportDetails] = useState(false);
  const [surveyPublic, setSurveyPublic] = useState(false)
  // console.debug('a1',surveyPublic)responseLimit
  const [otherOption, setOtherOption] = useState(false)
  const [closeSurvey, setCloseSurvey] = useState(false)
  const [surveyWorkflow, setSurveyWorkflow] = useState(false)
  // console.debug('a5',closeSurvey)
  const [scheduleStartEnd, setScheduleStartEnd] = useState(surveyQuestion?.survey?.startDate ? true : false)
  console.debug('SurveyCreate : useEffect :then : surveyResponce :', scheduleStartEnd)
  const [startDate, setStartDate] = useState(null);
  console.debug('SurveyCreate : useEffect :then : surveyResponce :', (dayjs(surveyQuestion?.survey?.endDate)))
  const [endDate, setEndDate] = useState(null)
  // console.debug('a1', endDate)
  const [response, setResponse] = useState(surveyQuestion?.survey?.responseLimit ? true : false)
  console.debug('SurveyCreate : useEffect :then : surveyResponce :', response, surveyQuestion?.survey?.responseLimit)
  const [responseLimit, setResponseLimit] = useState(null)
  const [customThanku, setCustomThanku] = useState(false)
  const [customThankuMsg, setCustomThankuMsg] = useState(null)
  // console.debug('a1', customThankuMsg)
  const [allowSurveyMoreThanOneDevice, setAllowSurveyMoreThanOneDevice] = useState(false)

  // const formatDate = (date) => {
  //   console.debug('a56 formatDate date', date)
  //   // const year = date.getFullYear();
  //   const year = date.year();
  //   const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  //   const day = String(date.getDate()).padStart(2, '0');
  //   return `${year}-${month}-${day}`;
  // };
  const handleDateChange = (newValue) => {
    if (newValue) {
      setStartDate(dayjs(newValue).format('YYYY-MM-DD'));
    } else {
      setStartDate(null);
    }
  };
  // const handleDateChange = (newValue) => {
  //   if (newValue) {
  //     setStartDate(dayjs(newValue).format('YYYY-MM-DD'));
  //   } else {
  //     setStartDate(null);
  //   }
  // };
  function textFieldChangeHandler(e) {
    if (e.target.name == "surveyTitle") {
      setSurveyTitle(e.target.value);
    }
    if (e.target.name == "surveyDescription") {
      setSurveyDescription(e.target.value);
    }
  }

  const surveyTitleAndDescriptionUpdate = () => {
    if (surveyQuestion)
      dispatch(updateSurvey({ ...surveyQuestion, surveyTitle: surveyTitle, description: surveyDescription }))
    let params = {};
    params.surveyTitle = surveyTitle;
    params.description = surveyDescription;

    ApiService.put("survey", params, id).then(response => {
      // console.debug("CanvasWrapper : surveyTitleAndDescriptionUpdate : r ", response)
      dispatch(openSnackbar({ dataLoad: true, message: 'Record updated successfully', severity: "info" }));
    }).catch(e => {
      // console.debug("CanvasWrapper : surveyTitleAndDescriptionUpdate : e : catch :", e);
      dispatch(openSnackbar({ dataLoad: true, message: e.message, severity: "error" }));
    })
  };

  const isTruthy = (data) => {
    return Boolean(data) && data !== "0" && data.toLowerCase() !== "false";
  };

  const [surveyIdentifier, setSurveyIdentifier] = useState(surveyQuestion?.survey?.surveyOrigin?.identifier);
  console.debug("SurveySettings : useEffect of surveyQuestion :  surveyQuestion : ", surveyIdentifier);
  useEffect(function () {

    console.debug("SurveySettings : useEffect of surveyQuestion :  surveyQuestion : ", surveyQuestion?.survey?.surveyOrigin?.identifier);
    console.debug("SurveySettings : useEffect of surveyQuestion :  surveyQuestion : Description", surveyQuestion?.survey?.description);
    if (surveyQuestion?.survey?.surveyTitle != undefined) {
      setSurveyTitle(surveyQuestion.survey.surveyTitle);
    }
    if (surveyQuestion?.survey?.description != undefined) {
      setSurveyDescription(surveyQuestion.survey.description);
    }
    if (surveyQuestion?.survey?.public != undefined) {
      setSurveyPublic(surveyQuestion.survey.public);
    }
    if (surveyQuestion?.survey?.showReportAttendeeSettings?.showReport != undefined) {
      setReportDetails(surveyQuestion.survey.showReportAttendeeSettings.showReport);
    }

    if (surveyQuestion?.survey?.showReportAttendeeSettings?.showAttendee != undefined) {
      setAttendeeDetails(surveyQuestion.survey.showReportAttendeeSettings.showAttendee);
    }
    if (surveyQuestion?.survey?.isOpen !== undefined) {
      setCloseSurvey(!Boolean(surveyQuestion.survey.isOpen));
      // console.debug('a5',surveyQuestion?.survey?.isOpen)
    }
    if (surveyQuestion?.survey?.isOpen !== undefined) {
      setCloseSurvey(!Boolean(surveyQuestion.survey.isOpen));
      // console.debug('a5',surveyQuestion?.survey?.isOpen)
    }
    if (surveyQuestion?.survey?.isOpen !== undefined) {
      setSurveyWorkflow(!Boolean(surveyQuestion.survey.workFlow));
      // console.debug('a5',surveyQuestion?.survey?.isOpen)
    }
    if (surveyQuestion?.survey?.startDate != undefined) {
      // setStartDate(surveyQuestion?.survey?.startDate);
      setStartDate(dayjs(surveyQuestion.survey.startDate).format('YYYY-MM-DD'));
    }
    if (surveyQuestion?.survey?.endDate != undefined) {
      setEndDate(dayjs(surveyQuestion?.survey?.endDate).format('YYYY-MM-DD'));
    }
    if (surveyQuestion?.survey?.responseLimit != undefined) {
      setResponseLimit(surveyQuestion.survey.responseLimit);
    }
    if (surveyQuestion?.survey?.completionMessage != undefined) {
      setCustomThankuMsg(surveyQuestion.survey.completionMessage);
    }

  }, [surveyQuestion]);

  function onMakeSurveyPublic(e) {
    setSurveyPublic(e.target.checked)

    if (!e.target.checked) {
      setAttendeeDetails(false);
    }

  }

  const onAttendeeDetailsChange = (event) => {
    setAttendeeDetails(event.target.checked);
  };

  const onReportDetailsChange = (event) => {
    setReportDetails(event.target.checked);
  };

  function onClickOtherOption(e) {
    setOtherOption(e.target.checked)

  }

  useEffect(() => {
    if (closeSurvey) {
      setScheduleStartEnd(false);
    }
  }, [closeSurvey]);

  const onCloseSurvey = (event) => {
    setCloseSurvey(event.target.checked);
  };

  const onSurveyWorkflow = (event) => {
    setSurveyWorkflow(event.target.checked);
  };

  const onScheduleStartEndDate = (event) => {
    const checked = event.target.checked;
    if (!closeSurvey) {
      setScheduleStartEnd(checked);
    }
    if (!checked) {
      setStartDate(null);
      setEndDate(null);
    }
  };

  
  function onClickResponseLimit(e) {
    setResponse(e.target.checked)
  }
  function onClickCustomThankuMessage(e) {
    setCustomThanku(e.target.checked)

  }
  function onAllowSurveyMoreThanOneDevice(e) {
    setAllowSurveyMoreThanOneDevice(e.target.checked)

  }

  // let formattedDate = new Date(startDate);
  // let day = formattedDate.getDate().toString().padStart(2, '0');
  // let month = (formattedDate.getMonth() + 1).toString().padStart(2, '0');
  // let year = formattedDate.getFullYear().toString().slice(-2);

  function onApplySettingChange() {
    let params = {}
    // if(attendeeDetails) {
    //   params.showAttendeeSetting = 1
    // }
    // else {
    //   params.showAttendeeSetting = 0

    // }
    if (reportDetails) {
      params.showReportSetting = 1
    }
    else {
      params.showReportSetting = 0

    }
    params.isOpen = !Boolean(closeSurvey)

    params.public = Boolean(surveyPublic)
    params.showAttendeeSetting = Boolean(attendeeDetails)
    // if (surveyPublic) {
    //   params.public = 1
    // }
    // else {
    //   params.public = 0

    // }
    //  if(otherOption){
    //   params.otherOption=otherOption
    //  }
    // if (closeSurvey) {
    //   params.isOpen = closeSurvey
    // }
    // if (scheduleStartEnd && endDate && startDate) {
    //   params.startDate = startDate
    //   params.endDate = endDate
    //   // console.debug('a1', params.startDate, params.endDate)
    // }
    if (scheduleStartEnd) {
      params.startDate = startDate;
      params.endDate = endDate;
      console.debug('a56 p Start', params.startDate)
      console.debug('a56 Start', startDate)
      console.debug('a56 p end', params.endDate)
      console.debug('a56 end', endDate)
    } else {
      params.startDate = 'Null';
      params.endDate = 'Null';
    }
    if (responseLimit) {
      params.responseLimit = responseLimit
    }
    if (customThankuMsg) {
      params.completionMessage = customThankuMsg
    }
    if (allowSurveyMoreThanOneDevice) {
      params.allowSurveyMoreThanOneDevice = allowSurveyMoreThanOneDevice
    }

    if ((surveyQuestion?.survey?.surveyTitle !== undefined && surveyTitle !== surveyQuestion.survey.surveyTitle)) {
      params.surveyTitle = surveyTitle;
    }
    if ((surveyQuestion?.survey?.description !== undefined && surveyDescription !== surveyQuestion.survey.description)) {
      params.description = surveyDescription;
    }
    setUpdateLoadingStaus(true);
    console.debug("a56 before", params)
    ApiService.put("survey", params, id).then(response => {
      console.debug("a56 after", params)
      setUpdateLoadingStaus(false);
      dispatch(openSnackbar({ dataLoad: true, message: 'Record updated successfully', severity: "info" }));
    }).catch(e => {
      // console.debug("SurveyCreate : useEffect : questionTypeList : catch :", e);
      setUpdateLoadingStaus(false);
      dispatch(openSnackbar({ dataLoad: true, message: e.message, severity: "error" }));
    })
    // console.debug("SurveySettings : onApplySettingChange :  surveyTitle : ", surveyTitle);
    // console.debug("SurveySettings : onApplySettingChange :  surveyQuestion?.survey?.surveyTitle : ", surveyQuestion?.survey?.surveyTitle);
    // console.debug("SurveySettings : onApplySettingChange :  surveyTitle : ", (surveyQuestion?.survey?.surveyTitle));

    // To update Survey Title and Description
    // if ((surveyQuestion?.survey?.surveyTitle !== undefined && surveyTitle !== surveyQuestion.survey.surveyTitle) || (surveyQuestion?.survey?.description !== undefined && surveyDescription !== surveyQuestion.survey.description)) {
    //   surveyTitleAndDescriptionUpdate();
    // }
  }

  const marks = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 20,
      label: '20',
    },
    {
      value: 50,
      label: '50',
    },
    {
      value: 70,
      label: '70',
    },
    {
      value: 100,
      label: '100',
    },
  ];

  const handleSliderChange = (event, newValue) => {
    setResponseLimit(newValue);
  };

  const handleInputChange = (event) => {
    setResponseLimit(event.target.value);
  };

  const handleBlur = () => {
    if (responseLimit < 0) {
      setResponseLimit(0);
    } else if (responseLimit > 100) {
      setResponseLimit(100);
    }
  };

  // <Button variant="contained" size="small" onClick={handleComment} sx={{ ml: 2 }} disabled={isLoading}>
  //   {!isLoading ? 'Comment' :
  //     <CircularProgress size={23} color="inherit" />}
  // </Button>
  return (
    // <Box>

    <Card sx={{ borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem' }}>
      <Box sx={{ backgroundColor: '#E1EDFC', p: 1, display: 'flex', justifyContent: "space-between" }}>
        <Typography variant="subtitle1" fontWeight="bold">
          Survey Settings
        </Typography>

        <EnahanceUpdateActionButton
          size="small"
          onCliCkHandler={onApplySettingChange}
          loadingStatus={surveyQuestion?.loading}
          updatingStatus={updateLoadingStaus}
        >
          Apply
        </EnahanceUpdateActionButton>

      </Box>
      <LoadingAndErrorHandling type={"contentBody"} isLoading={surveyQuestion?.loading}
        errorMessage={surveyQuestion?.error}
      >
        <Box sx={{ overflow: 'auto', height: 'calc(100vh - 104px)' }}>
          <Box sx={{ p: '1rem' }}>
            <Box sx={{ mb: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Box><Typography variant="body1" >Make the Survey public</Typography></Box>
                </Grid>
                <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Box><Switch {...label}
                    checked={surveyPublic} onChange={onMakeSurveyPublic} /></Box>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ mb: 1 }}>
              {surveyPublic == 1 && (
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Box><Typography variant="body1">Would you like to collect information on all attendees, regardless of whether the survey is public?</Typography></Box>
                  </Grid>
                  <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Box>
                      <Switch
                        checked={attendeeDetails}
                        onChange={onAttendeeDetailsChange}
                      />
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Box>
            {surveyQuestion?.survey?.surveyOrigin?.identifier == 'manual' || surveyQuestion?.survey?.workFlow == 1 && (
              <Box sx={{ mb: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Box>
                      <Typography variant="body1">Would you like to generate the report?</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Box>
                      <Switch
                        checked={reportDetails}
                        onChange={onReportDetailsChange}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
            <Box sx={{ mb: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Box><Typography variant="body1" >Survey Title</Typography></Box>
                </Grid>
                <Grid item xs={12}>
                  <FilledInput variant="filled" size="small" fullWidth name="surveyTitle" value={surveyTitle} onChange={textFieldChangeHandler} />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ mb: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Box><Typography variant="body1" >Survey Description</Typography></Box>
                </Grid>
                <Grid item xs={12}>
                  <FilledInput variant="filled" size="small" fullWidth name="surveyDescription" value={surveyDescription} onChange={textFieldChangeHandler} />
                </Grid>
              </Grid>
            </Box>
           
            {/* <Box sx={{ mb: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Box><Typography variant="body1" >Other Option</Typography></Box>
            </Grid>
            <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Box><Switch {...label}
                checked={otherOption} onChange={onClickOtherOption} /></Box>
            </Grid>
          </Grid>
        </Box> */}
            <Box sx={{ mb: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Box><Typography variant="body1" >Close Survey</Typography></Box>
                </Grid>
                <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Box><Switch {...label} checked={closeSurvey} onChange={onCloseSurvey} /></Box>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ mb: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Box><Typography variant="body1" >Survey Workflow</Typography></Box>
                </Grid>
                <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Box><Switch {...label} checked={surveyWorkflow} onChange={onSurveyWorkflow} /></Box>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ mb: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Box><Typography variant="body1" >Schedule a start & end time</Typography></Box>
                </Grid>
                <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Box>
                    <LightTooltip title={closeSurvey ? "Closed survey is remain open. Firstly close the switch button." : ""}>
                      <span>
                        <Switch
                          checked={scheduleStartEnd}
                          onChange={onScheduleStartEndDate}
                          defaultChecked
                          disabled={closeSurvey}
                        />
                      </span>
                    </LightTooltip>
                    {/* <Switch {...label} checked={scheduleStartEnd} onChange={onScheduleStartEndDate} defaultChecked disabled={closeSurvey} /> */}
                  </Box>
                </Grid>
              </Grid>

              {scheduleStartEnd && <Box sx={{ my: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        size='small'
                        sx={{ width: "100%" }}
                        label="Start Date"
                        // label={'"year", "month" and "day"'}
                        // views={['year', 'month', 'day']}
                        // value={dayjs(startDate)}
                        // onChange={(newValue) => {
                        //   if (dayjs.isDayjs(newValue)) {
                        //     setStartDate(newValue);
                        //   } else {
                        //     setStartDate(dayjs(newValue));
                        //   }
                        // }}
                        views={['year', 'month', 'day']}
                        // value={dayjs(startDate)}
                        value={startDate ? dayjs(startDate) : null}
                        onChange={handleDateChange}
                        format="DD-MM-YYYY"
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={6} md={6} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        size='small'
                        sx={{ width: "100%" }}
                        label="End Date"
                        views={['year', 'month', 'day']}
                        value={endDate ? dayjs(endDate) : null}
                        format="DD-MM-YYYY"
                        onChange={(newValue) => {
                          if (newValue) {
                            setEndDate(dayjs(newValue).format('YYYY-MM-DD'));
                          } else {
                            setEndDate(null);
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Box>}

            </Box>
            <Box sx={{ mb: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Box><Typography variant="body1" >Response limits</Typography></Box>
                </Grid>
                <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Box><Switch {...label} checked={response} onChange={onClickResponseLimit} /></Box>
                </Grid>
              </Grid>
              {/* <Box sx={{px:2, mt:4}}>
            <Grid container spacing={2} alignItems='center'>
              <Grid xs={10} sx={{pr:4, pl:1}}>
                <Slider
                  aria-label="Custom marks"
                  defaultValue={20}
                  // getAriaValueText={valuetext}
                  step={10}
                  sx={{height:10,
                    '& .MuiSlider-thumb': {
                      display: 'none',  // Hides the round thumb
                    },
                    '& .MuiSlider-mark': {
                      top: 0,  // Position marks on top
                    },
                    '& .MuiSlider-markLabel': {
                      top: -20, 
                      paddingX:30 // Adjust label position to avoid overlap
                    },
                  }}
                  valueLabelDisplay="auto"
                marks={marks}
                />
              </Grid>
              <Grid xs={2}>
              <TextField id="filled-basic" label="Response" variant="filled"  value={responseLimit}/>
              </Grid>
            </Grid>
          </Box> */}


              {response &&
                <Box sx={{ mx: 1, my: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={10} sx={{ mt: 2 }}>
                      <Slider
                        value={responseLimit}
                        onChange={handleSliderChange}
                        aria-labelledby="input-slider"
                        marks={marks}
                        sx={{
                          height: 10,
                          '& .MuiSlider-thumb': {
                            display: 'none',  // Hides the round thumb
                          },
                          '& .MuiSlider-mark': {
                            top: 0,  // Position marks on top
                          },
                          '& .MuiSlider-markLabel': {
                            top: -20,
                            paddingX: 30 // Adjust label position to avoid overlap
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <TextField id="filled-basic" size="small" sx={{ width: "100" }} value={responseLimit} inputProps={{
                        step: 100,
                        min: 100,
                        max: 10000,
                        type: 'number',
                        'aria-labelledby': 'input-slider',
                      }} onChange={handleInputChange} onBlur={handleBlur} label="Response" variant="filled" />

                    </Grid>
                  </Grid>
                </Box>}
            </Box>
            <Box sx={{ mb: 1 }}>

              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Box><Typography variant="body1" >Thank You Message</Typography></Box>
                </Grid>
                <Grid item xs={12}>
                  <FilledInput variant="filled" size="small" fullWidth name="completionMessage" value={customThankuMsg} onChange={(e) => setCustomThankuMsg(e.target.value)} />
                </Grid>
              </Grid>

            </Box>


            <Box sx={{ mb: 1, mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Box><Typography variant="body1" >Allow the survey to be taken more than once from the same device</Typography></Box>
                </Grid>
                <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Box><Switch {...label} checked={allowSurveyMoreThanOneDevice} onChange={onAllowSurveyMoreThanOneDevice} /></Box>
                </Grid>
              </Grid>
            </Box>
          </Box>

        </Box>
      </LoadingAndErrorHandling >
    </Card>
  )
}

export default SurveySettings
import React, { useContext, useState, useEffect } from 'react'
import { ContentCopy, Drafts, Box, Tooltip, tooltipClasses, DragIndicator, IconButton, List, ListItem, ListItemIcon, ListItemText, Menu, MenuItem, MoreVert, styled, ContentCopyIcon, BlockIcon, DeleteIcon, ListItemButton } from "../../lib/utils/AllImportsHelper"
import { useDispatch, useSelector } from 'react-redux'
import { Block, Delete } from '@mui/icons-material';
import CanvasSubLayoutContext from '../../lib/layouts/CanvasSubLayout/CanvasSubLayoutContext';
import MenuComponent from '../Menu/MenuComponent';
import QuestionTypeIcon from './questionSettings/questionType/QuestionTypeIcon';
import ApiService from '../../ApiService';
import { addQuestionInSurveyQuestionList, removeQuestionFromSurveyQuestionList } from '../../redux/slice/surveyQuetions/surveyQuestionSlice';
import QuestionListItem from './QuestionListItem';
import { Skeleton } from '@mui/material';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#347edd',
        color: '#ffffff',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: '#347edd',
    },
}));

function QuestionList(props) {
    let dispatch = useDispatch()

    const [selectedItem, setSelectedItem] = useState(props.selectedQuetionItem)
    const [lastAddedQuestionId, setLastAddedQuestionId] = useState(null);
    const questionList = useSelector(state => state?.surveyQuestion?.questions)
    const canvasSubLayoutContextData = useContext(CanvasSubLayoutContext);
    const selectedQuestion = useSelector(state => state.surveyQuestion.selectedQuestion);
    console.debug("QuestionList selectedQuetionItem id", questionList)

    // Two times setting data in redux, So this is use less
    // useEffect(() => {
    //     if (selectedQuestion) {
    //         props.onSelectItemChangeHandler(null, selectedQuestion);
    //     }
    // }, [selectedQuestion]);
    // ./Two times setting data in redux, So this is use less

    const addQuestionHandler = (newQuestion, parentQuestion) => {
        if (parentQuestion && parentQuestion.childQuestions) {
            // Add new question inside the parent question's childQuestions array
            const updatedParent = {
                ...parentQuestion,
                childQuestions: [...parentQuestion.childQuestions, newQuestion]
            };
            dispatch(addQuestionInSurveyQuestionList(updatedParent));
        } else {
            // Add new question at the top level
            dispatch(addQuestionInSurveyQuestionList(newQuestion));
        }
    };
    return (
        <Box>
            <List
                sx={{
                    maxWidth: 360,
                    bgcolor: 'background.paper',
                    paddingTop: 0,
                    height: 'calc(100vh - 64px)',
                    overflow: 'hidden',
                    pb: '25rem !important',
                    '&:hover': {
                        overflow: 'auto',
                    }
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
            >
                {
                    questionList?.length ?

                        questionList?.map((question, index) => (
                            <QuestionListItem question={question}
                                key={question?.id}
                                index={index}
                                selectedQuetionItem={props?.selectedQuetionItem}
                                selectedQuestionTypeToAddHandler={props?.selectedQuestionTypeToAddHandler}
                                onSelectItemChangeHandler={props?.onSelectItemChangeHandler}
                                addQuestionHandler={addQuestionHandler}
                                questionType={props?.questionType}
                                questionTypeWithCategory={props?.questionTypeWithCategory}
                                selectedQuestionId={props?.selectedQuetionItem?.id}
                            />
                        ))
                        :
                        <Box p={2}>
                            {Array.from({ length: 6 }).map((d, i) => <Skeleton key={i} height={60} />)}
                        </Box>
                }
            </List >
        </Box>
    )
}

export default QuestionList

import React, { useState } from 'react';
import { Avatar, Box, Button, Card, Grid, IconButton, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import StickyNote2OutlinedIcon from '@mui/icons-material/StickyNote2Outlined';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import CreateFromScratch from '../../component/CreateMaturityModelDrawer/CreateFromScratch';
import CreateFromExisting from '../../component/CreateMaturityModelDrawer/CreateFromExisting';
import CreateTable from '../../component/CreateMaturityModelDrawer/CreateTable';
import ApiService from '../../ApiService';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { openSnackbar } from '../../redux/snackbar/snackbarSlice';

const CreateMaturityModal = ({ onClose,selectedCard}) => {
    const [selectedOption, setSelectedOption] = useState('scratch');
    const [showCreateTable, setShowCreateTable] = useState(false);
    const [previousState, setPreviousState] = useState(null); // To track previous state
    console.debug('a5',previousState)
    const dispatch = useDispatch();
    // State management for CreateFromScratch fields
    const [modelName, setModelName] = useState('');
    const [description, setDescription] = useState('');
    const [level, setLevel] = useState(1);
    const [practiceCriteria, setPracticeCriteria] = useState('');
    const [area, setArea] = useState(1);
    const [areaExample, setAreaExample] = useState('');
    const [tableData, setTableData] = useState([]);

    const handleCloseDrawer = () => {
        onClose(); // Call onClose function when clear icon is clicked
    };

    const handleCardClick = (option) => {
        if (!showCreateTable) {
            setSelectedOption(option);
        }
    };

    const handleScratchClick = () => {
        setPreviousState(selectedOption);
        setShowCreateTable(true);
        console.debug('a1', { modelName, description, level, practiceCriteria, area, areaExample });
    };

    const handleExistingClick = () => {
        setPreviousState(selectedOption);
        setShowCreateTable(true);
        console.debug('a5', { modelName, description, level, practiceCriteria, area, areaExample });
    };

    const handlePreviousClick = () => {
        if (showCreateTable) {
            setShowCreateTable(false);
            setSelectedOption(previousState);
        } else if (selectedOption === 'existing') {
            setSelectedOption('scratch');
        }
    };

    const HandleSubmitModel = () => {
        let formData = {
            name: modelName,
            description: description,
            practiceCriteria: practiceCriteria,
            noOfLevel: level,
            noOfArea: area,
            areas: tableData.map((row, index) => ({
                name: row.level,
                levels: Array.from({ length: level }, (_, i) => ({
                    name: `Level ${i + 1}`,
                    definition: row[`level_${i + 1}`] || ''
                }))
            }))
        };
        ApiService.post('maturityModel', formData)
        .then(r => {
            if (r?.data) {
                dispatch(openSnackbar({ dataLoad: true, message: 'Submitted successfully', severity: 'info' }));
            }
            onClose();
        })
        .catch(e => {
            onClose();
        });
    };

    const cards = [
        {
            id: 1,
            title: 'Create from scratch',
            icon: <RateReviewOutlinedIcon sx={{ color: 'black' }} />,
            onClick: () => handleCardClick('scratch'),
            value: 'scratch',
        },
        {
            id: 2,
            title: 'Choose from existing',
            icon: <StickyNote2OutlinedIcon sx={{ color: 'black' }} />,
            onClick: () => handleCardClick('existing'),
            value: 'existing',
        },
    ];

    return (
        <Box sx={{ width: '60rem' }}>
            <Box sx={{ backgroundColor: '#E1EDFC' }}>
                <Grid container spacing={2} sx={{ p: 1 }}>
                    <Grid item xs={8}>
                        <Box sx={{ mt: 1 }}>
                            <Typography variant="subtitle1" fontWeight="bold">Create Maturity Modal</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Box>
                            <IconButton aria-label="close" onClick={handleCloseDrawer}>
                                <ClearIcon />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ p: 1 }}>
                <Grid container spacing={1}>
                    {cards.map((card, index) => (
                        <Grid item xs={12} sm={6} md={3.5} key={index}>
                            <Card
                                sx={{
                                    p: 2,
                                    cursor: 'pointer',
                                    mb: 2,
                                    border: selectedOption === card.value ? '2px solid #1976d2' : '2px solid transparent',
                                }}
                                onClick={card.onClick}
                            >
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Avatar sx={{ border: '1px dotted black', width: 56, height: 56, mr: 2 }}>
                                        {card.icon}
                                    </Avatar>
                                    <Typography variant="subtitle1">{card.title}</Typography>
                                </Box>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Box sx={{ p: 1 }}>
                {showCreateTable ? (
                    <Box sx={{ border: '1px solid #c6c6c6', borderRadius: '0.2rem', height: 'calc(100vh - 250px)', overflow: 'auto' }}>
                        <CreateTable
                            modelName={selectedCard ? selectedCard.name :  modelName}
                            description={selectedCard ? selectedCard.description : description}
                            level={selectedCard ? selectedCard.noOfLevel : level}
                            area={selectedCard ? selectedCard.noOfArea : area}
                            practiceCriteria={selectedCard ? selectedCard.practiceCriteria : practiceCriteria}
                            areaExample={areaExample}
                            setTableData={setTableData}
                        />
                    </Box>
                ) : (
                    <Box sx={{ border: '1px solid #c6c6c6', borderRadius: '0.2rem', height: 'calc(100vh - 250px)', overflow: 'auto' }}>
                        <Box sx={{ p: 2 }}>
                            {selectedOption === 'scratch' && (
                                <CreateFromScratch
                                    modelName={modelName}
                                    setModelName={setModelName}
                                    description={description}
                                    setDescription={setDescription}
                                    level={level}
                                    setLevel={setLevel}
                                    practiceCriteria={practiceCriteria}
                                    setPracticeCriteria={setPracticeCriteria}
                                    area={area}
                                    setArea={setArea}
                                    areaExample={areaExample}
                                    setAreaExample={setAreaExample}
                                />
                            )}
                            {selectedOption === 'existing' && <CreateFromExisting />}
                        </Box>
                    </Box>
                )}
            </Box>
            <Box sx={{ display: 'flex', p: 1, justifyContent: 'space-between' }}>
                <Button
                    size='small'
                    variant="outlined"
                    startIcon={<ArrowBackIosNewOutlinedIcon />}
                    onClick={handlePreviousClick}
                >
                    Previous
                </Button>
                {!showCreateTable ? (
                    <Button
                        size='small'
                        variant="contained"
                        endIcon={<ArrowForwardIosOutlinedIcon />}
                        onClick={selectedOption === 'scratch' ? handleScratchClick : handleExistingClick}
                    >
                        Next
                    </Button>
                ) : (
                    <Button
                        size='small'
                        variant="contained"
                        endIcon={<ArrowForwardIosOutlinedIcon />}
                        onClick={HandleSubmitModel}
                    >
                        Submit
                    </Button>
                )}
            </Box>
        </Box>
    );
};

export default CreateMaturityModal;

import React, { useRef } from 'react'
import {
    Box, Button, Grid, TextField, Tab, Tabs, Typography, styled, alpha, useTheme, Drawer, Card, CssBaseline, IconButton, Avatar, InputBase, Menu,
    AppBar as MuiAppBar, Toolbar, Divider, ListItemIcon, ToggleButtonGroup, MenuItem, Paper,
    AccountCircle as AccountCircleIcon, Collections as CollectionsIcon, Undo as UndoIcon, Redo as RedoIcon, Remove as RemoveIcon, Add as AddIcon, Search as SearchIcon, Delete as DeleteIcon,
    Image as ImageIcon, TextFields as TextFieldsIcon, ChangeHistoryTwoTone as ChangeHistoryTwoToneIcon, ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon, ContentCopyIcon,
    Home as HomeIcon, Preview as PreviewIcon, Download as DownloadIcon, Circle as CircleIcon, Rectangle as RectangleIcon, EggTwoTone, Star as StarIcon, Logout, Settings, FormatColorFill,
    Tooltip
} from '../../lib/utils/AllImportsHelper';
import FacebookIcon from '@mui/icons-material/Facebook';
// import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import routes from '../../routesComponent/Routes';
import SurveyEndUser from '../../pages/Survey/SurveyEndUser';
import SurveyLink from './SurveyLink';



const ShareLink = () => {
    let { id } = useParams()
    const textInput = useRef(null);
    const surveyQuestion = useSelector(state => state.surveyQuestion);

    console.debug("surveyQuestion", surveyQuestion?.survey?.isPublished)

    const handleCopy = () => {
        const textFieldValue = textInput.current.value;
        navigator.clipboard.writeText(textFieldValue).then(() => {
            console.log('Text copied to clipboard:', textFieldValue);
        }).catch((error) => {
            console.error('Error copying text:', error);
        });
    };

    const themebgcolor = "blue";
    const themecolor = "orange"; 

    const surveyBackgroundColor = surveyQuestion?.survey?.isPublished === '1' ? themecolor : themebgcolor;
    console.debug('surveyEndUser : backgroundcolor :',surveyBackgroundColor,surveyQuestion?.survey)


    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={7}>
                    <Card sx={{borderTopLeftRadius:'1rem',borderTopRightRadius:'1rem'}}>
                        <Box sx={{ backgroundColor: '#E1EDFC', p: 1.2 }}>
                            <Typography variant="subtitle1" fontWeight="bold">
                                Share the link
                            </Typography>
                        </Box>
                        <Box sx={{ p:2,height:'calc(100vh - 100px)',overflow:'auto'}} >
                            <SurveyEndUser showHeaderFooter={false}  backgroundColor={surveyBackgroundColor}/>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={5}>
                    <Card sx={{borderTopLeftRadius:'1rem',borderTopRightRadius:'1rem',height:'calc(100vh - 61px)',overflow:'auto' }}>
                        <Box sx={{ p: 1.2}}>
                            <Box>
                                <Typography variant="subtitle1" fontWeight="bold">
                                    Survey Link
                                </Typography>
                            </Box>
                            <SurveyLink/>
                            {/* <Box sx={{ my: 1,pt:1 }}>
                                <Box sx={{ display: 'flex' }}>
                                    <Box sx={{ width: "100%" }}>
                                        <TextField
                                            inputRef={textInput}
                                            value={window.location.origin + routes.surveyYourOpinion + id}
                                            variant="outlined"
                                            size="small"
                                            disabled={surveyQuestion?.survey?.isPublished === '0'}
                                            readOnly={surveyQuestion?.survey?.isPublished === '1'}
                                            fullWidth
                                            InputProps={{
                                                readOnly: true,
                                                style: {
                                                    userSelect: 'none',  // Prevents text selection
                                                },
                                            }}
                                        />
                                    </Box>
                                    <Box>
                                    <Tooltip
  title={
    surveyQuestion?.survey?.isPublished === '0'  ? (
      <Typography>You cannot share this survey until it has been published.</Typography>
    ) : (
      <Typography>You are welcome to share this survey.</Typography>
    )
  }
  arrow
>
                                            <span>
                                                <IconButton onClick={handleCopy} disabled={surveyQuestion?.survey?.isPublished === '0'}>
                                                    <ContentCopyIcon />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                    </Box>
                                </Box>
                            </Box>
                            <Box>
                                <Typography variant="subtitle1" fontWeight={"bold"}>
                                    Share
                                </Typography>
                            </Box>
                            <Box sx={{ my: 1, display: 'flex' }}>
                                <FacebookIcon disabled sx={{ height: 40, width: 40, mr: 2, color: "gray" }} />
                                <LinkedInIcon disabled sx={{ height: 40, width: 40, mr: 2, color: "gray" }} />
                                <TwitterIcon disabled sx={{ height: 40, width: 40, mr: 2, color: "gray" }} />
                            </Box> */}
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}

export default ShareLink
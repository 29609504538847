import {
    Box,
    Grid,
    Skeleton,
    Typography,
  } from "@mui/material";
import React, { useContext,useEffect, useRef, useState } from "react";
import { endUserPageMaster } from "../../constants/endUserPageMaster";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ApiService from "../../ApiService";
import PublicApiService from "../../PublicApiService";
import { setUserInfo } from '../../redux/userInfoStore/userSlice'
import { openSnackbar } from "../../redux/snackbar/snackbarSlice";
import EndUserFooterButton from "./EndUserFooterButton";
import { questionTypeConst } from "../../constants/questionTypeConst";
import { HtmlRender } from "../../lib";
import QuestionComponentWrapper from "./QuestionComponentWrapper";
import { useMediaQuery } from '@mui/material';
import { useFormValidation } from "../../hooks/useFormValidation";
import SurveyEndUserContext from "./SurveyEndUserContext";

  
function QuestionWrapperWithWorkflow(props) {
    // console.debug("SurveyEndUser : QuestionWrapperWithWorkflow : props : ", props);
    const isSmallScreenMUI = useMediaQuery('(max-width:768px)');
  //   console.debug("SurveyEndUser : QuestionWrapper : surveyEndUserContext : ", surveyEndUserContext);
    const surveyEndUserContext = useContext(SurveyEndUserContext);

    const dispatch = useDispatch();
    let { id } = useParams()
  
    const scrollableRef = useRef(null);

    // To provide back feature in survey 
    const [previousButtonActiveStatus, setPreviousButtonActiveStatus] = useState(false);
    const [currentQuestionGroup, setCurrentQuestionGroup] = useState(null);
    const [previousQuestionGroup, setPreviousQuestionGroup] = useState(null);
    const [questionHistoryTracking, setQuestionHistoryTracking] = useState([]);
    // ./To provide back feature in survey 
  

    
  
    const [surveyData, setSurveyData] = useState(null);
    const [surveyQuestions, setSurveyQuestions] = useState([]);
    const [surveyQuestionMasterData, setSurveyQuestionMasterData] = useState([]);
    const [followUpRulesResource, setFollowUpRulesResource] = useState(null);
  
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [selectedQuestionAnswers, setSelectedQuestionAnswers] = useState([])
  
  
    const [footerNextButtonVisibilityStatus, setFooterNextButtonVisibilityStatus] = useState(true);
  
    const [userId, setUserId] = useState(null);
   
    // Custom hook for handling validation logic
    const { errors, validate } = useFormValidation(surveyQuestions, selectedQuestionAnswers);

    useEffect(() => {
        if (scrollableRef.current) {
          scrollableRef.current.scrollTop = 0;
        }
    }, [surveyQuestions]);
    
  //  Set Survey Question Hanlder
  
  function setSurveyQuestionsHandler(questions) {
    //   console.debug("SurveyEndUser : QuestionWrapper : setSurveyQuestionsHandler : surveyData : ", surveyData);
    //   console.debug("SurveyEndUser : QuestionWrapper : setSurveyQuestionsHandler : questions : ", questions);
    //   console.debug("SurveyEndUser : QuestionWrapper : setSurveyQuestionsHandler : selectedQuestionAnswers : ", selectedQuestionAnswers);
    //   console.debug("SurveyEndUser : QuestionWrapper : setSurveyQuestionsHandler : questions : tempFollowUpRulesResource : ", questions?.followUpRulesResource?.[0]?.followUpQuestion?.id);
        setCurrentQuestionGroup(questions)
      if(surveyData !== null && surveyData?.workFlow !== undefined){
          if(surveyData.workFlow){
              // console.debug("SurveyEndUser : QuestionWrapper : setSurveyQuestionsHandler : with Workflow : ");
              if (selectedQuestionAnswers.length === 0) {
                //   console.debug("SurveyEndUser : QuestionWrapper : setSurveyQuestionsHandler : handleInitialRendering : ");
                  handleInitialRendering(questions);
              } else if (shouldRenderWithoutFollowUp(questions)) {
                //   console.debug("SurveyEndUser : QuestionWrapper : setSurveyQuestionsHandler : shouldRenderWithoutFollowUp(questions) : ",shouldRenderWithoutFollowUp(questions));
                //   console.debug("SurveyEndUser : QuestionWrapper : setSurveyQuestionsHandler : handleRenderingWithoutFollowUp : ");
                  handleRenderingWithoutFollowUp(questions);
              } else {
                //   console.debug("SurveyEndUser : QuestionWrapper : setSurveyQuestionsHandler : handleRenderingWithFollowUp : ");
                  handleRenderingWithFollowUp(questions);
              }
            // Below if block will set the restart button at end of the page
            // because the survey is with workflow but without followupResource i.e. it is end page   
              if(!(questions?.followUpRulesResource && questions.followUpRulesResource.length > 0)){
                setFooterNextButtonVisibilityStatus(false);
              }
          }else{
              // console.debug("SurveyEndUser : QuestionWrapper : setSurveyQuestionsHandler : without Workflow : ");
              let tempQuestions=[];
              // console.debug("SurveyEndUser : QuestionWrapper : setSurveyQuestionsHandler : workFlow else block : surveyQuestionMasterData : ", surveyQuestionMasterData);
              surveyQuestionMasterData.forEach(data=>{
                  if(data?.identifier==='question-group' && data?.childQuestions?.length>0){
                      // console.debug("SurveyEndUser : QuestionWrapper : setSurveyQuestionsHandler : workFlow else block : data?.childQuestions : ", data?.childQuestions);
                      data.forEach(childData=>{
                          tempQuestions.push(childData);
                      })
                  }else{
                      // console.debug("SurveyEndUser : QuestionWrapper : setSurveyQuestionsHandler : workFlow else block : data : ", data);
                      tempQuestions.push(data);
                  }
              })
              // console.debug("SurveyEndUser : QuestionWrapper : setSurveyQuestionsHandler : workFlow else block : tempQuestions : ", tempQuestions);
  
              setSurveyQuestions(tempQuestions);
          }
      }else{
          console.error("Something Went Wrong : No surveyData : ",surveyData);
          
      }
  }
  
    /**
     * Handles the initial rendering of questions when the user has not given any answers yet.
     * @param {Object} questions - The questions object to render.
     */
    function handleInitialRendering(questions) {
        // console.debug("SurveyEndUser : QuestionWrapper : handleInitialRendering : questions : ",questions);
        setSurveyQuestionsBasedOnType(questions);
        if (questions?.followUpRulesResource && questions.followUpRulesResource.length > 0) {
            // console.debug("SurveyEndUser : QuestionWrapper : handleInitialRendering : If Block With FollowUp Resource : questions.followUpRulesResource : ",questions.followUpRulesResource);
            setFollowUpRulesResource(questions.followUpRulesResource[0]);
        }
        // else{
        //     console.debug("SurveyEndUser : QuestionWrapper : handleInitialRendering : Else Block No FollowUp Resource : ");
        //     // footerNextButtonVisibilityStatus when their is no follow up Questions
        //     setFooterNextButtonVisibilityStatus(false);`
        // }
    }
  
    /**
     * Checks if the questions should be rendered without follow-up logic.
     * @param {Object} questions - The questions object to evaluate.
     * @returns {boolean} - True if the questions should be rendered without follow-up, false otherwise.
     */
    function shouldRenderWithoutFollowUp(questions) {
        // console.debug("SurveyEndUser : QuestionWrapper : shouldRenderWithoutFollowUp : selectedQuestionAnswers.length : ",selectedQuestionAnswers?.length);
        // console.debug("SurveyEndUser : QuestionWrapper : shouldRenderWithoutFollowUp : questions: ",questions);
        // console.debug("SurveyEndUser : QuestionWrapper : shouldRenderWithoutFollowUp : questions?.followUpRulesResource : ",questions?.followUpRulesResource);
        // console.debug("SurveyEndUser : QuestionWrapper : shouldRenderWithoutFollowUp : questions?.followUpRulesResource?.length : ",questions?.followUpRulesResource?.length);
    
        return selectedQuestionAnswers.length > 0 && 
                (!questions?.followUpRulesResource || questions.followUpRulesResource.length === 0);
    }
  
    /**
     * Handles rendering questions when no follow-up logic is involved.
     * @param {Object} questions - The questions object to render.
     */
    function handleRenderingWithoutFollowUp(questions) {
        console.debug("SurveyEndUser : QuestionWrapper : handleRenderingWithoutFollowUp : ");
        setSurveyQuestionsBasedOnType(questions);
    }
  
    /**
     * Handles rendering questions when follow-up logic is involved.
     * @param {Object} questions - The questions object to render.
     */
    function handleRenderingWithFollowUp(questions) {
        // console.debug("SurveyEndUser : QuestionWrapper : handleRenderingWithFollowUp : ");
    
        if (questions?.followUpRulesResource && questions.followUpRulesResource.length > 0) {
            // Need to modify once the relationship of backend updated
            setFollowUpRulesResource(questions.followUpRulesResource[0]);
    
            setSurveyQuestionsBasedOnType(questions);
            // Comment To provide back feature in survey, it was giving trouble when answer is selected but giving validation error
            // setSelectedQuestionAnswers([]);
            // ./Comment To provide back feature in survey, it was giving trouble when answer is selected but giving validation error
            /*
            if (followUpRulesResource?.followUpQuestionType?.identifier === "question-group") {
                setSurveyQuestionsBasedOnType(questions);
                setSelectedQuestionAnswers([]);
            } else {
                console.error("Invalid follow-up question type.");
            }
            */
        } else {
            console.error("No follow-up rules resource provided.");
        }
    }
    
    /**
     * Sets the survey questions based on the question type (e.g., question-group).
     * @param {Object} questions - The questions object to evaluate.
     */
    function setSurveyQuestionsBasedOnType(questions) {
        // console.debug("SurveyEndUser : QuestionWrapper : setSurveyQuestionsBasedOnType : questions : ",questions);
        if(questions){
            if (questions?.questionType.identifier === "question-group") {
                setSurveyQuestions(questions?.childQuestions);
            } else {
                setSurveyQuestions([questions]);
            }
        }

    }
     //  ./Set Survey Question Hanlder
  
  
    const getFomratAnswerForStoreRequestData = () => {
        // console.debug("SurveyEndUser : getFomratAnswerForStoreRequestData : selectedQuestionAnswers : ", selectedQuestionAnswers)
    
        var formetedData = [];
        // Object.entries(selectedQuestionAnswers).forEach((objectData) => {
        selectedQuestionAnswers.forEach((objectData) => {
            // console.debug("SurveyEndUser : getFomratAnswerForStoreRequestData : selectedQuestionAnswers : objectData : ", objectData)
    
            let d = {};
            // d.optionChoiceId = answer?.optionChoiceId;
            if(objectData?.question?.questionType?.identifier !== undefined && objectData?.answer !== undefined){
                d.questionId = objectData.question.id;
                /*
                switch (objectData.question.questionType.identifier) {
                    case '[questionTypeConst.yesNo]':
                        d.answer = objectData.answer;
                        break;
                    case "[questionTypeConst.multipleChoice]":
                            d.answer = objectData.answer?.optionChoiceName;
                        break;
                    case '[questionTypeConst.dropdown]':
                                d.answer = objectData.answer?.id;
                        break;
                    default:
                                d.answer = objectData.answer;
                        break;
                }
                */

                switch (objectData.question.questionType.identifier) {
                    case questionTypeConst.yesNo:
                        d.answer = objectData.answer;
                        break;
                    case questionTypeConst.multipleChoice:
                        d.answer = objectData.answer?.optionChoiceName;
                        break;
                    case questionTypeConst.dropdown:
                        // console.debug("SurveyEndUser : getFomratAnswerForStoreRequestData : selectedQuestionAnswers : objectData : ", objectData);
                        d.answer = objectData?.answer?.id;
                        break;
                    default:
                        // console.debug("SurveyEndUser : getFomratAnswerForStoreRequestData : selectedQuestionAnswers : objectData : default ", objectData.question.questionType.identifier);
                        d.answer = objectData.answer;
                        break;
                }
                // d.answer = answer?.optionChoiceName;
                formetedData.push(d);
            }
            
        // console.debug("SurveyEndUser : getFomratAnswerForStoreRequestData : Formatted : d : ", d);
        });
    
        // console.debug("SurveyEndUser : getFomratAnswerForStoreRequestData : Formatted answer: ", formetedData);
        // console.debug('a10', formetedData);
        return formetedData;
    
    
        // let formetedData = [];
        // Object.entries(selectedAnswers).forEach(([questionId, answer]) => {
        //     console.debug('a10', questionId);
        //     console.debug('a10', answer);
    
        //     let d = {};
        //     // d.optionChoiceId = answer?.optionChoiceId;
        //     d.answer = answer?.optionChoiceName;
        //     d.questionId = questionId;
        //     formetedData.push(d);
    
        //     console.debug('Formatted answer:', d);
        // });
    
        // console.debug('a10', formetedData);
        // return formetedData;
    }
  
  
    const submitSurveyResponse = async () => {
        // console.debug("a10", selectedAnswers);
        let formData = {};
        const formattedAnswers = getFomratAnswerForStoreRequestData();
        //   console.debug("SurveyEndUser : submitSurveyResponse : formattedAnswers : ", formattedAnswers);
        //   console.debug("SurveyEndUser : submitSurveyResponse : selectedQuestionAnswers : ", selectedQuestionAnswers);
        // console.debug("SurveyEndUser : submitSurveyResponse : props : ", props);
        formData.answers = formattedAnswers;
        // formData.surveyId = id;
        formData.surveyId = props.surveyData?.data.id;
        ;
    
        if(surveyData.public===0){
            formData.userId = userId;
        }
        // formData.userId = 1;
        formData.averageType = true;
        if(props?.surveySessionId !== undefined && props.surveySessionId !== null ){
            formData.sessionId = props.surveySessionId;
        }
    
        try {
            const response = await PublicApiService.post('surveyAnswerStore', formData);
            // console.debug("SurveyEndUser : submitSurveyResponse : surveyAnswerStore : response : ", response);
            // if(props?.setSessionHandler !== undefined && response?.data?.data?.report?.sessionId !== undefined){
            if(props?.setSessionHandler !== undefined && response?.data?.data?.session?.id !== undefined){
                props.setSessionHandler(response?.data?.data?.session?.id);
            }
            if(props?.setGraphDataHandler !== undefined && response?.data !== undefined){
                props.setGraphDataHandler(response?.data);
            }
            
            // setSurveySessionId(response?.data?.data?.report?.sessionId)
            // alert('Answer Submitted successfully');
            dispatch(openSnackbar({ dataLoad: true, message: 'Answer submitted successfully', severity: "info" }));
    
        } catch (error) {
            // alert('Answer not Submitted successfully')
            dispatch(openSnackbar({ dataLoad: true, message: error.message, severity: "error" }));;
        }
    
    };
  
  

    function previousProcessHandler() {
        
        if (questionHistoryTracking?.length > 0) {
            const previousSet = questionHistoryTracking[questionHistoryTracking.length - 1];
            // console.debug("SurveyEndUser : QuestionWrapperWithWorkflow : nextProcessHandler : previousProcessHandler : selectedAnswers : ", selectedAnswers);
            // console.debug("SurveyEndUser : QuestionWrapperWithWorkflow : nextProcessHandler : previousProcessHandler : selectedQuestionAnswers : ", selectedQuestionAnswers);
            // console.debug("SurveyEndUser : QuestionWrapperWithWorkflow : nextProcessHandler : previousProcessHandler : questionHistoryTracking : ", questionHistoryTracking);
            // console.debug("SurveyEndUser : QuestionWrapperWithWorkflow : nextProcessHandler : previousProcessHandler : previousSet : ", previousSet);
            setQuestionHistoryTracking(prevHistory => prevHistory.slice(0, -1));
            setSurveyQuestionsHandler(previousSet?.currentQuestionGroup);
        }
    }

    function updateQuestionHistoryTracking() {
        setQuestionHistoryTracking(prevHistory => [...prevHistory, {currentQuestionGroup:currentQuestionGroup,questions:surveyQuestions,followUpRulesResource:followUpRulesResource}]);
    }



  
    function nextProcessHandler() {
        const isValid = validate(); // Validate all required questions
        if (isValid) {
            console.debug('SurveyEndUser : QuestionWrapperWithWorkflow : nextProcessHandler : selectedQuestionAnswers :', selectedQuestionAnswers); // If valid, submit the answers
            console.debug('SurveyEndUser : QuestionWrapperWithWorkflow : nextProcessHandler : selectedAnswers :', selectedAnswers); // If valid, submit the answers
            submitSurveyResponse().then(r=>{
            //  props.nextClickHandler(page);
            if(surveyData.workFlow){
                console.debug("SurveyEndUser : QuestionWrapperWithWorkflow : nextProcessHandler : workFlow if block : ");
                // console.debug("SurveyEndUser : QuestionWrapper : nextProcessHandler : workFlow if block : ");
                // Need to modify to work for multiple If block
                let condition = followUpRulesResource?.condition[0];
                    if (condition) {
                        console.debug("SurveyEndUser : QuestionWrapperWithWorkflow : nextProcessHandler : submitSurveyResponse : condition : ", condition);
                        condition.fallBackQuestionId=followUpRulesResource.fallBackQuestionId
                        condition.fallBackQuestionTypeId=followUpRulesResource.fallBackQuestionTypeId
                        console.debug("SurveyEndUser : QuestionWrapperWithWorkflow : nextProcessHandler : submitSurveyResponse : updated condition : ", condition);
                        console.debug("SurveyEndUser : QuestionWrapperWithWorkflow : nextProcessHandler : submitSurveyResponse : selectedQuestionAnswers : ", selectedQuestionAnswers);

                        let filteredCurrentQuestionAnswer=[];
                        surveyQuestions?.forEach(surveyQuestionElement => {
                            selectedQuestionAnswers.forEach(selectedQuestionAnswerElement => {
                                if(surveyQuestionElement?.id===selectedQuestionAnswerElement?.question?.id){
                                    filteredCurrentQuestionAnswer.push(selectedQuestionAnswerElement)
                                }
                            });
                        });

                        console.debug('SurveyEndUser : QuestionWrapperWithWorkflow : nextProcessHandler : filteredCurrentQuestionAnswer :', filteredCurrentQuestionAnswer); // If valid, submit the answers

                        // let conditionStatus = evaluateCondition(condition, selectedQuestionAnswers);
                        let conditionStatus = evaluateCondition(condition, filteredCurrentQuestionAnswer);
                        console.debug("SurveyEndUser : QuestionWrapperWithWorkflow : nextProcessHandler : submitSurveyResponse : conditionStatus : ", conditionStatus);
                        console.debug("SurveyEndUser : QuestionWrapperWithWorkflow : nextProcessHandler : submitSurveyResponse : followUpRulesResource : ", followUpRulesResource);
                        console.debug("SurveyEndUser : QuestionWrapperWithWorkflow : nextProcessHandler : submitSurveyResponse : surveyQuestionMasterData : ", surveyQuestionMasterData);
                
                        let nextQuestion = getNextQuestion(conditionStatus, condition, surveyQuestionMasterData);
                        console.debug("SurveyEndUser : QuestionWrapperWithWorkflow : nextProcessHandler : next Question : nextQuestion : ", nextQuestion);
                        
                        if (nextQuestion) {
                            setSurveyQuestionsHandler(nextQuestion);
                            updateQuestionHistoryTracking();
                        } else {
                            console.error("Next question not found.");
                        }
                    } else {
                        console.error("Invalid or missing condition.");
                    }
                }else{
                    console.debug("SurveyEndUser : QuestionWrapperWithWorkflow : nextProcessHandler : workFlow else block : ");
                    if(props?.nextClickHandler !== undefined){
                        let page="";
                        if(surveyData?.public){
                            if(surveyData?.showReportAttendeeSettings?.showReport){
                                page = endUserPageMaster.graph;
                            }else if(surveyData?.showReportAttendeeSettings?.showAttendee){
                                page = endUserPageMaster.attendees;
                            }else{
                                page = endUserPageMaster.thankYou;
                            }
                        }else{
                            if(surveyData?.showReportAttendeeSettings?.showReport){
                                page = endUserPageMaster.graph;
                            }else{
                                page = endUserPageMaster.thankYou;
                            }
                        }

                        props.nextClickHandler(page);
                        
                    }else{
                        console.error("Something Went Wrong");
                    }
                }
            }).catch(e=>{
                console.error('SurveyEndUser : QuestionWrapperWithWorkflow : nextProcessHandler : submitSurveyResponse failed:', e); 
            });
            
        } else {
            console.error('SurveyEndUser : QuestionWrapperWithWorkflow : nextProcessHandler : Validation failed:', errors); // If invalid, log errors
        }
    }
  
    /**
     * Evaluates the condition based on the provided operator and value.
     * @param {Object} condition - The condition object containing type, operator, and value.
     * @param {Array} selectedAnswers - The selected answers to evaluate against the condition.
     * @returns {boolean} - The result of the condition evaluation.
     */
    function evaluateCondition(condition, selectedAnswers) {
        let countOfResponse = 0;
        // console.debug("SurveyEndUser : QuestionWrapper : evaluateCondition : condition : ", condition);
        // console.debug("SurveyEndUser : QuestionWrapper : evaluateCondition : selectedAnswers : ", selectedAnswers);
    
        if (condition.conditionType === 'count') {
            selectedAnswers.forEach(answer => {
                if (answer.answer === condition.response) {
                    countOfResponse++;
                }
            });
            // console.debug("SurveyEndUser : QuestionWrapper : evaluateCondition : countOfResponse : ", countOfResponse);
            // console.debug("SurveyEndUser : QuestionWrapper : evaluateCondition : condition.conditionOperator : ", condition?.conditionOperator);
            // console.debug("SurveyEndUser : QuestionWrapper : evaluateCondition : condition.conditionValue : ", condition?.conditionValue);
            return evaluateConditionStatus(countOfResponse, condition.conditionOperator, condition.conditionValue);
        }
        return false;
    }
  
    /**
     * Evaluates the condition status based on the operator and values.
     * @param {number} count - The count of responses that match the condition.
     * @param {string} operator - The operator to use for evaluation.
     * @param {number} value - The value to compare against.
     * @returns {boolean} - The result of the evaluation.
     */
    function evaluateConditionStatus(count, operator, value) {
        switch (operator) {
            case '==': return count == value;
            case '!=': return count != value;
            case '>': return count > value;
            case '>=': return count >= value;
            case '<': return count < value;
            case '<=': return count <= value;
            default:
                console.error('Invalid condition operator:', operator);
                return false;
        }
    }
  
    /**
     * Retrieves the next question based on the condition status.
     * @param {boolean} conditionStatus - The result of the condition evaluation.
     * @param {Object} followUpRules - The follow-up rules resource containing question IDs.
     * @param {Array} questionsData - The array of survey questions data.
     * @returns {Object|null} - The next question object or null if not found.
     */
    function getNextQuestion(conditionStatus, followUpRules, questionsData) {
        let questionId = conditionStatus ? followUpRules.followUpQuestionId : followUpRules.fallBackQuestionId;
        return questionsData.find(question => question.id === Number(questionId)) || null;
    }
  
  
  
  
    const handleAnswerSelect = (question, answer) => { 
        // console.debug("SurveyEndUser : QuestionWrapper : handleAnswerSelect : question : ", question)
        // console.debug("SurveyEndUser : QuestionWrapper : handleAnswerSelect : answer : ", answer)

        setSelectedAnswers(prevAnswers => ({
            ...prevAnswers,
            // [question.id]: {
            //     optionChoiceId: answer.optionChoiceId,
            //     optionChoiceName: answer.optionChoiceName
            // }
            [question.id]: answer,
        }));

        let tempSelectedQuestionAnswers = [...selectedQuestionAnswers];

        if (question?.id) {
            const selectedAnswerIndex = tempSelectedQuestionAnswers.findIndex(d => d.question?.id === question?.id);
        
            if (selectedAnswerIndex > -1) {
                // If the question already exists, update the answer
                tempSelectedQuestionAnswers[selectedAnswerIndex].answer = answer;
                setSelectedQuestionAnswers([...tempSelectedQuestionAnswers]);
            } else {
                // If the question does not exist, add a new question-answer pair
                setSelectedQuestionAnswers(prevAnswers => [
                    ...prevAnswers,
                    { question, answer }
                ]);
            }
        } else {
            console.error("Invalid question: question ID is missing.");
        }
    };
  
  
    useEffect(function () {
        setSurveyQuestionsHandler(surveyQuestionMasterData[0]);
    },[surveyQuestionMasterData]);

  
    useEffect(function () {
        if (props?.surveyData !== undefined) {
          let tempSurveyData = props.surveyData?.data;
          if (tempSurveyData?.surveyQuestion !== undefined) {
            // console.debug("SurveyEndUser : QuestionWrapper : useEffect : tempSurveyData.surveyQuestion : ",tempSurveyData.surveyQuestion);
            setSurveyQuestionMasterData(tempSurveyData.surveyQuestion);
          }
          setSurveyData(tempSurveyData);
          
          if(props?.surveyData?.data?.public===0){
              ApiService.get('userInfo').then(res => {
                  let response = res.data.data;
                  setUserId(res.data.data.userId)
                  console.log('a5', response);
                  if (response) {
                      dispatch(setUserInfo({
                          ...response,
                          // userAvatar: response.userAvatar,
                      }));
                  }
              });
          }
  
        }
      },
      [props?.surveyData]
    );
  
    const filteredChildQuestions = (data) =>
      data?.childQuestions?.filter(
        (cData) =>
          cData?.questionType?.identifier !== "multiple-choice" ||
          (cData?.optionChoice && cData?.optionChoice.length > 0)
    );
  

    function getAlignmentCss() {
        // const baseCss = { p: 1, pt: 2 };
        const baseCss = { pt: 2 ,};
      
        // If surveyQuestions is empty or undefined, return an empty object
        if (!surveyQuestions?.length) return {};
      
        const { identifier } = surveyQuestions[0]?.questionType || {};
      
        // Check if the question type is either "endPage" or "contactInfo"
        if (identifier === questionTypeConst.endPage || identifier === questionTypeConst.contactInfo) {
          // Handle the "endPage" case for small screens
          if (identifier === questionTypeConst.endPage && isSmallScreenMUI) {
            return {
              marginTop: "1rem",
            };
          }
      
          // Handle the "contactInfo" case
          if (identifier === questionTypeConst.contactInfo) {
            // Small screen styles for contactInfo
            if (isSmallScreenMUI) {
              return {
                ...baseCss,
                display: "flex",
                alignItems: "center",
                // height: "100%",
                justifyContent: "center",
              };
            }
      
            // Large screen styles for contactInfo
            return {
              ...baseCss,
              display: "flex",
              alignItems: "center",
            //   height: "100%",
              justifyContent: "center",
            //   width: "50%",
              margin: "auto",
            };
          }
      
          // Default flexbox styles for other cases (endPage on larger screens)
          return {
            ...baseCss,
            display: "flex",
            alignItems: "center",
            // height: "100%",
            justifyContent: "center",
          };
        }
      
        // Default styles when questionType doesn't match any specific case
        return baseCss;
    }
      
      
    return (
        <Box>

      <Box sx={{ px: "1rem"}}>
          <Box
          className="scrollHeight"
          ref={scrollableRef}
          sx={{
            height: {xs:'calc(80vh - 143px)', sm:'calc(80vh - 143px)', md:'auto', lg:'calc(100vh - 143px)'},
            overflow: "auto",
            "&:hover": {
              overflowY: "auto",
            },
            "&": {
              overflowY: "hidden",
            },
          }}
        //   sx={{ height: "calc(100vh - 143px)", overflow: "auto" ,color:'black'}}
        >
            <Box sx={getAlignmentCss()}>
                {surveyQuestions?.length > 0 ? 
                    (
                        <Box>
                            {(surveyQuestions?.[0].questionType?.identifier !== questionTypeConst.endPage) && (surveyQuestions?.[0].questionType?.identifier !== questionTypeConst.contactInfo)?
                                // (<h1>
                                //     Questionnaire
                                // </h1>)
                                 (<Box sx={{display:'flex',justifyContent:'center',alignItems:'center',pb:1}}>
                                        <Typography variant="h5" fontWeight='bold' sx={{color:'#2E4C8D'}} fontFamily={surveyEndUserContext?.theme?.question?.fontFamily}>
                                    Questionnaire
                                  </Typography>
                                </Box>)

                            :""}
                            {
                                surveyQuestions.map((data,i) => {
                                    return data?.questionType?.identifier === "question-group" ? 
                                        <Box>
                                            {
                                                                                    (
                                            filteredChildQuestions(data)?.map((cData) => (
                                                <QuestionComponentWrapper
                                                    questionDataObject={cData}
                                                    handleAnswerSelect={handleAnswerSelect}
                                                    error={errors[cData.id]} // Pass error message as a prop
                                                    answer={selectedAnswers[cData.id]}
                                                />
                                            ))
                                        )
                                        }
                                            </Box>
                                        : 
                                        (
                                            <Box key={i}>

                                            <Box key={data.id}>
                                                {" "}
                                                {/* Added key prop */}
                                                {data?.questionType?.identifier== questionTypeConst.endPage?
                                                    
                                                    (data?.questionDescription?.description !== undefined?
                                                        <Box sx={{
                                                            // display:"flex",
                                                            

                                                        }} >
                                                            <Grid container spacing={2}>
                                                                <Grid item 
                                                                sx={{
                                                                        alignItems:"center",
                                                                        display:"flex",
                                                                    }}
                                                                     xs={12} md={12}
                                                                >
                                                                {data?.questionTypeFileSurvey?.[0]?.path !== undefined ?
                                                                    <img 
                                                                        style={{
                                                                            maxWidth: "100%", /* Ensures image doesn't exceed its container's width */
                                                                            height: "auto",    /* Keeps aspect ratio intact */
                                                                            display: "block",  
                                                                        }}  
                                                                        alt="" src={data?.questionTypeFileSurvey?.[0]?.path} 
                                                                    />
                                                                :""}
                                                               
                                                                </Grid>
                                                                <Grid item xs={12} md={12}>
                                                                     <HtmlRender htmlContent={data.questionDescription.description}/>
                                                                </Grid>
                                                            </Grid>
                                                             {/* <Avatar alt="Remy Sharp" src={data?.questionTypeFileSurvey?.[0]?.path} /> */}
                                                             
                                                             {/* <Avatar>H</Avatar> */}
                                                             {/* <HtmlRender htmlContent={data.questionDescription.description}/> */}
                                                        </Box>
                                                        // ":cvcbnddf"
                                                        // <HtmlRender htmlContent={data.questionDescription.description}/> 
                                                        :
                                                        ""
                                                    )
                                                :
                                                    <QuestionComponentWrapper
                                                        questionDataObject={data}
                                                        handleAnswerSelect={handleAnswerSelect}
                                                        error={errors[data.id]} // Pass error message as a prop
                                                        answer={selectedAnswers[data.id]}

                                                    />
                                                }
                                            </Box>
                                            </Box>
                                            
                                        );
                                        
                                    }
                                )
                            }
                        </Box>  
                        // hjhhhj
                    ) 
                : (
                      <>
                          {[...Array(6)].map((_, index) => (
                          <Box key={index} sx={{ mb: 2 }}>
                              {" "}
                              {/* Added mb for space between boxes */}
                              <Skeleton
                              variant="text"
                              sx={{ fontSize: "1rem", width: "30%", mb: 1 }}
                              />
                              <Skeleton
                              variant="text"
                              sx={{ fontSize: "1rem", width: "30%", ml: 2, mb: 1 }}
                              />
                              <Skeleton
                              variant="text"
                              sx={{ fontSize: "1rem", width: "30%", ml: 2, mb: 1 }}
                              />
                              <Skeleton
                              variant="text"
                              sx={{ fontSize: "1rem", width: "30%", ml: 2, mb: 1 }}
                              />
                          </Box>
                          ))}
                      </>
                  )
              }
  
          </Box>
  
              
          </Box>
  
          {/* <EndUserFooterButton nextProcessHandler={nextProcessHandler}
              nextButtonVisibilityStatus={footerNextButtonVisibilityStatus}
          /> */}
  
      </Box>
      <Box sx={{background:'#e5e5e5',padding:'1rem',borderBottomRightRadius:'1rem',borderBottomLeftRadius:'1rem'}}>
      <EndUserFooterButton nextProcessHandler={nextProcessHandler}
              nextButtonVisibilityStatus={footerNextButtonVisibilityStatus}
              previousProcessHandler={previousProcessHandler}
              // previousButtonActiveStatus={previousButtonActiveStatus}
              previousButtonActiveStatus={questionHistoryTracking?.length}

         />
      </Box>
        </Box>
    );
  
  }
  
  export default QuestionWrapperWithWorkflow;
  
import { Delete, Save } from '@mui/icons-material';
import { Box, IconButton, InputAdornment, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ApiService from '../../../../ApiService';
import { openSnackbar } from '../../../../redux/snackbar/snackbarSlice';
import { updateSelectedQuestionAndQuestionInQuestionArray } from '../../../../redux/slice/surveyQuetions/surveyQuestionSlice';
import { setSelectedQuestionOptions } from '../../../../redux/survey/selectedQuestionOptionsSlice';

function MultipleChoiceQuesOptions(props) {

  const [optionData, setOptionData] = useState(null);
  const surveyQuestion = useSelector(state => state.surveyQuestion);
  let dispatch = useDispatch();

  useEffect(function () {
    setOptionData(props.option);
  }, [props?.option]);

  const handleOnSelectOption = (e, optionParam, index) => {
    // setCurrectSelectedOption(optionParam);
    // setSelectedIndex(index);
    if (props?.currentSelectionChangeHandler) {
      props.currentSelectionChangeHandler(optionParam);
    }
  }

  const handleEditOption = (index, newText) => {
    // // console.debug("MultipleChoiceQues : handleEditOption : newText : ", newText);
    // // console.debug("MultipleChoiceQues : handleEditOption : index : ", id);
    // // console.debug("MultipleChoiceQues : handleEditOption : options : ", options);
    // let tempOption = [...options];
    // // console.debug("MultipleChoiceQues : handleEditOption : tempOption[id] : ", tempOption[id]);
    // tempOption[index] = { ...tempOption[index], optionChoiceName: newText };
    // setOptions([...tempOption]);
    // // setOptions(options.map(option => option.optionChoiceId === id ? { ...option, optionChoiceName: newText } : option));
    // // setOptions(options.map(option => option.optionChoiceId === id ? { ...option, optionChoiceName: newText } : option));

    // console.debug("MultipleChoiceQuesOptions : handleEditOption : newText : ", newText);
    setOptionData({ ...optionData, optionChoiceName: newText });
  };

  // const handleSaveOption = (e, optionAsParam, index) => {
  const handleSaveOption = () => {

    let optionAsParam = { ...props?.currentSelectedOption };
    if (optionAsParam?.tempFlag != "add") {
      // console.debug("MultipleChoiceQuesOptions : handleSaveOption : work as edit : optionAsParam : ", optionAsParam);
      console.debug("MultipleChoiceQues : handleSaveOption : work as edit : optionAsParam : ", optionAsParam);
      let updatedSelectedQuestion = { ...surveyQuestion?.selectedQuestion };
      // let parentQuestion = { ...surveyQuestion?.selectedParentQuestion }
      let params = {};
      params.optionChoiceName = optionAsParam.optionChoiceName;
      params.returnPayload = true;
      ApiService.post('updateOneOptionInMultipleChoice', params, optionAsParam.optionChoiceId).then(r => {
        // console.debug("MultipleChoiceQues : handleSaveOption : updateOneOptionInMultipleChoice : r : ", r);
        if (r.data) {
          let tempOptions = updatedSelectedQuestion?.optionChoice;
          tempOptions = tempOptions.map(function (d) {
            let temp = { ...d }
            if (temp?.optionChoiceId == optionAsParam?.optionChoiceId) {
              temp.optionChoiceName = optionAsParam?.optionChoiceName;
            }
            return temp;
          })
          // updatedSelectedQuestion = { ...updatedSelectedQuestion, optionChoice: [...tempOptions] };
          // let actionPayload = { question: updatedSelectedQuestion, parentQuestion: parentQuestion };
          // dispatch(updateSelectedQuestionAndQuestionInQuestionArray(actionPayload))
          dispatch(setSelectedQuestionOptions(tempOptions))
          dispatch(openSnackbar({ message: 'Option updated successfully', severity: "info" }));
        }
      }).catch(e => {
        console.error("MultipleChoiceQues : handleSaveOption : updateOneOptionInMultipleChoice : e : ", e);
        dispatch(openSnackbar({ message: "Something went wrong", severity: "error" }));
      });
    } else {
      console.debug("MultipleChoiceQuesOptions : handleSaveOption : work as create : optionAsParam : ", optionAsParam);

      let optionsArrayToAdd = [{ ...optionAsParam }];
      // optionAsParam.optionChoiceName
      let params = {};
      params.questionId = surveyQuestion?.selectedQuestion?.id;
      params.returnPayload = true;
      optionsArrayToAdd.forEach((data, i) => {
        params[`optionChoiceName[${i}]`] = data.optionChoiceName;
      });
      ApiService.post("addOptionInMultipleChoice", params).then(r => {
        // console.debug("MultipleChoiceQues : handleSaveOption : addOptionInMultipleChoice : r : ", r);
        if (r.data) {
          // console.debug("MultipleChoiceQues : handleSaveOption : addOptionInMultipleChoice : r : ", r?.data?.data?.option_choice);
          let tempOptions = [...r?.data?.data?.optionChoice];
          // console.debug("MultipleChoiceQues : handleSaveOption : addOptionInMultipleChoice : tempOptions : ",tempOptions);
          // let updatedSelectedQuestion = { ...surveyQuestion?.selectedQuestion };
          // updatedSelectedQuestion = { ...updatedSelectedQuestion, optionChoice: [...tempOptions] };
          // let parentQuestion = { ...surveyQuestion?.selectedParentQuestion }
          // let actionPayload = { question: updatedSelectedQuestion, parentQuestion: parentQuestion };
          // dispatch(updateSelectedQuestionAndQuestionInQuestionArray(actionPayload))
          dispatch(setSelectedQuestionOptions(tempOptions))
          dispatch(openSnackbar({ message: 'Option added successfully', severity: "info" }));
        }
      }).catch(e => {
        console.error("MultipleChoiceQues : handleSaveOption : addOptionInMultipleChoice : e : ", e);
        dispatch(openSnackbar({ message: "Something went wrong", severity: "error" }));
      });

    }

  }

  return (
    <Box key={optionData?.optionChoiceId} display="flex" alignItems="center" mb={2}>
      <TextField
        InputProps={{
          startAdornment: <InputAdornment position="start" sx={{ border: "1px solid gray ", p: 1.5, borderRadius: 1.5 }}>{props?.index + 1}</InputAdornment>,
        }}
        value={optionData?.optionChoiceName}
        onSelect={e => handleOnSelectOption(e, optionData)}
        onChange={(e) => handleEditOption(props?.index, e.target.value)}
        // onBlur={textFieldBlurHandlder}
        // onKeyDown={textFieldKeyDownHandler}
        variant="outlined"
      // sx={{
      //   marginRight: 1,
      //   border: selectedIndex === index ? '1px solid blue' : '1px solid gray',
      //   borderRadius: 1.5,
      // }}
      />
      {optionData?.optionChoiceId == props?.currentSelectedOption?.optionChoiceId ?
        <Box>
          {/* <IconButton onClick={(e) => handleSaveOption(e, optionData, "index")}> */}
          <IconButton onClick={handleSaveOption}>
            <Save />
          </IconButton>
          <IconButton onClick={(e) => props?.handleDeleteOption(e, optionData, props?.index)}>
            <Delete />
          </IconButton>
        </Box>
        : ""
      }
      {/* <Box>
        <IconButton onClick={(e) => handleSaveOption(e, optionData, index)}>
          <Save />
        </IconButton>
        <IconButton onClick={(e) => handleDeleteOption(e, optionData, index)}>
          <DeleteIcon />
        </IconButton>
      </Box> */}
    </Box >
  );
}

export default MultipleChoiceQuesOptions;
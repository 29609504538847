import React, { useState, useRef, useEffect } from 'react';
import { Box, Grid, Typography, TextField, Button, IconButton, Tooltip, tooltipClasses, styled } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import routes from '../../../routesComponent/Routes';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#347edd',
        color: '#ffffff',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: '#347edd',
    },
}));

const CanvasGenAIHeader = (props) => {
    const navigate = useNavigate();
    const [isEditing, setIsEditing] = useState(false);
    const [text, setText] = useState('Food Survey');
    const textFieldRef = useRef(null);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleTextChange = (event) => {
        setText(event.target.value);
    };

    const handleBlur = () => {
        setIsEditing(false);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            setIsEditing(false);
        }
    };

    useEffect(() => {
        if (isEditing && textFieldRef.current) {
            textFieldRef.current.focus();
        }
    }, [isEditing]);

    const truncateText = (text, charLimit) => {
        if (text.length > charLimit) {
            return text.slice(0, charLimit) + '...';
        }
        return text;
    };

    const truncatedText = truncateText(text, 15);
    const tooltipText = text.length > 15 ? text : '';

    const goBack = () => {
        // window.history.back(); 
        navigate(routes.dashboard);
    };


    return (
        <>
            <Box sx={{ padding: 1, backgroundColor: '#f5f5f5' }}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item>
                                <IconButton edge="start" color="inherit" aria-label="back" onClick={goBack}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box sx={{ p: 1 }}>
                                        {isEditing ? (
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                value={text}
                                                onChange={handleTextChange}
                                                onBlur={handleBlur}
                                                onKeyDown={handleKeyDown}
                                                size="small"
                                                sx={{ bgcolor: 'transparent', '& .MuiOutlinedInput-root': { borderRadius: '0' } }}
                                                inputRef={textFieldRef}
                                            />
                                        ) : (
                                            <LightTooltip title={tooltipText} arrow>
                                                <Typography variant="subtitle1" component="div" color="#000">
                                                    {truncatedText}
                                                </Typography>
                                            </LightTooltip>
                                        )}
                                    </Box>
                                    <Box sx={{ ml: 2 }}>
                                        <IconButton
                                            color="black"
                                            aria-label="Edit"
                                            edge="start"
                                            sx={{ mr: 2 }}
                                            fontSize="small"
                                            onClick={handleEditClick}
                                            size='small'
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary"
                            size='small'
                            onClick={props?.startToCustomiseClickHandler}
                            disabled={props?.headerButtonDisabledStatus}
                        >
                            START TO CUSTOMIZE
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default CanvasGenAIHeader;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    activeStatus: false,
};

const selectedQuestionRequiredStatusSlice = createSlice({
    name: 'selectedQuestionRequiredStatusSlice',
    initialState,
    reducers: {
        setSelectedQuestionRequiredStatus: (state, action) => {
            state.activeStatus = Boolean(action.payload);
        },
    }
});

export const {
    setSelectedQuestionRequiredStatus,
} = selectedQuestionRequiredStatusSlice.actions;

export default selectedQuestionRequiredStatusSlice.reducer;
import React, { useContext, useState } from 'react';
import { Box, Card, CardContent, Typography, Button, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import RefreshIcon from '@mui/icons-material/Refresh';
import BoltIcon from '@mui/icons-material/Bolt';
import GenAIContext from './GenAIContext';
import SwitchableCard from '../card/SwitchableCard';
import { AutoAwesome } from '@mui/icons-material';
import { EnahanceUpdateActionButton } from '../../lib';

function ProblemStatementCard(props) {
  const genAiContextData = useContext(GenAIContext);
  console.debug('a11',genAiContextData.totalQuestionInSourceItemList)
  const [promptArray, setPromptArray] = useState([]);
  console.debug('a11',promptArray)
  const [currentPrompt, setCurrentPrompt] = useState("");

  function resetIntialContent() {
    setPromptArray([]);
  }

  function onGenerateClickHandler(e) {
    e.preventDefault();
    if (genAiContextData?.currentPrompt) {
      setPromptArray([genAiContextData?.currentPrompt, ...promptArray]);
      console.debug('a11',[genAiContextData?.currentPrompt, ...promptArray])
    }


    if (genAiContextData?.onGenerateClickHandler) {
      genAiContextData.onGenerateClickHandler()
    }


    // if (editValue) {
    //   let contentTemp = [...content];
    //   contentTemp.push(editValue);
    //   // setContent([...content, editValue])
    //   setContent([...contentTemp])
    //   // content.push(editValue)

    // }

  }





  return <>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '16px',
        backgroundColor: '#E3F2FD',
        border: '1px solid #BBDEFB',
        borderRadius: '8px',
        maxWidth: '400px',
        margin: 'auto',
      }}
    // sx={{ display: 'flex', alignItems: 'center', width: '100%' }}
    //  sx={{ maxWidth: 345 }}
    >

      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <BoltIcon sx={{ marginRight: '8px' }} />
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
          Your problem statement
        </Typography>
      </Box>
      <Typography variant="caption" color='#757575' sx={{mr:'1rem'}}>
        AI generated result based on the prompt
      </Typography>
      <SwitchableCard
        viewMode={"textfield"}
        initialContent={promptArray}
        // textFieldChangeHandler={textFieldChangeHandler}
        textFieldChangeHandler={genAiContextData?.textFieldChangeHandler}

        resetIntialContent={resetIntialContent}
      />
      <Box>
        <Typography variant="caption" >
          Ex: Create an assessment survey for MDM prospects in the technology industry
        </Typography>
      </Box>
      <Box mt='1.5rem'>
        {/* <Button name="generateContent" variant='outlined' startIcon={<AutoAwesome />}
          disabled={props?.generatePromptLoading ? true : false || genAiContextData.totalQuestionInSourceItemList > 0} 
          // onClick={props?.onGenerateContentWithAI}
          onClick={onGenerateClickHandler}
          color="primary"
        >
          {promptArray?.length ? "RE-GENERATE" : "GENERATE"}
        </Button> */}

        <EnahanceUpdateActionButton
          size="small"
          variant='outlined'
          startIcon={<AutoAwesome />}
          onCliCkHandler={onGenerateClickHandler}
          // loadingStatus={genAiContextData?.loading}
          loadingStaus={props?.generatePromptLoading ? true : false || genAiContextData.totalQuestionInSourceItemList > 0}
          // updatingStatus={updateLoadingStaus}
        >
          {promptArray?.length ? "RE-GENERATE" : "GENERATE"}
        </EnahanceUpdateActionButton>
      </Box>
    </Box>
  </>
}

export default ProblemStatementCard;

import React, { useContext, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    Box,
    Typography,
    Skeleton,
} from '@mui/material';
import QuestionContext from './QuestionContext';
import QuestionInputTypes from './addQuestion/QuestionInputTypes';
import EndPageQues from './addQuestion/addQuestionInputTypesComponents/EndPageQues';

function QuestionCanvas({ selectedItems, ...props }) {
    const questionContextData = useContext(QuestionContext);
    console.debug("QuestionPage : QuestionCanvas : questionContextData : ", questionContextData);
    const surveyQuestion = useSelector(state => state.surveyQuestion);

    const [question, setQuestion] = useState(questionContextData?.selectedQuetionItem?.question);
    console.debug('QuestionPage : QuestionCanvas : questionContextData : updatedQuestion', questionContextData?.selectedQuetionItem?.question);
    const [questionUpdate, setQuestionUpdate] = useState(false);

    const [editable, setEditable] = useState(false);

    // Handle text update
    const handleQuestionEdit = (updatedQuestion) => {
        setQuestionUpdate(updatedQuestion);
    };

    // Handle click to edit
    const handleEditClick = () => {
        setEditable(true);
    };

    // Handle when user finishes editing
    const handleTextBlur = (e) => {
        const updatedQuestion = e.target.innerText.trim();
        setQuestion(updatedQuestion);
        setEditable(false);
        questionContextData?.selectedQuetionItemChangeHandler(updatedQuestion);
    };

    const handleTextKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent the default action of the Enter key
            handleTextBlur(e);
        }
    };

    const [description, setDescription] = useState(questionContextData?.selectedQuetionItem?.questionDescription?.description || '');
    console.debug('questionCanvas:questionContextData:questionDescription :', description);

    const handleBlur = (e) => {
        const newText = e.target.innerText.trim();
        setDescription(newText); // Save the edited text
    };

    const displayText = description || 'Description (optional)';

    useEffect(() => {
        console.debug('Editable state changed:', questionUpdate, question);
        if (questionUpdate) {
            setEditable(false);
            setQuestionUpdate(false);
        }
    }, [questionUpdate]);

    console.debug("Updated question:", { question, editable });

    return (
        <Box sx={{ padding: '5rem', maxHeight: "100vh" }}>
            {questionContextData?.selectedQuetionItem?.childQuestions?.length === 0 && (
                <Box sx={{ my: 2, backgroundColor: '#90A4AE' }}>
                    <Box sx={{ mx: 2 }}>
                        <Typography variant="subtitle1" fontWeight="bold" color="white">
                            {surveyQuestion?.selectedParentQuestion?.question}
                        </Typography>
                    </Box>
                </Box>
            )}

            <Box sx={{ ml: 4 }}>
                <Box>
                    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                        {questionContextData?.selectedQuetionItem?.questionSequence &&
                            <Box>{questionContextData?.selectedQuetionItem?.questionSequence + "."}</Box>
                        }

                        <Box onClick={handleEditClick}>
                            {editable ? (
                                (
                                    <Typography
                                        variant="subtitle2"
                                        contentEditable
                                        suppressContentEditableWarning
                                        onBlur={handleTextBlur}
                                        onKeyDown={handleTextKeyDown}
                                        style={{
                                            cursor: 'text',
                                            outline: 'none',
                                            minWidth: '200px', 
                                        }}
                                    >
                                        {question || 'Click to edit'}
                                    </Typography>
                                )
                            ) : (
                                <Box>
                                    {question ? (
                                        <Typography
                                            variant="subtitle2"
                                            onClick={handleEditClick}
                                            style={{ cursor: 'text' }}
                                        >
                                            {question}
                                        </Typography>
                                    ) : (
                                        <Skeleton height={40} width={600} />
                                    )}
                                </Box>
                            )}
                        </Box>

                        <Box sx={{ ml: 1 }}>
                            {questionContextData?.selectedQuetionItem?.questionRequired !== undefined && questionContextData.selectedQuetionItem.questionRequired !== 0 ? (
                                <Typography variant="subtitle1" fontWeight="bold">
                                    *
                                </Typography>
                            ) : ""}
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ ml: 1 }}>
                    {questionContextData?.selectedQuetionItem?.questionDescriptionStatus === 1 ? (
                        <Typography
                            variant="subtitle2"
                            contentEditable
                            suppressContentEditableWarning
                            onBlur={handleBlur}
                            onClick={(e) => e.stopPropagation()}
                            style={{
                                cursor: 'text',
                                outline: 'none',
                            }}
                        >
                            {displayText}
                        </Typography>
                    ) : (
                        <Typography
                            variant="subtitle2"
                            contentEditable
                            suppressContentEditableWarning
                            onBlur={handleBlur}
                            onClick={(e) => e.stopPropagation()}
                            style={{
                                cursor: 'text',
                                outline: 'none',
                                color: 'gray',
                            }}
                        >
                            Description (optional)
                        </Typography>
                    )}
                </Box>

                {
                    questionContextData.selectedQuetionItem?.questionType?.identifier !== 'end-screen' &&
                    <Box sx={{ m: 2 }}>
                        <QuestionInputTypes selectedQuesType={questionContextData.selectedQuetionItem?.questionType?.identifier} />
                    </Box>
                }
            </Box>
        </Box>
    );
}

export default QuestionCanvas;

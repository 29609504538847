import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    descriptionObject: null,
};

const selectedQuestionDescriptionSlice = createSlice({
    name: 'selectedQuestionDescriptionSlice',
    initialState,
    reducers: {
        setSelectedQuestionDescription: (state, action) => {
            state.descriptionObject = action.payload;
        },
    }
});

export const {
    setSelectedQuestionDescription,
} = selectedQuestionDescriptionSlice.actions;

export default selectedQuestionDescriptionSlice.reducer;
import { ChevronRight } from '@mui/icons-material';
import { Avatar, Box, CssBaseline, styled } from '@mui/material';
import React, { useState } from 'react';
// import CanvasDrawer from '../CanvasLayout/CanvasDrawer';
import CanvasDrawer from './CanvasDrawer';
import CanvasSubLayoutContext from './CanvasSubLayoutContext';
const drawerWidth = 315;


function CanvasSubLayout(props) {
    // console.debug("CanvasSubLayout : props :", props);
    const [value, setValue] = useState(0);
    const [open, setOpen] = useState(true);
    const [questions, setQuestions] = useState(["Default Question"]);
    const [openMenuIcon, setopenMenuIcon] = useState(false);

    const [anchorE9, setAnchorE9] = useState(null);
    const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(null);



    const handleMenuClick = (event, index) => {
        setAnchorE9(event.currentTarget);
        setSelectedQuestionIndex(index);
        setopenMenuIcon(true);
    };
    const handleMenuClose = () => {
        setAnchorE9(null);
        setopenMenuIcon(false);
    };

    const handleDuplicate = () => {
        const newQuestions = [...questions];
        newQuestions.splice(selectedQuestionIndex + 1, 0, questions[selectedQuestionIndex]);
        setQuestions(newQuestions);
        handleMenuClose();
    };

    const [disabledItems, setDisabledItems] = useState(Array(questions.length).fill(false));

    const handleDisable = (index) => {
        const updatedDisabledItems = [...disabledItems];
        updatedDisabledItems[index] = true;
        setDisabledItems(updatedDisabledItems);
        handleMenuClose();
    };

    const handleDelete = () => {
        const newQuestions = [...questions];
        newQuestions.splice(selectedQuestionIndex, 1);
        setQuestions(newQuestions);
        handleMenuClose();
    };



    const handleDrawerOpen = (e) => {
        if (e)
            e.preventDefault();
        setOpen(true);
    };


    const handleDrawerClose = (e) => {
        if (e)
            e.preventDefault();
        setOpen(false);
    };

    const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
        ({ theme, open }) => ({
            flexGrow: 1,
            padding: theme.spacing(3),
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: `-${drawerWidth}px`,
            // marginLeft: `-100px`,
            // marginLeft: `-10px`,
            ...(open && {
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                marginLeft: 0,
            }),
        }),
    );

    const canvasSubLayoutContextData = {
        ...props,
        open: open,
        drawerWidth: drawerWidth,
        handleDrawerClose: handleDrawerClose,
        handleDrawerOpen: handleDrawerOpen,
        value: value,

        questions: questions,



        handleMenuClick: handleMenuClick,
        handleDuplicate: handleDuplicate,
        handleDisable: handleDisable,
        handleDelete: handleDelete,
    };
    return (
        <CanvasSubLayoutContext.Provider value={canvasSubLayoutContextData}>
            <Box sx={{ display: 'flex' }} className='width-100 min-hight-6'>
                <CssBaseline />
                {/* <Avatar onClick={(e) => { handleDrawerOpen(e) }}
                    sx={{ ml: -2.2, mt: 1, opacity: 0.4, cursor: 'pointer', }} >
                    <ChevronRight sx={{ color: '#1976D2' }} fontSize='small' />
                </Avatar> */}
                <CanvasDrawer />
                <Main open={open} className="overflow-auto" sx={{padding: '0px !important',width:'1rem',borderTopLeftRadius:'1rem',borderTopRightRadius:'1rem' }}>
                    <Box className="overflow-auto" sx={{ padding: '0px !important' }}>
                        {props.children}
                    </Box>
                </Main>
            </Box>
        </CanvasSubLayoutContext.Provider>

    );
}

export default CanvasSubLayout;
import React, { useState, useEffect } from 'react';
import { Box, Card, Grid, Typography, Chip, Autocomplete, TextField, Switch, FormGroup, FormControlLabel, FormControl } from '@mui/material';
import { EnahanceUpdateActionButton } from '../../lib';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ApiService from '../../ApiService';
import { openSnackbar, closeSnackbar } from '../../redux/snackbar/snackbarSlice';

const SeoSettings = () => {
    const dispatch = useDispatch();
    const surveyQuestion = useSelector(state => state.surveyQuestion.survey?.surveySeo);
    const [slug, setSlug] = useState('');
    const [seoMetaTitle, setSeoMetaTitle] = useState('');
    const [seoMetaDescription, setSeoMetaDescription] = useState('');
    const [seoRelatedKeyword, setSeoRelatedKeyword] = useState('');
    const [seoCanonicalTag, setSeoCanonicalTag] = useState('');
    const [seoKeyword, setSeoKeyword] = useState([]);
    const [seoSchema, setSeoSchema] = useState('');
    const [seoIndex, setSeoIndex] = useState(false);
    const [isApplyState, setIsApplyState] = useState(true);
    const [seoMetaTitleError, setSeoMetaTitleError] = useState(false);
    const [seoMetaDescriptionError, setSeoMetaDescriptionError] = useState(false);
    const [seoMetaTitleErrorText, setSeoMetaTitleErrorText] = useState('');
    const [seoMetaDescriptionErrorText, setSeoMetaDescriptionErrorText] = useState('');

    let { id } = useParams();

    const tags = [
        { title: 'user' },
        { title: 'name' },
        { title: 'address' },
        { title: 'fields' },
    ];

    const handleSeoMetaTitleChange = (e) => {
        const value = e.target.value;
        setSeoMetaTitle(value);

        if (value.length > 60) {
            setSeoMetaTitleError(true);
            setSeoMetaTitleErrorText('Meta title should not exceed 60 characters.');
        } else {
            setSeoMetaTitleError(false);
            setSeoMetaTitleErrorText('');
        }
    };

    const handleSeoMetaDescriptionChange = (e) => {
        const value = e.target.value;
        setSeoMetaDescription(value);

        if (value.length > 160) {
            setSeoMetaDescriptionError(true);
            setSeoMetaDescriptionErrorText('Meta description should not exceed 160 characters.');
        } else {
            setSeoMetaDescriptionError(false);
            setSeoMetaDescriptionErrorText('');
        }
    };


    useEffect(() => {
        if (surveyQuestion) {
            setSlug(surveyQuestion[0]?.slug || '');
            setSeoMetaTitle(surveyQuestion[0]?.seoMetaTitle || '');
            setSeoMetaDescription(surveyQuestion[0]?.seoMetaDescription || '');
            setSeoRelatedKeyword(surveyQuestion[0]?.seoRelatedKeyword || '');
            setSeoCanonicalTag(surveyQuestion[0]?.seoCanonicalTag || '');

            const keywords = surveyQuestion[0]?.seoKeyword || [];
            setSeoKeyword(keywords.map(keyword => keyword.title));

            setSeoSchema(surveyQuestion[0]?.seoSchema || '');
            setSeoIndex(surveyQuestion[0]?.seoIndex || false);

            const hasValue = surveyQuestion[0]?.slug ||
                surveyQuestion[0]?.seoMetaTitle ||
                surveyQuestion[0]?.seoMetaDescription ||
                surveyQuestion[0]?.seoRelatedKeyword ||
                surveyQuestion[0]?.seoCanonicalTag ||
                keywords.length > 0 ||
                surveyQuestion[0]?.seoSchema;

            setIsApplyState(!hasValue);
        }
    }, [surveyQuestion]);

    const hasFieldValues = () => {
        return slug || seoMetaTitle || seoMetaDescription || seoRelatedKeyword || seoCanonicalTag || seoKeyword.length > 0 || seoSchema;
    };

    const handleApply = () => {
        const formData = {
            slug,
            seoMetaTitle,
            seoMetaDescription,
            seoRelatedKeyword,
            seoCanonicalTag,
            seoKeyword,
            seoSchema,
            seoIndex: seoIndex ? 1 : 0,
            surveyId: id,
        };

        ApiService.post('seoIndex', formData)
            .then(response => {
                console.debug("a9", response.data);
                dispatch(openSnackbar({ dataLoad: true, message: 'SEO data post successfully', severity: "info" }));
            })
            .catch(error => {
                console.error("Error updating SEO data: ", error);
                dispatch(openSnackbar({ dataLoad: true, message: error.message, severity: "error" }));
            });
    };

    const onApplySettingChange = () => {
        let params = {
            slug,
            seoMetaTitle,
            seoMetaDescription,
            seoRelatedKeyword,
            seoCanonicalTag,
            seoKeyword,
            seoSchema,
            seoIndex,
        };

        const id = surveyQuestion[0]?.id;

        ApiService.put("seoUpdateIndex", params, id).then(response => {
            dispatch(openSnackbar({ dataLoad: true, message: 'Record updated successfully', severity: "info" }));
        }).catch(e => {
            dispatch(openSnackbar({ dataLoad: true, message: e.message, severity: "error" }));
        })
    }

    return (
        <Box>
            <Card sx={{ height: "calc(100vh - 64px)", borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem', overflow: 'auto' }}>
                <Box sx={{ backgroundColor: '#E1EDFC', p: 1, display: 'flex', justifyContent: "space-between" }}>
                    <Typography variant="subtitle1" fontWeight="bold">
                        Seo Settings
                    </Typography>
                    {isApplyState ? (
                        <EnahanceUpdateActionButton size="small" onClick={handleApply} disabled={!hasFieldValues()}>
                            Apply
                        </EnahanceUpdateActionButton>
                    ) : (
                        <EnahanceUpdateActionButton size="small" onClick={onApplySettingChange}>
                            Update
                        </EnahanceUpdateActionButton>
                    )}
                </Box>
                <Box sx={{ p: 1 }}>
                    <Box sx={{ my: 1 }}>
                        <FormControl component="fieldset">
                            <FormGroup aria-label="position" row>
                                <FormControlLabel
                                    value="top"
                                    control={
                                        <Switch
                                            color="primary"
                                            checked={seoIndex}
                                            onChange={(e) => setSeoIndex(e.target.checked)}
                                        />
                                    }
                                    label="Seo Index"
                                    labelPlacement="top"
                                />
                            </FormGroup>
                        </FormControl>
                        <Box sx={{ my: 1 }}>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={4}>
                                    <TextField
                                        id="slug"
                                        label="Slug"
                                        variant="outlined"
                                        size='small'
                                        fullWidth
                                        value={slug}
                                        onChange={(e) => setSlug(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        id="seoMetaTitle"
                                        label="Seo Meta Title"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        value={seoMetaTitle}
                                        onChange={handleSeoMetaTitleChange}
                                        error={!!seoMetaTitleError}
                                    // helperText={seoMetaTitleError}
                                    />
                                    {seoMetaTitleError && (
                                        <Typography variant='subtitle2' color='#e51414'>
                                            *Meta title should not exceed 60 characters.
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        id="seoMetaDescription"
                                        label="Seo Meta Description"
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        value={seoMetaDescription}
                                        onChange={handleSeoMetaDescriptionChange}
                                        error={!!seoMetaDescriptionError}
                                    // helperText={seoMetaDescriptionError}
                                    />
                                    {seoMetaDescriptionError && (
                                        <Typography variant='subtitle2' color='#e51414'>
                                            *Meta description should not exceed 160 characters.
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        id="seoRelatedKeyword"
                                        label="Seo Related Keyword"
                                        variant="outlined"
                                        fullWidth
                                        size='small'
                                        value={seoRelatedKeyword}
                                        onChange={(e) => setSeoRelatedKeyword(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        id="seoCanonicalTag"
                                        label="Seo Canonical Tag"
                                        variant="outlined"
                                        size='small'
                                        fullWidth
                                        value={seoCanonicalTag}
                                        onChange={(e) => setSeoCanonicalTag(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{ my: 2 }}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    multiple
                                    id="tags-outlined"
                                    options={tags.map((option) => option.title)}
                                    freeSolo
                                    size='small'
                                    value={seoKeyword}
                                    onChange={(event, newValue) => setSeoKeyword(newValue)}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            id="enterTags"
                                            {...params}
                                            label="Press Enter to Add Seo Keywords"
                                            placeholder="Press Enter to Add Seo Keywords"
                                        />
                                    )}
                                />
                            </Grid>
                        </Box>
                        <Box sx={{ my: 2 }}>
                            <Grid item xs={12}>
                                <TextField
                                    id="outlined-multiline-flexible"
                                    label="SEO Schema"
                                    multiline
                                    size='small'
                                    fullWidth
                                    rows={4}
                                    value={seoSchema}
                                    onChange={(e) => setSeoSchema(e.target.value)}
                                />
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Card>
        </Box>
    );
};

export default SeoSettings;

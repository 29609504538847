// QuestionInputTypes.js
import React from 'react';
import { Box } from '../../../lib/utils/AllImportsHelper';
import MultipleChoiceQues from './addQuestionInputTypesComponents/MultipleChoiceQues';
import OpinionState from './addQuestionInputTypesComponents/OpinionState';
import RateQues from './addQuestionInputTypesComponents/RateQues';
import YesNoQues from './addQuestionInputTypesComponents/YesNoQues';
import WebsiteQues from './addQuestionInputTypesComponents/WebsiteQues';
import PaymentQues from './addQuestionInputTypesComponents/PaymentQues';
import LegalQues from './addQuestionInputTypesComponents/LegalQues';
import NumberQues from './addQuestionInputTypesComponents/NumberQues';
import MatrixQues from './addQuestionInputTypesComponents/MatrixQues';
import EmailQues from './addQuestionInputTypesComponents/EmailQues';
import RankingQues from './addQuestionInputTypesComponents/RankingQues';
import PictureChoiceQues from './addQuestionInputTypesComponents/PictureChoiceQues';
import StatementQues from './addQuestionInputTypesComponents/StatementQues';
import LongTextQues from './addQuestionInputTypesComponents/LongTextQues';
import ShortTextQues from './addQuestionInputTypesComponents/ShortTextQues';
import DropdownQues from './addQuestionInputTypesComponents/DropdownQues';
import ContactInfo from './addQuestionInputTypesComponents/ContactInfo';
import DateQues from './addQuestionInputTypesComponents/DateQues';
import EndPageQues from './addQuestionInputTypesComponents/EndPageQues'
import PhoneNumberQues from './addQuestionInputTypesComponents/PhoneNumberQues';
import { Skeleton } from '@mui/material';
import MultipleChoiceEnduser from './addQuestionInputTypesComponents/MultipleChoiceEnduser';
import QuestionGroup from './addQuestionInputTypesComponents/QuestionGroup';
import DropDownEnduserQues from './addQuestionInputTypesComponents/DropDownEnduserQues';
import { questionTypeConst } from '../../../constants/questionTypeConst'; 

function QuestionInputTypes(props) {
  // console.debug('QuestionInputTypes : props', props);

  const PAGES = {
    [questionTypeConst.opinionState]: OpinionState,
    [questionTypeConst.multipleChoice]: props?.enduser ? MultipleChoiceEnduser : MultipleChoiceQues,
    [questionTypeConst.rating]: RateQues,
    [questionTypeConst.questionGroup]: QuestionGroup,
    [questionTypeConst.contactInfo]: ContactInfo,
    [questionTypeConst.webSite]: WebsiteQues,
    [questionTypeConst.payment]: PaymentQues,
    [questionTypeConst.legal]: LegalQues,
    [questionTypeConst.dropdown]: DropDownEnduserQues,
    // [questionTypeConst.dropdown]: DropdownQues,
    [questionTypeConst.number]: NumberQues,
    [questionTypeConst.date]: DateQues,
    [questionTypeConst.matrix]: MatrixQues,
    [questionTypeConst.email]: EmailQues,
    [questionTypeConst.yesNo]: YesNoQues,
    [questionTypeConst.ranking]: RankingQues,
    [questionTypeConst.pictureChoice]: PictureChoiceQues,
    [questionTypeConst.statement]: StatementQues,
    [questionTypeConst.longText]: LongTextQues,
    [questionTypeConst.shortText]: ShortTextQues,
    [questionTypeConst.phone]: PhoneNumberQues,
    [questionTypeConst.endPage]: EndPageQues,
    [questionTypeConst.pageBreak]: EndPageQues,
  };

  // console.debug('PAGES[props.selectedQuesType]', props.selectedQuesType);
  const Handler = PAGES[props.selectedQuesType] || (() => <Skeleton height={40} width={400} />);

  return (
    <Box>
      <Handler {...props} />
    </Box>
  );
}

export default QuestionInputTypes;

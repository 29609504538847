import React, { useContext, useEffect, useState } from "react";
import List from '@mui/material/List';
import { FormControl, MenuItem, Select, Box, Typography } from "@mui/material";
import { FormTypeContext } from "../FromType/FormTypeContext";
import { QuestionContext } from "../FromType/QuestionContext";
function OpinionState(props) {
  const questionContextData = useContext(QuestionContext);

  const [rangeFrom, setRangeFrom] = useState(0)
  const [rangeTo, setRangeTo] = useState(10)

  function onChangeRangeToHandler(e) {
    setRangeFrom(e.target.value)

  }
  function onChangeRangeFromHandler(e) {
    setRangeTo(e.target.value)

  }
  useEffect(function () {
    // questionContextData.updateQuestionTypeOpinion(rangeFrom, rangeTo); 
    console.debug('a10',rangeFrom,rangeTo)
  }, [rangeFrom, rangeTo])

  return (
    <Box sx={{ p: 2, my: 1 }}>
      <Box>
        <Typography variant="body1" fontWeight="bold" >
          Setting
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', mt: 2, width: "100%" }}>
      <FormControl fullWidth>
          <Select
            labelId="range-to-select-label"
            id="range-to-select"
            value={rangeFrom}
            size="small"
            label="Range From"
            onChange={onChangeRangeToHandler}
          >
            <MenuItem value={0}>0</MenuItem>
            <MenuItem value={1}>1</MenuItem>
          </Select>
        </FormControl>
        <Box sx={{ p: 1 }}>
          <Typography variant="body1" fontWeight="bold" >
            To
          </Typography>
        </Box>
        <FormControl fullWidth>
          <Select
            labelId="range-from-select-label"
            id="range-from-select"
            value={rangeTo}
            size="small"
            label="Range To"
            onChange={onChangeRangeFromHandler}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={6}>6</MenuItem>
            <MenuItem value={7}>7</MenuItem>
            <MenuItem value={8}>8</MenuItem>
            <MenuItem value={9}>9</MenuItem>
            <MenuItem value={10}>10</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
}

export default OpinionState 

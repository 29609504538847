import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import ApiService from '../../ApiService';
import {
  TextField,
  Button,
  Skeleton,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  FormGroup,
  createTheme,
  ThemeProvider,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import EndUserFooterButton from './EndUserFooterButton';
import { openSnackbar } from '../../redux/snackbar/snackbarSlice';
import { endUserPageMaster } from '../../constants/endUserPageMaster';

const theme = createTheme({
  palette: {
    background: {
      default: '#fff', // Light blue background
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#ccc',
            },
          },
        },
      },
    },
  },
});
// onFormChange,

const SurveyAttendeesForm = ({ isPreview, ...props }) => {
  const [formFields, setFormFields] = useState({});
  console.debug('a3', formFields)
  const [formData, setFormData] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [loading, setLoading] = useState(true);
  const formRef = useRef(null);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isAttendeesFormValid, setIsAttendeesFormValid] = useState(false);
  const [attendeesFormData, setAttendeesFormData] = useState({});

  useEffect(() => {
    // if (!isPreview) {
    fetchFormFields();
    // } else {
    //   // Set dummy form fields for preview
    //   setFormFields({
    //     firstName: '1',
    //     email: '1',
    //     organizationName: '1',
    //     lastName: '1',
    //     role: '1',
    //     // domain: '1',
    //     sizeOfOrganization: '1',
    //     // duration: '1',
    //     CheckBox: [
    //       { id: 1, checkBoxActiveStatus: '1', checkBoxDescription: 'Checkbox 1', mandatory: '1' },
    //       // { id: 2, checkBoxActiveStatus: '1', checkBoxDescription: 'Checkbox 2', mandatory: '0' },
    //     ],
    //     requiredFieldStatus: {
    //       firstName: '0',
    //       email: '0',
    //       organizationName: '0',
    //     },
    //   });
    // }
  }, []);

  const handleAttendeesFormChange = (formData, isValid) => {
    setAttendeesFormData(formData);
    setIsAttendeesFormValid(isValid);
  };

  const handleAttendeesSubmit = async () => {
    // e.preventDefault();
    console.debug("SurveyEndUser : handleAttendeesSubmit : ");

    if (isAttendeesFormValid) {
      try {
        const payload = {
          ...attendeesFormData,  // This will spread checkBoxId and isChecked fields
          sessionId: props.surveySessionId
        };
        await ApiService.post('surveyattendeeFormData', payload);
        console.debug("SurveyEndUser : handleAttendeesSubmit : surveyattendeeFormData : api suceess : attendeesFormData : ", attendeesFormData);
        dispatch(openSnackbar({ dataLoad: true, message: 'Attendee stored successfully', severity: "info" }));
        if (props?.nextClickHandler !== undefined) {
          props.nextClickHandler(endUserPageMaster.thankYou);
        } else {
          console.error("Something Went Wrong");
        }
      } catch (error) {
        dispatch(openSnackbar({ dataLoad: true, message: error.message, severity: "error" }));
        console.error('Error submitting attendees form:', error);
      }
    } else {
      dispatch(openSnackbar({ dataLoad: true, message: 'Please fill in all required fields', severity: "error" }));
    }
  };



  const fetchFormFields = async () => {
    try {
      const response = await ApiService.get('attendeeFieldStatus', {}, id);
      let Data = response?.data?.data
      console.log('a3', Data)

      setFormFields(Data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching form fields:", error);
      setLoading(false);
    }
  };


  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    let newFormData = { ...formData };

    if (type === 'checkbox') {
      const checkboxId = parseInt(name.replace('checkBoxId', ''));

      // Find the index of this checkbox if it already exists
      const existingIndex = Object.keys(newFormData)
        .filter(key => key.startsWith('checkBoxId'))
        .findIndex(key => newFormData[key] === checkboxId);

      if (existingIndex !== -1) {
        // If the checkbox already exists, update its checked state
        newFormData[`isChecked[${existingIndex}]`] = checked ? 1 : 0;
      } else {
        // If it's a new checkbox, add it to the end
        const newIndex = Object.keys(newFormData)
          .filter(key => key.startsWith('checkBoxId'))
          .length;

        newFormData[`checkBoxId[${newIndex}]`] = checkboxId;
        newFormData[`isChecked[${newIndex}]`] = checked ? 1 : 0;
      }
    } else {
      newFormData[name] = value;
    }

    setFormData(newFormData);

    if (formRef.current) {
      const isValid = formRef.current.checkValidity();
      setIsFormValid(isValid);
      handleAttendeesFormChange(newFormData, isValid);
    } else {
      handleAttendeesFormChange(newFormData, false);
    }
  };

  const renderCheckboxes = () => {
    if (!formFields.CheckBox || !Array.isArray(formFields.CheckBox)) {
      return null;
    }
    return (
      <FormGroup>
        {formFields.CheckBox.map((checkbox) => {
          if (checkbox.checkBoxActiveStatus === "1") {
            const checkboxIndex = Object.keys(formData)
              .filter(key => key.startsWith('checkBoxId'))
              .findIndex(key => formData[key] === checkbox.id);

            const isChecked = checkboxIndex !== -1 ? formData[`isChecked[${checkboxIndex}]`] === 1 : false;

            return (
              <FormControlLabel
                key={checkbox.id}
                control={
                  <Checkbox
                    size='small'
                    name={`checkBoxId${checkbox.id}`}
                    checked={isChecked}
                    onChange={handleInputChange}
                    required={checkbox.mandatory === "1"}
                  />
                }
                label={<Typography variant='caption'> {checkbox.checkBoxDescription} </Typography>}
              />
            );
          }
          return null;
        })}
      </FormGroup>
    );
  };


  function nextProcessHandler() {
    // console.debug("SurveyEndUser : handleAttendeesSubmit : nextProcessHandler : ");
    handleAttendeesSubmit();
  }

  const renderFormField = (fieldName, label) => {
    if (formFields[fieldName] === '1') {
      const isRequired = formFields.requiredFieldStatus[fieldName] === '1';
      console.log('require', isRequired)
      return (
        <>

          <TextField
            key={fieldName}
            fullWidth
            size="small"
            label={label}
            name={fieldName}
            value={formData[fieldName] || ''}
            onChange={handleInputChange}
            required={isRequired}
            variant="outlined"
            margin="normal"
          />

        </>
      );
    }
    return null;
  };

  return (
    <ThemeProvider theme={theme}>
      <Box >
        <Box
          sx={{
            display: 'flex',
            flexFlow:'row wrap',
            alignItems: 'center',
            justifyContent: 'center',
            p: 1,
            height: "calc(100vh - 64px)", overflow: "auto" 
          }}
        >
         
          <Box
            // component="form"
            // onSubmit={handleSubmit}
            sx={{
              width: '100%',
              // height:'100vh',
              maxWidth: 600,
              p: 2,
              backgroundColor: 'background.default',
              borderRadius: '10px'
            }}
          >
            
              <form ref={formRef} onSubmit={(e) => e.preventDefault()}>
                <Typography variant="h6" gutterBottom>
                  Please fill in your details to submit your response.
                </Typography>
                {loading ?
              <Box>
                <Skeleton variant="text" sx={{ fontSize: '1rem',my:2 }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem',my:2 }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem',my:2}} />
                <Skeleton variant="text" sx={{ fontSize: '1rem',my:2 }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem',my:2 }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem',my:2 }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem',my:2 }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem',my:2 }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem',my:2 }} />
                <Skeleton variant="text" sx={{ fontSize: '1rem',my:2 }} />
              </Box>
              :
                <Box>
                  {renderFormField('firstName', 'First Name')}
                  {renderFormField('lastName', 'Last Name')}
                  {renderFormField('email', 'Email')}
                  {renderFormField('organizationName', 'Organization Name')}
                  {renderFormField('role', 'Role')}
                  {renderFormField('domain', 'Domain')}
                  {renderFormField('sizeOfOrganization', 'Size of Org.')}
                  {renderFormField('duration', 'Duration')}
                  {renderCheckboxes()}

                </Box>
            }
              </form>
          </Box>
          {/* <Box sx={{display:'flex'}}> */}
        <EndUserFooterButton nextProcessHandler={nextProcessHandler} />
        {/* </Box> */}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default SurveyAttendeesForm;
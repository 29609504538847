import React from "react";
import { Box, Select, Typography, FormControl, MenuItem, Grid } from "@mui/material";
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
// import Required from "./Required";
// import Description from "./Description";
function Date(props) {
  return (
    <Box sx={{ p: 2, my: 1 }}>
      <Box>
        <Typography variant="body1" fontWeight="bold" >
          Setting
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box>
              <FormControl fullWidth>
                <Select
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label='Fromat'
                  // name="type" defaultValue={props.type} onChange={((e) => props.onChangeTextHandler(e))}
                // value={age}
                // label="Age"
                // onChange={handleChange}
                >
                  <MenuItem value={0}>MMDDYYYY</MenuItem>
                  <MenuItem value={1}>DDMMYYYY</MenuItem>
                  <MenuItem value={3}>YYYYMMDD</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <FormControl fullWidth>
                <Select
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label='Symbol'
                  // name="type" defaultValue={props.type} onChange={((e) => props.onChangeTextHandler(e))}
                // value={age}
                // label="Age"
                // onChange={handleChange}
                >
                  <MenuItem value={5}>/</MenuItem>
                  <MenuItem value={6}>-</MenuItem>
                  <MenuItem value={7}>.</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>


      </Box>
    </Box>
  );
}

export default Date 

import { Box, FormControl, FormLabel } from '@mui/material';
import React, { useContext } from 'react';
import QuestionComponent from './QuestionComponent';
import QuestionTypeLayout from '../../lib/layouts/QuestionFormatLayout';
import QuestionInputTypes from '../question/addQuestion/QuestionInputTypes';
import SurveyEndUserContext from './SurveyEndUserContext';
import { questionTypeConst } from '../../constants/questionTypeConst';
import { HtmlRender } from '../../lib';
import EndUserFooterButton from './EndUserFooterButton';
import Avatar from '../../lib/theme/overrides/Avatar';

function QuestionComponentWrapper({questionDataObject,handleAnswerSelect,...props}) {
    const surveyEndUserContext = useContext(SurveyEndUserContext);
    // console.debug("SurveyEndUser : QuestionWrapper : QuestionComponentWrapper : surveyEndUserContext : ", surveyEndUserContext);

    const marginX=2;
    const marginY=1;

    function getCss(){
        // let questionColour=surveyEndUserContext?.theme?.question?.backgroundColor;
        // let answerColour=surveyEndUserContext?.theme?.answer?.backgroundColor;
        let bodyColour=surveyEndUserContext?.theme?.body?.backgroundColor;
        let questionWrapperColour=surveyEndUserContext?.theme?.cardStyle?.backgroundColor;
        // console.debug("SurveyEndUser : QuestionWrapper : QuestionComponentWrapper : bodyColour : ", bodyColour);
        // console.debug("SurveyEndUser : QuestionWrapper : QuestionComponentWrapper : questionWrapperColour : ", questionWrapperColour);
    // if((questionWrapperColour ==  questionColour) && (questionWrapperColour ==  questionColour)){
        if((questionWrapperColour ===  bodyColour)){
            return {backgroundColor:surveyEndUserContext?.theme?.cardStyle?.backgroundColor
                
            }
        }else{
            if(surveyEndUserContext?.surveyQuestions?.length===1){
                return {
                        p:"1rem",borderRadius:'.5rem' ,backgroundColor:surveyEndUserContext?.theme?.cardStyle?.backgroundColor,
                    }

            }else{
                return {
                    p:"1rem",borderRadius:'.5rem' ,backgroundColor:surveyEndUserContext?.theme?.cardStyle?.backgroundColor,
                }
            }
        }
        
    }

    function getAlignmentCss(){
        if(questionDataObject?.questionType?.identifier==questionTypeConst.contactInfo){
            //  this condition is added while working with workflow and 
            // contactInfo question is on parent question with MD AI
            //  Assesment Use case
            return {
                display: "flex",
                flexDirection: "column",
                justifyContent: 'center',
                alignItems: 'center',
            }
        }else{
            return {};
        }
    }
    if(questionDataObject?.questionType?.identifier== questionTypeConst.endPage){
        return <HtmlRender htmlContent={questionDataObject.questionDescription.description}/>
    }else{
        return (
            <Box>
   {/* <Box key={questionDataObject.id} sx={{mb:'1rem',p:"1rem",backgroundColor:surveyEndUserContext?.theme?.cardStyle?.backgroundColor}} > */}
            <Box key={questionDataObject.id} sx={getCss()} >
                <Box sx={getAlignmentCss()}>
                    <FormControl component="fieldset">
                            <FormLabel
                                sx={{
                                    // color: "black",
                                    // display: "flex",
                                    // flexDirection: "column",
                                }}
                            >
            
                                <QuestionComponent
                                    questionData={questionDataObject}
                                    error={props?.error} 
                                />
                            </FormLabel>
            
                            <Box sx={{ mx: marginX, my: marginY }}>
                                <QuestionTypeLayout>
                                    <QuestionInputTypes
                                        selectedQuesType={
                                            questionDataObject?.questionType?.identifier
                                        }
                                        onAnswerSelect={(answer) => {
                                            // console.debug("SurveyEndUser : QuestionWrapper : onAnswerSelect : ",answer);
                                            handleAnswerSelect(questionDataObject, answer);
                                        }}
                                        optionsData={questionDataObject?.optionChoice}
                                        dropdownOptionsData={questionDataObject?.dropDown}
                                        enduser={true}
                                        value={
                                            props?.selectedAnswers?.[questionDataObject?.id]
                                            ?.optionChoiceId || ""
                                        }
                                        error={props?.error} 
                                        answer={props?.answer} 
                                    />
                                    
                                </QuestionTypeLayout>
                               
                            </Box>
                            
                    </FormControl>
                    
                </Box>
               
            </Box>
            
                <Box sx={{ }} >
                    {surveyEndUserContext?.surveyQuestions?.length===1?
                        <EndUserFooterButton nextProcessHandler={surveyEndUserContext?.nextProcessHandler}
                            nextButtonVisibilityStatus={surveyEndUserContext?.footerNextButtonVisibilityStatus}
                            nextLabel={surveyEndUserContext?.nextButtonLabel}
                        />
                    :""}
                </Box>
            </Box>
          
        );
    }

}

export default QuestionComponentWrapper;
import React, { useContext, useState } from 'react';
import { Box, Checkbox, Divider, Grid, Button, IconButton, Card, TextField, styled, Tooltip, Menu, MenuItem, tooltipClasses, List, ListItem, ListItemButton, ListItemText, ListItemIcon, Typography } from '@mui/material';
// import { styled } from '@mui/system';
import StartIcon from '@mui/icons-material/Start';
import EastIcon from '@mui/icons-material/East';
import RefreshIcon from '@mui/icons-material/Refresh';
import GenAIContext from './GenAIContext';


const StyledSpan = styled('span')(({ theme }) => ({
    fontWeight: 400,
}));

function SourceQuestionHeader(props) {
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const genAiContextData = useContext(GenAIContext);
    console.debug("a9", genAiContextData);

    return (
        <Box>
            <List
                sx={{ width: '100%', bgcolor: 'background.paper', py: '0px !important' }}
                component="nav"
                aria-labelledby="nested-list-subheader">
                <ListItem
                    sx={{ pl: '4px !important' }}
                    secondaryAction={
                        <Box sx={{}}>
                            <Button variant="outlined" size="small" startIcon={<EastIcon />}
                                onClick={genAiContextData?.handleMoveAllSelectedItemFromSourceToDestination}
                                disabled={genAiContextData?.totalQuestionInSourceItemList === 0}
                            >

                            </Button>
                        </Box>
                    }
                >
                    <ListItemIcon>
                        <Checkbox {...label}

                            // indeterminate={Boolean(genAiContextData?.selectedSourceRows.size > 0 && genAiContextData?.selectedSourceRows.size)}
                            indeterminate={(genAiContextData?.selectedSourceRows.size > 0 && genAiContextData?.selectedSourceRows.size < genAiContextData?.totalQuestionInSourceItemList) ? genAiContextData.totalQuestionInSourceItemList : null}
                            // indeterminate={(genAiContextData?.selectedSourceRows.size > 0 && genAiContextData?.selectedSourceRows.size < genAiContextData?.sourceItemList.length) ? genAiContextData.selectedSourceRows : null}
                            // indeterminate={(genAiContextData?.selectedSourceRows.size > 0 && genAiContextData?.selectedSourceRows.size) ? genAiContextData.selectedSourceRows : null}
                            checked={genAiContextData?.sourceItemList?.length == 0 ? false : (genAiContextData?.selectedSourceRows.size != 0 && genAiContextData?.selectedSourceRows.size === genAiContextData?.totalQuestionInSourceItemList)}
                            onClick={genAiContextData.handleSelectAllClick ? (e) => genAiContextData.handleSelectAllClick(e) : undefined}
                        />
                    </ListItemIcon>
                    <ListItemText
                        sx={{ ml: '0.7rem !important' }}
                        primary={
                            // <Typography variant="subtitle2">
                            //     {genAiContextData?.selectedSourceRows.size > 0 ?
                            //         genAiContextData?.selectedSourceRows.size + "  Selected to move"
                            //         :
                            //         "QUESTION SET (" + genAiContextData?.sourceItemList?.length +")"
                            //     }

                            // </Typography>
                            <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                                {genAiContextData?.selectedSourceRows.size > 0 ? (
                                    <>
                                        {genAiContextData?.selectedSourceRows.size} Selected<StyledSpan> to move</StyledSpan>
                                    </>
                                ) : (
                                    <>QUESTION SET ({genAiContextData?.totalQuestionInSourceItemList})</>
                                )}
                            </Typography>
                        } />
                </ListItem>
            </List>
        </Box>
    );
}

export default SourceQuestionHeader;
import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, Radio, RadioGroup, FormControl, FormControlLabel, FormLabel, Typography, AppBar, Toolbar, Grid, createTheme } from '@mui/material';
import { KeyboardArrowRight } from '@mui/icons-material';
import FooterWrapper from '../../lib/layouts/AdminLayout/Footer/FooterWrapper';
import { useParams, useSearchParams } from 'react-router-dom';
import ApiService from '../../ApiService';
import QuestionInputTypes from '../../component/question/addQuestion/QuestionInputTypes';
import QuestionTypeLayout from '../../lib/layouts/QuestionFormatLayout';
import FooterSurvey from '../../lib/layouts/AdminLayout/Footer/FooterSurvey';
import { v4 as uuidv4 } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import { setUserInfo } from '../../redux/userInfoStore/userSlice'
import { openSnackbar, closeSnackbar } from '../../redux/snackbar/snackbarSlice';
import DynamicSnackbar from '../../redux/snackbar/Snackbar';
import SurveyThankYoupage from './SurveyThankYoupage';
// import FormLabel from '@mui/material/FormLabel';
import Logo from '../../../src/assets/companyLogo.png'
import Skeleton from '@mui/material/Skeleton';
import SurveyGraphReport from './SurveyGraphReport';
import SurveyAttendeesForm from './SurveyAttendeesForm';
import SurveyErrorPage from './SurveyErrorPage';
// import SurveyErrorPage from './SurveyErrorPage';
import { questionTypeConst } from '../../constants/questionTypeConst';
import typography from '../../lib/theme/typography';
import { endUserPageMaster } from '../../constants/endUserPageMaster';
import QuestionWrapper from '../../component/surveyEndUser/QuestionWrapper';
import { ApiGatewayBaseUrl } from '../../constants/ApiCosnstant';
import { PublicLoginHandler } from 'auth-component';
import SurveyEndUserContext from '../../component/surveyEndUser/SurveyEndUserContext';
import QuestionWrapperWithWorkflow from './QuestionWrapperWithWorkflow';
import backgroundImage from '../../assets/background.jpg'
import bgImage from '../../assets/bgImage.jpg'

const generateSessionId = () => {
    return uuidv4(); // Generate a random UUID
};

//  THP Design
// const theme = {
//     bodyBackgroundColor:"#ffffff",
//     headerBackgroundColor:"#e1edfc",
//     themecolor:"orange",
//     typographyVarient: 'h5',
//     logo:'surveyLogo',
//     buttonSize:'small',
//     topBottom:'',
//     buttonVariant1:'text',
//     cardStyle: {
//         border: " ",
//         my: " ",
//         padding: " ",
//         backgroundColor:' ',
//         boxShadow:' ',
//       },
// }

//  Mastech Design
const theme = {
    fontFamily: "Montserrat",
    // fontFamily:"Roboto",
    question: {
        // backgroundColor:"#ccd4e0",
        backgroundColor: "#ffffff",
        color: "#ccd4e0",
        fontVariant: 'small',
        fontWeight: "600",
        fontFamily: "Montserrat",
    },
    answer: {
        backgroundColor: "#ccd4e0",
        color: "#ccd4e0",
        fontVariant: 'small',
        fontWeight: "regular",
        fontFamily: "Montserrat",
    },
    body: {
        backgroundColor: "#ccd4e0",
        color: "#ccd4e0",
        fontVariant: 'small',
        fontWeight: "regular",
        fontFamily: "Montserrat",
    },
    footer: {
        backgroundColor: "#ccd4e0",
        color: "#ccd4e0",
        fontVariant: 'small',
        fontWeight: "regular",
        fontFamily: "Montserrat",
    },
    bodyBackgroundColor: "#ffffff",
    headerBackgroundColor: "#ffffff",
    themecolor: "orange",
    typographyVarient: 'h5',
    logo: 'surveyLogo',
    buttonSize: 'small',
    topBottom: '1rem',
    buttonVariant1: 'outlined',
    buttonColor: 'yellow',
    cardStyle: {
        // border: "1px solid", 
        borderRadius: '1rem',
        border: '1px solid #b1b1b1',
        my: "2px",
        padding: "1rem",
        backgroundColor: '#ffffff',
        boxShadow: ' 0 4px 8px 0 rgba(0, 0, 0, 0.2)',
    },
}

const SurveyEndUserWorkFlowComponent = ({ isPreview, surveyBackgroundColor, showHeaderFooter, ...props }) => {
    // console.debug('SurveyEndUserWorkFlowComponent : props :', props)
    const dispatch = useDispatch();
    // const userData = useSelector((state) => state?.user?.userListName)
    let { id } = useParams();
    const [searchParams] = useSearchParams();

    const [surveyQuestions, setSurveyQuestions] = useState([])
    const [selectedQuestionAnswers, setSelectedQuestionAnswers] = useState([])
    const [surveyGraphData, setSurveyGraphData] = useState([])
    const [errorPage, setErrorPage] = useState('')
    const [userId, setUserId] = useState([])

    const [currentStep, setCurrentStep] = useState(endUserPageMaster.survey);
    const [attendeesFormData, setAttendeesFormData] = useState({});
    const [surveySessionId, setSurveySessionId] = useState(null);



    // useEffect(() => {
    //     ApiService.get('userInfo').then(res => {
    //         let response = res.data.data;
    //         setUserId(res.data.data.userId)
    //         console.log('a5', response);
    //         if (response) {
    //             dispatch(setUserInfo({
    //                 ...response,
    //                 // userAvatar: response.userAvatar,
    //             }));
    //         }
    //     });
    // }, [])
    const [publicPrivate, setPublicPrivate] = useState()
    const [showAttendee, setShowAttendee] = useState()
    const [closeSurvey, setCloseSurvey] = useState()
    const [startDate, setStartDate] = useState()
    const [endDate, setEndDate] = useState()
    const [showReport, setShowReport] = useState()
    const [publish, setPublish] = useState()
    // console.log('a1', publicPrivate)
    const [surveyData, setSurveyData] = useState(null)
    const [surveyLogo, setSurveyLogo] = useState([])
    useEffect(function () {
        window.scrollTo(0, 0);
        // console.debug("SurveyEndUserWorkFlowComponent : UseEffect : id : ", id);
        // console.debug("SurveyEndUserWorkFlowComponent : UseEffect : searchParams : ", searchParams.get('app'));
        let app = searchParams.get('app');
        // console.debug("SurveyEndUserWorkFlowComponent : UseEffect : params : ",{useParams()});
        let url = ApiGatewayBaseUrl + "v1/validate-survey";
        let dataParams = {
            url: url,
            uuid: id,
            // tokenRelated:['questionSurveyAccessTokens','userSurveyAccessTokens'],
            related: ['reportSettingSurvey', 'surveyQuestion.followUps'],
            app: app,
            endUserSectionProperties: 1,
        }
        PublicLoginHandler(dataParams).then(r => {
            // console.debug("SurveyCreate : PublicLoginHandler : r : ", r);
            if (r?.data?.data) {
                let surveyData = {}
                surveyData.data = r?.data?.data?.survey;
                // let surveyData = r?.data?.data?.survey;
                if (surveyData != undefined) {
                    setSurveyData(surveyData);
                    setPublicPrivate(surveyData?.data?.public)
                    setPublish(surveyData?.data?.isPublished)
                    setShowAttendee(surveyData?.data?.showReportAttendeeSettings?.showAttendee)
                    setShowReport(surveyData?.data?.showReportAttendeeSettings?.showReport)
                    setCloseSurvey(surveyData?.data?.isOpen)
                    setStartDate(surveyData?.data?.startDate)
                    setEndDate(surveyData?.data?.endDate)
                }

                let tempLogo = surveyData?.data?.sectionProperties?.filter(l => l?.sectionPropertyLabel == 'logo')?.[0]?.sectionProperty?.filePath;
                // console.log('SurveyEndUserWorkFlowComponent : surveyView : tempLogo : ', tempLogo)
                setSurveyLogo(tempLogo);
                setSurveyQuestions(surveyData.data.surveyQuestion)
            }
        }).catch(e => {
            setErrorPage(e?.status == 503)
            console.debug("SurveyCreate : useEffect :catch : e : ", e?.status);
        });

    }, [])

    // const [selectedAnswers, setSelectedAnswers] = useState({});
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [sessionId, setSessionId] = useState(() => {
        const savedSessionId = localStorage.getItem('sessionId');
        if (savedSessionId) {
            localStorage.removeItem('sessionId'); // Remove old session ID on first load
        }
        const newSessionId = generateSessionId();
        localStorage.setItem('sessionId', newSessionId);
        return newSessionId;
    });
    useEffect(() => {
        localStorage.setItem('sessionId', sessionId);
    }, [sessionId]);

    const setSessionHandler = (updatedSession) => {
        if (updatedSession) {
            setSurveySessionId(updatedSession)
        }
    }

    const setGraphDataHandler = (graphData) => {
        if (graphData) {
            setSurveyGraphData(graphData)
        }
    }



    const handleAnswerSelect = (question, answer) => {
        console.debug("SurveyEndUserWorkFlowComponent : handleAnswerSelect : question : ", question)
        console.debug("SurveyEndUserWorkFlowComponent : handleAnswerSelect : answer : ", answer)

        setSelectedAnswers(prevAnswers => ({
            ...prevAnswers,
            // [question.id]: {
            //     optionChoiceId: answer.optionChoiceId,
            //     optionChoiceName: answer.optionChoiceName
            // }
            [question.id]: answer,
        }));
        setSelectedQuestionAnswers(prevAnswers => ([
            ...prevAnswers,
            { question: question, answer: answer, }
        ]));
        window.scrollTo(0, 0);
    };








    const handleRestartSurvey = () => {
        window.location.reload();
    };
    const [isLoaded, setIsLoaded] = useState(false);

    const filteredChildQuestions = data => data?.childQuestions?.filter(cData => (
        cData?.questionType?.identifier !== '[questionTypeConst.multipleChoice]' || (cData?.optionChoice && cData?.optionChoice.length > 0)
    ));



    const [isAttendeesFormValid, setIsAttendeesFormValid] = useState(false);

    const handleAttendeesFormChange = (formData, isValid) => {
        setAttendeesFormData(formData);
        setIsAttendeesFormValid(isValid);
    };



    function nextClickHandler(page) {
        console.debug("SurveyEndUserWorkFlowComponent : nextClickHandler ");
        setCurrentStep(page)
    }

    const renderContent = () => {
        switch (currentStep) {
            case 'survey':
                // console.debug("SurveyEndUserWorkFlowComponent : renderContent : surveyData : ",surveyData);
                if (surveyData?.data?.workFlow !== undefined && surveyData?.data?.workFlow === 1) {

                    return <QuestionWrapperWithWorkflow
                        surveyData={surveyData}
                        handleAnswerSelect={handleAnswerSelect}
                        selectedAnswers={selectedAnswers}
                        selectedQuestionAnswers={selectedQuestionAnswers}

                        //  Live 
                        nextClickHandler={nextClickHandler}
                        setSessionHandler={setSessionHandler}
                        setGraphDataHandler={setGraphDataHandler}
                        surveySessionId={surveySessionId}
                    /> // Render your survey questions here

                } else {
                    return <QuestionWrapper
                        surveyData={surveyData}
                        handleAnswerSelect={handleAnswerSelect}
                        selectedAnswers={selectedAnswers}
                        selectedQuestionAnswers={selectedQuestionAnswers}

                        //  Live 
                        nextClickHandler={nextClickHandler}
                        setSessionHandler={setSessionHandler}
                        setGraphDataHandler={setGraphDataHandler}
                        surveySessionId={surveySessionId}
                    /> // Render your survey questions here
                }
            case 'graph':
                return (
                    <SurveyGraphReport
                        surveyGraphData={surveyGraphData}
                        surveyData={surveyData}
                        nextClickHandler={nextClickHandler}
                    />
                )
            case 'attendees':
                return <SurveyAttendeesForm
                    isPreview={isPreview}
                    onFormChange={handleAttendeesFormChange}
                    nextClickHandler={nextClickHandler}
                    surveySessionId={surveySessionId}
                />
            case 'thankYou':
                return <SurveyThankYoupage surveyData={surveyData} handleRestartSurvey={handleRestartSurvey} />;
            default:
                return null;
        }
    };

    const areAllRequiredQuestionsAnswered = () => {
        for (const data of surveyQuestions) {
            if (data?.questionType?.identifier === "question-group") {
                for (const cData of filteredChildQuestions(data)) {
                    if (cData?.questionRequired === 1 && !selectedAnswers[cData.id]) {
                        return false;
                    }
                }
            } else {
                if (data?.questionRequired === 1 && !selectedAnswers[data.id]) {
                    return false;
                }
            }
        }
        return true;
    };


    useEffect(() => {
        areAllRequiredQuestionsAnswered();
    }, [selectedAnswers]);


    const surveyEndUserContext = {
        theme: theme
    }


    return (
        <Box>
            <SurveyEndUserContext.Provider value={surveyEndUserContext}>

                <Box sx={{ fontFamily: theme.fontFamily }}>

                    {errorPage ?
                        <Box className='ErrorPage' sx={{ height: 'calc(100vh - 64px)', p: 4 }}>
                            <SurveyErrorPage />
                        </Box>

                        :

                        <Box>
                            {/* {(
                            <AppBar position="static" sx={{ justifyContent:'center',display:'flex',alignItems:'center',backgroundColor:'#110120', boxShadow: 'none' }}>
                                <Toolbar>
                                    <Box className='logo' sx={{ paddingTop: '3px', mx: '3rem' }}>
                                        {!isLoaded && <Skeleton variant="circular" width={60} height={40} />}
                                        <img
                                            // className='logoSize'
                                            // src={theme.logo}
                                            src={surveyLogo}
                                            onLoad={() => setIsLoaded(true)}
                                            style={{ display: isLoaded ? 'block' : 'none',height:'4rem',width:'11rem' }}
                                            alt='Survey Logo'
                                        />
                                    </Box>
                                </Toolbar>
                            </AppBar>
                        )} */}
                            {/* <Box className="vikram" sx={{display:'flex',justifyContent:'center',alignItems:'center',backgroundImage:''}}> */}
                            <Box
                                className="vikram main-bgCss"
                                // sx={{
                                //     backgroundImage: `url(${backgroundImage})`,
                                //     backgroundSize: 'cover',
                                // }}
                                sx={{
                                    backgroundImage: {
                                        // xs: `url(${bgImage})`,       // Default image for xs and larger screens
                                        // sm: `url(${bgImage})`,               // Use bgImage at sm and md breakpoints
                                        md: `url(${backgroundImage})`,
                                        lg: `url(${backgroundImage})`,       // Use backgroundImage for larger screens again, if needed
                                    },
                                    backgroundColor: {
                                        xs: '#110020',                // Background color for xs and sm
                                        sm: '#110020 ',
                                        md: '#000000',                // Background color for md
                                        lg: '#000000',                // Background color for lg
                                    },
                                    backgroundSize: { xs: 'inherit', sm: 'inherit', md: 'cover', lg: 'cover' },
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'repeat',
                                    minHeight: '100vh',
                                    height: { xs: '100vh', sm: '100vh', md: 'auto', lg: 'auto' }, 
                                    // or any preferred height
                                }}
                            >
                                <Box>

                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Box className='logo' sx={{ paddingTop: '3px', mx: '3rem', mt: '1rem' }}>
                                            {!isLoaded && <Skeleton variant="circular" height="56px" width="239px" />}
                                            <img
                                                // className='logoSize'
                                                // src={theme.logo}
                                                src={surveyLogo}
                                                onLoad={() => setIsLoaded(true)}
                                                style={{ display: isLoaded ? 'block' : 'none', height: '56px', width: '239px' }}
                                                alt='Survey Logo'
                                            />
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '1rem' }}>

                                        <Box
                                            sx={{
                                                backgroundColor: theme.bodyBackgroundColor,
                                                border: theme.cardStyle.border,
                                                borderRadius: theme.cardStyle.borderRadius,
                                                minWidth:{ xs: '60%',},
                                                // height: 'calc(100vh - 64px)',
                                                width: {
                                                    // xs: '80%',
                                                    sm: '70%',
                                                    md: '60%',
                                                    lg:'50%',
                                                }
                                            }}
                                        >
                                            {surveyData !== null ? renderContent() : (
                                                <Box sx={{ padding: '2rem', height: '50vh',maxHeight:'80vh',}}>
                                                    <Box>
                                                        <Skeleton animation="wave" width='100% !important' />
                                                        <Skeleton animation="wave" width='50% !important' />
                                                        <Skeleton animation="wave" width='50% !important' />
                                                        <Skeleton animation="wave" width='50% !important' />
                                                    </Box>
                                                    <Box sx={{ mt: '2rem' }}>
                                                        <Skeleton animation="wave" width='100% !important' />
                                                        <Skeleton animation="wave" width='50% !important' />
                                                        <Skeleton animation="wave" width='50% !important' />
                                                        <Skeleton animation="wave" width='50% !important' />
                                                    </Box>
                                                </Box>
                                            )}
                                            {/* {renderContent()} */}
                                        </Box>
                                    </Box>

                                    {showHeaderFooter && (
                                        <Box>
                                            <FooterSurvey />
                                        </Box>
                                    )}
                                </Box>
                            </Box>

                            <DynamicSnackbar />
                        </Box>
                    }

                </Box>

            </SurveyEndUserContext.Provider>

        </Box>
    );
};

export default SurveyEndUserWorkFlowComponent;

import React, { useState } from 'react'
import ClearIcon from '@mui/icons-material/Clear';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
// import DateRangeIcon from '@mui/icons-material/DateRange';
// import AttachEmailIcon from '@mui/icons-material/AttachEmail';
// import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
// import ChecklistIcon from '@mui/icons-material/Checklist';
import {
  Box, Switch, Grid, Button, TextField, Tab, Tabs, Typography, styled, useTheme, Drawer, Card, CssBaseline, IconButton, Avatar, InputBase, Menu,
  AppBar as MuiAppBar, Toolbar, Divider, ListItemIcon, ToggleButtonGroup, MenuItem, Paper,
  AccountCircle as AccountCircleIcon, Collections as CollectionsIcon, Undo as UndoIcon, Redo as RedoIcon, Remove as RemoveIcon, Add as AddIcon, Search as SearchIcon, Delete as DeleteIcon,
  Image as ImageIcon, DateRange as DateRangeIcon, AttachEmail as AttachEmailIcon, LocalPhone as LocalPhoneIcon, Checklist as ChecklistIcon, TextFields as TextFieldsIcon, ChangeHistoryTwoTone as ChangeHistoryTwoToneIcon, ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon, ContentCopy as ContentCopyIcon,
  Home as HomeIcon, Preview as PreviewIcon, Download as DownloadIcon, Circle as CircleIcon, Rectangle as RectangleIcon, EggTwoTone, Star as StarIcon, Logout, Settings, FormatColorFill
} from '../../lib/utils/AllImportsHelper';
import QuestionTypeIcon from './questionSettings/questionType/QuestionTypeIcon';
import QuestionTypeItem from './addQuestion/QuestionTypeItem';
import QuestionTypeItemContainer from './addQuestion/QuestionTypeItemContainer';


const AddQuestionDrawer = ({ onClose, handleItemClick, ...props }) => {

  console.debug("AddQuestionDrawer : props", props)
  console.debug("AddQuestionDrawer : props questionType", props?.questionType?.length)
  console.debug("AddQuestionDrawer : props questionType floor", Math.ceil(props?.questionType?.length / 2))

  // Calculate the split index
  const splitIndex = Math.ceil(props?.questionType?.length / 2);

  // Split the items array
  const firstGridItems = props?.questionType?.slice(0, splitIndex);
  const secondGridItems = props?.questionType?.slice(splitIndex);



  const handleCloseDrawer = () => {
    onClose(); // Call onClose function when clear icon is clicked
  };
  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);


  // const handleListCategoryClick = (itemName) => {
  //   console.log('a9', itemName); // Set the itemName in the state
  //   handleItemClick(itemName); 
  //   onClose();
  // };
  const handleListCategoryClick = (itemName, itemIcon) => {
    console.log('Item Name:', itemName);
    console.log('Item Icon:', itemIcon);
    // handleItemClick(itemName, itemIcon);
    // onClose();
  };

  return (
    <>
      <Box sx={{ width: '30rem' }}>
        <Box sx={{ backgroundColor: '#E1EDFC' }}>
          <Grid container spacing={2} sx={{ p: 1 }}>
            <Grid item xs={8}>
              <Box sx={{ mt: 1 }}><Typography variant="subtitle1" fontWeight="bold">Question Types</Typography></Box>
            </Grid>
            <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Box>
                <IconButton aria-label="close" onClick={handleCloseDrawer}>
                  <ClearIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ p: 1 }}>

          {
            props?.questionTypeWithCategory.filter(item=>item?.questionType?.length>0).map(item => {
              console.debug('a10',item.questionType,item.questionType.length,props?.questionTypeWithCategory)
              return (
                <Box>
                  <Box sx={{ mt: 1 }}>
                    <Typography variant="subtitle2" fontWeight="bold">
                      {item?.name}
                    </Typography>
                  </Box>
                  <Box sx={{ flexGrow: 1 }}>
                    <QuestionTypeItemContainer
                      questionType={item?.questionType}
                      handleItemClick={handleItemClick}
                    />
                  </Box>
                </Box>
              );
            })
         }

        </Box>
      </Box >
    </>
  )
}

export default AddQuestionDrawer
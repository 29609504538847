import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router';
import CanvasWrapper from '../../component/layoutWrapper/CanvasWrapper';
import { CanvasSubLayout } from '../../lib';
import QuestionPage from '../../component/question/QuestionPage';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ApiService from '../../ApiService';
import { updateSurvey, addArrayOfQuestionsInSurveyQuestionList, addQuestionInSurveyQuestionList, setSelectedQuestion, setSelectedParentQuestion, setSelectedQuestionDescriptionObject, } from '../../redux/slice/surveyQuetions/surveyQuestionSlice';
import { openSnackbar, closeSnackbar } from '../../redux/snackbar/snackbarSlice';
import QuestionList from '../../component/question/QuestionList';
import AddQuestions from '../../component/question/AddQuestions';
import { useLocation } from 'react-router-dom';
import routes from '../../routesComponent/Routes';
import DynamicSnackbar from '../../redux/snackbar/Snackbar';
import { setSelectedQuestionDescription } from '../../redux/survey/selectedQuestionDescriptionSlice';
import { setSelectedQuestionRequiredStatus } from '../../redux/survey/selectedQuestionRequiredStatusSlice';
import { setSelectedQuestionDescriptionStatus } from '../../redux/survey/selectedQuestionDescriptionStatusSlice';
import { setSelectedQuestionOptions } from '../../redux/survey/selectedQuestionOptionsSlice';
import { questionTypeConst } from '../../constants/questionTypeConst';

function SurveyCreate({ match, ...props }) {
    const dispatch = useDispatch();
    // console.debug("SurveyCreate : ");

    let { id } = useParams();
    const location = useLocation();

    // // Split the pathname by '/' and get the part you need
    // const pathSegments = location.pathname.split('/');
    // const section = pathSegments[3];  // This gets "create-assessment"

    const surveyQuestion = useSelector(state => state.surveyQuestion);

    const [selectedQuetionItem, setSelectedQuestionItem] = useState(null);
    const [questionType, setQuestionType] = useState([]);
    const [questionTypeWithCategory, setQuestionTypeWithCategory] = useState([]);
    const [questionUpdateLoading, setQuestionUpdateLoading] = useState(false);
    const [loadingSurvey, setLoadingSurvey] = useState(false)

    function onSelectItemStateHandler(selectedItem) {
        dispatch(setSelectedQuestion(selectedItem))
        if (selectedItem?.questionType?.identifier == [questionTypeConst.multipleChoice]) {
            dispatch(setSelectedQuestionOptions([...selectedItem?.optionChoice]));
        } else {
            dispatch(setSelectedQuestionOptions([]));
        }
        if (selectedItem?.questionDescriptionId) {
            dispatch(setSelectedQuestionDescription(selectedItem?.questionDescription));
        }
        if (selectedItem?.questionDescriptionStatus) {
            // dispatch(setSelectedQuestionDescriptionStatus({ descriptionStatus: selectedItem?.questionDescriptionStatus }));
            dispatch(setSelectedQuestionDescriptionStatus(selectedItem?.questionDescriptionStatus));
        }
        if (selectedItem?.questionRequired && selectedItem?.questionRequired !== undefined) {
            // dispatch(setSelectedQuestionDescriptionStatus({ descriptionStatus: selectedItem?.questionDescriptionStatus }));
            dispatch(setSelectedQuestionRequiredStatus(selectedItem?.questionRequired));
        }
    }


    function onSelectItemChangeHandler(e, selectedItem, selectedPrarentItem) {
        // setSelectedQuestionItem(selectedItem);
        if (selectedPrarentItem !== undefined && selectedPrarentItem !== null) {
            // console.debug("MultipleChoiceQues : onSelectItemChangeHandler : selectedPrarentItem : if : ", selectedPrarentItem);
            // setSelectedPrarentQuestionItem(selectedPrarentItem);
            dispatch(setSelectedParentQuestion(selectedPrarentItem))
        } else {
            // console.debug("MultipleChoiceQues : onSelectItemChangeHandler : selectedPrarentItem : else : ", selectedPrarentItem);
            // setSelectedPrarentQuestionItem(null);
            dispatch(setSelectedParentQuestion(null))
        }


        onSelectItemStateHandler(selectedItem);


        // console.debug("SurveyCreate : onSelectItemChangeHandler : selectedItem : ", selectedItem);
        // if (selectedItem?.questionType?.identifier == "multiple-choice") {
        //     dispatch(setSelectedQuestionOptions([...selectedItem?.optionChoice]));
        // } else {
        //     dispatch(setSelectedQuestionOptions([]));
        // }
        // if (selectedItem?.questionDescriptionId) {
        //     dispatch(setSelectedQuestionDescription(selectedItem?.questionDescription));
        // }
        // if (selectedItem?.questionDescriptionStatus) {
        //     // dispatch(setSelectedQuestionDescriptionStatus({ descriptionStatus: selectedItem?.questionDescriptionStatus }));
        //     dispatch(setSelectedQuestionDescriptionStatus(selectedItem?.questionDescriptionStatus));
        // }
        // dispatch(setSelectedQuestion(selectedItem))
    }

    useEffect(function () {
        setSelectedQuestionItem(surveyQuestion?.selectedQuestion);
    }, [surveyQuestion?.selectedQuestion]);

    function onUpdateQuestionDetails(params) {
        setQuestionUpdateLoading(true)
        ApiService.post('updateQuestion', params, selectedQuetionItem.id).then(response => {
            console.debug("QuestionTitle update ", response.data)
            if (response) {
                setQuestionUpdateLoading(false)
                dispatch(updateSurvey(response.data));
            }
        }).catch(e => {
            setQuestionUpdateLoading(false)
        })
    }
    function selectedQuetionItemChangeHandler(value) {
        setSelectedQuestionItem({ ...selectedQuetionItem, question: value });
        let formData = {}
        formData.question = value
        onUpdateQuestionDetails(formData)
        // formData.app = getAppToken()

    }

    function selectedQuestionItemObjectChangeHandler(descriptionObject) {
        // if (descriptionObject.description) {
        //     if (selectedQuetionItem?.questionDescription != descriptionObject?.description) {
        //         setSelectedQuestionItem({ ...selectedQuetionItem, questionDescription: descriptionObject.description });
        //     }
        // }
        // console.debug("descriptionObject", descriptionObject)
        // if (descriptionObject.descriptionActiveStatus != undefined) {
        //     console.debug("descriptionObject : questionDescriptionStatus", descriptionObject?.descriptionActiveStatus)
        //     if (selectedQuetionItem.questionDescriptionStatus != descriptionObject.descriptionActiveStatus) {
        //         setSelectedQuestionItem({ ...selectedQuetionItem, questionDescriptionStatus: descriptionObject.descriptionActiveStatus });
        //     }
        // }
        if (descriptionObject.questionType) {
            if (selectedQuetionItem?.questionType != descriptionObject.questionType) {
                setSelectedQuestionItem({ ...selectedQuetionItem, questionType: descriptionObject.questionType });
            }
        }
        // if (descriptionObject.questionRequired) {
        //     if (selectedQuetionItem?.questionRequired != descriptionObject.questionRequired) {
        //         setSelectedQuestionItem({ ...selectedQuetionItem, questionRequired: descriptionObject.questionRequired });
        //     }
        // }

    }
    function selectedQuestionDescriptionToggleChangeHandler(descriptionObject) {
        setSelectedQuestionItem({ ...selectedQuetionItem, questionDescriptionStatus: descriptionObject.descriptionActiveStatus });
    }
    function selectedQuestionDescriptionChangeHandler(descriptionObject) {
        setSelectedQuestionItem({ ...selectedQuetionItem, questionDescription: descriptionObject.questionDescription });
    }
    function selectedQuestionRequiredChangeHandler(questionRequired) {
        setSelectedQuestionItem({ ...selectedQuetionItem, questionRequired: questionRequired });
    }
    const updateQuestionTypeRating = (newRatingValue) => {
        setSelectedQuestionItem({
            ...selectedQuetionItem,
            questionTypeRating: {
                ...selectedQuetionItem.questionTypeRating,
                ratings: newRatingValue
            },
        });
    };
    const updateQuestionTypeOpinion = (minNumber, maxNumber) => {
        setSelectedQuestionItem({
            ...selectedQuetionItem,
            questionTypeOpinionState: {
                ...selectedQuetionItem.questionTypeOpinionState,
                minNumber: minNumber,
                maxNumber: maxNumber
            },
        });
    };
    const updateQuestionTypeNumber = (minNumber, maxNumber) => {
        setSelectedQuestionItem({
            ...selectedQuetionItem,
            questionTypeNumber: {
                ...selectedQuetionItem.questionTypeNumber,
                minNumber: minNumber,
                maxNumber: maxNumber
            },
        });
    };



    function questionAddHandler(seletedQuestionTypeObj) {
        let formData = {};
        // formData.queGroupId = surveyQuestion?.survey?.id;
        formData.surveyId = surveyQuestion?.survey?.id;
        formData.questionTypeId = seletedQuestionTypeObj.id;
        formData.question = "Untitle Question";
        formData.returnPayload = true;
        let related = ['questionType'];
        related.forEach((data, i) => {
            formData[`related[${i}]`] = data;
        });

        // formData.related[0] = 'questionType';
        if (surveyQuestion?.selectedQuestion?.questionType?.identifier == "question-group") {
            formData.questionGroupId = selectedQuetionItem?.id;
        }
        ApiService.post('questionCreate', formData).then(r => {
            if (r?.data) {
                const newQuestion = r.data.data;
                // newQuestion, parentQuestionId, afterQuestionId // Actual Parameter
                // newQuestion, parentQuestionId // Add Question using + Icon
                if (surveyQuestion?.selectedQuestion?.questionType?.identifier == "question-group") {
                    onSelectItemChangeHandler(null, newQuestion, selectedQuetionItem);
                } else {
                    onSelectItemChangeHandler(null, newQuestion);
                }
                dispatch(addQuestionInSurveyQuestionList({
                    newQuestion,
                    parentQuestionId: surveyQuestion?.selectedQuestion?.questionType?.identifier === "question-group" ? selectedQuetionItem.id : null
                }));

                // dispatch(setSelectedQuestion(newQuestion));
                dispatch(openSnackbar({ dataLoad: true, message: 'Question added successfully', severity: "info" }));
            }
        }).catch(e => {
            dispatch(openSnackbar({ dataLoad: true, message: e.message, severity: "error" }));
            console.error("questionCreate error:", e);
        });

    }


    //  this is use for setting the selected question type add question drawer
    function selectedQuestionTypeToAddHandler(e, seletedQuestionTypeObj) {
        questionAddHandler(seletedQuestionTypeObj);
    }

    useEffect(function () {
        if (id) {
            // if (surveyQuestion.survey == null) {
            setLoadingSurvey(true)
            ApiService.get('surveyView', {}, id).then(r => {
                // setData(r.data.data)
                if (r.data?.data) {
                    let surveyResponce = r.data.data;
                    if (surveyResponce) {
                        dispatch(updateSurvey(surveyResponce));
                        dispatch(addArrayOfQuestionsInSurveyQuestionList([...surveyResponce.surveyQuestion]))
                        setSelectedQuestionItem(surveyResponce?.surveyQuestion?.[0]);
                        // dispatch(setSelectedQuestion(surveyResponce?.surveyQuestion?.[0]));
                        onSelectItemStateHandler(surveyResponce?.surveyQuestion?.[0]);
                        setLoadingSurvey(false)
                    }

                    else
                        console.error("APi response issue");
                    setLoadingSurvey(false)

                }
                // return ({ data: r.data.data, page: r.data.meta.current_page, total: r.data.meta.total });
            }).catch(e => {
                setLoadingSurvey(false)
                // navigate to 404 page
                // or error
            });
            // }
        } else {
            // navigate to 404 page
        }
    }, []);



    useEffect(function () {
        let pathSegments = location.pathname.split('/');
        let section = "/" + pathSegments[1] + "/" + pathSegments[2] + "/";
        let questionTypeListDataParams = {};
        console.log('a1', section)
        if (section == routes.surveyCreateAssessment || section == routes.surveyEditAssessment) {
            questionTypeListDataParams.assessment = true;
        }

        ApiService.get('questionTypeList', questionTypeListDataParams).then(r => {
            // console.debug("SurveyCreate : useEffect : questionTypeList : then :", r);
            if (r.data?.data) {
                setQuestionType(r.data.data.filter((d) => d.status != 0));
            } else {
                console.error("APi response issue");
            }
        }).catch(e => {
        })

        let dataParams = {
            // related: ['questionType'],
        };
        if (section == routes.surveyCreateAssessment || section == routes.surveyEditAssessment) {
            dataParams.assessment = true;
            console.log('a1', section,)
        } else {
            dataParams.related = ['questionType'];
        }
        ApiService.get('questionTypeListCategory', dataParams).then(r => {
            // console.debug("SurveyCreate : useEffect : questionTypeListCategory : then :", r);
            if (r.data?.data) {
                let qType = [];
                if (section == routes.surveyCreateAssessment || section == routes.surveyEditAssessment) {
                    qType = r.data.data.filter((d) => d.activeStatus != 0 && d.identifier != 'recommended');
                } else {
                    qType = r.data.data.filter((d) => d.activeStatus != 0);
                }
                // console.debug("SurveyCreate : useEffect : questionTypeListCategory : then : qType : ", qType);
                setQuestionTypeWithCategory(qType);
            } else {
                console.error("APi response issue");
            }
        }).catch(e => {
        });

    }, []);



    return (
        <CanvasWrapper
            // headerComponent={surveyQuestion?.survey?.surveyTitle}
            // canvasHeaderTitle={surveyQuestion?.survey?.surveyTitle}
            loadingSurvey={loadingSurvey}
        // canvasHeaderTitle={surveyTitle}
        >
            <CanvasSubLayout
                drawerComp={
                    <QuestionList
                        selectedQuetionItem={selectedQuetionItem}
                        onSelectItemChangeHandler={onSelectItemChangeHandler}
                        questionType={questionType}
                        questionTypeWithCategory={questionTypeWithCategory}
                        surveyId={surveyQuestion?.survey?.id}
                        selectedQuestionTypeToAddHandler={selectedQuestionTypeToAddHandler}

                    />
                }
                // drawerHeaderTool={""}
                drawerHeaderTool={
                    <AddQuestions
                        questionType={questionType}
                        questionTypeWithCategory={questionTypeWithCategory}
                        selectedQuestionTypeToAddHandler={selectedQuestionTypeToAddHandler}
                    />
                }
                drawerHeaderTitle={"Question"}
            >
                <QuestionPage
                    selectedQuetionItem={selectedQuetionItem}
                    selectedQuetionItemChangeHandler={selectedQuetionItemChangeHandler}
                    questionType={questionType}
                    selectedQuestionItemObjectChangeHandler={selectedQuestionItemObjectChangeHandler}
                    onUpdateQuestionDetails={onUpdateQuestionDetails}
                    questionUpdateLoading={questionUpdateLoading}
                    loadingSurvey={loadingSurvey}
                    selectedQuestionDescriptionToggleChangeHandler={selectedQuestionDescriptionToggleChangeHandler}
                    selectedQuestionDescriptionChangeHandler={selectedQuestionDescriptionChangeHandler}
                    selectedQuestionRequiredChangeHandler={selectedQuestionRequiredChangeHandler}
                    updateQuestionTypeRating={updateQuestionTypeRating}
                    updateQuestionTypeOpinion={updateQuestionTypeOpinion}
                    updateQuestionTypeNumber={updateQuestionTypeNumber}
                />
            </CanvasSubLayout>
            <DynamicSnackbar />
        </CanvasWrapper>
    );
}

export default SurveyCreate;
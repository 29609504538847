import React from 'react';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import PushPinIcon from '@mui/icons-material/PushPin';
import ContactsIcon from '@mui/icons-material/Contacts';
import {
    Box, Collections as CollectionsIcon, DateRange as DateRangeIcon,
    AttachEmail as AttachEmailIcon, Checklist as ChecklistIcon, ChangeHistoryTwoTone as ChangeHistoryTwoToneIcon,
    ChevronLeft as ChevronLeftIcon, Home as HomeIcon, Preview as PreviewIcon,
    DateRange,
    Phone,
    InsertPageBreakIcon,
} from '../../../../lib/utils/AllImportsHelper';
import { AlignHorizontalLeft, BarChart, FormatListNumberedRtl, Notes, ShortText, StarHalf } from '@mui/icons-material';
import RuleIcon from '@mui/icons-material/Rule';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import AttachEmailOutlinedIcon from '@mui/icons-material/AttachEmailOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import { questionTypeConst } from '../../../../constants/questionTypeConst';
// import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import FeaturedPlayListOutlinedIcon from '@mui/icons-material/FeaturedPlayListOutlined';
function QuestionTypeIcon(props) {
    const renderIcon = (iconName) => {
        // console.debug("QuestionPage : QuetionSettings : Autocomplete : renderOption : renderIcon : ", iconName);
        console.debug("iconName", iconName)
        switch (iconName) {
            case 'date':
                return <DateRange />;
            case 'email':
                return <AttachEmailOutlinedIcon />;
            case 'long-text':
                return <Notes />;
            case 'number':
                return <FormatListNumberedRtl />;
            case 'contact-info':
                return <ContactPageOutlinedIcon />;
            case 'Option State':
                return <CollectionsIcon />;
            case 'phone':
                return <Phone />;
            case 'multiple-choice':
                return <ChecklistIcon />;
            case 'rating':
                return <StarHalf/>;
            case 'dropdown':
                return <ArrowDropDownCircleOutlinedIcon />;
            case 'short-text':
                return <ShortText />;
            case 'opinion-state':
                return <BarChart />;
            case 'statement':
                return <ListAltOutlinedIcon />;
            case 'question-group':
                return <AlignHorizontalLeft />;
            case 'yes-no':
                return <CheckCircleOutlineIcon />;
            case 'picture-choice':
                return <ImageSearchIcon />;
            case 'matrix':
                return <HubOutlinedIcon />;
            case 'ranking':
                return <Groups2OutlinedIcon />;   
            case 'end-screen':
                return <FeaturedPlayListOutlinedIcon />;  
            // Add cases for other icon names as needed
            case questionTypeConst.pageBreak:
                return <InsertPageBreakIcon />;  
            default:
                return null;
        }
    };
    return (
        renderIcon(props?.questionTypeLabel)
    );
}

export default QuestionTypeIcon;
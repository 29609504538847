import React, { useState } from 'react';
import {
    Box, Switch, Grid, Button, TextField, Tab, Tabs, Typography, styled, useTheme, Drawer, Card, CssBaseline, IconButton, Avatar, InputBase, Menu,
    AppBar as MuiAppBar, Toolbar, Divider, ListItemIcon, ToggleButtonGroup, MenuItem, Paper,
    AccountCircle as AccountCircleIcon, Collections as CollectionsIcon, Undo as UndoIcon, Redo as RedoIcon, Remove as RemoveIcon, Add as AddIcon, Search as SearchIcon, Delete as DeleteIcon,
    Image as ImageIcon, DateRange as DateRangeIcon, AttachEmail as AttachEmailIcon, LocalPhone as LocalPhoneIcon, Checklist as ChecklistIcon, TextFields as TextFieldsIcon, ChangeHistoryTwoTone as ChangeHistoryTwoToneIcon, ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon, ContentCopy as ContentCopyIcon,
    Home as HomeIcon, Preview as PreviewIcon, Download as DownloadIcon, Circle as CircleIcon, Rectangle as RectangleIcon, EggTwoTone, Star as StarIcon, Logout, Settings, FormatColorFill,
    AddBoxIcon
} from '../../lib/utils/AllImportsHelper';
import AddQuestionDrawer from './AddQuestionDrawer';
import { Opacity } from '@mui/icons-material';

function AddQuestions(props) {
    console.debug("AddQuestionDrawer : props", props)
    console.debug("AddQuestionDrawer : props questionType", props?.questionType?.length)
    console.debug("AddQuestionDrawer : props questionType floor", Math.ceil(props?.questionType?.length / 2))
    console.debug("AddQuestionDrawer : props",props)
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const toggleDrawer = (e) => {
        if(e)
        e.stopPropagation();
        setIsDrawerOpen(!isDrawerOpen);
    };

    const handleItemClick = (e, item) => {
        console.debug("AddQuestions : handleItemClick : item", item);
        // setSelectedItems(itemName);
        // const newQuestion = `${itemName}`;
        // setQuestions(prevQuestions => [...prevQuestions, newQuestion]);
        props?.selectedQuestionTypeToAddHandler(e, item);
        console.log(("AddQuestionDrawer : props",props?.selectedQuestionTypeToAddHandler))

        toggleDrawer();
    };

    const conditionalStyles = props?.addCss === 'true' ? {
        width: '100%',
        textAlign: 'center',
        position: 'absolute',
        opacity:0
    } : {};
    return (
        <Box onClick={toggleDrawer} sx={{padding: props?.flag ? '0px' : '0.5rem',...conditionalStyles }}>
            <IconButton onClick={toggleDrawer} sx={{padding: props?.flag ? '0px' : 'inherit'}}>
                <AddBoxIcon fontSize="small" />
            </IconButton>
            <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer}>
                <AddQuestionDrawer
                    onClose={toggleDrawer} handleItemClick={handleItemClick}
                    questionType={props?.questionType}
                    questionTypeWithCategory={props?.questionTypeWithCategory}
                />
            </Drawer>
        </Box>
    );
}

export default AddQuestions;
import { Security } from '@mui/icons-material';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React from 'react';
import routes from '../../routesComponent/Routes';

function SettingList(props) {


    return (
        <List
            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
        >
            {props?.itemsList.map((item, index) => (

                <ListItemButton
                    className='listButtonBase'
                    key={index}
                    sx={{
                        '&.listButtonBase:hover': {
                            backgroundColor: '#eceff1 !important'
                        },
                        '&.Mui-selected': {
                            backgroundColor: '#DBEAFE',
                            '&.MuiListItemButton-root:hover': {
                                backgroundColor: '#E1EDFC !important',
                            }
                        },
                        position: 'relative',
                        '&:hover .more-vert-button': {
                            opacity: 1,
                        },
                    }}
                    onClick={(e) => props?.selectedItemChangeHandlder(e, item)}
                    selected={props?.selectedItem?.id === item?.id}

                >
                    <ListItemIcon>
                        {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.name} />

                </ListItemButton>
            ))
            }
        </List >
    );
}

export default SettingList;
import React, { useContext } from "react";
import {TextField} from '../../../../lib/utils/AllImportsHelper';
// import { AnswerContext } from "../../../_services/FromType/AnswerContext";
// import { QuestionContext } from "../../../_services/FromType/QuestionContext";

function MatrixQues(props) {
  // const number = useContext(QuestionContext);

  return (

    <div className="">
      <h3 className="m-0"> <TextField
        autosize="true"
        className="w-100"
        rows={2}
        error
        placeholder="Example@email.com"
        name="email"
      />
      </h3>
    </div>
  );
}

export default MatrixQues

import { REQUIRED_FIELD_ERROR } from "../constants/formMessages";
import { contactInfoField, questionTypeConst } from "../constants/questionTypeConst";

/**
 * validateRequiredQuestions
 * @param {Array} questions - List of all questions in the form.
 * @param {Object} answers - Object containing user answers keyed by question ID.
 * 
 * Validates that all required questions are answered. If a question is required and not answered,
 * an error message is returned for that question.
 * 
 * @returns {Object} errors - Object containing error messages for any unanswered required questions.
 */
export const validateRequiredQuestions = (questions, answers) => {
  const errors = {};

  // Working for question Validation
  /*
  questions.forEach((q) => {
    const answer = answers.find((a) => a.question.id === q.id);
    if (q?.questionRequired && (!answer || !answer.answer)) {
        // console.debug("SurveyEndUser : QuestionWrapperWithWorkflow : nextProcessHandler : workFlow : This question is required : ",q);
        // console.debug("SurveyEndUser : QuestionWrapperWithWorkflow : nextProcessHandler : workFlow : answer : ",answer);
        // console.debug("SurveyEndUser : QuestionWrapperWithWorkflow : nextProcessHandler : workFlow : answers : ",answers);
        errors[q.id] = 'This question is required';
        // errors[q.id] = true;
    }
  });
  */
  // ./Working for question Validation

  questions.forEach((q) => {
    const answer = answers.find((a) => a.question.id === q.id);
    if ((q?.questionRequired) ) {
      // console.debug("SurveyEndUser : QuestionWrapperWithWorkflow : nextProcessHandler : validateRequiredQuestions : questionRequired is required : workFlow : This question is required : ",q);
      // console.debug("SurveyEndUser : QuestionWrapperWithWorkflow : nextProcessHandler : validateRequiredQuestions : questionRequired is required : workFlow : answer : ",answer);
        // if(!answer.answer){
      //   errors[q.id] = REQUIRED_FIELD_ERROR;
      //   // errors[q.id] = `${question.text} ${REQUIRED_FIELD_ERROR}`;
      // }

      if(q?.questionType?.identifier===questionTypeConst.contactInfo){
        const fieldErrors = {};
        const parsedAnswer=answer?.answer?JSON.parse(answer?.answer):{};
        // console.debug("SurveyEndUser : QuestionWrapperWithWorkflow : nextProcessHandler : validateRequiredQuestions : questionRequired is required : QuestionType is questionTypeConst.contactInfo : workFlow : q?.questionType?.identifier : ",q?.questionType?.identifier);
        console.debug("SurveyEndUser : QuestionWrapperWithWorkflow : nextProcessHandler : validateRequiredQuestions : questionRequired is required : QuestionType is questionTypeConst.contactInfo : workFlow : parsedAnswer : ",parsedAnswer);
        
        if (!parsedAnswer?.[contactInfoField?.name]) {
          fieldErrors.name = `Name ${REQUIRED_FIELD_ERROR}`;
        }

        if (!parsedAnswer?.[contactInfoField?.company]) {
          fieldErrors.company = `Company ${REQUIRED_FIELD_ERROR}`;
        }

        if (!parsedAnswer?.[contactInfoField?.email]) {
          fieldErrors.email = `Email ${REQUIRED_FIELD_ERROR}`;
        }

        if (!parsedAnswer?.[contactInfoField?.terms]) {
          fieldErrors[contactInfoField?.terms] = `You must agree to the terms & conditions`;
        }

        if (Object.keys(fieldErrors).length > 0) {
          errors[q.id] = JSON.stringify(fieldErrors);
        }
        // console.debug("SurveyEndUser : QuestionWrapperWithWorkflow : nextProcessHandler : validateRequiredQuestions : questionRequired is required : workFlow : fieldErrors : ",fieldErrors);

      }else{
        if (!answer || !answer.answer) {
          // console.debug("SurveyEndUser : QuestionWrapperWithWorkflow : nextProcessHandler : validateRequiredQuestions : questionRequired is required : QuestionType is Other than ContactInfo : workFlow : q?.questionType?.identifier : ", q?.questionType?.identifier);
        // // errors[q.id] = `${question.text} ${REQUIRED_FIELD_ERROR}`;
        errors[q.id] = `${REQUIRED_FIELD_ERROR}`;
        }

      }

      // if(!answer.answer){
      //   errors[q.id] = REQUIRED_FIELD_ERROR;
      //   // errors[q.id] = `${question.text} ${REQUIRED_FIELD_ERROR}`;
      // }



    }else{
      // console.debug("SurveyEndUser : QuestionWrapperWithWorkflow : nextProcessHandler : validateRequiredQuestions : questionRequired is not required : workFlow : This question is required : ",q);
      // console.debug("SurveyEndUser : QuestionWrapperWithWorkflow : nextProcessHandler : validateRequiredQuestions : questionRequired is not required : workFlow : answer : ",answer);
    }
  });

  // console.debug("SurveyEndUser : QuestionWrapperWithWorkflow : nextProcessHandler : validateRequiredQuestions : workFlow : errors : ",errors);

  
  return {...errors}; // Return an object containing errors, if any
};

import React, { useContext, useEffect } from "react";
import {Box, TextField} from '../../../../lib/utils/AllImportsHelper';
// import { QuestionContext } from "../../../_services/FromType/QuestionContext";

function NumberQues(props) {
  // const answer = useContext(QuestionContext);
  // useEffect(function () {
  //   if (answer) {

  //     if (answer.setPressEnterText) {
  //       answer.setPressEnterText(true)
  //     }
  //   }
  // }, [answer])
  return (
    <Box>
      {/* {answer ? <TextField
        className="w-100 helpertxt"
        type="number"
        inputProps={{ min: answer.numberMinValue, max: answer.numberMaxValue, step: 1 }}
        error={answer.answer ? answer.answer == answer.numberMaxValue || answer.answer == answer.numberMinValue ? "limit exceed !" : '' : ""}
        helperText={answer.answer ? answer.answer == answer.numberMaxValue || answer.answer == answer.numberMinValue ? `Min value : ${answer.numberMinValue} and Max value : ${answer.numberMaxValue}` : '' : ""}
        name="number"
        placeholder="Enter Number..." variant="standard"
        onChange={answer.onChangeAnswer}
        onKeyDown={answer.onChangeAnswer} />
        :  */}
        <TextField
        sx={{width:"100%" , '& .MuiInputBase-input': {
          fontSize: '1.2rem', // Change this value to your desired font size
        }}}
       size='small'
        type="number"
          name="number"
          placeholder="Enter Number..." 
        />
        {/* } */}
    </Box>
  );
}

export default NumberQues

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    survey: null,
    questions: [],
    selectedQuestion: null,
    selectedParentQuestion: null,
    loading: false,
    error: null,
};

const surveyQuestionSlice = createSlice({
    name: 'surveyQuestionSlice',
    initialState,
    reducers: {
        updateSurvey: (state, action) => {
            state.survey = action.payload;
        },
        addQuestionInSurveyQuestionList: (state, action) => {
            const { newQuestion, parentQuestionId, afterQuestionId } = action.payload;
            console.log("Adding new question:", newQuestion, "Parent ID:", parentQuestionId, "After ID:", afterQuestionId);

            const insertAfter = (array, afterId, newItem) => {
                const index = array.findIndex(item => item.id === afterId);
                if (index !== -1) {
                    array.splice(index + 1, 0, newItem);
                } else {
                    array.push(newItem);
                }
            };

            const updateQuestionsRecursively = (questions, isTopLevel = false) => {
                for (let i = 0; i < questions.length; i++) {
                    if (questions[i].id === afterQuestionId) {
                        // Insert the new question after the found question
                        questions.splice(i + 1, 0, newQuestion);
                        return true; // Question inserted
                    }
                    if (questions[i].childQuestions && questions[i].childQuestions.length > 0) {
                        // If this question has children, recursively search them
                        const inserted = updateQuestionsRecursively(questions[i].childQuestions);
                        if (inserted) return true;
                    }
                }
                if (isTopLevel && !parentQuestionId) {
                    // If we've searched all questions and didn't find a place to insert, add to the end
                    questions.push(newQuestion);
                    return true;
                }
                return false;
            };

            if (parentQuestionId) {
                // If there's a parent question ID, we're adding to a specific question group
                state.questions = state.questions.map(question => {
                    if (question.id === parentQuestionId) {
                        const updatedChildQuestions = [...(question.childQuestions || [])];
                        insertAfter(updatedChildQuestions, afterQuestionId, newQuestion);
                        return {
                            ...question,
                            childQuestions: updatedChildQuestions
                        };
                    }
                    return question;
                });
            } else {
                // If there's no parent question ID, we need to search the entire question tree
                updateQuestionsRecursively(state.questions, true);
            }
            state.selectedQuestion = newQuestion;
            console.log("Updated questions:", state.questions);
        },
        addArrayOfQuestionsInSurveyQuestionList: (state, action) => {
            state.questions = action.payload;
        },
        // removeQuestionFromSurveyQuestionList: (state, action) => {
        //     state.questions = state.questions.filter(question => question.id !== action.payload.id);
        // },
        removeQuestionFromSurveyQuestionList: (state, action) => {
            const removeQuestionRecursive = (questions, idToRemove) => {
                return questions.filter(question => {
                    if (question.id === idToRemove) {
                        return false;
                    }
                    if (question.childQuestions && question.childQuestions.length > 0) {
                        question.childQuestions = removeQuestionRecursive(question.childQuestions, idToRemove);
                    }
                    return true;
                });
            };

            state.questions = removeQuestionRecursive(state.questions, action.payload.id);

            // If the removed question was selected, clear the selection
            if (state.selectedQuestion && state.selectedQuestion.id === action.payload.id) {
                state.selectedQuestion = null;
            }
        },

        updateQuestionStatus: (state, action) => {
            const updateStatusRecursive = (questions, idToUpdate, newStatus) => {
                return questions.map(question => {
                    if (question.id === idToUpdate) {
                        return { ...question, status: newStatus };
                    }
                    if (question.childQuestions && question.childQuestions.length > 0) {
                        return {
                            ...question,
                            childQuestions: updateStatusRecursive(question.childQuestions, idToUpdate, newStatus)
                        };
                    }
                    return question;
                });
            };

            state.questions = updateStatusRecursive(state.questions, action.payload.id, action.payload.status);
            if (state.selectedQuestion && state.selectedQuestion.id === action.payload.id) {
                state.selectedQuestion = { ...state.selectedQuestion, status: action.payload.status };
            }
        },

        updateSelectedQuestionAndQuestionInQuestionArray: (state, action) => {
            const { question, parentQuestion } = { ...action.payload };
            // let surveyQuestion = [...state.questions]
            let surveyQuestion = state.questions?.map(r => { return { ...r } });
            if (parentQuestion !== undefined && parentQuestion !== null && Object.keys(parentQuestion).length) {
                // console.debug("surveyQuestionSlice : updateSelectedQuestionAndQuestionInQuestionArray : updateQuestionInSurvey : surveyQuestion :", surveyQuestion);
                // console.debug("surveyQuestionSlice : updateSelectedQuestionAndQuestionInQuestionArray : updateQuestionInSurvey : parentQuestion :", parentQuestion);
                // console.debug("surveyQuestionSlice : updateSelectedQuestionAndQuestionInQuestionArray : updateQuestionInSurvey : question :", question);
                let parentQuestionIndex = surveyQuestion.findIndex(d => d?.id == parentQuestion?.id);
                let childQuestionIndex = surveyQuestion[parentQuestionIndex]?.childQuestions?.findIndex(d => d?.id == question?.id);
                // console.debug("surveyQuestionSlice : updateSelectedQuestionAndQuestionInQuestionArray : updateQuestionInSurvey : parentQuestionIndex :", parentQuestionIndex);
                // console.debug("surveyQuestionSlice : updateSelectedQuestionAndQuestionInQuestionArray : updateQuestionInSurvey : childQuestionIndex :", childQuestionIndex);

                // let updatedChild=surveyQuestion[parentQuestionIndex]?.childQuestions?.[childQuestionIndex]={...question};
                let updatedChildQuestionArray = surveyQuestion[parentQuestionIndex]?.childQuestions;
                updatedChildQuestionArray[childQuestionIndex] = { ...question };
                // let updatedQuestion = surveyQuestion[parentQuestionIndex] = { ...parentQuestion, childQuestions: [...surveyQuestion[parentQuestionIndex]?.childQuestions] };
                let updatedQuestionObject = surveyQuestion[parentQuestionIndex];
                updatedQuestionObject = { ...updatedQuestionObject, childQuestions: [...updatedChildQuestionArray] };
                // console.debug("surveyQuestionSlice : updateSelectedQuestionAndQuestionInQuestionArray : updateQuestionInSurvey : parentQuestionIndex :", parentQuestionIndex);
                // console.debug("surveyQuestionSlice : updateSelectedQuestionAndQuestionInQuestionArray : updateQuestionInSurvey : surveyQuestion before :", surveyQuestion);
                // surveyQuestion[parentQuestionIndex] = updatedQuestionObject;
                let updatedSurveyQuestion = surveyQuestion.map((item, index) => {
                    return index === parentQuestionIndex ? { ...updatedQuestionObject } : { ...item }
                });

                // console.debug("surveyQuestionSlice : updateSelectedQuestionAndQuestionInQuestionArray : updateQuestionInSurvey : updatedQuestionObject :", updatedQuestionObject);
                // console.debug("surveyQuestionSlice : updateSelectedQuestionAndQuestionInQuestionArray : updateQuestionInSurvey : surveyQuestion after :", surveyQuestion);
                // console.debug("surveyQuestionSlice : updateSelectedQuestionAndQuestionInQuestionArray : updateQuestionInSurvey : surveyQuestion after :", updatedSurveyQuestion);
                state.questions = [...updatedSurveyQuestion];
            } else {
                let QuestionIndex = surveyQuestion.findIndex(d => d?.id == question?.id);
                surveyQuestion[QuestionIndex] = question;
            }
            state.selectedQuestion = { ...question };

        },

        setSelectedParentQuestion: (state, action) => {
            state.selectedParentQuestion = action.payload;
        },
        setSelectedQuestion: (state, action) => {
            state.selectedQuestion = action.payload;
        },
        toggleSurveyLoadingStatus: (state) => {
            state.loading = !state.loading;
        },
        setSurveyError: (state, action) => {
            state.error = action.payload;
        },
    }
});

export const {
    updateSurvey,
    updateQuestionStatus,
    updateSelectedQuestionAndQuestionInQuestionArray,
    addArrayOfQuestionsInSurveyQuestionList,
    addQuestionInSurveyQuestionList,
    removeQuestionFromSurveyQuestionList,
    toggleSurveyLoadingStatus,
    setSurveyError,
    setSelectedParentQuestion,
    setSelectedQuestion,
} = surveyQuestionSlice.actions;

export default surveyQuestionSlice.reducer;
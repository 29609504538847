import React, { useContext, useEffect, useState } from "react";
import {Box, TextField} from '../../../../lib/utils/AllImportsHelper';
import QuestionTypeContext from "../../../../lib/layouts/QuestionFormatLayout/QuestionTypeContext";
// import { QuestionContext } from "../../../_services/FromType/QuestionContext";

function StatementQues(props) {
  const questionContect = useContext(QuestionTypeContext)

  const [answer,setAnswer]=useState('')

  const quetionChangeHandler = (event) => {
    setAnswer(event.target.value);
  };

  const textFieldBlurHandlder = () => {
    questionContect?.setValue(answer);
  };

  const textFieldKeyDownHandler = (e) => {
    if (e.key === 'Enter') {
      textFieldBlurHandlder();
    }
  };
  return (
    <Box >
      <TextField
        sx={{
          width: "100%", '& .MuiInputBase-input': {
            fontSize: '1rem', // Change this value to your desired font size
          }
        }}
        readOnly={!questionContect}
        value={answer}
        onChange={quetionChangeHandler}
        onBlur={textFieldBlurHandlder}
        onKeyDown={textFieldKeyDownHandler}
        type="email"
        name="number"
        placeholder="Enter Text"
        size="small"
      />
    </Box>
  );
}

export default StatementQues

import React, { useContext } from "react";
// import { QuestionContext } from "../../../_services/FromType/QuestionContext";
import { Button } from "../../../../lib/utils/AllImportsHelper";

function LegalQues(props) {
  // const answer = useContext(QuestionContext);
  return (
    <div>
      {/* {answer ? <>
        <Button variant="contained" value='1' className="bg-gray mui-button text-black border border-dark" startIcon={"A"} onClick={answer.onChangeAnswer}>
          I accept
        </Button>
        <Button variant="contained" value='0' className=" bg-gray mui-button text-black border border-dark ml-3" startIcon={"B"} onClick={answer.onChangeAnswer}>
          I don't accept
        </Button>
      </>
        : */}
        <>
          <Button variant="contained" className="bg-gray mui-button text-black border border-dark" startIcon={"A"} onClick={""}>
            I accept
          </Button>
          <Button variant="contained" className=" bg-gray mui-button text-black border border-dark ml-3" startIcon={"B"} onClick={""}>
            I don't accept
          </Button>
        </>
        {/* } */}
    </div >
  );
}

export default LegalQues

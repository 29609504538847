import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const FocalView = ({ selectedImage }) => {
  const [imageUrl, setImageUrl] = useState(null);
  console.debug('focalView', imageUrl)
  console.debug('focalView : selectedImage :'  , selectedImage)
  useEffect(() => {
    if (selectedImage) {
      // Handle both uploaded files and API images
      const url = selectedImage.file 
        ? URL.createObjectURL(selectedImage.file) 
        : selectedImage.mediaPath;
      setImageUrl(url);

      // Cleanup
      return () => {
        if (selectedImage.file) {
          URL.revokeObjectURL(url);
        }
      };
    } else {
      setImageUrl(null);
    }
  }, [selectedImage]);

  console.debug('focalView', imageUrl)

  return (

    <Box sx={{ backgroundColor: '#ECEFF1', p: 1, my: 1, borderRadius: 2 }}>
      <Box><Typography variant="subtitle2">Focal View</Typography></Box>
      <Box>
      {imageUrl ? (
        <img
          src={imageUrl}
          alt="Selected"
          style={{ width: '100%', height: 'auto', borderRadius: 4 }}
        />
      ) : (
        <Typography variant="body2" color="text.secondary">
          No image selected
        </Typography>
      )}
        </Box>
    </Box>
  );
};

FocalView.propTypes = {
  selectedImage: PropTypes.shape({
    file: PropTypes.instanceOf(File),
    mediaPath: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string
  })
};

export default FocalView;

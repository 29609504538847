
export const questionTypeConst = {
    yesNo: "yes-no",
    opinionState: "opinion-state",
    multipleChoice:"multiple-choice",
    rating:'rating',
    questionGroup:'question-group',
    contactInfo:'contact-info',
    webSite:'website',
    payment:'payment',
    fileUpload:'file-upload',
    legal:'legal',
    dropdown:'dropdown',
    number:'number',
    date:'date',
    matrix:'matrix',
    email:'email',
    ranking:'ranking',
    pictureChoice:'picture-choice',
    statement:'statement',
    longText:'long-text',
    shortText:'short-text',
    phone:'phone',
    endPage:'end-screen',
    pageBreak:'page-break',
  };

export const contactInfoField = {
  name:"name",
  firstName:"firstName",
  lastName:"lastName",
  phoneNumber:"phoneNumber",
  email:"email",
  company:"company",
  terms:"termsAndCondition",
  receiveCaseStudies:"acceptNewsletter",
}
import React, { useEffect, useState } from 'react'
import { Box } from '@mui/material';
import { PolarArea } from 'react-chartjs-2';
import { Chart, ArcElement, RadialLinearScale,Tooltip, Legend } from 'chart.js';
import { endUserPageMaster } from '../../constants/endUserPageMaster';
import EndUserFooterButton from './EndUserFooterButton';
Chart.register(ArcElement, RadialLinearScale,Tooltip, Legend);

// const SurveyGraphReport = ({ surveyGraphData }) => {
//     const graphData = surveyGraphData?.data?.report?.reportData;
//     console.log(graphData)
//     const data = {
//         labels: ['Red', 'Green', 'Yellow', 'Grey', 'Blue'],
//         datasets: [
//             {
//                 label: 'My First Dataset',
//                 data: [11, 16, 7, 3, 14],
//                 backgroundColor: [
//                     'rgba(255, 99, 132, 0.2)',
//                     'rgba(75, 192, 192, 0.2)',
//                     'rgba(255, 205, 86, 0.2)',
//                     'rgba(201, 203, 207, 0.2)',
//                     'rgba(54, 162, 235, 0.2)',
//                 ],
//                 borderColor: [
//                     'rgb(255, 99, 132)',
//                     'rgb(75, 192, 192)',
//                     'rgb(255, 205, 86)',
//                     'rgb(201, 203, 207)',
//                     'rgb(54, 162, 235)',
//                 ],
//                 borderWidth: 1,
//             },
//         ],
//     };

//     const options = {
//         responsive: true,
//         plugins: {
//             legend: {
//                 position: 'bottom',
//             },
//             title: {
//                 display: true,
//                 text: 'Polar Area Chart Example',
//             },
//         },
//         responsive: true,
//     maintainAspectRatio: true,
//     scales: {
//         r: {
//           ticks: {
//             stepSize: 1,
//              },
//           pointLabels: {
//             display: true,
//             centerPointLabels: true,
//             font: {
//               size: 12,
//               color: '#000000',
//             },
//             // fontColor: '#000000',
//           },
        
//         }
//     },
//     };
//   return (
//     <div>
//          <PolarArea data={data} options={options} />
//     </div>
//   )
// }

const SurveyGraphReport = ({ surveyGraphData,...props  }) => {

    const [surveyData, setSurveyData] = useState(null);
    const graphData = surveyGraphData?.data?.report?.reportData;
    console.log('zzz',graphData)

    // Convert graphData to the format needed for the chart
    const labels = graphData?.map(item => item.groupName);
    // const values = graphData?.map(item => item.itemsCount);
    const values = graphData?.map(item => item.average);

    // Generate random colors for each data point
    const generateColors = (count) => {
        const hueStep = 360 / count;
        return Array.from({ length: count }, (_, i) => {
            const hue = i * hueStep;
            return `hsla(${hue}, 70%, 60%, 0.7)`;
        });
    };

    const backgroundColor = generateColors(graphData?.length);
    const borderColor = backgroundColor.map(color => color.replace('0.7', '1'));

    const data = {
        labels: labels,
        datasets: [
            {
                label: 'Average Score',
                data: values,
                backgroundColor: backgroundColor,
                borderColor: borderColor,
                borderWidth: 2,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'right',
                labels: {
                    font: {
                        size: 14,
                        family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
                    },
                    padding: 20,
                    usePointStyle: true,
                    pointStyle: 'circle'
                }
            },
            title: {
                display: true,
                text: 'Survey Results',
                font: {
                    size: 20,
                    weight: 'bold',
                    family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
                },
                padding: {
                    top: 10,
                    bottom: 30
                }
            },
            tooltip: {
                callbacks: {
                    title: (context) => context[0].label,
                    label: (context) => `Average: ${context.raw.toFixed(2)}`,
                },
                titleFont: {
                    size: 16,
                    weight: 'bold'
                },
                bodyFont: {
                    size: 14
                },
                backgroundColor: 'rgba(0,0,0,0.8)',
                padding: 12,
                cornerRadius: 6
            }
        },
        scales: {
            r: {
                ticks: {
                    stepSize: 1,
                    font: {
                        size: 12
                    }
                },
                pointLabels: {
                    display: true,
                    centerPointLabels: true,
                    font: {
                        size: 12,
                        family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
                    },
                },
                grid: {
                    color: 'rgba(0, 0, 0, 0.1)'
                }
            }
        },
        animation: {
            animateScale: true,
            animateRotate: true
        }
    };


    const dummyData = {
                labels: ['CRM Features', 'CRM Mobile Capabilities', 'CRM Features', 'CRM Analytics & Reporting', 'CRM Features'],
                datasets: [
                    {
                        label: 'My First Dataset',
                        data: [1, 6, 5, 3, 4],
                        backgroundColor: [
                                                'rgba(255, 99, 132, 0.2)',
                                                'rgba(75, 192, 192, 0.2)',
                                                'rgba(255, 205, 86, 0.2)',
                                                'rgba(201, 203, 207, 0.2)',
                                                'rgba(54, 162, 235, 0.2)',
                                            ],
                                            borderColor: [
                                                'rgb(255, 99, 132)',
                                                'rgb(75, 192, 192)',
                                                'rgb(255, 205, 86)',
                                                'rgb(201, 203, 207)',
                                                'rgb(54, 162, 235)',
                                            ],
                                            borderWidth: 2,
                    },
                ],
            };
        
            const dummyOptions = {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        position: 'bottom',
                        labels: {
                            font: {
                                size: 14,
                                family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
                            },
                            padding: 20,
                            usePointStyle: true,
                            pointStyle: 'circle'
                        }
                    },
                    title: {
                        display: true,
                        text: 'Survey Results',
                        font: {
                            size: 20,
                            weight: 'bold',
                            family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
                        },
                        padding: {
                            top: 10,
                            bottom: 30
                        }
                    },
                    tooltip: {
                        callbacks: {
                            title: (context) => context[0].label,
                            label: (context) => `Average: ${context.raw.toFixed(2)}`,
                        },
                        titleFont: {
                            size: 16,
                            weight: 'bold'
                        },
                        bodyFont: {
                            size: 14
                        },
                        backgroundColor: 'rgba(0,0,0,0.8)',
                        padding: 12,
                        cornerRadius: 6
                    }
                },
                scales: {
                    r: {
                        ticks: {
                            stepSize: 1,
                            font: {
                                size: 12
                            }
                        },
                        pointLabels: {
                            display: true,
                            centerPointLabels: true,
                            font: {
                                size: 12,
                                family: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
                            },
                        },
                        grid: {
                            color: 'rgba(0, 0, 0, 0.1)'
                        }
                    }
                },
                animation: {
                    animateScale: true,
                    animateRotate: true
                }
            };


    
    useEffect(function () {
        if (props?.surveyData !== undefined) {
            let tempSurveyData = props.surveyData?.data;
            setSurveyData(tempSurveyData);
        }
    },[props?.surveyData]);

    function nextProcessHandler() {
        // console.debug("SurveyEndUser : SurveyGraphReport : nextProcessHandler : ");
        // console.debug("SurveyEndUser : QuestionWrapper : nextProcessHandler : workFlow else block : ");
        if(props?.nextClickHandler !== undefined){
            let page="";
            if(surveyData?.public){
                if(surveyData?.showReportAttendeeSettings?.showAttendee){
                    page = endUserPageMaster.attendees;
                }else{
                    page = endUserPageMaster.thankYou;
                }
            }else{
                    page = endUserPageMaster.thankYou;
            }
            props.nextClickHandler(page);
        }else{
            console.error("Something Went Wrong");
        }
    }

    
    return (
    <Box sx={{ padding: "1rem", mx: "2rem" }}>

        <>
        { (graphData?.length>0) ?
        <Box style={{ width: '60vw', height: '70vh', margin: 'auto',padding: '20px',
            backgroundColor: '#f8f9fa',
            borderRadius: '10px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'  }}>
            <PolarArea data={data} options={options} />
        </Box>
        :
        <Box style={{ width: '60vw', height: '70vh', margin: 'auto',padding: '20px',
            backgroundColor: '#f8f9fa',
            borderRadius: '10px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'  }}>
            <PolarArea data={dummyData} options={dummyOptions} />
        </Box>
}
        </>

        <EndUserFooterButton nextProcessHandler={nextProcessHandler}/>

    </Box>
    )
}

export default SurveyGraphReport
import React from "react";
import List from '@mui/material/List';
import Required from "./Required";
import Description from "./Description";
function YesNo(props) {
  return (
    <div className="">
      <div>
        <List
          sx={{ width: '100%', bgcolor: 'background.paper' }}
          aria-labelledby="nested-list-subheader"
        >    
        <Required/>
        <Description />
        
        </List>     
      </div>
    </div>
  );
}

export default YesNo

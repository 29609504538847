import React from 'react';
import { Box, Button, Menu, MenuItem, IconButton } from '@mui/material';
import { Add, Edit, Delete, Preview, FileCopy, Visibility } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReplyIcon from '@mui/icons-material/Reply';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import DvrIcon from '@mui/icons-material/Dvr';
import routes from '../../routesComponent/Routes';
import { useNavigate } from 'react-router-dom';
import ApiService from '../../ApiService';
import { updateArray } from '../../lib/utils';
import { surveyOrigin } from '../../constants/surveyOrigin';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../redux/snackbar/snackbarSlice';

function TableToolbar({ refreshData, ...props }) {
    const navigate = useNavigate();
    let dispatch = useDispatch()

    const onEditClickHandler = (rowData, e) => {

        // console.debug("TableToolbar : onEditClickHandler : rowData : ", rowData)
        let rowDataItem = JSON.parse(rowData?.[0]);
        if (rowDataItem && rowDataItem?.id) {
            // console.debug("TableToolbar : onEditClickHandler : rowDataItem : ", rowDataItem)
            let sOrigin = rowDataItem?.surveyOrigin?.identifier;
            // console.debug("TableToolbar : onEditClickHandler : rowDataItem : sOrigin", sOrigin);
            if (sOrigin == surveyOrigin.aiGenerated || sOrigin == surveyOrigin.assessmentBased) {
                navigate(routes.surveyEditAssessment + rowDataItem.id);

            } else {
                navigate(routes.surveyEdit + rowDataItem.id);
            }
            // rowDataItem
        } else {
            console.error("Something Went Wrong");
        }
    };

    const onPreviewClickHandler = (rowData, e) => {
        // console.debug("TableToolbar : onPreviewClickHandler : rowData : ", rowData);
        // console.debug("TableToolbar : onPreviewClickHandler : e : ", e);
        // navigate(routes.surveyPreview + rowData?.[0]);

        let rowDataItem = JSON.parse(rowData?.[0]);
        if (rowDataItem && rowDataItem?.id) {
            // console.debug("TableToolbar : onPreviewClickHandler : rowDataItem : ", rowDataItem)
            navigate(routes.surveyPreview + rowDataItem.id);
        } else {
            console.error("Something Went Wrong");
        }

    };

    const onResultClickHandler = (rowData, e) => {
        // navigate(routes.surveyResult + rowData?.[0]);

        let rowDataItem = JSON.parse(rowData?.[0]);
        if (rowDataItem && rowDataItem?.id) {
            // console.debug("TableToolbar : onResultClickHandler : rowDataItem : ", rowDataItem)
            navigate(routes.surveyResult + rowDataItem.id);
        } else {
            console.error("Something Went Wrong");
        }

    };

    const onDuplicateClickHandler = (rowData, e) => {
        let rowDataItem = JSON.parse(rowData?.[0]);
        if (rowDataItem && rowDataItem?.id) {
            // console.debug("TableToolbar : onResultClickHandler : rowDataItem : ", rowDataItem)
            let dataId = rowDataItem.id;

            if (dataId) {
                ApiService.get('surveyDuplicate', {}, dataId).then(r => {
                    // console.debug("TableToolbar : onDuplicateClickHandler : r : ", r.data.data)
                    dispatch(openSnackbar({ message: 'Duplicated survey completed', severity: "info" }));
                    if (r?.data?.data?.surveyOrigin?.identifier !== undefined && (r?.data?.data?.surveyOrigin?.identifier !== surveyOrigin.assessmentBased || r?.data?.data?.surveyOrigin?.identifier !== surveyOrigin.aiGenerated)) {
                        navigate(routes.surveyEditAssessment + r?.data?.data.id);
                    } else {
                        navigate(routes.surveyEdit + r?.data?.data.id);
                    }
                }).catch(e => {
                    console.error("TableToolbar : onDuplicateClickHandler : e : ", e)
                    dispatch(openSnackbar({ message: e?.message, severity: "error" }));
                })
            }
        } else {
            console.error("Something Went Wrong");
        }
    };

    const onDeleteClickHandler = (rowData, e) => {
        // console.debug("TableToolbar : onDeleteClickHandler : rowData : ", rowData)
        if (rowData?.length > 1) {
            let rowIds = [];
            rowData.forEach(element => {
                let d = JSON.parse(element)
                if (d?.id) {
                    rowIds.push(d.id)
                }
            });

            const formData = {
                ids: rowIds
            };
            // console.debug("TableToolbar : onDeleteClickHandler : Multiple Delete : formData : ", formData)
            ApiService.delete('surveyMultipleDelete', formData).then(r => {
                // console.debug("TableToolbar : onDeleteClickHandler : r : ", r)
                // const formattedArray = rowData.map(value => ({ id: value }));
                // console.debug("TableToolbar : onDeleteClickHandler : r : formattedArray : ", formattedArray);
                refreshData();
                dispatch(openSnackbar({ message: 'Survey deleted successfully', severity: "info" }));

                // if (props?.tableData?.length && props?.surveyListDataChangeHandler) {
                //     let updateDataArray = updateArray(props.tableData, formattedArray, "id", "remove");
                //     // console.debug("    : onDeleteClickHandler : r : updateDataArray : ", updateDataArray)
                //     props.surveyListDataChangeHandler([...updateDataArray]);
                //     refreshData();
                // }
            }).catch(e => {
                console.error("TableToolbar : onDeleteClickHandler : e : ", e)
                dispatch(openSnackbar({ message: e?.message, severity: "error" }));
            })

        } else {
            // console.debug("TableToolbar : onDeleteClickHandler : Single Delete : ");
            let data = JSON.parse(rowData?.[0]);
            let dataId = data?.id;
            // refreshData();

            if (dataId) {
                ApiService.delete('surveyDelete', {}, dataId).then(r => {
                    refreshData();
                    // // console.debug("TableToolbar : onDeleteClickHandler : r : ", r)
                    // if (props?.tableData?.length && props?.surveyListDataChangeHandler) {
                    //     let updateDataArray = updateArray(props.tableData, [{ id: dataId }], "id", "remove");
                    //     // console.debug("TableToolbar : onDeleteClickHandler : r : updateDataArray : ", updateDataArray)
                    //     props.surveyListDataChangeHandler([...updateDataArray]);
                    //     refreshData();
                    // }
                    dispatch(openSnackbar({ message: 'Survey deleted successfully', severity: "info" }));

                }).catch(e => {
                    console.error("TableToolbar : onDeleteClickHandler : e : ", e)
                    dispatch(openSnackbar({ message: e?.message, severity: "error" }));
                })
            } else {
                console.error("TableToolbar : onDeleteClickHandler : Something went wrong : ");
                dispatch(openSnackbar({ message: "Something went wrong", severity: "error" }));
            }
        }
    };

    const onRenameClickHandler = (rowData, e) => {
        console.debug("TableToolbar : onRenameClickHandler : rowData : ", rowData);
        // console.debug("TableToolbar : onEditClickHandler : e : ", e);
        // navigate(routes.surveyResult + rowData?.[0]);
    };

    const onCreateClickHandler = (rowData, e) => {
        console.debug("TableToolbar : onCreateClickHandler : rowData : ", rowData);
        console.debug("TableToolbar : onCreateClickHandler : e : ", e);
    };

    const buttons = [
        { label: 'Edit', icon: <Edit />, onClick: onEditClickHandler },
        // { label: 'Share', icon: <ReplyIcon />, onClick: onCreateClickHandler },
        { label: 'Delete', icon: <Delete />, onClick: onDeleteClickHandler },
        { label: 'Preview', icon: <Preview />, onClick: onPreviewClickHandler },
        // { label: 'Rename', icon: <DriveFileRenameOutlineIcon />, onClick: onCreateClickHandler },
        { label: 'Duplicate', icon: <FileCopy />, onClick: onDuplicateClickHandler },
        { label: 'Result', icon: <DvrIcon />, onClick: onResultClickHandler },
    ];

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box>
            {props?.selectedRows?.length > 1 ?
                <Box sx={{ display: 'flex', ml: '1rem' }}>
                    <Button
                        key={0}
                        variant="text"
                        startIcon={buttons[1].icon}
                        onClick={(e) => buttons[1].onClick(props?.selectedRows, e)}
                        sx={{ color: 'black' }}
                    >
                        {buttons[1].label}
                    </Button>
                </Box>

                :

                <Box sx={{ display: 'flex', ml: '1rem' }}>
                    {buttons.slice(0, 3).map((button, index) => (
                        <Button
                            key={index}
                            variant="text"
                            startIcon={button.icon}
                            onClick={(e) => button.onClick(props?.selectedRows, e)}
                            sx={{ color: 'black' }}
                        >
                            {button.label}
                        </Button>
                    ))}
                    <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={handleClick}
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                    >
                        {buttons.slice(3).map((button, index) => (
                            <MenuItem
                                key={index}
                                onClick={(e) => {
                                    // button.onClick(props?.selectedRows, e);
                                    handleClose();
                                }}
                            >
                                <Button
                                    key={index}
                                    variant="text"
                                    startIcon={button.icon}
                                    onClick={(e) => button.onClick(props?.selectedRows, e)}
                                    sx={{ color: 'black' }}
                                >
                                    {button.label}
                                </Button>
                            </MenuItem>
                        ))}
                    </Menu>
                </Box>
            }

        </Box>
    );
}

export default TableToolbar;

import React from 'react';
import { Box, Typography, List, ListItem, ListItemText } from '@mui/material';
import LightbulbIcon from '@mui/icons-material/Lightbulb';

function TipsAndTricks() {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        padding: '2rem',
        // backgroundColor: '#E3F2FD',
        border: '1px solid #BBDEFB',
        borderRadius: '4px',
        maxWidth: '400px',
        justifyContent:'center'
      }}
    >
      <LightbulbIcon sx={{ marginRight: '8px' }} />
      <Box>
        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
          Tips & Tricks to get better results:
        </Typography>
        <List sx={{py:'0px !important'}}>
          <ListItem sx={{p:'0px !important'}}>
          <ListItemText primary={
                   <>
                   <span style={{fontWeight:'bold'}}>1.Demographic Details:</span> Specify gender, age group, location, and any other relevant parameters.
                 </>
           
              } />
          </ListItem>
          <ListItem sx={{p:'0px !important'}}>
            <ListItemText primary={
                   <>
                   <span style={{fontWeight:'bold'}}>2.Be Specific:</span> Avoid vague language. Use precise terms to ensure respondents understand what you’re asking.
                 </>
           
              } />
          </ListItem>
        </List>
      </Box>
    </Box>
  );
}

export default TipsAndTricks;

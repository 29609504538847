import React, { useEffect } from 'react';
import { Box, Typography, Avatar, Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

const SurveyThankYouPage = ({ surveyData, handleRestartSurvey }) => {
    const completionMessage = surveyData?.data?.completionMessage;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
    //     <Box sx={{height:'calc(100vh - 64px)', display: 'flex', alignItems: 'center' }}>
    //         <Box sx={{height:'100%',
    // display: 'flex',
    // width: '100%',
    // textAlign: 'center',
    // alignItems: 'center',
    // flexFlow:'row wrap'}}>
    //     <Box sx={{width:'100%',display:'flex',justifyContent:'center'}}>
    //         <Avatar sx={{ bgcolor: '#00C187', }}>
    //             <CheckIcon />
    //         </Avatar>
    //     </Box>
    //     <Box sx={{width:'100%',display:'inline-block',justifyContent:'center'}}>
    //         <Typography variant="h4" fontSize='1.5rem !important'>
    //             {completionMessage && completionMessage.length > 0
    //                 ? completionMessage
    //                 : 'Thank you for taking the time to complete this survey'}
    //         </Typography>
    //     </Box>
    //     <Box sx={{width:'100%',display:'inline-block',justifyContent:'center'}}>
    //         <Button variant="contained" onClick={handleRestartSurvey} size='medium' sx={{ mt: 2 }}>
    //             Restart
    //         </Button>
    //     </Box>
    //         </Box>
    //     </Box>
//     <Box sx={{height:'calc(100vh - 64px)'}}>
//         <Box sx={{height:'100%',
//     // display: 'flex',
//     width: '100%',
//     textAlign: 'center',
//     alignItems: 'center',
//     // flexFlow:'row wrap'
// }}
//     >
//         <Box sx={{width:'100%',display:'flex',justifyContent:'center'}}>
//         <Avatar sx={{ bgcolor: '#00C187'}}>
//        <CheckIcon />
//           </Avatar>
//           </Box>
//           <Typography variant="h4" fontSize='1.5rem !important'>
//            {completionMessage && completionMessage.length > 0
//                    ? completionMessage
//                     : 'Thank you for taking the time to complete this survey'}
//            </Typography>
//            <Button variant="contained" onClick={handleRestartSurvey} size='medium' sx={{ mt: 2 }}>
//              Restart
//           </Button>
//         </Box>
//     </Box>
<Box
  sx={{
    height: 'calc(100vh - 64px)',
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
  }}
>
  <Box
    sx={{
      height: '100%',
      width: '100%',
      textAlign: 'center',
      display: 'flex', 
      flexDirection: 'column', 
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
      <Avatar sx={{ bgcolor: '#00C187' }}>
        <CheckIcon />
      </Avatar>
    </Box>
    <Typography variant="h4" fontSize="1.5rem !important">
      {completionMessage && completionMessage.length > 0
        ? completionMessage
        : 'Thank you for taking the time to complete this survey'}
    </Typography>
    <Button
      variant="contained"
      onClick={handleRestartSurvey}
      size="medium"
      sx={{ mt: 2 }}
    >
      Restart
    </Button>
  </Box>
</Box>

    );
}

export default SurveyThankYouPage;

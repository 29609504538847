import React, { useEffect, useState } from 'react';
import { Table } from '../../lib';
import TableToolbar from '../tableToolbar/TableToolbar';
import { useParams } from 'react-router-dom';
import ApiService from '../../ApiService';
import { Box, Tooltip, styled, tooltipClasses } from '@mui/material';
import QuestionTypeIcon from '../question/questionSettings/questionType/QuestionTypeIcon';


const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#347edd',
        color: '#ffffff',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: '#347edd',
    },
}));

const spanStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 2,
    // width: '50px',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical'
};

function SurveyResponse(props) {
    let { id } = useParams();

    const [data, setData] = useState([]);
    const [tableHead, setTableHead] = useState([]);

    const questionColumnWidth = "200px";
    // const  =[];
    const setMaterialTableRowLimit = null;
    const setPageNo = null;
    const pageNo = null;
    const page = null;
    const setPage = null;
    const metaData = null;

    // const selectedRows = null;
    const [rowsPerPage, setRowsPerPage] = useState(10);


    const [selectedRows, setSelectedRows] = useState(new Set());



    const searchHandler = (data, e) => {
        console.debug("searchHandler : data ", data);
        console.debug("searchHandler : e ", e);
    }


    const handleRowSelected = (selectedRow) => {
        console.debug("handleRowSelected : selectedRows ", selectedRow);
    };


    function answerformater(answers) {
        // console.debug("SurveyResult : useEffect : Survey Detail : then : surveyResponse : surveyQuestion : filterdata : answerformater : answers :", answers);
        let a = {};
        let formatedAnswers = answers ? answers.map(ans => {
            let ansKeyTemp = ans.questionId;
            let obj = {};
            // console.debug("SurveyResult : useEffect : Survey Detail : then : surveyResponse : surveyQuestion : filterdata : answerformater : ans :", ans);
            if (ans.answerable_type == "App\\Model\\AnswerTypeMultipleOption") {
                // console.debug("SurveyResult : useEffect : Survey Detail : then : surveyResponse : surveyQuestion : filterdata : answerformater : ans : ans : if : ",ans);
                // let b = JSON.parse(ans.answerable.answer);
                let b = (ans.answerable.answer);
                obj[ansKeyTemp] = b;
                // console.debug("SurveyResult : useEffect : Survey Detail : then : surveyResponse : surveyQuestion : filterdata : answerformater : ans : obj : if : ", obj);
            } else {
                // console.debug("SurveyResult : useEffect : Survey Detail : then : surveyResponse : surveyQuestion : filterdata : answerformater : ans : ans : else : ",ans);
                obj[ansKeyTemp] = ans.answerable.answer
                // console.debug("SurveyResult : useEffect : Survey Detail : then : surveyResponse : surveyQuestion : filterdata : answerformater : ans : obj : else : ", obj);
            }
            // console.debug("SurveyResult : useEffect : Survey Detail : then : surveyResponse : surveyQuestion : filterdata : answerformater : ans : a : before : ", a);
            a = { ...a, ...obj };
            // console.debug("SurveyResult : useEffect : Survey Detail : then : surveyResponse : surveyQuestion : filterdata : answerformater : ans : a : after : ", a);
            return obj;
        }) : [];
        // console.debug("SurveyResult : useEffect : Survey Detail : then : surveyResponse : surveyQuestion : filterdata : answerformater : a :", a);
        return a;
    }

    function sesstionAnswerformater(sessionAnswers) {
        let formatedSessionAnswers = [];
        let filteredSessionAnswers = sessionAnswers.filter(function (r) {
            return (r.answers && Object.keys(r.answers).length > 1)
        });
        // console.debug("SurveyResult : useEffect : Survey Detail : then : surveyResponse : surveyQuestion : filterdata : sesstionAnswerformater : filteredSessionAnswers :", filteredSessionAnswers);
        formatedSessionAnswers = filteredSessionAnswers.map(r => {
            let formatedAnswers = [];
            var respondentName = "";
            if (r.user && r.user.firstName) {
                respondentName = r.user.firstName;
                respondentName = respondentName + (r.user.middleName ? " " + r.user.middleName : "");
                respondentName = respondentName + (r.user.lastName ? " " + r.user.lastName : "");
                respondentName = respondentName + (r.user.email ? " " + r.user.email : "");
                respondentName = respondentName + (r.user.employeeId ? " " + "Employee ID : " + r.user.employeeId : "");
            }
            // let respondents = { respondentName: respondentName }
            let respondents = r?.user
            formatedAnswers = answerformater(r.answers)
            let sessionAnswer = { id: r.id, ...formatedAnswers, ...respondents };
            return sessionAnswer;
        })
        return formatedSessionAnswers;
    }

    function getQuestionLabel(element) {
        const formatLabel = (question) => {
            if (typeof question === 'object') {
                return JSON.stringify(question, null, 2).replace(/"/g, '');
            }
            return question;
        };
        const shouldShowTooltip = (question) => {
            const formattedLabel = formatLabel(question);
            return formattedLabel.length > 25; // Show tooltip if length exceeds 25
        };

        const formatLabelWithMinWidth = (question, minWidth) => {
            const formattedLabel = formatLabel(question);
            if (minWidth < 30) {
                return formattedLabel.slice(0, 12) + '...';
            } else if (minWidth >= 30 && formattedLabel.length > 25) {
                return formattedLabel.slice(0, 25) + '...';
            }
            return formattedLabel;
        };

        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <QuestionTypeIcon questionTypeLabel={element?.questionType?.identifier} />
                {/* <Box sx={{ml:1,display:'flex'}}>{element.question}</Box> */}
                <Box sx={{ ml: 1, display: 'flex' }}>
                    {shouldShowTooltip(element.question) ? (
                        <LightTooltip title={formatLabel(element.question)} arrow>
                            <span style={spanStyle}>
                                {formatLabelWithMinWidth(element.question,)}
                            </span>
                        </LightTooltip>
                    ) : (
                        <span style={spanStyle}>
                            {formatLabelWithMinWidth(element.question)}
                            {console.debug('element.question :', element.question)}

                        </span>
                    )}
                </Box>
            </Box>
        )
    }

    const EmployeeDetails = ({ r }) => {
        const fullName = r.firstName + (r.middleName ? " " + r.middleName : "") + (r.lastName ? " " + r.lastName : "");
    
        return (
            <LightTooltip 
                title={
                    <React.Fragment>
                        <div>{fullName}</div>
                        <div>{r.email}</div>
                        <div>{r.employeeId}</div>
                    </React.Fragment>
                }
            >
                <Box component="span">
                    {fullName}
                </Box>
            </LightTooltip>
        );
    }

    useEffect(function () {
        var surveyPublicVisibilityStatus = null;
        if (id) {
            // Fetch Survey Detail
            {
                ApiService.get('surveyView', {}, id).then(r => {
                    console.debug("SurveyResult : useEffect : Survey Detail :then : r : ", r);
                    if (r.data?.data) {
                        let surveyResponse = r.data.data;
                        // console.debug("SurveyResult : useEffect : Survey Detail :then : surveyResponse : ", surveyResponse);
                        if (surveyResponse) {
                            surveyPublicVisibilityStatus = surveyResponse.public;
                            var tableHeadData = [];
                            surveyResponse?.surveyQuestion?.forEach(element => {
                                // element
                                if (element?.questionType?.identifier === "question-group") {

                                    element?.childQuestions.forEach(childElement => {
                                        let h = {
                                            id: childElement.id,
                                            selector: childElement.id,
                                            label: getQuestionLabel(childElement),
                                            // label: childElement.question,
                                            minWidth: questionColumnWidth,

                                        };
                                        tableHeadData.push(h);
                                    });

                                } else {
                                    let h = {
                                        id: element.id,
                                        selector: element.id,
                                        label: element.question,
                                        minWidth: questionColumnWidth,

                                    };
                                    tableHeadData.push(h);
                                }
                            });

                            if (surveyPublicVisibilityStatus == 0) {
                                let respondents = {
                                    "id": "respondentName",
                                    "label": "Respondents",
                                    minWidth: "200px",
                                    // selector: r => {
                                    //     console.debug("AVIkram : ", r)
                                    //     return (r.firstName + (r.middleName ? " " + r.middleName : "") + (r.lastName ? " " + r.lastName : ""));
                                    // }
                                    selector: r => {
                                        return <EmployeeDetails r={r} />;
                                    }
                                }
                                tableHeadData = [respondents, ...tableHeadData];
                            }
                            console.debug("a1", r)
                            console.debug("SurveyResult : useEffect : Survey Detail : then : surveyResponse : surveyQuestion : filterdata : tableHeadData : ", tableHeadData);

                            setTableHead(tableHeadData);
                            ApiService.get('surveyAnswer', {}, id).then(r => {
                                // console.debug("SurveyResult : surveyAnswer : useEffect :then : r : ", r);
                                if (r.data) {
                                    let surveyResponse = r.data;
                                    console.debug("SurveyResult : useEffect : Survey Detail : then : surveyResponse : surveyQuestion : filterdata : SurveyResponse :", surveyResponse);
                                    if (surveyResponse?.session?.length) {
                                        // console.debug("SurveyResult : useEffect : Survey Detail : then : surveyResponse : surveyQuestion : filterdata : surveyResponse?.session :", surveyResponse?.session);
                                        let formatedSessionAnswer = sesstionAnswerformater(surveyResponse?.session);
                                        setData(formatedSessionAnswer);
                                        console.debug("SurveyResult : useEffect : Survey Detail : then : surveyResponse : surveyQuestion : filterdata : SurveyResponse : formatedSessionAnswer :", formatedSessionAnswer);
                                        // console.debug("SurveyResult : surveyAnswer : useEffect :then : surveyResponse : ", surveyResponse);
                                    }
                                    else
                                        console.error("APi response issue");
                                }
                            }).catch(e => {
                                console.error("SurveyResult : useEffect :catch : e : ", e);
                                // navigate to 404 page
                                // or error
                            });


                            setData(surveyResponse?.session)
                            // dispatch(addArrayOfQuestionsInSurveyQuestionList([...surveyResponse.surveyQuestion]))
                        }
                        else
                            console.error("APi response issue");
                    }
                }).catch(e => {
                    console.debug("SurveyResult : useEffect :catch : e : ", e);
                    // navigate to 404 page
                    // or error
                });
            }
        } else {
            // navigate to 404 page
        }
    }, []);

    return (
        <Table
            title="Responses"
            setMaterialTableRowLimit={setMaterialTableRowLimit}
            setPageNo={setPageNo}
            pageNo={pageNo}
            page={page}
            setPage={setPage}
            metaData={metaData}
            rowsPerPage={rowsPerPage}
            // Final and corrected as per API DOC
            loading={false}
            // columns={ColumnsHeadingsTemp}
            columns={tableHead}
            data={data}
            // selectableRows
            actions={<TableToolbar selectedRows={selectedRows} />}
            onSelectedRowsChange={handleRowSelected}
            searchHandler={searchHandler}

        />
    );
}

export default SurveyResponse;
import React, { useEffect, useState } from 'react';
import { Typography, IconButton, TextField, Button, Box, Card, Divider } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import BoltIcon from '@mui/icons-material/Bolt';
import { AutoAwesome, ChevronLeft, ChevronRight, Refresh } from '@mui/icons-material';

function SwitchableCard({ initialContent, viewMode, ...props }) {
    // console.debug("genaiResponse : CardContentWithUtility : initialContent : ", initialContent);
    const [content, setContent] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isEditing, setIsEditing] = useState(false);
    const [editValue, setEditValue] = useState(content?.[0]);
    const [generateButtonValue, setGenerateButtonValue] = useState("GENERATE");

    useEffect(function () {
        if (initialContent) {
            setContent(initialContent);
            // props?.selectedPromptChangeHandler(initialContent[currentIndex])
        }

    }, [initialContent]);

    useEffect(function () {
        if (viewMode) {
            if (viewMode == 'textfield') {
                setIsEditing(true);
            }
            // setContent(textfield);
            // props?.selectedPromptChangeHandler(initialContent[currentIndex])
        }

    }, [viewMode]);


    const handleEdit = () => {
        setIsEditing(true);
        setEditValue(content[currentIndex]);
    };

    const textFieldChangeHandler = (e) => {
        // setIsEditing(true);
        // setEditValue(content[currentIndex]);
        setEditValue(e.target.value)
        if (props?.textFieldChangeHandler) {
            props.textFieldChangeHandler(e.target.value);
        }

    };

    const handleSave = () => {
        const updatedContent = [...content];
        updatedContent[currentIndex] = editValue;
        setContent(updatedContent);
        setIsEditing(false);
    };

    const handleDelete = () => {
        const updatedContent = content.filter((_, index) => index !== currentIndex);
        setContent(updatedContent);
        setCurrentIndex(0);
        setIsEditing(false);
    };

    const handleRefresh = () => {
        // const updatedContent =    content.filter((_, index) => index !== currentIndex);
        if (content?.length) {
            setContent([]);
            setCurrentIndex(0);
            setEditValue("");
            if (props?.resetIntialContent) {
                props.resetIntialContent();
            }
        }
    };

    const handlePrev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
            // setEditValue(content[currentIndex - 1]);

            if (isEditing) {
                setEditValue(content[currentIndex - 1]);
            }
            if (props?.textFieldChangeHandler) {
                props.textFieldChangeHandler(content[currentIndex - 1]);
            }
        }
    };

    const handleNext = () => {
        if (currentIndex < content.length - 1) {
            setCurrentIndex(currentIndex + 1);
            // setEditValue(content[currentIndex + 1]);
            if (isEditing) {
                setEditValue(content[currentIndex + 1]);
            }
            if (props?.textFieldChangeHandler) {
                props.textFieldChangeHandler(content[currentIndex + 1]);
            }
        }
    };


    function onGenerateClickHandler(e) {
        e.preventDefault();
        if (editValue) {
            let contentTemp = [...content];
            contentTemp.push(editValue);
            // setContent([...content, editValue])
            setContent([...contentTemp])
            // content.push(editValue)

        }

    }
    const renderContent = () => {
        switch (viewMode) {
            case 'textfield':
            case 'switch':
                return (
                    // <TextField
                    //     fullWidth
                    //     variant="outlined"
                    //     value={editValue}
                    //     multiline
                    //     maxRows={8}
                    //     onChange={textFieldChangeHandler}
                    // />
                    <Box >
                        {isEditing ? (
                            <Box>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    value={editValue}
                                    multiline
                                    rows={8}
                                    // maxRows={8}
                                    onChange={textFieldChangeHandler}
                                />
                            </Box>
                        ) : (
                            <Box sx={{ height: '5.2rem', overflow: 'auto', padding: '0.2rem 0rem 0rem 0.5rem' }}>
                                <Typography variant="subtitle2" color="text.secondary">
                                    {content[currentIndex]}
                                </Typography>
                            </Box>
                        )}
                    </Box>

                );
            case 'card':
                return (
                    <Box sx={{ height: '5.2rem', overflow: 'auto', padding: '0.2rem 0rem 0rem 0.5rem' }}>
                        <Typography variant="subtitle2" color="text.secondary">
                            {content[currentIndex]}
                        </Typography>
                    </Box>
                );
            // case 'switch':
            //     return (
            //         <Box >
            //             {isEditing ? (
            //                 <Box>
            //                     <TextField
            //                         fullWidth
            //                         variant="outlined"
            //                         value={editValue}
            //                         multiline
            //                         maxRows={8}
            //                         onChange={textFieldChangeHandler}
            //                     />
            //                 </Box>
            //             ) : (
            //                 <Box sx={{ height: '5.2rem', overflow: 'auto', padding: '0.2rem 0rem 0rem 0.5rem' }}>
            //                     <Typography variant="subtitle2" color="text.secondary">
            //                         {content[currentIndex]}
            //                     </Typography>
            //                 </Box>
            //             )}
            //         </Box>
            //     )
            default:
                return (
                    <Box sx={{ height: '5.2rem', overflow: 'auto', padding: '0.2rem 0rem 0rem 0.5rem' }}>
                        <Typography variant="subtitle2" color="text.secondary">
                            {content[currentIndex]}
                        </Typography>
                    </Box>
                );
        }
    };

    return (

        <Card
            //  sx={{ border: '0.5px solid #2563EB', p: '8px' }}
            sx={{ width: '100%', marginBottom: '16px' }}
        >
            {renderContent()}
            {/* <Divider sx={{ mt: 1 }} /> */}
            <Box display="flex" justifyContent="flex-end">
                <Box sx={{ display: 'flex' }}>
                    {/* <Box>
                        {isEditing ? (
                            <IconButton onClick={handleSave} color="primary">
                                <SaveIcon />
                            </IconButton>
                        ) : (
                            <IconButton onClick={handleEdit} color="primary">
                                <EditIcon />
                            </IconButton>
                        )}
                        <IconButton onClick={handleDelete} color="secondary">
                            <DeleteIcon />
                        </IconButton>
                    </Box> */}
                    <Box sx={{ display: 'flex' }}>
                        <IconButton onClick={handlePrev} disabled={currentIndex === 0}>
                            <ChevronLeft />
                        </IconButton>
                        <Typography sx={{ mt: 1 }}>
                            {content.length == 0 ? "0/0" :
                                (currentIndex + 1) + "/" + content.length
                            }
                        </Typography>
                        <IconButton onClick={handleNext} disabled={content.length == 0 || currentIndex === content.length - 1}>
                            <ChevronRight />
                        </IconButton>
                        <IconButton size="small" onClick={handleRefresh}>
                            <Refresh fontSize='inherit' />
                        </IconButton>
                    </Box>
                </Box>
            </Box>
        </Card>

    );

}

export default SwitchableCard;
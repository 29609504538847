import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { 
  Box, 
  Button, 
  IconButton, 
  Typography, 
  Tabs, 
  Tab, 
  Divider 
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FileUpload from './questionSettings/UploadImage/FileUpload';
import UploadFiles from './questionSettings/UploadImage/UploadFiles';

const CustomTabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const UploadImage = ({ handleClose, onImageSave }) => {
    const [activeTab, setActiveTab] = useState(0);
    const [uploadedFiles, setUploadedFiles] = useState([]); // State for uploaded files
    const [selectedImage, setSelectedImage] = useState(null);
    console.debug('UploadImage : selectedImage : 1:',selectedImage)
    const handleTabChange = (event, newValue) => {
      setActiveTab(newValue);
    };
  
    const handleFileUpload = (newFile) => {
        const fileAlreadyExists = uploadedFiles.some(
          (file) => file.name === newFile.name
        );
    
        if (fileAlreadyExists) {
          alert('This file has already been uploaded.');
          return;
        }
    
        const newFileWithTimestamp = {
          ...newFile,
          timestamp: new Date().getTime()
        };
        setUploadedFiles(prev => [...prev, newFileWithTimestamp]);
        setSelectedImage(newFileWithTimestamp); // Auto-select the most recent upload
      };
  
    const handleSelectImage = (image) => {
      setSelectedImage(image);
    };
  
    const handleSave = () => {
      if (!selectedImage) {
        alert('Please select an image first.');
        return;
      }
      onImageSave(selectedImage);
      console.debug('UploadImage : onImageSave : 3:',onImageSave(selectedImage))
      console.debug('UploadImage : selectedImage : 2:',selectedImage)
      handleClose();
    };
  
    return (
      <Box sx={{ width: '30rem' }}>
        <Box sx={{ p: 1, background: '#e1edfc' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant='subtitle1' fontWeight='bold'>Upload Image</Typography>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Divider />
  
        <Box sx={{ p: 1, height: 'calc(100vh - 190px)', overflow: 'auto' }}>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs 
                value={activeTab} 
                onChange={handleTabChange} 
                aria-label="upload image tabs"
              >
                <Tab label="Upload" {...a11yProps(0)} />
                <Tab label="Image" {...a11yProps(1)} />
              </Tabs>
            </Box>
  
            <CustomTabPanel value={activeTab} index={0}>
            <FileUpload
              uploadedFiles={uploadedFiles} // Pass uploadedFiles instead of uploadedImages
              setUploadedFiles={setUploadedFiles} // Pass setUploadedFiles to allow updates
              onFileUpload={handleFileUpload}
              onSelectImage={handleSelectImage}
              selectedImage={selectedImage}
            />
            </CustomTabPanel>
  
            <CustomTabPanel value={activeTab} index={1}>
            <UploadFiles
              uploadedFiles={uploadedFiles}
              onSelectImage={handleSelectImage}
              selectedImage={selectedImage}
            />
            </CustomTabPanel>
          </Box>
        </Box>
  
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', m: 2 }}>
          <Button
            variant='contained'
            size='small'
            aria-label='Save'
            sx={{ mr: 1 }}
            onClick={handleSave}
            disabled={!selectedImage}
          >
            Save
          </Button>
          <Button 
            variant='text' 
            size='small' 
            aria-label='Cancel' 
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    );
  };

UploadImage.propTypes = {
  handleClose: PropTypes.func.isRequired,
  onImageSave: PropTypes.func.isRequired
};

export default UploadImage;
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    activeStatus: false,
};

const selectedQuestionDescriptionStatusSlice = createSlice({
    name: 'selectedQuestionDescriptionStatusSlice',
    initialState,
    reducers: {
        setSelectedQuestionDescriptionStatus: (state, action) => {
            state.activeStatus = Boolean(action.payload);
        },
    }
});

export const {
    setSelectedQuestionDescriptionStatus,
} = selectedQuestionDescriptionStatusSlice.actions;

export default selectedQuestionDescriptionStatusSlice.reducer;
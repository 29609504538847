import { Box, Typography } from "@mui/material";
import React, { useContext } from "react";
import SurveyEndUserContext from "./SurveyEndUserContext";
import { questionTypeConst } from "../../constants/questionTypeConst";

function QuestionComponent({ questionData, ...props }) {
  const surveyEndUserContext = useContext(SurveyEndUserContext);
//   console.debug(
//     "SurveyEndUser : QuestionWrapper : QuestionComponent : ;surveyEndUserContext : ",
//     surveyEndUserContext
//   );
//   console.debug(
//     "SurveyEndUser : QuestionWrapper : QuestionComponent : ;surveyEndUserContext : ",
//     surveyEndUserContext
//   );

  return (
    <Box sx={{px:"1rem",backgroundColor:surveyEndUserContext?.theme?.question?.backgroundColor}}>
      {/* <Box sx={{ display: "flex"}}>
        <Typography variant="subtitle1" 
            fontWeight={surveyEndUserContext?.theme?.question?.fontWeight}
            fontFamily={surveyEndUserContext?.theme?.question?.fontFamily}
        >
          {questionData.questionSequence
            ? `${questionData.questionSequence}-${questionData.question}`
            : questionData.question}
        {questionData?.questionRequired === 1 && (
            <Typography variant="subtitle1" fontWeight={"bold"} sx={{ marginLeft: "4px"}}>
                *
            </Typography>
          )}
        </Typography>
      </Box> */}
      <Box sx={{ display: "flex", alignItems: "center" }}>
  <Typography
    variant="subtitle1"
    fontWeight={surveyEndUserContext?.theme?.question?.fontWeight}
    fontFamily={surveyEndUserContext?.theme?.question?.fontFamily}
  >
    {questionData.questionSequence
      ? `${questionData.questionSequence}-${questionData.question}`
      : questionData.question}
    {questionData?.questionRequired === 1 && (
      <Box component="span" fontWeight="bold" sx={{ml:0.3, color:'red'}}>
        *
      </Box>
    )}
  </Typography>
</Box>

        {questionData?.questionDescription?.description && (
            <Box sx={{ marginTop: "4px" }} >
                <Typography variant="subtitle1" fontWeight={"bold"}>
                {questionData.questionDescription.description}
                </Typography>
            </Box>
        )}
         {props?.error && (!(questionData?.questionType?.identifier===questionTypeConst.contactInfo)) && (
            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
              {props?.error}
            </Typography>
          )}

    </Box>
  );
}

export default QuestionComponent;

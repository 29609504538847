import React from 'react'
import {
    Box, Button, TextField, Tab, Tabs, Typography, styled, alpha, useTheme, Drawer, Card, CssBaseline, IconButton, Avatar, InputBase, Menu,
    AppBar as MuiAppBar, Toolbar, Divider, ListItemIcon, ToggleButtonGroup, MenuItem, Paper,
    AccountCircle as AccountCircleIcon, Collections as CollectionsIcon, Undo as UndoIcon, Redo as RedoIcon, Remove as RemoveIcon, Add as AddIcon, Search as SearchIcon, Delete as DeleteIcon,
    Image as ImageIcon, TextFields as TextFieldsIcon, ChangeHistoryTwoTone as ChangeHistoryTwoToneIcon, ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon, ContentCopy as ContentCopyIcon,
    Home as HomeIcon, Preview as PreviewIcon, Download as DownloadIcon, Circle as CircleIcon, Rectangle as RectangleIcon, EggTwoTone, Star as StarIcon, Logout, Settings, FormatColorFill
  } from '../../lib/utils/AllImportsHelper';

const ShareWebPage = () => {
  return (
    <Box>ShareWebPage</Box>
  )
}

export default ShareWebPage
import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import StayCurrentLandscapeIcon from '@mui/icons-material/StayCurrentLandscape';
import StayCurrentPortraitIcon from '@mui/icons-material/StayCurrentPortrait';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import AodIcon from '@mui/icons-material/Aod';
import ComputerIcon from '@mui/icons-material/Computer';
import RemoveFromQueueIcon from '@mui/icons-material/RemoveFromQueue';
import DvrIcon from '@mui/icons-material/Dvr';
import PersonalVideoIcon from '@mui/icons-material/PersonalVideo';

const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color: 'rgb(55, 65, 81)',
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
      ...theme.applyStyles('dark', {
        color: theme.palette.grey[300],
      }),
    },
  }));

const LayoutSelection = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const [anchorE2, setAnchorE2] = React.useState(null);
    const openMenu = Boolean(anchorE2);
    const handleMenuClick = (event) => {
      setAnchorE2(event.currentTarget);
    };
    const handleMenuClose = () => {
      setAnchorE2(null);
    };
  
    return (
        <Box sx={{ backgroundColor: '#ECEFF1', p: 1, my: 1, borderRadius: 2 }}>
            <Box><Typography variant="subtitle2">Layout</Typography></Box>
            <Box sx={{ my: 1 }}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={8} >
                        <Typography variant="subtitle2" >Mobile</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            id="demo-customized-button"
                            aria-controls={open ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            variant="outlined"
                            disableElevation
                            onClick={handleClick}
                            endIcon={<KeyboardArrowDownIcon />}
                            size="small"
                            sx={{
                                color: '#6c6e6f', 
                                borderColor: '#6c6e6f' 
                              }}
                        >
                            <PhoneAndroidIcon />
                        </Button>
                        <StyledMenu
                            id="demo-customized-menu"
                            MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                            }}
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleClose} disableRipple>
                                <StayCurrentLandscapeIcon/> Horizontal
                            </MenuItem>
                            <MenuItem onClick={handleClose} disableRipple>
                                <StayCurrentPortraitIcon/> Vertical
                            </MenuItem>
                            <MenuItem onClick={handleClose} disableRipple>
                                <AdUnitsIcon/> Top
                            </MenuItem>
                            <MenuItem onClick={handleClose} disableRipple>
                                <AodIcon/> Bottom
                            </MenuItem>
                        </StyledMenu>

                    </Grid>
                </Grid>
            </Box>
            <Box>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={8} >
                        <Typography variant="subtitle2">Desktop</Typography>
                    </Grid>
                    <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                            id="demo-customized-button"
                            aria-controls={openMenu ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={openMenu ? 'true' : undefined}
                            variant="outlined"
                            disableElevation
                            onClick={handleMenuClick}
                            endIcon={<KeyboardArrowDownIcon />}
                            size="small"
                            sx={{
                                color: '#6c6e6f', 
                                borderColor: '#6c6e6f',
                              }}
                        >
                            <PersonalVideoIcon />
                        </Button>
                        <StyledMenu
                            id="demo-customized-menu"
                            MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                            }}
                            anchorEl={anchorE2}
                            open={openMenu}
                            onClose={handleMenuClose}
                        >
                            <MenuItem onClick={handleMenuClose} disableRipple>
                                <ComputerIcon/> Horizontal
                            </MenuItem>
                            <MenuItem onClick={handleMenuClose} disableRipple>
                                <RemoveFromQueueIcon/> Center
                            </MenuItem>
                            <MenuItem onClick={handleMenuClose} disableRipple>
                                <DvrIcon/> Bottom
                            </MenuItem>
                        </StyledMenu>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default LayoutSelection
import React,{useState} from 'react';
import { Box, Button, FormControl, FormControlLabel, FormLabel, Grid, IconButton, Radio, RadioGroup, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import FormHelper from '../../../src/component/form/FormHelper';
import RenderHelper from '../../../src/component/form/ReactiveForm';
import { surveyOrigin } from '../../constants/surveyOrigin';

const CreateWorkflowdetails = ({ onClose, onCreateHandler }) => {

    const [formValues, setFormValues] = useState({
        name: '',
        description: '',
        workflowType: '0'
      });
    
      const handleFieldChange = (value, modelKey) => {
        setFormValues(prevValues => ({
          ...prevValues,
          [modelKey]: value
        }));
      };
    
      const handleCloseDrawer = () => {
        if (onClose) onClose();
      };
    
      const handleSubmitDrawer = () => {
        onCreateHandler(null, { 
          identifier: surveyOrigin.manual, 
          ...formValues 
        });
      };

      const handleRadioChange = (e) => {
        const newValue = e.target.value;
        console.log('Selected Radio Value:', newValue); 
        setFormValues(prevValues => ({
          ...prevValues,
          workflowType: newValue
        }));
      };
    
      let JobSeoInformationModel = [
        { label: 'Name', type: "textField", size: 'small', value: formValues.name, validators: [{ name: 'required' }, { name: 'pattern', regex: /^[A-Za-z]+$/, errorMessage: 'Field must only contain alphabets' }] },
        { label: 'Description', type: "textField", size: 'small', value: formValues.description, multiline: true, rows: 4, validators: [{ name: 'pattern', regex: /^[A-Za-z]+$/, errorMessage: 'Field must only contain alphabets' }] },
      ];
//   let JobSeoInformationHelper = new FormHelper({ model: JobSeoInformationModel, id: 'JobSeoInformation' });

  return (
    <Box sx={{ width: '30rem' }}>
      <Box sx={{ backgroundColor: '#E1EDFC' }}>
        <Grid container spacing={2} sx={{ p: 1 }}>
          <Grid item xs={8}>
            <Box sx={{ mt: 1 }}>
              <Typography variant="subtitle1" fontWeight="bold">Create Workflow Detail</Typography>
            </Box>
          </Grid>
          <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Box>
              <IconButton aria-label="close" onClick={handleCloseDrawer}>
                <ClearIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ p: 1, my: 2, height: 'calc(100vh - 145px)' }}>
        <Box component="form" sx={{ display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)', width: "100%", rowGap: "1rem", columnGap: "1rem" }}>
          {/* <RenderHelper {...JobSeoInformationHelper.model} /> */}
          <RenderHelper 
            model={JobSeoInformationModel} 
            id='JobSeoInformation' 
            onChange={(value, index) => handleFieldChange(value, JobSeoInformationModel[index]?.label.toLowerCase())} 
          />

        </Box>
        <Box sx={{ my: 2, p: 1 }}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Flow of survey</FormLabel>
              <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={formValues.workflowType}
              onChange={handleRadioChange} 
              name="radio-buttons-group"
            >
              <FormControlLabel value="1" control={<Radio />} label="Workflow" />
              <FormControlLabel value="0" control={<Radio />} label="Without Workflow" />
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
      <Box sx={{ p: 1, display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant='text' onClick={handleCloseDrawer} aria-label="Cancel" size='small'>Cancel</Button>
        <Button variant="contained" onClick={handleSubmitDrawer} aria-label="Submit" size='small'>Submit</Button>
      </Box>
    </Box>
  );
};

export default CreateWorkflowdetails;

import { Box, TextField } from '@mui/material';
import { useState, useEffect } from 'react';
import React from 'react';
import { Table } from '../../lib';  // Adjust the import as needed for your project

const CreateTable = ({ modelName, description, level, area, practiceCriteria, areaExample,setTableData  }) => {
    // console.debug('a1', [modelName, description, level, area, practiceCriteria, areaExample,setTableData ]);

    const [selectedRows, setSelectedRows] = useState([]);
    const [columnsHeadings, setColumnsHeadings] = useState([]);
    const [dummyData, setDummyData] = useState([]);
    useEffect(() => {
        const generateColumns = () => {
            const columns = [
                {
                    id: 'level',
                    label: 'Level',
                    name: 'level',
                    selector: row => (
                        <TextField
                            placeholder={`Group ${row.id}`}
                            value={row.level || `Group ${row.id}`}
                            onChange={(e) => handleInputChange(row.id, 'level', e.target.value)}
                            size='small'
                        />
                    ),
                    minWidth: 100,
                }
            ];

            for (let i = 1; i <= level; i++) {
                columns.push({
                    id: `level_${i}`,
                    label: `Level ${i}`,
                    name: `level_${i}`,
                    selector: row => (
                        <TextField
                            placeholder='Description'
                            value={row[`level_${i}`] || ''}
                            onChange={(e) => handleInputChange(row.id, `level_${i}`, e.target.value)}
                            size='small'
                        />
                    ),
                    minWidth: 150,
                });
            }
            return columns;
        };

        const generateRows = () => {
            return Array.from({ length: area }, (_, index) => ({
                id: index + 1,
                level: `Group ${index + 1}`,
                ...Array.from({ length: level }, (_, i) => ({
                    [`level_${i + 1}`]: '',
                })).reduce((acc, curr) => ({ ...acc, ...curr }), {})
            }));
        };

        setColumnsHeadings(generateColumns());
        setDummyData(generateRows());
    }, [level, area]);

    useEffect(() => {
        setTableData(dummyData);
    }, [dummyData, setTableData]);

    const handleInputChange = (id, field, value) => {
        setDummyData(prevData => {
            const updatedData = prevData.map(row =>
                row.id === id ? { ...row, [field]: value } : row
            );
            return updatedData;
        });
    };

    const handleRowSelected = (selectedRow) => {
        setSelectedRows(selectedRow);
    };

    const rowStyles = {
        backgroundColor: '#ECEFF1',
    };

    return (
        <Box sx={{ p: 2 }}>
            <Table
                className='assessmentTable'
                columns={columnsHeadings}
                data={dummyData}
                onSelectedRowsChange={handleRowSelected}
                pagination={false}
                customStyles={{
                    rows: {
                        style: rowStyles,
                    },
                }}
            />
        </Box>
    );
};

export default CreateTable;

import React, { useEffect, useState } from 'react';
import CanvasWrapper from '../../component/layoutWrapper/CanvasWrapper';
import { CanvasSubLayout } from '../../lib';
import SettingList from '../../component/surveySettings/SettingList';
import { Security } from '@mui/icons-material';
import ShareLink from '../../component/share/ShareLink';
import ShareWebPage from '../../component/share/ShareWebPage';
import ApiService from '../../ApiService';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateSurvey, addArrayOfQuestionsInSurveyQuestionList, } from '../../redux/slice/surveyQuetions/surveyQuestionSlice';
import { modeBasedDataProcessor } from '../../lib/utils/developmentMode';

function SurveyShare(props) {
    let { id } = useParams();
    const dispatch = useDispatch();
    const surveyQuestion = useSelector(state => state.surveyQuestion);
    const itemsList = [
        {
            id: "1", name: "Share the link", icon: <Security />, component: <ShareLink />
        },
        ...modeBasedDataProcessor([{
            id: "2", name: "Embeded in the web page", icon: <Security />, component: <ShareWebPage />
        }]),
        // {
        //     id: "2", name: "Embeded in the web page", icon: <Security />, component: <ShareWebPage />
        // },
    ];
    const [selectedItem, setSelectedItem] = useState(itemsList[0]);


    const selectedItemChangeHandlder = (e, item) => {
        if (item) {
            setSelectedItem(item);
        }
    }


    useEffect(function () {
        if (id) {
            // console.debug("SurveyResult : useEffect :surveyQuestion :", surveyQuestion);
            if (surveyQuestion.survey == null) {
                ApiService.get('surveyView', {}, id).then(r => {
                    console.debug("SurveyCreate : useEffect :then : r : ", r);
                    // setData(r.data.data)
                    if (r.data?.data) {
                        let surveyResponce = r.data.data;
                        console.debug("SurveyCreate : useEffect :then : surveyResponce : ", surveyResponce);
                        if (surveyResponce) {
                            dispatch(updateSurvey(surveyResponce));
                            dispatch(addArrayOfQuestionsInSurveyQuestionList([...surveyResponce.surveyQuestion]))
                            // setSelectedQuestionItem(surveyResponce?.surveyQuestion?.[0]);
                        }
                        else
                            console.error("APi response issue");
                    }
                    // return ({ data: r.data.data, page: r.data.meta.current_page, total: r.data.meta.total });
                }).catch(e => {
                    console.debug("SurveyCreate : useEffect :catch : e : ", e);
                    // navigate to 404 page
                    // or error
                });
            }
        } else {
            // navigate to 404 page
        }
    }, []);

    return (
        <CanvasWrapper>
            <CanvasSubLayout
                drawerComp={
                    <SettingList
                        itemsList={itemsList}
                        selectedItem={selectedItem}
                        selectedItemChangeHandlder={selectedItemChangeHandlder}
                    />
                }

                drawerHeaderTitle={"Menu"}
            >
                {selectedItem?.component}
            </CanvasSubLayout>
        </CanvasWrapper>
    );
}

export default SurveyShare;
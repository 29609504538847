import { useState } from 'react';
import { validateRequiredQuestions } from '../utils/validateRequiredQuestions';

/**
 * useFormValidation Hook
 * @param {Array} questions - List of all questions in the form.
 * @param {Object} answers - Object containing user answers keyed by question ID.
 * 
 * Returns an object with the current validation errors and a function to validate the form.
 */
export const useFormValidation = (questions, answers) => {
  const [errors, setErrors] = useState({});

  /**
   * validate
   * Checks if all required questions are answered and updates the error state.
   * @returns {boolean} - Returns true if the form is valid, false otherwise.
   */
  const validate = () => {
    const validationErrors = validateRequiredQuestions(questions, answers);
    // console.debug("SurveyEndUser : QuestionWrapperWithWorkflow : nextProcessHandler : useFormValidation hook : validationErrors : ",validationErrors);
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0; // Returns true if no errors
  };
  // console.debug("SurveyEndUser : QuestionWrapperWithWorkflow : nextProcessHandler : useFormValidation hook : errors : ",errors);

  return { errors, validate }; // Expose errors and validate function
};

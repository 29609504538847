import React, { memo, useEffect, useState } from 'react';
import { TextField, Button, MenuItem, Box, Typography, FormControl } from '@mui/material';
import EndUserFooterButton from '../../../surveyEndUser/EndUserFooterButton';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';
import { contactInfoField } from '../../../../constants/questionTypeConst';
import { REQUIRED_FIELD_ERROR } from '../../../../constants/formMessages';
import { REQUIRED_VALID_ERROR } from '../../../../constants/formMessages';


// const ContactInfo = (props) => {
  // console.debug("QuestionInputTypes : ContactInfo : props : ", props);
// import { TextField, Box, FormGroup, FormControlLabel, Checkbox } from '@mui/material';

const ContactInfo = (props) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [company, setCompany] = useState('');

  const [isLabel1Checked, setIsLabel1Checked] = useState(false);
  const [isLabel2Checked, setIsLabel2Checked] = useState(false);

  const [hasError, setHasError] = useState({}); // Example error state

  useEffect(function () {
    // console.debug("QuestionInputTypes : ContactInfo : useEffect : props : ", props);

    if (props?.enduser == undefined && props.enduser == false) {
      setFirstName('Jane')
      setLastName('Smith');
      setPhoneNumber('(201) 555-0123');
      setEmail('name@example.com');
      setCompany('Acme Corporation');
    }

    if(props?.error !== undefined){
      const tempError=JSON.parse(props?.error);
      // console.debug("QuestionInputTypes : ContactInfo : props?.error : ", tempError);
      setHasError((tempError))
    }else{
      setHasError({})
    }
  }, [props?.error]);

  function updateErrorState(errorFieldKey) {
    if(hasError[errorFieldKey]){
      let updatedValue={};
      updatedValue[errorFieldKey]=false;
      let newData={...hasError,...updatedValue};
      delete newData[errorFieldKey];
      // console.debug("QuestionInputTypes : ContactInfo : nameChangeHandler : newData : ", newData);
      setHasError(newData)
    }
  }

  function firstNameChangeHandler(e) {
    let targetValue = e.target.value.trimStart();
    setFirstName(targetValue);
    // contactInfoSubmitHandler();
  }

  function nameChangeHandler(e) {
    let targetValue = e.target.value.trimStart();
    setName(targetValue);
    if(hasError && targetValue.length>0){
      updateErrorState(contactInfoField?.name);
    }
  }

  function lastNameChangeHandler(e) {
    let targetValue = e.target.value.trimStart();
    setLastName(targetValue);
    if(hasError && targetValue.length>0){
      updateErrorState(contactInfoField?.lastName);
    }
  }

  function phoneNumberChangeHandler(e) {
    let targetValue = e.target.value.trimStart();
    setPhoneNumber(targetValue);
    if(hasError && targetValue.length>0){
      updateErrorState(contactInfoField?.phoneNumber);
    }
  }

  // function emailChangeHandler(e) {
  //   let targetValue = e.target.value;
  //   setEmail(targetValue);
  //   if(hasError && targetValue.length>0){
  //     updateErrorState(contactInfoField?.email);
  //   }
  // }

  function emailChangeHandler(e) {
    let targetValue = e.target.value;
    setEmail(targetValue);

    // Email validation pattern
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    
    if (hasError && targetValue.length > 0) {
        updateErrorState(contactInfoField?.email);
    }

    // Check if the email matches the pattern
    if (!emailPattern.test(targetValue)) {
      setHasError((prevErrors) => ({
            ...prevErrors,
            [contactInfoField?.email]: "valid-value",
        }));
    } else {
      setHasError((prevErrors) => ({
            ...prevErrors,
            [contactInfoField?.email]: null,
        }));
    }
}


  // function emailChangeHandler(e) {
  //   let targetValue = e.target.value.trimStart();

  //   // Email regex pattern
  //   const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  //   const isValidEmail = emailPattern.test(targetValue);

  //   setEmail(targetValue);

  //   if (!isValidEmail && targetValue.length > 0) {
  //     setHasError((prevErrors) => ({
  //       ...prevErrors,
  //       [contactInfoField.email]: "Please enter a valid email",
  //     }));
  //   } else if (targetValue.length === 0) {
  //     setHasError((prevErrors) => ({
  //       ...prevErrors,
  //       [contactInfoField.email]: REQUIRED_FIELD_ERROR,
  //     }));
  //   } else {
  //     updateErrorState(contactInfoField.email);
  //   }
  // }
  

  function companyChangeHandler(e) {
    let targetValue = e.target.value.trimStart();
    setCompany(targetValue);
    if(hasError && targetValue.length>0){
      updateErrorState(contactInfoField?.company);
    }
  }

  const handleLabel1Change = (event) => {
    setIsLabel1Checked(event.target.checked);
  };

  const handleLabel2Change = (event) => {
    setIsLabel2Checked(event.target.checked);
    if(hasError && event.target.checked===true){
      updateErrorState(contactInfoField?.terms);
    }
  };

  useEffect(function () {
    contactInfoSubmitHandler();
  }, [name, firstName, lastName, phoneNumber, email, company, isLabel1Checked, isLabel2Checked]);
  
  function contactInfoSubmitHandler() {
    let contactInfo = {};
    if (name) {
      contactInfo[contactInfoField?.name] = name;
    }
    if (firstName) {
      contactInfo[contactInfoField?.firstName] = firstName;
    }
    if (lastName) {
      contactInfo[contactInfoField?.firstName] = lastName;
    }
    if (phoneNumber) {
      contactInfo[contactInfoField?.phoneNumber] = phoneNumber;
    }
    if (email && !hasError[contactInfoField.email]) {
      contactInfo[contactInfoField?.email] = email;
      
    }
    // if (email) {
    //   contactInfo[contactInfoField?.email] = email;
    // }
    if (company) {
      contactInfo[contactInfoField?.company] = company;
    }
     // Add label1 and label2 values to the contactInfo object
    contactInfo[contactInfoField?.receiveCaseStudies] = isLabel1Checked;
    contactInfo[contactInfoField?.terms] = isLabel2Checked;
    let stringContactInfo = JSON.stringify(contactInfo);
    if (props?.onAnswerSelect) {
      props.onAnswerSelect(stringContactInfo);
      // console.debug("SurveyEndUser : QuestionInputTypes : ContactInfo : contactInfoSubmitHandler : contactInfo : ", contactInfo);
    }

  }
  // const [isLabel1Checked, setIsLabel1Checked] = useState(false);
  // const [isLabel2Checked, setIsLabel2Checked] = useState(false);
  
  // Validation states
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [companyError, setCompanyError] = useState(false);
  const [checkboxError, setCheckboxError] = useState(false);

  // // Input field change handlers
  // const nameChangeHandler = (e) => {
  //   setName(e.target.value);
  //   if (!e.target.value) {
  //     setNameError(true);
  //   } else {
  //     setNameError(false);
  //   }
  // };

  // const emailChangeHandler = (e) => {
  //   const emailValue = e.target.value;
  //   setEmail(emailValue);
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email validation
  //   if (!emailValue || !emailRegex.test(emailValue)) {
  //     setEmailError(true);
  //   } else {
  //     setEmailError(false);
  //   }
  // };

  // const companyChangeHandler = (e) => {
  //   setCompany(e.target.value);
  //   if (!e.target.value) {
  //     setCompanyError(true);
  //   } else {
  //     setCompanyError(false);
  //   }
  // };

  // const handleLabel1Change = (event) => {
  //   setIsLabel1Checked(event.target.checked);
  //   setCheckboxError(!event.target.checked && !isLabel2Checked); // If none of the checkboxes are checked, show error
  // };

  // const handleLabel2Change = (event) => {
  //   setIsLabel2Checked(event.target.checked);
  //   setCheckboxError(!event.target.checked && !isLabel1Checked); // Same logic for second checkbox
  // };

  // Validate form fields
  // const isFormValid = () => {
  //   let isValid = true;

  //   if (!name) {
  //     setNameError(true);
  //     isValid = false;
  //   }
  //   if (!email || emailError) {
  //     setEmailError(true);
  //     isValid = false;
  //   }
  //   if (!company) {
  //     setCompanyError(true);
  //     isValid = false;
  //   }
  //   if (!isLabel1Checked && !isLabel2Checked) {
  //     setCheckboxError(true);
  //     isValid = false;
  //   }

  //   return isValid;
  // };

  // Submit handler
  // const contactInfoSubmitHandler = () => {
  //   if (!isFormValid()) return; // Block submission if form is invalid

  //   let contactInfo = {
  //     name,
  //     email,
  //     company,
  //     label1: isLabel1Checked,
  //     label2: isLabel2Checked,
  //   };

  //   let stringContactInfo = JSON.stringify(contactInfo);
  //   if (props?.onAnswerSelect) {
  //     props.onAnswerSelect(stringContactInfo);
  //     // console.debug("SurveyEndUser : QuestionInputTypes : ContactInfo : contactInfoSubmitHandler : contactInfo : ", contactInfo);
  //   }
  // }


  return (
    <Box >
      <Box>
        {/* <TextField sx={{marginTop:"1rem"}} onChange={firstNameChangeHandler} label="First Name"  size='small' value={firstName} fullWidth/> */}
        <Box>
          <TextField error={!!hasError?.[contactInfoField?.name]}
            sx={{ marginTop: "1rem" }} onChange={nameChangeHandler} label="Name" size='small' value={name} fullWidth />
            {hasError?.[contactInfoField?.name] && (
              <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                  {REQUIRED_FIELD_ERROR}
              </Typography>
            )}
      </Box>

      <Box>
          <TextField error={!!hasError?.[contactInfoField?.email]} sx={{ marginTop: "1rem" }} onChange={emailChangeHandler} label="Email" size='small' value={email} fullWidth />
          {hasError?.[contactInfoField?.email] && (
        <Typography variant="body2" color="error" sx={{ mt: 1 }}>
            {hasError[contactInfoField?.email] === "valid-value" 
                ? REQUIRED_VALID_ERROR 
                : REQUIRED_FIELD_ERROR}
        </Typography>
    )}
        </Box>

      {/* <Box>
    <TextField
      error={!!hasError?.[contactInfoField?.email]} // Dynamically set error state
      sx={{ marginTop: "1rem" }}
      onChange={emailChangeHandler}
      label="Email"
      size="small"
      value={email}
      fullWidth
    />
    {hasError?.[contactInfoField?.email] && (
      <Typography variant="body2" color="error" sx={{ mt: 1 }}>
        {hasError[contactInfoField?.email]}
      </Typography>
    )}
  </Box> */}


        <Box>
          <TextField error={!!hasError?.[contactInfoField?.company]} sx={{ marginTop: "1rem" }} onChange={companyChangeHandler} label="Organization" size='small' value={company} fullWidth />
          {hasError?.[contactInfoField?.company] && (
            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
              {REQUIRED_FIELD_ERROR}        
            </Typography>
          )}
        </Box>

        {/* <TextField sx={{marginTop:"1rem"}} onChange={lastNameChangeHandler} label="Last Name"  size='small' value={lastName} fullWidth/> */}
        {/* <TextField sx={{marginTop:"1rem"}} onChange={phoneNumberChangeHandler} label="Phone Number"  size='small'value={phoneNumber} fullWidth/> */}
      </Box>
      <Box sx={{mt:2  }}>
      <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={isLabel1Checked} onChange={handleLabel1Change} />}
              label="Would you like the receive the case studies, whitepaper, announcements, and other relevant updates about products and services,events and webniars from Mastech InfoTrellis? You may unsubscribe from these communications at any time."
            />

          <FormControl>
            <FormControlLabel
              control={<Checkbox checked={isLabel2Checked} onChange={handleLabel2Change} />}
              label={
                <Box 
                // sx={{display:'flex'}}
                >
                  I have read {''}
                  <a href="https://mastechinfotrellis.com/privacy-policy" target="_blank" rel="noopener noreferrer"> 
                    Mastech InfoTrellis Privacy Policy
                  </a>{' '}
                   and agree to the {''}
                  <a href="https://mastechinfotrellis.com/term-of-use" target="_blank" rel="noopener noreferrer">
                  Terms and Conditions
                  </a>{''}
                  *
                </Box>
              }
            />
            
            {hasError?.[contactInfoField?.terms] && (
              <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                {hasError?.[contactInfoField?.terms]}
              </Typography>
            )}
          </FormControl>
        </FormGroup>
      </Box>
    </Box>
  );
};

export default memo(ContactInfo);

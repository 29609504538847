import React from 'react'
// import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
    Box, Button, Grid, TextField, Tab, Tabs, Typography, styled, alpha, useTheme, Drawer, Card, CssBaseline, IconButton, Avatar, InputBase, Menu,
    AppBar as MuiAppBar, Toolbar, Divider, ListItemIcon, ToggleButtonGroup, MenuItem, Paper,
    AccountCircle as AccountCircleIcon, Collections as CollectionsIcon, Undo as UndoIcon, Redo as RedoIcon, Remove as RemoveIcon, Add as AddIcon, Search as SearchIcon, Delete as DeleteIcon,
    Image as ImageIcon, TextFields as TextFieldsIcon, ChangeHistoryTwoTone as ChangeHistoryTwoToneIcon, ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon, ContentCopy as ContentCopyIcon,
    Home as HomeIcon, Preview as PreviewIcon, Download as DownloadIcon, Circle as CircleIcon, Rectangle as RectangleIcon, EggTwoTone, Star as StarIcon, Logout, Settings, FormatColorFill
} from '../../lib/utils/AllImportsHelper';

const Integrations = () => {
    return (
        <Box>
            <Card sx={{borderTopLeftRadius:'1rem',borderTopRightRadius:'1rem' }}>
                <Box sx={{ backgroundColor: '#E1EDFC', p: 1.2 }}>
                    <Typography variant="subtitle1" fontWeight="bold" >
                        Integrations
                    </Typography>
                </Box>
                <Box sx={{ p: 2 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Card>
                                <Box sx={{ p: 1 }}>
                                    <Box sx={{ display: 'flex' }}>
                                        <Box>sdfg</Box>
                                        <Box sx={{ ml: 1 }}><Typography variant="subtitle1" >
                                            Hubspot
                                        </Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{mt:1}}>
                                        Send contact, company, or deal info to HubSpot to quickly follow up on new leads or update existing details to your free HubSpot account.
                                    </Box>
                                </Box>
                                <Divider />
                                <Box sx={{ p: 1, display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button variant="text" endIcon={<ArrowForwardIcon  size="small"/>}>
                                        Connect
                                    </Button>
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <Card>
                                <Box sx={{ p: 1 }}>
                                    <Box sx={{ display: 'flex' }}>
                                        <Box>sdfg</Box>
                                        <Box sx={{ ml: 1 }}><Typography variant="subtitle1" >
                                            Hubspot
                                        </Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{mt:1}}>
                                        Send contact, company, or deal info to HubSpot to quickly follow up on new leads or update existing details to your free HubSpot account.
                                    </Box>
                                </Box>
                                <Divider />
                                <Box sx={{ p: 1, display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button variant="text" endIcon={<ArrowForwardIcon  size="small"/>}>
                                        Connect
                                    </Button>
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <Card>
                                <Box sx={{ p: 1 }}>
                                    <Box sx={{ display: 'flex' }}>
                                        <Box>sdfg</Box>
                                        <Box sx={{ ml: 1 }}><Typography variant="subtitle1" >
                                            Hubspot
                                        </Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{mt:1}}>
                                        Send contact, company, or deal info to HubSpot to quickly follow up on new leads or update existing details to your free HubSpot account.
                                    </Box>
                                </Box>
                                <Divider />
                                <Box sx={{ p: 1, display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button variant="text" endIcon={<ArrowForwardIcon  size="small"/>}>
                                        Connect
                                    </Button>
                                </Box>
                            </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <Card>
                                <Box sx={{ p: 1 }}>
                                    <Box sx={{ display: 'flex' }}>
                                        <Box>sdfg</Box>
                                        <Box sx={{ ml: 1 }}><Typography variant="subtitle1" >
                                            Hubspot
                                        </Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{mt:1}}>
                                        Send contact, company, or deal info to HubSpot to quickly follow up on new leads or update existing details to your free HubSpot account.
                                    </Box>
                                </Box>
                                <Divider />
                                <Box sx={{ p: 1, display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button variant="text" endIcon={<ArrowForwardIcon  size="small"/>}>
                                        Connect
                                    </Button>
                                </Box>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Card>
        </Box>
    )
}

export default Integrations
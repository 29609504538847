import React, { useState } from 'react';
import { Box, Dialog, Grid, IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import UploadImage from '../../UploadImage';
import VideoLibraryOutlinedIcon from '@mui/icons-material/VideoLibraryOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { styled } from '@mui/material/styles';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

const ImageSelection = ({ onImageSelect }) => {
    const [open, setOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    console.debug('selectedImage : ImageSelection',selectedImage)
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleImageSelect = (image) => {
        setSelectedImage(image);
        onImageSelect(image); // Pass image to parent component
    };

    return (
        <Box sx={{ backgroundColor: '#ECEFF1', p: 1, my: 1, borderRadius: 2 }}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={8}>
                    <Typography variant="subtitle2">Image Selection</Typography>
                </Grid>
                <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {!selectedImage ? (
                        <IconButton aria-label="add" onClick={handleClickOpen} size="small">
                            <AddIcon />
                        </IconButton>
                    ) : (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton aria-label="video" onClick={handleClickOpen} size="small">
                                <VideoLibraryOutlinedIcon />
                            </IconButton>
                            <IconButton aria-label="tune" onClick={handleClickOpen} size="small">
                                <TuneOutlinedIcon />
                            </IconButton>
                            <IconButton aria-label="delete" onClick={() => setSelectedImage(null)} size="small">
                                <DeleteOutlineOutlinedIcon />
                            </IconButton>
                        </Box>
                    )}
                </Grid>
            </Grid>
            <BootstrapDialog onClose={handleClose} open={open}>
                <UploadImage handleClose={handleClose} onImageSave={handleImageSelect} />
                {/* <UploadImage
        handleClose={() => setIsUploadDialogOpen(false)}
        onImageSave={handleImageSelect} // Changed from onImageSelect to onImageSave
      /> */}
            </BootstrapDialog>
        </Box>
    );
};

export default ImageSelection;

import React from 'react';
import CanvasGenAIHeader from '../CanvasGenAI/CanvasGenAIHeader';
import { Box } from '@mui/material';

function index(props) {
    return (
        <Box sx={{ backgroundColor: '#DBEAFE', height: '100vh' }}>
            <Box sx={{ mb: 2 }}>
                <CanvasGenAIHeader
                    startToCustomiseClickHandler={props?.startToCustomiseClickHandler}
                    headerButtonDisabledStatus={props?.headerButtonDisabledStatus}
                />
            </Box>
            <Box sx={{ mx: 2 }}>
                {props?.children}
                {/* <CanvasGenAIBody /> */}
            </Box>
        </Box>
    );
}

export default index;
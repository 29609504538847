import React, { useContext, useEffect, useState } from "react";
import { Box, FormControl, TextField } from '../../../../lib/utils/AllImportsHelper';
import { FormHelperText, OutlinedInput } from "@mui/material";
import QuestionTypeContext from "../../../../lib/layouts/QuestionFormatLayout/QuestionTypeContext";
function ShortTextQues(props) {
  const questionContect = useContext(QuestionTypeContext)
  const [answer,setAnswer]=useState('')

  const quetionChangeHandler = (e) => {
    setAnswer(e.target.value);
    if (props?.onAnswerSelect !== undefined) {
      props.onAnswerSelect(e.target.value);
      // console.debug("SurveyEndUser : QuestionInputTypes : ShortTextQues : handleChange : if Block", e.target.value, props)
    }
  };

  const textFieldBlurHandlder = () => {
    questionContect?.setValue(answer);
  };

  const textFieldKeyDownHandler = (e) => {
    if (e.key === 'Enter') {
      textFieldBlurHandlder();
    }
  };
  return (
    <Box>
      <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
        <OutlinedInput
         size="small"
          id="outlined-adornment-weight"
          aria-describedby="outlined-weight-helper-text"
          multiline={true}
          readOnly={!questionContect}
          // rows={1}
          minRows={1}
          maxRows={4}
          value={answer}
          inputProps={{
            'aria-label': 'weight',
          }}
          onChange={quetionChangeHandler}
          onBlur={textFieldBlurHandlder}
          onKeyDown={textFieldKeyDownHandler}
          placeholder="Type your answer here"
        />
      </FormControl>
    </Box>
  );
}

export default ShortTextQues

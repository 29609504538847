import React, { useContext, useEffect, useState } from 'react';
import { Box, Divider, Grid } from '@mui/material';
import SourceQuestionHeader from './SourceQuestionHeader';
import SourceQuestionBody from './SourceQuestionBody';
import GenAIContext from './GenAIContext';
import ApiService from '../../ApiService';
import DestinationQuestionHead from './DestinationQuestionHead';
import DestinationQuestionBody from './DestinationQuestionBody';

const QuestionSetTable = (props) => {
    // const genAiContextData = useContext(GenAIContext);

    // // Source Item
    // const [sourceItemList, setSourceItemList] = useState([]);
    // const [selectedSourceRows, setSelectedSourceRows] = useState(new Set());

    // // useEffect(function () {
    // //     setSourceItemList(props.currentgeneratedResponseFromPrompt)
    // // }, [props.currentgeneratedResponseFromPrompt]);


    // useEffect(function () {
    //     if (genAiContextData?.sourceItemList)
    //         setSourceItemList(genAiContextData.sourceItemList)
    // }, [genAiContextData?.sourceItemList]);


    // function selectedSourceItemChangeHandler(selectedRow) {
    //     console.debug("QuestionSetTable : selectedSourceItemChangeHandler : selectedRows ", selectedRow);

    // }

    // const handleRowSelect = (rowId, row, e) => {
    //     const newSelectedRows = new Set(selectedSourceRows);
    //     // if (newSelectedRows.has(rowId)) {
    //     //     newSelectedRows.delete(rowId);
    //     // } else {
    //     //     newSelectedRows.add(rowId);
    //     // }

    //     if (newSelectedRows.has(row)) {
    //         newSelectedRows.delete(row);
    //     } else {
    //         newSelectedRows.add(row);
    //     }

    //     setSelectedSourceRows(newSelectedRows);
    //     // genAiContextData?.selectedSourceItemChangeHandler(Array.from(newSelectedRows));
    // };


    // // const handleSelectAllClick = (event) => {
    // //     console.debug("QuestionSetTable : handleSelectAllClick : event ", event);
    // //     console.debug("QuestionSetTable : handleSelectAllClick : selectedSourceItemList ", sourceItemList);

    // //     // const newSelectedRows = event.target.checked ? new Set(sourceItemList.map((row) => row.id)) : new Set();
    // //     const newSelectedRows = event.target.checked ? new Set(sourceItemList.map((row) => row)) : new Set();
    // //     console.debug("QuestionSetTable : handleSelectAllClick : newSelectedRows ", newSelectedRows);
    // //     setSelectedSourceRows(newSelectedRows);
    // //     // tableContextData?.onSelectedRowsChange(Array.from(newSelectedRows));
    // // };

    // // ./Source Item
    // // const [destinationItemList, setDestinationItemList] = useState([]);
    // const [destinationItemList, setDestinationItemList] = useState([]);
    // const [selectedDestinationItemList, setSelectedDestinationItemList] = useState([]);
    // const [selectedDestinationRows, setSelectedDestinationRows] = useState(new Set());



    // useEffect(function () {
    //     let formBody = {};
    //     formBody.promtMessage = "";
    //     // ApiService.post('generateSurveyQuestion',formBody).then(r=>{
    //     //     console.debug("QuestionSetTable : generateSurveyQuestion : then : r : ", r.data);
    //     // }).catch(e=>{
    //     //     console.debug("QuestionSetTable : generateSurveyQuestion : catch : e : ", e);

    //     // });

    //     let id = 326;
    //     // ApiService.get('surveyView', {}, id).then(r => {
    //     //     // console.debug("generateSurveyQuestion : useEffect :then : r : ", r);
    //     //     // setData(r.data.data)
    //     //     if (r.data?.data) {
    //     //         let surveyResponce = r.data.data;
    //     //         console.debug("generateSurveyQuestion : useEffect :then : surveyResponce : ", surveyResponce?.surveyQuestion);
    //     //         if (surveyResponce) {
    //     //             setSourceItemList(surveyResponce?.surveyQuestion);
    //     //             // setDestinationItemList(surveyResponce?.surveyQuestion);
    //     //             // dispatch(updateSurvey(surveyResponce));
    //     //             // dispatch(addArrayOfQuestionsInSurveyItemList([...surveyResponce.surveyQuestion]))
    //     //             // setSelectedQuestionItem(surveyResponce?.surveyQuestion?.[0]);
    //     //         }
    //     //         else
    //     //             console.error("APi response issue");
    //     //     }
    //     //     // return ({ data: r.data.data, page: r.data.meta.current_page, total: r.data.meta.total });
    //     // }).catch(e => {
    //     //     console.debug("generateSurveyQuestion : useEffect :catch : e : ", e);
    //     //     // navigate to 404 page
    //     //     // or error
    //     // });

    // }, []);

    // const [selectedMenuItem, setSelectedMenuItem] = useState('');

    // const genAiContextData = {
    //     selectedMenuItem: selectedMenuItem,
    //     sourceItemList: sourceItemList,
    //     selectedSourceItemChangeHandler: selectedSourceItemChangeHandler,
    //     selectedSourceRows: selectedSourceRows,
    //     handleRowSelect: handleRowSelect,
    //     // handleSelectAllClick: handleSelectAllClick,
    //     // handleMoveAllSelectedItemFromSourceToDestination: handleMoveAllSelectedItemFromSourceToDestination,
    //     // handleMoveClickedItemFromSourceToDestination: handleMoveClickedItemFromSourceToDestination,
    //     destinationItemList: destinationItemList,
    // }

    return (
        <>
            <Box sx={{ mt: 2, border: '1px solid ##2563EB' }}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <SourceQuestionHeader />
                        <Divider />
                        <SourceQuestionBody />
                    </Grid>
                    <Grid item xs={6} sx={{ pl: '0px !important' }}>
                        <DestinationQuestionHead />
                        <Divider />
                        <DestinationQuestionBody />
                    </Grid>
                </Grid>

            </Box>
        </>
    );
};

export default QuestionSetTable;

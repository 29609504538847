// src/StarRating.js
import React, { useContext, useState } from 'react';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import QuestionContext from '../../QuestionContext';

const RateQues = () => {
  const questionContextData = useContext(QuestionContext);
  console.debug('a1',QuestionContext)
 let maxStars=questionContextData?.selectedQuetionItem?.questionTypeRating?.ratings || 5 
  const [value, setValue] = useState("");
  console.debug('a10',value)
console.debug("a10", typeof(maxStars))
const handleRatingChange = (event, newValue) => {
  setValue(newValue);
  console.debug('a10', setValue(newValue));
  // if (onRatingChange) {
  //   onRatingChange(newValue); // Pass the value to the parent or context
  // }
};
  return (
    <Box
    >
      <Rating
        name="customized-rating"
        value={value}
        // readOnly
        onChange={handleRatingChange}
        sx={{fontSize:"2.5rem"}}
        max={Number(maxStars)}
      />
    </Box>
  );
};

export default RateQues;

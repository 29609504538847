import React, { useEffect, useState } from 'react';
import CanvasWrapper from '../../component/layoutWrapper/CanvasWrapper';
import { CanvasSubLayout } from '../../lib';
import SettingList from '../../component/surveySettings/SettingList';
import { Security } from '@mui/icons-material';
import routes from '../../routesComponent/Routes';
import SurveySettings from '../../component/surveySettings/SurveySettings';
import PublishSettings from '../../component/surveySettings/PublishSettings';
import ApiService from '../../ApiService';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { updateSurvey, addArrayOfQuestionsInSurveyQuestionList, toggleSurveyLoadingStatus, setSurveyError, } from '../../redux/slice/surveyQuetions/surveyQuestionSlice';
import SeoSettings from '../../component/surveySettings/SeoSettings';

function Setting(props) {
    const selectedItemChangeHandlder = (e, item) => {
        if (item) {
            setSelectedItem(item);
        }
    }
    let { id } = useParams();
    const dispatch = useDispatch();
    const surveyQuestion = useSelector(state => state.surveyQuestion);


    const itemsList = [
        {
            id: "1", name: "Survey Settings", icon: <Security />, path: routes.dashboard, component: <SurveySettings />
        },
        {
            id: "2", name: "Publish Settings", icon: <Security />, path: routes.surveyList, component: <PublishSettings />
        },
        {
            id: "3", name: "SEO", icon: <Security />, path: routes.surveyList, component: <SeoSettings />
        },
    ];
    const [selectedItem, setSelectedItem] = useState(itemsList[0]);

    

    useEffect(function () {
        if (id) {
            if (surveyQuestion.survey == null) {
                const status = dispatch(toggleSurveyLoadingStatus());
                console.log('a1',status);
                // let dataParams = {}
               let related = [ 'reportSettingSurvey','surveySeo']
            //    let related = [ 'reportSettingSurvey'] 
                ApiService.get('surveyView', {related}, id).then(r => {
                    console.debug("SurveyCreate : useEffect :then : r : ", r);
                    // setData(r.data.data)
                    const status = dispatch(toggleSurveyLoadingStatus());
                    console.log('a1',status);
                    if (r.data?.data) {
                        let surveyResponce = r.data.data;
                        console.debug("SurveyCreate : useEffect :then : surveyResponce : ", surveyResponce);
                        if (surveyResponce) {
                            dispatch(updateSurvey(surveyResponce));
                            dispatch(addArrayOfQuestionsInSurveyQuestionList([...surveyResponce.surveyQuestion]))
                            // setSelectedQuestionItem(surveyResponce?.surveyQuestion?.[0]);
                        }
                        else
                            console.error("APi response issue");
                    }
                    // return ({ data: r.data.data, page: r.data.meta.current_page, total: r.data.meta.total });
                }).catch(e => {
                    const status = dispatch(toggleSurveyLoadingStatus());
                    console.log('a1',status);
                    dispatch(setSurveyError({...e}));
                    // navigate to 404 page
                    // or error
                });
            }
        } else {
            // navigate to 404 page
        }
    }, []);


    return (
        <CanvasWrapper>
            <CanvasSubLayout
                drawerComp={
                    <SettingList
                        itemsList={itemsList}
                        selectedItem={selectedItem}
                        selectedItemChangeHandlder={selectedItemChangeHandlder}
                    />
                }
                drawerHeaderTitle={"Menu"}>
                {selectedItem?.component}
            </CanvasSubLayout>
        </CanvasWrapper>
    );
}

export default Setting;
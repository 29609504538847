import React  from 'react'
import { Box } from '@mui/material'
import { ComboBox } from '../../../../lib'
// import { useDispatch, useSelector } from 'react-redux';

const DropDownEnduserQues = (props) => {
    // console.debug('question : DropDownEnduserQues : ', props)
    // let dispatch = useDispatch();
    // const [options, setOptions] = useState([]);
    // const questionContextData = useContext(QuestionContext);
    // const surveyQuestion = useSelector(state => state.surveyQuestion);
    // const selectedQuestionDropdownSlice = useSelector(state => state.selectedQuestionDropdown);
    // const selectedQuestionDropdownSlice = useSelector(state => state.selectedQuestionDropdown?.dropDown);
    // console.debug('question : DropDownEnduserQues : ', surveyQuestion)

    const handleComboBoxChange = (selectedItem) => {
        // console.debug('question : ComboBox : DropDownEnduserQues : ', selectedItem);
        if(props?.onAnswerSelect){
            props?.onAnswerSelect(selectedItem);
        }
    };
 

    
    return (
        <Box>
            {/* <ComboBox /> */}
            <ComboBox
                options={props?.dropdownOptionsData?.length>0?props?.dropdownOptionsData:[]}
                optionKey={"id"}
                optionLabelKey={"name"}
                onChangeHandler={handleComboBoxChange}
            />
        </Box>
    )
}

export default DropDownEnduserQues
import React, { useCallback, useContext, useEffect, useState } from "react";
// import { AnswerContext } from "../../../_services/FromType/AnswerContext";
// import { QuestionContext } from "../../../_services/FromType/QuestionContext";
import {TextField} from '../../../../lib/utils/AllImportsHelper'

function WebsiteQues(props) {
  // const answer = useContext(QuestionContext);
  // const [error, setError] = useState(false)
  // var pattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
  //   '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
  //   '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
  //   '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
  //   '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
  //   '(\\#[-a-z\\d_]*)?$', 'i')
  // useEffect(function () {
  //   if (answer) {
  //     if (answer.setPressEnterText) {
  //       answer.setPressEnterText(true)
  //     }
  //   }
  // }, [answer])
  // const onBlur = useCallback(() => {
  //   if (answer.answer) {
  //     if (!pattern.test(answer.answer)) {
  //       setError(true)
  //     }
  //     else {
  //       setError(false)

  //     }
  //   }
  // }, [answer]);
  return (

    <div className="">
      {/* {answer ? <TextField
        autosize="true"
        rows={2}
        placeholder="https://"
        readOnly={true}
        name="email"
        onBlur={onBlur}
        onChange={answer.onChangeAnswer}
        onKeyDown={answer.onChangeAnswer}
        error={error}
        helperText={error ? "Please enter valid url " : ""}
        className="font-weight-bold w-100 h3"
      /> : */}
        <TextField
          autosize="true"
          rows={2}
          placeholder="https://"
          readOnly={true}
          name="email"
          // onBlur={onBlur}
          // onChange={answer.onChangeAnswer}
          // onKeyDown={answer.onChangeAnswer}
          className="font-weight-bold w-100 h3"
        />
        {/* \} */}
    </div>
  );
}

export default WebsiteQues

import { SurveyList, SurveyConnect, SurveyCreate,SurveyGenAI, Setting, SurveyShare, Result } from "../pages/Survey"
import SurveyEndUser from "../pages/Survey/SurveyEndUser";
import SurveyFlow from "../pages/Survey/SurveyFlow";
import SurveyPreview from "../pages/Survey/SurveyPreview";
import routes from "./Routes";
function PrivateRoutesComponent() {
    return (
        [
            { path: routes.dashboard, page: <SurveyList /> },
            { path: routes.surveyList, page: <SurveyList /> },
            { path: routes.surveyCreate + ":id", page: <SurveyCreate /> },
            { path: routes.surveyCreateAssessment + ":id", page: <SurveyCreate /> },
            { path: routes.surveyEdit + ":id", page: <SurveyCreate /> },
            { path: routes.surveyEditAssessment + ":id", page: <SurveyCreate /> },
            { path: routes.surveyConnect + ":id", page: <SurveyConnect /> },
            { path: routes.surveySetting + ":id", page: <Setting /> },
            { path: routes.surveyShare + ":id", page: <SurveyShare /> },
            { path: routes.surveyResult + ":id", page: <Result /> },
            { path: routes.surveyGenAI, page: <SurveyGenAI /> },
            // { path: routes.surveyEndUser+ ":id", page: <SurveyEndUser /> },
            { path: routes.surveyPreview+ ":id", page: <SurveyPreview /> },
            // { path: routes.surveyYourOpinion+ ":id", page: <SurveyEndUser /> },
            { path: routes.surveyFlow+ ":id", page: <SurveyFlow /> },

            
        ]
    )
}

export default PrivateRoutesComponent;
import React, { useEffect } from "react";
import { useState, useContext } from "react";
import ListItemText from '@mui/material/ListItemText';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import { useSelector } from 'react-redux'
import { Switch, Box } from "@mui/material";
import { TextField } from "@mui/material";
import { FormTypeContext } from "../FromType/FormTypeContext";
import QuestionContext from "../../QuestionContext";


function Number(props) {
  const questionContextData = useContext(QuestionContext);
  const [minNumberCheck, setMinNumberCheck] = useState(false)
  const [minNumberValue, setMinNumberValue] = useState("")
  const [maxNumberCheck, setMaxNumberCheck] = useState(false)
  const [maxNumberValue, setMaxNumberValue] = useState("")
  // const handleSwitch = (e) => {
  //   if (e.target.name === 'min') {
  //     setMinNumberCheck(!minNumberCheck)
  //   }
  //   if (e.target.name === 'max') {
  //     setMaxNumberCheck(!maxNumberCheck)
  //   }
  // };
  const activeMinNumberChangeHandler = (e) => {
    setMinNumberCheck(e.target.checked)

  };
  const activeMaxNumberChangeHandler = (e) => {
    setMaxNumberCheck(e.target.checked)

  };

  const quetionChangeHandler = (e) => {
    if (e.target.name === 'minNumber') {
      setMinNumberValue(e.target.value)
      questionContextData?.updateQuestionTypeNumber(e.target.value, maxNumberValue);

    }
    if (e.target.name === 'maxNumber') {
      setMaxNumberValue(e.target.value)
      questionContextData?.updateQuestionTypeNumber(minNumberValue, e.target.value);

    }
  };
  // useEffect(function(){
  //   questionContextData?.updateQuestionTypeNumber(minNumberValue, maxNumberValue);

  // },[minNumberValue&& maxNumberValue])
  // const textFieldBlurHandlder = () => {
  //   questionContextData?.updateQuestionTypeNumber(minNumberValue, maxNumberValue);
  // };
  // const textFieldKeyDownHandler = (e) => {
  //   if (e.key === 'Enter') {
  //     textFieldBlurHandlder();
  //   }
  // };
  console.debug("SelctedQuestion.selectedQuetionItem", questionContextData.selectedQuetionItem)
  return (
    <Box className="">
      <label className="fs-14">Setting</label>
      <Box>
        {/* <List
          sx={{ width: '100%', bgcolor: 'background.paper' }}
          aria-labelledby="nested-list-subheader"
        > */}
        <ListItemButton >
          <ListItemText primary="Min Number" />
          <Switch
            className=""
            checked={minNumberCheck}
            onChange={activeMinNumberChangeHandler}
            name="min"
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </ListItemButton>
        {minNumberCheck &&
          <Box sx={{ mt: 1 }}>
            <TextField
              id="input-field"
              label="0-9999"
              type="number"
              fullWidth
              name="minNumber"
              onChange={quetionChangeHandler}
            // onBlur={textFieldBlurHandlder}
            // onKeyDown={textFieldKeyDownHandler}
            />
          </Box>
        }
        {/* <textarea value={numSetting.minValue} onChange={(e) => numSetting.setMinValue(e.target.value)} /> */}
        {/* <TextField variant="outlined" value={numSetting.minValue} onChange={(e) => numSetting.setMinValue(e.target.value)} placeholder="0-999999" /> */}

        <ListItemButton>
          <ListItemText primary="Max Number" />
          <Switch
            className=""
            checked={maxNumberCheck}
            onChange={activeMaxNumberChangeHandler}
            name="max"
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </ListItemButton>
        {maxNumberCheck &&
          <Box sx={{ mt: 1 }}>
            <TextField
              id="input-field"
              label="0-9999"
              type="number"
              fullWidth
              name="maxNumber"
              onChange={quetionChangeHandler}
            // onBlur={textFieldBlurHandlder}
            // onKeyDown={textFieldKeyDownHandler}
            />
          </Box>
          // <TextField variant="outlined" value={numSetting.maxValue} onChange={(e) => numSetting.setMaxValue(e.target.value)} placeholder="1-9999999" />
        }
        {/* </List> */}
      </Box>
    </Box>
  );
}

export default Number

import React from 'react';
import {
    Box, Typography,
} from '../../../../lib/utils/AllImportsHelper';
import QuestionTypeIcon from './QuestionTypeIcon';

function QuestionTypeOption({ questionType, ...props }) {
    // console.debug("QuestionPage : QuetionSettings : QuestionTypeOption : questionType : ", questionType);
    return (
        <Box component="li" sx={{ display: 'flex', alignItems: 'center' }} {...props}>
            <Box component="span" sx={{ marginRight: '8px' }}>
                <QuestionTypeIcon questionTypeLabel={questionType?.identifier} />
            </Box>
            {/* <Typography variant="body1">{questionType.label}</Typography> */}
            <Typography variant="body1">{questionType?.questionTypeLabel}</Typography>
        </Box>
    );
}

export default QuestionTypeOption;
import React, { useEffect, useState } from 'react'
import { Box, Grid, Button, Typography, IconButton } from '@mui/material'
// import CanvasGenAIHeader from '../../lib/layouts/CanvasGenAI/CanvasGenAIHeader'
// import CanvasGenAIBody from '../../lib/layouts/CanvasGenAI/CanvasGenAIBody'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import { CanvasBlankLayout } from '../../lib';
import GenAiBody from '../../component/genAi/GenAiBody';
import GenAIContext from '../../component/genAi/GenAIContext';
import ApiService from '../../ApiService';
// import CanvasHeader from '../../lib/layouts/CanvasLayout/CanvasHeader'
import { useDispatch } from 'react-redux';
import { addArrayOfQuestionsInSurveyQuestionList, updateSurvey } from '../../redux/slice/surveyQuetions/surveyQuestionSlice';
import { useNavigate } from 'react-router-dom';
import routes from '../../routesComponent/Routes';
import { useLocation } from 'react-router-dom';

function isEmptyObject(obj) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

const SurveyGenAI = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const app = queryParams.get('app');
  // const allParam = queryParams.getAll();
  const queryParamsObject = Object.fromEntries(queryParams.entries());
  console.debug("a9", queryParamsObject);

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [promptTextArray, setPromptTextArray] = useState([]);
  const [currentPromptText, setPromptText] = useState('Create an assessment survey for MDM prospects in the technology industry');
  const [currentgeneratedResponseFromPrompt, setCurrentgeneratedResponseFromPrompt] = useState([]);
  const [selectedSourceRows, setSelectedSourceRows] = useState(new Set());
  const [sourceItemList, setSourceItemList] = useState([]);
  const [totalQuestionInSourceItemList, setTotalQuestionInSourceItemList] = useState(0);
  const [sourceItemListLoadingStatus, setSourceItemListLoadingStatus] = useState(false);
  const [totalQuestionInDestionationItemList, setTotalQuestionInDestionationItemList] = useState(0);


  const [currentPrompt, setCurrentPrompt] = useState("");

  function textFieldChangeHandler(value) {
    // console.debug("SurveyGenAI : textFieldChangeHandler : value : ", value);
    setCurrentPrompt(value);
  }

  // Helper Method
  const formatQuestionOfQuetionGroup = (item, itemGroup) => {
    let modifiedItem = { ...item };
    let tempRowParent = { ...itemGroup };

    if (tempRowParent?.childQuestions) {
      delete tempRowParent.childQuestions;
    }
    modifiedItem.parentQuestions = { ...tempRowParent };
    return modifiedItem;
  }
  // ./Helper Method

  // Source Component
  const [selectedSourceRowsToMove, setSelectedSourceRowsToMove] = useState(new Set());
  const [selectedSourceItemList, setSelectedSourceItemList] = useState([]);

  const sourceRowCheckboxSelectionHandler = (rowId, row, rowParent, e) => {
    const newSelectedRows = new Set(selectedSourceRows);
    const newSelectedRowsToMoveTemp = new Set(selectedSourceRowsToMove);

    let tempRow = { ...row };
    let tempRowParent = { ...rowParent };

    if (tempRowParent?.childQuestions) {
      delete tempRowParent.childQuestions;
    }
    // console.debug("SurveyGenAI : sourceRowCheckboxSelectionHandler : tempRowParent ", tempRowParent);
    // tempRow = { ...tempRow, parentQuestions: { ...tempRowParent } };
    tempRow.parentQuestions = { ...tempRowParent };
    // console.debug("SurveyGenAI : sourceRowCheckboxSelectionHandler : tempRow ", tempRow);

    if (newSelectedRows.has(JSON.stringify(row))) {
      newSelectedRows.delete(JSON.stringify(row));
      // console.debug("SurveyGenAI : sourceUNCheckboxSelectionHandler : newSelectedRowsToMoveTemp ", newSelectedRowsToMoveTemp);
      // console.debug("SurveyGenAI : sourceUNCheckboxSelectionHandler : tempRow ",tempRow );
      // No need to use spread operator, just pass the object
      newSelectedRowsToMoveTemp.delete(JSON.stringify(tempRow));
    } else {
      newSelectedRows.add(JSON.stringify(row));
      // console.debug("SurveyGenAI : sourceRowCheckboxSelectionHandler : tempRow ", tempRow);
      newSelectedRowsToMoveTemp.add(JSON.stringify(tempRow));
    }

    console.debug("SurveyGenAI : sourceRowCheckboxSelectionHandler : newSelectedRowsToMove ", newSelectedRowsToMoveTemp);
    setSelectedSourceRows(newSelectedRows);
    setSelectedSourceRowsToMove(newSelectedRowsToMoveTemp);
  };

  // const removeSelectedItemFromSourceItemAndSelectedItem = (selectedSourceRowsParam, selectedSourceRowsToMoveParam) => {
  //   let selectedSourceRowsInArray = Array.from(selectedSourceRowsParam);
  //   let selectedSourceItemToMoveInArray = Array.from(selectedSourceRowsToMoveParam);
  const removeSelectedItemFromSourceItemAndSelectedItem = () => {
    let selectedSourceRowsInArray = Array.from(selectedSourceRows);
    let selectedSourceItemToMoveInArray = Array.from(selectedSourceRowsToMove);
    let updatedSelectedSourceItemToMoveInArray = [...selectedSourceItemToMoveInArray];
    let sourceItemListTemp = sourceItemList;
    let updatedSourceItemListTemp = [...sourceItemListTemp];
    selectedSourceItemToMoveInArray.forEach(element => {
      let elementTemp = JSON.parse(element);
      // console.debug("SurveyGenAI : removeSelectedItemFromSourceItemAndSelectedItem : parse : elementTemp : ", elementTemp);
      let findParent = sourceItemListTemp.findIndex(d => d?.question == elementTemp?.parentQuestions?.question);
      if (findParent >= 0) {
        // console.debug("SurveyGenAI : removeSelectedItemFromSourceItemAndSelectedItem : findParent : Found ", findParent);
        let findInChildren = sourceItemListTemp[findParent]?.childQuestions?.findIndex(d => d?.question == elementTemp?.question);
        if (findInChildren >= 0) {
          // console.debug("SurveyGenAI : removeSelectedItemFromSourceItemAndSelectedItem : findParent : Found : findInChildren : Found", findInChildren);
          if (updatedSourceItemListTemp[findParent] && Array.isArray(updatedSourceItemListTemp[findParent].childQuestions)) {
            updatedSourceItemListTemp[findParent].childQuestions.splice(findInChildren, 1);
          }
          // console.debug("After deletion : tempSource : ", tempSource);

          selectedSourceRowsInArray = selectedSourceRowsInArray.filter(item => JSON.parse(item)?.question !== elementTemp.question);
          // Remove the item from selectedSourceItemToMoveInArray
          updatedSelectedSourceItemToMoveInArray = updatedSelectedSourceItemToMoveInArray.filter(item => item !== element);
        } else {
          console.debug("SurveyGenAI : removeSelectedItemFromSourceItemAndSelectedItem : findParent : Found : findInChildren : NotFound", findInChildren);
        }
      } else {
        console.debug("SurveyGenAI : removeSelectedItemFromSourceItemAndSelectedItem : findParent : Not Found ", findParent);
      }
    });

    // console.debug("SurveyGenAI : removeSelectedItemFromSourceItemAndSelectedItem : findParent : Found ", findParent);

    // return ([...updatedSourceItemListTemp], new Set(updatedSelectedSourceItemToMoveInArray), (new Set(selectedSourceRowsInArray)))
    setSourceItemList([...updatedSourceItemListTemp]);
    setSelectedSourceRowsToMove(new Set(updatedSelectedSourceItemToMoveInArray));
    setSelectedSourceRows(new Set(selectedSourceRowsInArray));
  }

  const handleMoveAllSelectedItemFromSourceToDestination = (e) => {
    // console.debug("SurveyGenAI : handleMoveAllSelectedItemFromSourceToDestination : selectedSourceRowsToMove ", selectedSourceRowsToMove);

    if (selectedSourceRowsToMove?.size > 0) {
      let sourceTodestionationMoveCount = totalQuestionInDestionationItemList;
      let destionationToSourceMoveCount = totalQuestionInSourceItemList;
      let destinationItemListTemp = [...destinationItemList];
      let selectedSourceRowsToMoveInArray = Array.from(selectedSourceRowsToMove);
      selectedSourceRowsToMoveInArray.forEach(element => {
        // console.debug("SurveyGenAI : handleMoveAllSelectedItemFromSourceToDestination : element : ", element);
        let elementTemp = JSON.parse(element);
        console.debug("SurveyGenAI : handleMoveAllSelectedItemFromSourceToDestination : parse : elementTemp : ", elementTemp);
        let findParent = destinationItemListTemp.findIndex(d => d?.question == elementTemp?.parentQuestions?.question);
        if (findParent >= 0) {
          console.debug("SurveyGenAI : handleMoveAllSelectedItemFromSourceToDestination : findParent : Found ", findParent);
          let findInChildren = destinationItemListTemp[findParent]?.childQuestions?.findIndex(d => d?.question == elementTemp?.question);

          if (findInChildren >= 0) {
            console.debug("SurveyGenAI : handleMoveAllSelectedItemFromSourceToDestination : findParent : Found : findInChildren : Found", findInChildren);
          } else {
            console.debug("SurveyGenAI : handleMoveAllSelectedItemFromSourceToDestination : findParent : Found : findInChildren : NotFound", findInChildren);
            let tempDestination = [...destinationItemListTemp];
            let elementTempToPush = elementTemp
            delete elementTempToPush.parentQuestions;
            tempDestination[findParent].childQuestions.push(elementTempToPush);
            // setDestinationItemList([...tempDestination]); // vk temp
            destinationItemListTemp = [...tempDestination];
            sourceTodestionationMoveCount++;
            destionationToSourceMoveCount--;

          }

        } else {
          console.debug("SurveyGenAI : handleMoveAllSelectedItemFromSourceToDestination : findParent : Not Found ", findParent);
          // let tempItemGroup = { ...itemGroup };
          // tempItemGroup.childQuestions = tempItemGroup?.childQuestions.filter(d => d?.question == item?.question);
          // // console.debug("SurveyGenAI : handleMoveAllSelectedItemFromSourceToDestination : tempItemGroup : ", tempItemGroup);
          let tempElement = elementTemp;
          let tempElementParent = {};
          if (tempElement?.parentQuestions) {
            tempElementParent = tempElement.parentQuestions;
            delete tempElement.parentQuestions;
          }

          tempElementParent.childQuestions = [elementTemp];
          // console.debug("SurveyGenAI : handleMoveAllSelectedItemFromSourceToDestination : findParent : Not Found : tempElementParent : ", tempElementParent);

          let tempDestination = [...destinationItemListTemp, tempElementParent];
          // console.debug("SurveyGenAI : handleMoveAllSelectedItemFromSourceToDestination : tempDestination : ", tempDestination);
          // setDestinationItemList([...tempDestination]); // vk temp
          destinationItemListTemp = [...tempDestination];
          sourceTodestionationMoveCount++;
          destionationToSourceMoveCount--;
        }
      });

      setTotalQuestionInDestionationItemList(sourceTodestionationMoveCount);
      setTotalQuestionInSourceItemList(destionationToSourceMoveCount);
      setDestinationItemList([...destinationItemListTemp]); // vk Final
      removeSelectedItemFromSourceItemAndSelectedItem();
    };

  };


  const addObjectIfMatches = (array, obj) => {
    array.forEach(item => {
      // const matchFound = item.childQuestions.some(child => child.question === obj.question);
      const matchFound = item.childQuestions.find(child => child.question === obj.question);
      // console.debug("QuestionSetTable : handleMoveClickedItemFromSourceToDestination : addObjectIfMatches : array", array);
      // console.debug("QuestionSetTable : handleMoveClickedItemFromSourceToDestination : addObjectIfMatches : obj", obj);
      // console.debug("QuestionSetTable : handleMoveClickedItemFromSourceToDestination : addObjectIfMatches : matchFound", matchFound);
      if (!matchFound) {
        console.debug("QuestionSetTable : handleMoveClickedItemFromSourceToDestination : addObjectIfMatches : matchNotFound", matchFound);
        item.childQuestions.push(obj);
      } else {
        console.debug("QuestionSetTable : handleMoveClickedItemFromSourceToDestination : addObjectIfMatches : matchFound", matchFound);
      }
    });
    return array;
  };

  // Function to remove the abc object
  const removeObjectIfMatches = (array, obj) => {
    array.forEach(item => {
      item.childQuestions = item.childQuestions.filter(child => child.question !== obj.question);
    });
    return array;
  };



  const handleMoveClickedItemFromSourceToDestination = (e, item, itemGroup) => {
    // console.debug("QuestionSetTable : handleMoveClickedItemFromSourceToDestination : event ", e);
    // console.debug("QuestionSetTable : handleMoveClickedItemFromSourceToDestination : item ", item);
    // console.debug("QuestionSetTable : handleMoveClickedItemFromSourceToDestination : itemGroup ", itemGroup);
    // console.debug("QuestionSetTable : handleMoveClickedItemFromSourceToDestination : destinationItemList ", destinationItemList);
    if (item) {
      let sourceTodestionationMoveCount = totalQuestionInDestionationItemList;
      let destionationToSourceMoveCount = totalQuestionInSourceItemList;
      let findParent = destinationItemList.findIndex(d => d?.question == itemGroup?.question);
      if (findParent >= 0) {

        let findInChildren = destinationItemList[findParent]?.childQuestions?.findIndex(d => d?.question == item?.question);
        if (findInChildren >= 0) {
          console.debug("QuestionSetTable : handleMoveClickedItemFromSourceToDestination : findParent : Found : findInChildren : Found", findInChildren);
        } else {
          console.debug("QuestionSetTable : handleMoveClickedItemFromSourceToDestination : findParent : Found : findInChildren : NotFound", findInChildren);
          let tempDestination = [...destinationItemList];
          tempDestination[findParent].childQuestions.push(item);
          setDestinationItemList([...tempDestination]);
          sourceTodestionationMoveCount++;
          destionationToSourceMoveCount--;
        }
      } else {

        console.debug("QuestionSetTable : handleMoveClickedItemFromSourceToDestination : findParent : Not Found ", findParent);
        let tempItemGroup = { ...itemGroup };
        tempItemGroup.childQuestions = tempItemGroup?.childQuestions.filter(d => d?.question == item?.question);
        // console.debug("QuestionSetTable : handleMoveClickedItemFromSourceToDestination : tempItemGroup : ", tempItemGroup);
        let tempDestination = [...destinationItemList, tempItemGroup];
        // console.debug("QuestionSetTable : handleMoveClickedItemFromSourceToDestination : tempDestination : ", tempDestination);
        setDestinationItemList([...tempDestination]);
        sourceTodestionationMoveCount++;
        destionationToSourceMoveCount--;
      }


      setTotalQuestionInDestionationItemList(sourceTodestionationMoveCount);
      setTotalQuestionInSourceItemList(destionationToSourceMoveCount);

      let updatedSourceItemList = removeObjectIfMatches([...sourceItemList], item);
      setSourceItemList(updatedSourceItemList);

      let modifiedItem = formatQuestionOfQuetionGroup(item, itemGroup)

      const newSelectedRows = new Set(selectedSourceRows);
      const newSelectedRowsToMoveTemp = new Set(selectedSourceRowsToMove);

      if (newSelectedRows.has(JSON.stringify(item))) {
        newSelectedRows.delete(JSON.stringify(item));
        newSelectedRowsToMoveTemp.delete(JSON.stringify(modifiedItem));
      }
      setSelectedSourceRows(newSelectedRows);
      setSelectedSourceRowsToMove(newSelectedRowsToMoveTemp);
      // let { updatedSourceItemListTemp, updatedSelectedSourceItemToMove, selectedSourceRows } = removeSelectedItemFromSourceItemAndSelectedItem([item], [modifiedItem]);
    }

  };


  const handleSelectAllClick = (event) => {
    console.debug("QuestionSetTable : handleSelectAllClick : event ", event);
    console.debug("QuestionSetTable : handleSelectAllClick : selectedSourceItemList ", sourceItemList);

    if (event.target.checked) {
      let questionItem = [];
      let questionItemWithParentKey = [];
      sourceItemList?.forEach(element => {
        let tempElement = { ...element };
        if (tempElement?.childQuestions) {
          delete tempElement.childQuestions;
        }
        element?.childQuestions?.forEach(childElement => {
          let tempChildElement = { ...childElement };
          tempChildElement.parentQuestions = { ...tempElement };
          questionItem.push(JSON.stringify(childElement));
          questionItemWithParentKey.push(JSON.stringify(tempChildElement));
        });
      });
      const newSelectedRows = new Set([...questionItem]);
      setSelectedSourceRows(newSelectedRows);
      setSelectedSourceRowsToMove(new Set([...questionItemWithParentKey]));
    } else {
      const newSelectedRows = new Set();
      setSelectedSourceRows(newSelectedRows);
      setSelectedSourceRowsToMove(new Set());
    }

    // sourceItemList.map((row) => {

    //   return row;
    // })
    // const newSelectedRows = event.target.checked ? new Set(sourceItemList.map((row) => row.id)) : new Set();
    // const newSelectedRows = event.target.checked ? new Set(sourceItemList.map((row) => row)) : new Set();
    // const newSelectedRows = event.target.checked ? new Set([...questionItem]) : new Set();
    // console.debug("QuestionSetTable : handleSelectAllClick : newSelectedRows ", newSelectedRows);
    // setSelectedSourceRows(newSelectedRows);
    // tableContextData?.onSelectedRowsChange(Array.from(newSelectedRows));
  };

  // ./Source Component

  const [destinationItemList, setDestinationItemList] = useState([]);


  function onGenerateClickHandler(e) {
    // e.preventDefault();
    let postData = {
      promtMessage: currentPrompt
    }

    if (!isEmptyObject(queryParamsObject)) {
      postData.testPrompt = 1;
    }

    console.debug("SurveyGenAI : onGenerateClickHandler : currentPrompt ", currentPrompt);

    setSourceItemListLoadingStatus(true);
    ApiService.post('generateSurveyQuestion', postData).then(r => {
      console.debug("GenAiBody : onGenerateClickHandler : r : ", r.data);
      setSourceItemListLoadingStatus(false);
      if (r.data?.data) {
        // console.debug("GenAiBody : onGenerateClickHandler : r.data : ", r.data);
        // setCurrentgeneratedResponseFromPrompt(r.data.data?.surveyQuestion);
        setSourceItemList(r.data.data?.surveyQuestion)
        // setDestinationItemList(r.data.data?.surveyQuestion)
      }

      if (r.data?.meta && r.data?.meta?.totalQuestions) {
        setTotalQuestionInSourceItemList(r.data.meta.totalQuestions);
      }
    }).catch(e => {
      setSourceItemListLoadingStatus(false);
    });

  }



  // Destination Item


  function formatQuestion(question) {
    console.debug("Vikram : Question : ", question);
    return {
      "question": question.question,
      "questionTypeLabel": question.questionType.questionTypeLabel,
      "identifier": question.questionType.identifier,
      "optionChoiceName": question.optionChoiceName || [] // Handling case when optionChoiceName is not provided
    };
  }

  function formatQuestions(questions) {
    const formattedQuestions = [];
    questions.forEach(q => {
      formattedQuestions.push(formatQuestion(q));
      if (q.childQuestions && q.childQuestions.length > 0) {
        q.childQuestions.forEach(childQ => {
          formattedQuestions.push(formatQuestion(childQ));
        });
      }
    });
    return formattedQuestions;
  }

  function formatData(questions) {
    return { "questions": formatQuestions(questions) };
  }

  // Function to convert JSON to FormData
  function jsonToFormData(jsonData) {
    let formData = new FormData();

    // Iterate through the JSON structure
    jsonData.forEach((group, index) => {
      // Add question group details
      // formData.append(`related[${index}]:questionType`, group.questionType.identifier);
      // formData.append(`//returnPayload`, 1);
      // formData.append(`questionGroupName`, 'sandip');
      // formData.append(`questions[${index}][url]`, 'bds.com');
      formData.append(`questions[${index}][identifier]`, group.questionType.identifier);
      formData.append(`questions[${index}][question]`, group.question);
      formData.append(`questions[${index}][status]`, 1);

      // Add child questions details
      group.childQuestions.forEach((question, childIndex) => {
        formData.append(`questions[${index}][childQuestions][${childIndex}][identifier]`, question.questionType.identifier);
        formData.append(`questions[${index}][childQuestions][${childIndex}][question]`, question.question);

        // Add option choice names
        question.optionChoiceName.forEach((option, optionIndex) => {
          formData.append(`questions[${index}][childQuestions][${childIndex}][optionChoiceName][${optionIndex}]`, option);
        });
      });
    });

    return formData;
  }

  // Header
  const [headerButtonDisabledStatus, setHeaderButtonDisabledStatus] = useState(true);

  useEffect(function () {
    if (destinationItemList?.length > 0) {
      setHeaderButtonDisabledStatus(false);
    } else {
      setHeaderButtonDisabledStatus(true);
    }

  }, [destinationItemList]);

  const startToCustomiseClickHandler = (event) => {
    // console.debug("SurveyGenAI : QuestionSetTable : startToCustomiseClickHandler : event ", event);
    if (destinationItemList?.length > 0) {
      console.debug("SurveyGenAI : startToCustomiseClickHandler : destinationItemList ", destinationItemList);

      let formData = jsonToFormData([...destinationItemList]);

      formData.append("returnPayload", 1);
      formData.append("identifier", "assessment-based");
      formData.append("questionGroupName", "Vk assessment-based");

      // formData.append("related", ['questionType']);
      // formData.append("key", "");

      // //  Old Working Code
      // let formData = new FormData();

      // let formattedData = formatData([...destinationItemList]);
      // formattedData.surveyOriginId = 2;
      // formattedData.returnPayload = 1;
      // formattedData.identifier = "assessment-based";
      // formattedData.questionGroupName = "Vk assessment-based";
      // formattedData.related = ['questionType'];

      // console.debug("SurveyGenAI : startToCustomiseClickHandler : formattedData : ", formattedData);

      // // Convert formattedData to FormData Working
      // for (const key in formattedData) {
      //   if (key === "questions") {
      //     formattedData[key].forEach((question, index) => {
      //       for (const qKey in question) {
      //         formData.append(`questions[${index}][${qKey}]`, question[qKey]);
      //       }
      //     });
      //   } else if (Array.isArray(formattedData[key])) {
      //     formattedData[key].forEach((value, index) => {
      //       formData.append(`${key}[${index}]`, value);
      //     });
      //   } else {
      //     formData.append(key, formattedData[key]);
      //   }
      // }
      // //  ./Old Working Code



      // console.debug("SurveyGenAI : startToCustomiseClickHandler : formData ", formData);

      ApiService.post('surveyCreateGeneratedByAI', formData).then(r => {
        if (r?.data?.data) {
          let surveyResponse = r.data.data;
          // console.debug("SurveyGenAI : startToCustomiseClickHandler : generateSurveyQuestion : surveyResponse : ", surveyResponse);
          if (surveyResponse) {
            dispatch(updateSurvey(surveyResponse));
            dispatch(addArrayOfQuestionsInSurveyQuestionList(surveyResponse?.surveyQuestion));
            navigate(routes.surveyCreateAssessment + surveyResponse.id);
          } else {
            console.error("SurveyGenAI : startToCustomiseClickHandler : generateSurveyQuestion : surveyResponse : ", surveyResponse);
          }
        }

      }).catch(e => {
        console.error("SurveyGenAI : startToCustomiseClickHandler : generateSurveyQuestion : e : ", e);

      });
    } else {
      console.error("SurveyGenAI : startToCustomiseClickHandler : destinationItemList : is empty", destinationItemList);
    }
    // console.debug("QuestionSetTable : handleSelectAllClick : selectedSourceItemList ", sourceItemList);
  };

  // ./Header



  const resetDestinationHandler = (e) => {
    setDestinationItemList([]);
    setTotalQuestionInDestionationItemList(0);
  };

  const handleMoveClickedItemFromDestinationToSource = (e, item, itemGroup) => {
    // console.debug("SurveyGenAI : handleMoveClickedItemFromDestinationToSource : event ", e);
    // console.debug("SurveyGenAI : handleMoveClickedItemFromDestinationToSource : item ", item);
    // console.debug("SurveyGenAI : handleMoveClickedItemFromDestinationToSource : itemGroup ", itemGroup);
    // console.debug("SurveyGenAI : handleMoveClickedItemFromDestinationToSource : destinationItemList ", destinationItemList);
    if (item) {
      let sourceTodestionationMoveCount = totalQuestionInDestionationItemList;
      let destionationToSourceMoveCount = totalQuestionInSourceItemList;
      let findParent = sourceItemList.findIndex(d => d?.question == itemGroup?.question);
      if (findParent >= 0) {

        let findInChildren = sourceItemList[findParent]?.childQuestions?.findIndex(d => d?.question == item?.question);
        if (findInChildren >= 0) {
          console.debug("SurveyGenAI : handleMoveClickedItemFromDestinationToSource : findParent : Found : findInChildren : Found", findInChildren);
        } else {
          console.debug("SurveyGenAI : handleMoveClickedItemFromDestinationToSource : findParent : Found : findInChildren : NotFound", findInChildren);
          let tempSource = [...sourceItemList];
          tempSource[findParent].childQuestions.push(item);
          setSourceItemList([...tempSource]);
          sourceTodestionationMoveCount--;
          destionationToSourceMoveCount++;
        }
      } else {

        console.debug("SurveyGenAI : handleMoveClickedItemFromDestinationToSource : findParent : Not Found ", findParent);
        let tempItemGroup = { ...itemGroup };
        tempItemGroup.childQuestions = tempItemGroup?.childQuestions.filter(d => d?.question == item?.question);
        // console.debug("SurveyGenAI : handleMoveClickedItemFromDestinationToSource : tempItemGroup : ", tempItemGroup);
        let tempSource = [...sourceItemList, tempItemGroup];
        // console.debug("SurveyGenAI : handleMoveClickedItemFromDestinationToSource : tempDestination : ", tempDestination);
        setSourceItemList([...tempSource]);
        sourceTodestionationMoveCount--;
        destionationToSourceMoveCount++;
      }


      setTotalQuestionInDestionationItemList(sourceTodestionationMoveCount);
      setTotalQuestionInSourceItemList(destionationToSourceMoveCount);

      let updatedDestinationItemList = removeObjectIfMatches([...destinationItemList], item);
      updatedDestinationItemList = updatedDestinationItemList.filter(d => d.childQuestions.length > 0);
      setDestinationItemList(updatedDestinationItemList);

      let modifiedItem = formatQuestionOfQuetionGroup(item, itemGroup)

      // const newSelectedRows = new Set(selectedSourceRows);
      // const newSelectedRowsToMoveTemp = new Set(selectedSourceRowsToMove);

      // if (newSelectedRows.has(JSON.stringify(item))) {
      //   newSelectedRows.delete(JSON.stringify(item));
      //   newSelectedRowsToMoveTemp.delete(JSON.stringify(modifiedItem));
      // }
      // setSelectedSourceRows(newSelectedRows);
      // setSelectedSourceRowsToMove(newSelectedRowsToMoveTemp);
      // let { updatedSourceItemListTemp, updatedSelectedSourceItemToMove, selectedSourceRows } = removeSelectedItemFromSourceItemAndSelectedItem([item], [modifiedItem]);
    }

  };
  // ./Destination Item

  const genAiContextData = {
    promptTextArray: promptTextArray,
    currentPromptText: currentPromptText,
    onGenerateClickHandler: onGenerateClickHandler,
    currentgeneratedResponseFromPrompt: currentgeneratedResponseFromPrompt,
    selectedSourceRows: selectedSourceRows,
    sourceItemList: sourceItemList,
    sourceRowCheckboxSelectionHandler: sourceRowCheckboxSelectionHandler,
    handleMoveAllSelectedItemFromSourceToDestination: handleMoveAllSelectedItemFromSourceToDestination,
    totalQuestionInSourceItemList: totalQuestionInSourceItemList,
    handleSelectAllClick: handleSelectAllClick,
    sourceItemListLoadingStatus: sourceItemListLoadingStatus,
    //  Destination
    destinationItemList: destinationItemList,
    resetDestinationHandler: resetDestinationHandler,
    totalQuestionInDestionationItemList: totalQuestionInDestionationItemList,
    handleMoveClickedItemFromDestinationToSource, handleMoveClickedItemFromDestinationToSource,
    //  ./Destination
    handleMoveClickedItemFromSourceToDestination: handleMoveClickedItemFromSourceToDestination,
    selectedSourceItemList: selectedSourceItemList,

    // Prompt
    textFieldChangeHandler, textFieldChangeHandler,
    currentPrompt, currentPrompt
    // ./Prompt

  }

  return (
    <CanvasBlankLayout
      startToCustomiseClickHandler={startToCustomiseClickHandler}
      headerButtonDisabledStatus={headerButtonDisabledStatus}
    >
      <GenAIContext.Provider value={genAiContextData}>
        <GenAiBody />
      </GenAIContext.Provider>
    </CanvasBlankLayout>
  )
}

export default SurveyGenAI
import React, { useContext, useEffect, useState } from 'react';
import { Box, Checkbox, Divider, Grid, Button, IconButton, Card, TextField, styled, Tooltip, Menu, MenuItem, tooltipClasses, List, ListItem, ListItemButton, ListItemText, ListItemIcon, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import ReplayIcon from '@mui/icons-material/Replay';
import GenAIContext from './GenAIContext';
import { Done } from '@mui/icons-material';
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


const StyledSpan = styled('span')(({ theme }) => ({
    fontWeight: 400,
}));

function DestinationQuestionHead(props) {
    const genAiContextData = useContext(GenAIContext);
    return (
        <Box sx={{ p: 0.9, backgroundColor: '#8DCEFF' }} >
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100% Important' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {/* <Box><Checkbox {...label} /></Box> */}

                            <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                                {genAiContextData?.totalQuestionInDestionationItemList ? (
                                    <Box sx={{ padding: '0.1rem' }}>
                                        <IconButton>
                                            <Done />
                                        </IconButton>
                                        {genAiContextData?.totalQuestionInDestionationItemList} Selected to customize
                                       
                                    </Box>
                                ) : (
                                    <>
                                        <IconButton>
                                            <Done />
                                        </IconButton>
                                        SELECTED (0)
                                       
                                    </>
                                )}

                            </Typography>


                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Box >
                        <IconButton aria-label="Refresh" onClick={genAiContextData?.resetDestinationHandler}>
                            <ReplayIcon />
                        </IconButton>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default DestinationQuestionHead;
import React, { useContext, useEffect, useState } from 'react';
import { Box, Checkbox, Divider, Grid, Button, IconButton, Card, TextField, styled, Tooltip, Menu, MenuItem, tooltipClasses, List, ListItem, ListItemButton, ListItemText, ListItemIcon, Typography } from '@mui/material';
// import { styled } from '@mui/system';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import WestIcon from '@mui/icons-material/West';
import GenAIContext from './GenAIContext';
import IlustrationScreen from '../../assets/IlustrationScreens.png'

function DestinationQuestionBody(props) {
    const genAiContextData = useContext(GenAIContext);

    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const [secondary, setSecondary] = React.useState(false);
    return (
        <Box sx={{ backgroundColor: '#DBEAFE', height: 'calc(100vh - 200px)', overflow: 'auto' }}>
            {genAiContextData?.destinationItemList.length === 0 ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <img src={IlustrationScreen} alt="No data illustration" />
                </Box>
            ) : (
                <Box>
            {genAiContextData?.destinationItemList.map((ig) => (
                <Box sx={{ p: 2 }}>
                    <Card sx={{ borderRadius: '8px' }}>
                        
                        <Box>
                            {/* {console.debug("QuestionSetTable : handleMoveClickedItemFromSourceToDestination : tempDestination : itemGroup", ig)} */}

                            <Box sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                        <Box sx={{padding:'2px'}}><Typography variant="subtitle2">{ig?.question}</Typography></Box>

                                    </Grid>
                                    <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <Box sx={{mr:'0.8rem'}}><KeyboardArrowUpIcon /></Box>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Divider />
                            <Box sx={{}}>
                                <List>
                                    {ig.childQuestions.map((d, i) => (
                                        <ListItem
                                            key={d.id} // Added key prop for each ListItem
                                            secondaryAction={
                                                <Button
                                                    variant="outlined"
                                                    size="small"
                                                    startIcon={<WestIcon />}
                                                    className="more-vert-button"
                                                    // Uncomment the line below if you want the button to handle clicks
                                                    onClick={(e) => genAiContextData?.handleMoveClickedItemFromDestinationToSource(e, d, ig)}
                                                >
                                                </Button>
                                            }
                                        >
                                            {/* <ListItemAvatar>
                                                <DragIndicatorIcon />
                                            </ListItemAvatar> */}
                                            <ListItemText
                                                sx={{ mr: '3.3rem' }}
                                                primary={
                                                    <Typography variant="body2">
                                                        {d.id ? `Q.${d.id}` : ""} {d.question}
                                                    </Typography>
                                                }
                                            />
                                        </ListItem>



                                    ))}

                                </List>


                            </Box>
                        </Box>
                    </Card >

                </Box >
            ))}
            </Box>
        )}
        </Box >
    );
}

export default DestinationQuestionBody;
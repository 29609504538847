import React, { useState, useEffect } from 'react';
import { Box, Button, Card, Grid, Typography } from '@mui/material';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import CreateFromScratch from './CreateFromScratch';
import ApiService from '../../ApiService';

const Slider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [cards, setCards] = useState([]);

  const cardsPerPage = 3;

  useEffect(() => {
    ApiService.get('maturityModel')
      .then(response => {
        const data = response.data;
        setCards(data.data);
      })
      .catch(error => {
        console.error('Error fetching maturityModel:', error);
      });
  }, []);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - cardsPerPage, 0));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + cardsPerPage, cards.length - cardsPerPage));
  };

  const handleCardClick = (index) => {
    setSelectedCardIndex(index);
    setSelectedCard(cards[index]);
  };

  const displayedCards = cards.slice(currentIndex, currentIndex + cardsPerPage);

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Button
          variant="contained"
          size="small"
          onClick={handlePrev}
          endIcon={<ArrowBackIosNewOutlinedIcon />}
          sx={{
            height: '2rem',
            minWidth: '2rem !important',
            '& .MuiButton-endIcon': { ml: '0px !important' }
          }}
          disabled={currentIndex === 0}
        />
        <Box sx={{ mx: '1rem', flexGrow: 1 }}>
          <Grid container spacing={2}>
            {displayedCards.map((card, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card
                  sx={{
                    height: '100%',  // Ensures the card takes full height of the grid item
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    p: 2,
                    cursor: 'pointer',
                    border: selectedCardIndex === index ? '2px solid blue' : 'none',
                  }}
                  onClick={() => handleCardClick(index)}
                >
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography 
                      variant="subtitle2" 
                      sx={{ 
                        fontWeight: 'bold', 
                        mb: 1, 
                        overflow: 'hidden', 
                        textOverflow: 'ellipsis', 
                        whiteSpace: 'nowrap' 
                      }}
                    >
                      {card.name}
                    </Typography>
                    <Typography 
                      variant="body2" 
                      sx={{ 
                        overflow: 'hidden', 
                        textOverflow: 'ellipsis', 
                        display: '-webkit-box', 
                        WebkitLineClamp: 3, 
                        WebkitBoxOrient: 'vertical',
                      }}
                    >
                      {card.description}
                    </Typography>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
        <Button
          variant="contained"
          size="small"
          onClick={handleNext}
          endIcon={<ArrowForwardIosOutlinedIcon />}
          sx={{
            height: '2rem',
            minWidth: '2rem !important',
            '& .MuiButton-endIcon': { ml: '0px !important' }
          }}
          disabled={currentIndex >= cards.length - cardsPerPage}
        />
      </Box>
  
      {selectedCard && cards.length > 0 &&(
        <Box sx={{ mt: 1 }}>
          <CreateFromScratch selectedCard={selectedCard} />
        </Box>
      )}
    </Box>
  );
};

export default Slider;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addTab, removeTab, setSelectedTab, updateTabData } from '../../component/redux/NodeRedux/TabSlice';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Card, IconButton, Tab, Button, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Node from './Node';
import CanvasWrapper from '../../component/layoutWrapper/CanvasWrapper';
import ApiService from '../../ApiService';
import { updateSurvey, addArrayOfQuestionsInSurveyQuestionList } from '../../redux/slice/surveyQuetions/surveyQuestionSlice';

function SurveyFlow() {
    let { id } = useParams();
    const dispatch = useDispatch();
    const tabNodeData = useSelector((state) => state.tabNodeData);
    console.log('Tab Node Data:', tabNodeData);

    const [loadingSurvey, setLoadingSurvey] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (id) {
            setLoadingSurvey(true);
            ApiService.get('surveyView', {}, id).then(r => {
                if (r.data?.data) {
                    const surveyResponse = r.data.data;
                    dispatch(updateSurvey(surveyResponse));
                    dispatch(addArrayOfQuestionsInSurveyQuestionList([...surveyResponse.surveyQuestion]));
                    setLoadingSurvey(false);
                }
            }).catch(e => {
                setLoadingSurvey(false);
                setError('Failed to load survey data');
                console.error('API Error:', e);
            });
        }
    }, [id, dispatch]);

    if (!tabNodeData) {
        console.error('Tab Node Data is undefined');
        return <div>Error: Tab data not found. Check console for more details.</div>;
    }

    const { tabs, selectedTab } = tabNodeData;

    const handleChange = (event, newValue) => {
        dispatch(setSelectedTab(newValue));
    };

    const handleAddTab = () => {
        if (tabs.length < 4) {
            const newTabIndex = tabs.length + 1;
            const newTab = { label: `Node${newTabIndex}`, value: newTabIndex.toString(), data: {} };
            dispatch(addTab(newTab));
            dispatch(setSelectedTab(newTab.value));
        }
    };

    const handleRemoveTab = (tabValue) => {
        dispatch(removeTab(tabValue));
        if (selectedTab === tabValue && tabs.length > 1) {
            dispatch(setSelectedTab(tabs[0].value));
        }
    };

    const handleUpdateTabContent = (tabValue, newData) => {
        dispatch(updateTabData({ value: tabValue, data: newData }));
    };

    return (
        <CanvasWrapper loadingSurvey={loadingSurvey}>
            <Card sx={{ height: '100vh' }}>
                {error && <div>Error: {error}</div>}
                <Box>
                    <TabContext value={selectedTab}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="dynamic tabs example">
                                {tabs.map((tab, index) => (
                                    <Tab
                                        key={tab.value}
                                        label={
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                {tab.label}
                                                {index > 0 && (
                                                    <IconButton
                                                        size="small"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleRemoveTab(tab.value);
                                                        }}
                                                        sx={{ ml: 1 }}
                                                    >
                                                        <CloseIcon fontSize="small" />
                                                    </IconButton>
                                                )}
                                            </div>
                                        }
                                        value={tab.value}
                                    />
                                ))}
                            </TabList>
                        </Box>
                        {tabs.map((tab) => (
                            <TabPanel key={tab.value} value={tab.value}>
                                <Box>
                                    {tab.value === '1' ? (
                                        <>
                                            {tabs.length < 4 && (
                                                <Button variant="outlined" size="small" onClick={handleAddTab}>
                                                    Add Tab
                                                </Button>
                                            )}
                                            <Node />
                                        </>
                                    ) : (
                                        <>
                                            <TextField
                                                fullWidth
                                                multiline
                                                rows={4}
                                                value={tab.data?.content || ''}
                                                onChange={(e) => handleUpdateTabContent(tab.value, { content: e.target.value })}
                                                placeholder={`Enter content for ${tab.label}`}
                                            />
                                        </>
                                    )}
                                </Box>
                            </TabPanel>
                        ))}
                    </TabContext>
                </Box>
            </Card>
        </CanvasWrapper>
    );
}

export default SurveyFlow;
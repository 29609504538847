// EndPageQues.js
import React, { useState, useEffect, useRef } from 'react';
import 'ckeditor5/ckeditor5.css';
import { Box, IconButton } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { CKEditor } from '@ckeditor/ckeditor5-react'; 
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const EndPageQues = ({ question, setQuestion, questionUpdate, setQuestionUpdate}) => {
  const [editorData, setEditorData] = useState(question || 'Hello from CKEditor 5 multi-root!');
  const editorRef = useRef(null);
  // console.debug('Updated question:',questionUpdate)
  useEffect(() => {
    if (editorRef.current) {
      // editorRef.current.editor.setData(question);
    }
  }, [question]);

  const handleSend = () => {
    console.debug('Updated question:', editorRef);
    if (editorRef.current) {
      const content = editorRef.current.editor.getData();
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = content;
      const questionEdit = tempDiv.textContent || tempDiv.innerText || '';
      console.log('Updated question:', questionEdit);
      setQuestion(questionEdit)
      setQuestionUpdate(true)
      
     // Call the callback function to send the data back
    } else {
      console.error('Editor is not initialized.');
    }
  };

  return (
    <Box className="App" sx={{ display: 'flex' }}>
      <Box sx={{ flex: 1 }}>
        <CKEditor
          editor={ClassicEditor}
          data={editorData}
          onChange={(event, editor) => {
            const data = editor.getData();
            setEditorData(data);
          }}
          onReady={(editor) => {
            editorRef.current = { editor };
          }}
        />
      </Box>
      <Box sx={{ mx: 1 }}>
        <IconButton aria-label="send" onClick={handleSend}>
          <SendIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default EndPageQues;

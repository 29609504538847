import React, { useContext, useEffect, useState } from 'react';
import {
    Box,
    Typography,
    styled,
    Tooltip,
    tooltipClasses,
    TextField,
} from "./../../../lib/utils/AllImportsHelper";
import ImageIcon from "@mui/icons-material/Image";
import InfoIcon from "@mui/icons-material/Info";
import DesignColorPalette from './DesignColorPalette';
import PublishSettingContext from './PublishSettingContext';
import TextAlignmentPalette from './TextAlignmentPalette';


const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#347edd",
        color: "#ffffff",
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: "#347edd",
    },
}));



function DesignFooter(props) {
    const publishSettingContextData = useContext(PublishSettingContext);

    const [footerText, setFooterText] = useState("");
    const textChangeHandler = (e) => {
        // console.debug("DesignFooter : textChangeHandler : e : ", e.target.value);
        setFooterText(e.target.value);
        if (publishSettingContextData?.footerTextChangeHandler) {
            publishSettingContextData?.footerTextChangeHandler(e.target.value)
        }

    }
    useEffect(function () {
        if (publishSettingContextData?.footerText?.sectionProperty?.name) {
            setFooterText(publishSettingContextData.footerText.sectionProperty.name);
        }
    }, [publishSettingContextData?.footerText]);
    return (
        <Box >
            <Box sx={{ backgroundColor: "#ECEFF1" }}>
                <Box sx={{ p: 2, display: "flex" }}>
                    <Box>
                        <ImageIcon fontSize="small" />
                    </Box>
                    <Box sx={{ ml: 1 }}>
                        <Typography variant="body1">
                            Footer
                        </Typography>
                    </Box>

                </Box>
            </Box>
            <Box sx={{ p: 2 }}>

                <Box sx={{ my: 1 }}>
                    <Box>
                        <TextField
                            id="outlined-basic"
                            label="Footer Text"
                            variant="outlined"
                            value={footerText}
                            onChange={textChangeHandler}
                            fullWidth
                        />
                    </Box>
                </Box>
                <Box sx={{ my: 1 }}>
                    <Box sx={{ display: "flex" }}>
                        <Box>
                            <Typography variant="subtitle2" fontWeight="bold">
                                Background Color
                            </Typography>
                        </Box>
                        <Box sx={{ ml: 1 }}>
                            <LightTooltip
                                title="The text color will be automatically set based on the background color"
                                placement="top"
                                arrow
                            >
                                <InfoIcon fontSize="small" />
                            </LightTooltip>
                        </Box>
                    </Box>

                    <DesignColorPalette
                        itemData={publishSettingContextData?.footerBackgroundColor}
                        changeHandler={publishSettingContextData?.footerBackgroundColorChangeHandler}
                        defaultSelectedId={publishSettingContextData?.footerBackgroundColor?.filter(d => d?.activeStatus == "1")?.[0]?.id}
                    />

                    <Box sx={{ display: "flex" }}>
                        <Box>
                            <Typography variant="subtitle2" fontWeight="bold">
                                Text Color
                            </Typography>
                        </Box>
                        <Box sx={{ ml: 1 }}>
                            <LightTooltip
                                title="The text color will be automatically set based on the background color"
                                placement="top"
                                arrow
                            >
                                <InfoIcon fontSize="small" />
                            </LightTooltip>
                        </Box>
                    </Box>

                    <DesignColorPalette
                        itemData={publishSettingContextData?.footerTextColor}
                        changeHandler={publishSettingContextData?.footerTextColorChangeHandler}
                        defaultSelectedId={publishSettingContextData?.footerTextColor?.filter(d => d?.activeStatus == "1")?.[0]?.id}
                    />

                    <Box sx={{ display: "flex" }}>
                        <Box>
                            <Typography variant="subtitle2" fontWeight="bold">
                                Text Align
                            </Typography>
                        </Box>
                        <Box sx={{ ml: 1 }}>
                            <LightTooltip
                                title="The text color will be automatically set based on the background color"
                                placement="top"
                                arrow
                            >
                                <InfoIcon fontSize="small" />
                            </LightTooltip>
                        </Box>
                    </Box>

                    <TextAlignmentPalette
                        itemData={publishSettingContextData?.footerAlignment}
                        changeHandler={publishSettingContextData?.footerAlignmentChangeHandler}
                        defaultSelectedId={publishSettingContextData?.footerAlignment?.filter(d => d?.activeStatus == "1")?.[0]?.id}
                    />

                </Box>
            </Box>


        </Box>
    );
}

export default DesignFooter;
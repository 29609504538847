import React, { useState } from 'react'
import ClearIcon from '@mui/icons-material/Clear';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
// import DateRangeIcon from '@mui/icons-material/DateRange';
// import AttachEmailIcon from '@mui/icons-material/AttachEmail';
// import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
// import ChecklistIcon from '@mui/icons-material/Checklist';
import {
  Box, Switch, Grid, Button, TextField, Tab, Tabs, Typography, styled, useTheme, Drawer, Card, CssBaseline, IconButton, Avatar, InputBase, Menu,
  AppBar as MuiAppBar, Toolbar, Divider, ListItemIcon, ToggleButtonGroup, MenuItem, Paper,
  AccountCircle as AccountCircleIcon, Collections as CollectionsIcon, Undo as UndoIcon, Redo as RedoIcon, Remove as RemoveIcon, Add as AddIcon, Search as SearchIcon, Delete as DeleteIcon,
  Image as ImageIcon, DateRange as DateRangeIcon, AttachEmail as AttachEmailIcon, LocalPhone as LocalPhoneIcon, Checklist as ChecklistIcon, TextFields as TextFieldsIcon, ChangeHistoryTwoTone as ChangeHistoryTwoToneIcon, ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon, ContentCopy as ContentCopyIcon,
  Home as HomeIcon, Preview as PreviewIcon, Download as DownloadIcon, Circle as CircleIcon, Rectangle as RectangleIcon, EggTwoTone, Star as StarIcon, Logout, Settings, FormatColorFill
} from '../../../lib/utils/AllImportsHelper';
// import QuestionTypeIcon from './questionSettings/questionType/QuestionTypeIcon';
import QuestionTypeItem from '../addQuestion/QuestionTypeItem';
// import QuestionTypeItem from '../';
// import QuestionTypeItemContainer from './addQuestion/QuestionTypeItemContainer';


function QuestionTypeItemContainer({handleItemClick,...props}) {

    // Calculate the split index
    const splitIndex = Math.ceil(props?.questionType?.length / 2);

    // Split the items array
    const firstGridItems = props?.questionType?.slice(0, splitIndex);
    const secondGridItems = props?.questionType?.slice(splitIndex);


    return (
        <Grid container spacing={2}>
            {/* First Grid */}
            <Grid item xs={12} md={5}>
                <Grid container spacing={2}>
                    {firstGridItems.map((item, index) => (
                        <Grid item xs={12} key={index}>
                            <QuestionTypeItem
                                questionType={item}
                                handleItemClick={handleItemClick}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            {/* Second Grid */}
            <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                    {secondGridItems.map((item, index) => (
                        <Grid item xs={12} key={index}>
                            <QuestionTypeItem
                                questionType={item}
                                handleItemClick={handleItemClick}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Grid>


        </Grid>
    )
}
export default QuestionTypeItemContainer;
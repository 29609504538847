import React, { useEffect, useState } from 'react';
import { CanvasLayout } from '../../lib';
import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';
import CastConnectedIcon from '@mui/icons-material/CastConnected';
import ShareIcon from '@mui/icons-material/Share';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import { AccountTree, ChangeHistoryTwoTone } from '@mui/icons-material';
import ImageIcon from '@mui/icons-material/Image';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import routes from '../../routesComponent/Routes';
import { Box } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateSurvey } from '../../redux/slice/surveyQuetions/surveyQuestionSlice';
import ApiService from '../../ApiService';
import DynamicSnackbar from '../../redux/snackbar/Snackbar';
import SurveyErrorPage from '../surveyEndUser/SurveyErrorPage';
import { setUserInfo } from '../../redux/userInfoStore/userSlice';
import { modeBasedDataProcessor } from '../../lib/utils/developmentMode';

function CanvasWrapper(props) {
    const { id } = useParams();
    const surveyQuestion = useSelector(state => state.surveyQuestion);
    const dispatch = useDispatch();
    const location = useLocation();
    const [wrapperItemLabel, setWrapperItemLabel] = useState("");
    const [wrapperItemPath, setWrapperItemPath] = useState("");
    const [errorPage, setErrorPage] = useState('')


    useEffect(() => {
        ApiService.get('userInfo').then(res => {
            let response = res.data.data;
            if (response) {
                dispatch(setUserInfo({
                    ...response,
                }));
            }
        });
    }, [])


    useEffect(function () {
        const savedLabel = localStorage.getItem('lastVisitedLabel') || 'CREATE';
        const savedPath = localStorage.getItem('lastVisitedPath') || routes.surveyCreateAssessment;

        setWrapperItemLabel(savedLabel);
        setWrapperItemPath(savedPath);
        let pathSegments = location.pathname.split('/');
        let section = "/" + pathSegments[1] + "/" + pathSegments[2] + "/";
        let questionTypeListDataParams = {};
        if (section == routes.surveyCreateAssessment || section == routes.surveyEditAssessment) {
            questionTypeListDataParams.assessment = true;
        }
        switch (section) {
            case routes.surveyCreateAssessment:
                setWrapperItemLabel('CREATE');
                setWrapperItemPath(routes.surveyCreateAssessment);
                break;
            case routes.surveyCreate:
                setWrapperItemLabel('CREATE');
                setWrapperItemPath(routes.surveyCreate)
                break;

            case routes.surveyEdit:
                setWrapperItemLabel('EDIT');
                setWrapperItemPath(routes.surveyEdit)
                break;

            case routes.surveyEditAssessment:
                setWrapperItemLabel('EDIT');
                setWrapperItemPath(routes.surveyEditAssessment)
                break;

            // default:
            //     setWrapperItemLabel('Create');
            //     setWrapperItemPath(routes.surveyCreateAssessment);
            //     break;
        }

    }, [location.pathname]);

    useEffect(() => {
        // Save the current label and path to local storage
        localStorage.setItem('lastVisitedLabel', wrapperItemLabel);
        localStorage.setItem('lastVisitedPath', wrapperItemPath);
    }, [wrapperItemLabel, wrapperItemPath]);

    const tabs = [
        // { name: "Create", value: 0, icon: <EditIcon />, path: routes.surveyCreate + id },
        // { name: props?.wrapperItemLabel, value: 0, icon: <EditIcon />, path: props?.wrapperItemPath + id },
        { name: wrapperItemLabel, value: 0, icon: <EditIcon />, path: wrapperItemPath + id },
        { name: "SETTINGS", value: 1, icon: <SettingsIcon />, path: routes.surveySetting + id },
        ...modeBasedDataProcessor([{ name: "CONNECT", value: 2, icon: <CastConnectedIcon />, path: routes.surveyConnect + id }]),
        // { name: "CONNECT", value: 2, icon: <CastConnectedIcon />, path: routes.surveyConnect + id },
        { name: "SHARE", value: 3, icon: <ShareIcon />, path: routes.surveyShare + id },
        { name: "RESULTS", value: 4, icon: <TextSnippetIcon />, path: routes.surveyResult + id },
        { name: "LOGIC", value: 4, icon: <AccountTree />, path: routes.surveyFlow + id },
    ]

    // Need to modify this function so that it can be used for all types of survey update   like question Type updatye question types setting update 
    const onSurveyTitleChangehandler = (value) => {
        console.debug("a2", value);
        if (surveyQuestion)
            dispatch(updateSurvey({ ...surveyQuestion, questionGroupName: value }))
        console.debug('a2', updateSurvey({ ...surveyQuestion, questionGroupName: value }))
        let params = {}
        params.questionGroupName = value

        ApiService.put('survey', params, id).then(response => {
            console.debug("a2", response)
        }).catch(e => {
            setErrorPage(e?.status == 503)
            console.debug("a2", e?.status);
        })
    };


    return (
        <Box>
            {/* {
                (surveyQuestion.questions && surveyQuestion.questions.length == 0 && surveyQuestion.questions.length == '') ? <SurveyErrorPage /> : */}
            <CanvasLayout tabs={tabs} {...props}
                canvasHeaderTitle={surveyQuestion.survey?.questionGroupName}
                canvasHeaderTitleChangeHeader={onSurveyTitleChangehandler}
            >
                <Box sx={{ mx: 2, width: '100% !important' }}>
                    {/* {console.log('a2', props.children)} */}
                    {props.children}
                </Box>
                <DynamicSnackbar />
            </CanvasLayout>

            {/* } */}
        </Box>
    );
}

export default CanvasWrapper;
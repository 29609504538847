import React, { useState } from 'react';
import {
    Box, Typography, IconButton, Button, Radio, RadioGroup, FormControlLabel, FormControl,
    FormLabel, Checkbox, InputLabel, OutlinedInput, FormGroup, InputAdornment, TextField
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ApiService from '../../ApiService';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { openSnackbar } from '../../redux/snackbar/snackbarSlice';
import { setUserInfo } from '../../redux/userInfoStore/userSlice';
import { updateSurvey } from '../../redux/slice/surveyQuetions/surveyQuestionSlice';

const ScheduleSurveyDrawer = ({ onClose }) => {
    const [startDateValue, setStartDateValue] = useState(null);
    const [endDateValue, setEndDateValue] = useState(null);
    const [startDateError, setStartDateError] = useState(false);
    const [endDateError, setEndDateError] = useState(false);
    const [value, setValue] = useState('Entire account');
    const [showPasswordField, setShowPasswordField] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showDate, setShowDate] = useState(false);
    const [reportPassword, setReportPassword] = useState('');
    const [checkboxConfirmationMail, setCheckboxConfirmationMail] = useState(false);
    const [checkboxReportMail, setCheckboxReportMail] = useState(false);
    let { id } = useParams();
    const surveyQuestion = useSelector(state => state.surveyQuestion);
    const dispatch = useDispatch();
    const userInfo = useSelector((state) => state.user);

    const handleChange = (event) => {
        setValue(event.target.value);
        setShowDate(event.target.value === 'Specific period');
    };

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleCheckboxChange = (event) => {
        setShowPasswordField(event.target.checked);
        if (!event.target.checked) {
            setReportPassword('');
        }
    };

    const handlePasswordChange = (event) => {
        setReportPassword(event.target.value);
    };

    function getFormattedDate(date) {
        if (!date) return '';
        const dateObj = new Date(date);
        const year = dateObj.getFullYear();
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const day = String(dateObj.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const validateDates = () => {
        setStartDateError(!startDateValue);
        setEndDateError(!endDateValue);
    };

    const handleSubmitNewReport = () => {
        validateDates();
        if ((value === 'Specific period' && startDateValue && endDateValue) || value === 'Entire account') {
            const formData = {
                userId: userInfo.userListName.userId,
                email: userInfo.userListName.email,
                surveyId: id,
                sendConfirmationMail: checkboxConfirmationMail,
                sendReportMail: checkboxReportMail,
                ...(value === 'Specific period' && {
                    sourceDate: getFormattedDate(startDateValue),
                    destinationDate: getFormattedDate(endDateValue),
                }),
                ...(showPasswordField && { reportPassword }),
            };
            ApiService.post('scheduleSurvey', formData)
                .then(r => {
                    if (r?.data) {
                        dispatch(openSnackbar({ dataLoad: true, message: 'Submitted successfully', severity: 'info' }));
                    }
                    onClose();
                })
                .catch(e => {
                    onClose();
                });
        }
    };

    const handleCheckboxConfirmationMail = (event) => {
        setCheckboxConfirmationMail(event.target.checked);
    };

    const handleCheckboxReportMail = (event) => {
        setCheckboxReportMail(event.target.checked);
    };

    const isSubmitEnabled = () => {
        if (value === 'Entire account') {
            if (showPasswordField) {
                return !!reportPassword;
            }
            return true;
        }
        if (value === 'Specific period' && startDateValue && endDateValue) {
            if (showPasswordField) {
                return !!reportPassword;
            }
            return true;
        }
        return false;
    };

    return (
        <Box sx={{ width: '30rem' }}>
            <Box sx={{ backgroundColor: '#E1EDFC', p: 1, display: 'flex', alignItems: 'center' }}>
                <Box sx={{ flexGrow: 1, ml: 1 }}>
                    <Typography variant="subtitle1" fontWeight="bold">
                        Schedule new report
                    </Typography>
                </Box>
                <IconButton onClick={onClose} sx={{ ml: 1 }}>
                    <CloseIcon />
                </IconButton>
            </Box>
            <Box sx={{ height: 'calc(100vh - 131px)', overflow: 'auto' }}>
                <Box sx={{ p: 2 }}>
                    <FormControl>
                        <FormLabel id="demo-controlled-radio-buttons-group">
                            <Typography variant="subtitle2" fontWeight="bold" sx={{ color: 'black' }}>
                                Export Mail from
                            </Typography>
                        </FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={value}
                            onChange={handleChange}
                        >
                            <Box sx={{ display: 'flex' }}>
                                <FormControlLabel value="Entire account" control={<Radio />} label="Entire account" />
                                <FormControlLabel value="Specific period" control={<Radio />} label="Specific period" />
                            </Box>
                        </RadioGroup>
                    </FormControl>
                    {showDate && (
                        <Box sx={{ display: 'flex', mt: 2 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker
                                        label="Start Date"
                                        value={startDateValue}
                                        onChange={(newValue) => {
                                            setStartDateValue(newValue);
                                            setStartDateError(!newValue);
                                        }}
                                        renderInput={(params) => (
                                            <>
                                                <TextField {...params} error={startDateError} />
                                                {startDateError && (
                                                    <Typography color="error" variant="caption">
                                                        Choose a start date
                                                    </Typography>
                                                )}
                                            </>
                                        )}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                            <Box sx={{ ml: 1 }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker
                                            label="End Date"
                                            value={endDateValue}
                                            onChange={(newValue) => {
                                                setEndDateValue(newValue);
                                                setEndDateError(!newValue);
                                            }}
                                            renderInput={(params) => (
                                                <>
                                                    <TextField {...params} error={endDateError} />
                                                    {endDateError && (
                                                        <Typography color="error" variant="caption">
                                                            Choose an end date
                                                        </Typography>
                                                    )}
                                                </>
                                            )}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Box>
                        </Box>
                    )}
                    <Box sx={{ mt: 2 }}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox onChange={handleCheckboxChange} />} label="Export as password protected file" />
                        </FormGroup>
                    </Box>
                    {showPasswordField && (
                        <Box sx={{ mt: 2 }}>
                            <FormControl sx={{ width: '100%' }} variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    size="medium"
                                    type={showPassword ? 'text' : 'password'}
                                    value={reportPassword}
                                    onChange={handlePasswordChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                />
                            </FormControl>
                        </Box>
                    )}
                </Box>
                <Box sx={{ p: 2 }}>
                    <Typography variant="subtitle1" fontWeight="bold">
                        Notification settings
                    </Typography>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={checkboxConfirmationMail} onChange={handleCheckboxConfirmationMail} />} label="You will be notified once the export is successfully completed." />
                        <FormControlLabel control={<Checkbox checked={checkboxReportMail} onChange={handleCheckboxReportMail} />} label="User will receive notification once the exports for their accounts are successfully completed." />
                    </FormGroup>
                </Box>
            </Box>
            <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                    variant="contained"
                    onClick={handleSubmitNewReport}
                    disabled={!isSubmitEnabled()}
                >
                    Submit
                </Button>
            </Box>
        </Box>
    );
};

export default ScheduleSurveyDrawer;

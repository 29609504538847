import React, { useEffect, useState } from 'react';
import CanvasWrapper from '../../component/layoutWrapper/CanvasWrapper';
import ApiService from '../../ApiService';
import { CanvasSubLayout, Table } from '../../lib';
import TableToolbar from '../../component/tableToolbar/TableToolbar';
import { Box } from '@mui/material';
import { Security } from '@mui/icons-material';
import SettingList from '../../component/surveySettings/SettingList';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { updateSurvey, addArrayOfQuestionsInSurveyQuestionList, addQuestionInSurveyQuestionList, } from '../../redux/slice/surveyQuetions/surveyQuestionSlice';
import SurveyResponse from '../../component/surveyResult/SurveyResponse';
import SurveyExport from '../../component/surveyResult/SurveyExport';
import FeedIcon from '@mui/icons-material/Feed';
import SpeakerIcon from '@mui/icons-material/Speaker';

function SurveyResult(props) {
    let { id } = useParams();
    const dispatch = useDispatch();
    const surveyQuestion = useSelector(state => state.surveyQuestion);

    const itemsList = [
        {
            id: "1", name: "Insight", icon: <FeedIcon />, component: <h1>Insight</h1>
        },
        {
            id: "2", name: "Responses", icon: <SpeakerIcon />,
            component: <SurveyResponse />
        },
        {
            id: "3", name: "Schedule Survey", icon: <Security />,
            component: <SurveyExport />
        },
    ];
    const [selectedItem, setSelectedItem] = useState(itemsList[1]);


    const selectedItemChangeHandlder = (e, item) => {
        if (item) {
            setSelectedItem(item);
        }
    }


    return (
        <Box sx={{ my: 2 }}>
            <CanvasWrapper>
                <CanvasSubLayout
                    drawerComp={
                        <SettingList
                            itemsList={itemsList}
                            selectedItem={selectedItem}
                            selectedItemChangeHandlder={selectedItemChangeHandlder}
                        />
                    }

                    drawerHeaderTitle={"Menu"}
                >
                    {selectedItem?.component}
                </CanvasSubLayout>



            </CanvasWrapper>
        </Box>

    );
}

export default SurveyResult;